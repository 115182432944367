import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  CoreDecrementHttpRequestsInProgressCount,
  CoreIncrementHttpRequestsInProgressCount,
  CoreSetIsShowingLoading
} from '../core.actions';
import { CoreState } from '../core.state';

@Injectable({ providedIn: 'root' })
export class LoadingBarHandler {
  constructor(
    private actions$: Actions,
    private store: Store,
    private loadingBar: LoadingBarService
  ) {
    this.actions$
      .pipe(
        ofActionSuccessful(
          CoreDecrementHttpRequestsInProgressCount,
          CoreIncrementHttpRequestsInProgressCount
        ),
        mergeMap(_ => {
          const httpRequestsInProgressCount = this.store.selectSnapshot(
            CoreState.httpRequestsInProgressCount
          );

          const isShowingLoading = this.store.selectSnapshot(
            CoreState.isShowingLoading
          );

          if (httpRequestsInProgressCount < 1 && isShowingLoading) {
            this.loadingBar.complete();
            return this.store.dispatch(new CoreSetIsShowingLoading(false));
          }

          if (httpRequestsInProgressCount === 1 && !isShowingLoading) {
            this.loadingBar.start();
            return this.store.dispatch(new CoreSetIsShowingLoading(true));
          }

          return of(null);
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }
}
