import { Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, skip, tap } from 'rxjs/operators';
import { OrdersUpdateList } from 'src/app/features/orders/state/orders.actions';
import { ProductsSetList } from 'src/app/features/products/state/products.actions';
import { OrdersUpdatesGQL, ProductsUpdatesGQL } from 'src/app/graphql/graphql';
import { isKnownJwtError } from 'src/app/shared/helpers/is-known-jwt-error';
import { OnlineCheckService } from 'src/app/shared/services/online-check.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "../../graphql/graphql";
import * as i3 from "../../shared/services/online-check.service";
var GraphqlSubscriptionsService = /** @class */ (function () {
    function GraphqlSubscriptionsService(store, ordersUpdatesGQL, productsUpdatesGQL, onlineCheckService) {
        this.store = store;
        this.ordersUpdatesGQL = ordersUpdatesGQL;
        this.productsUpdatesGQL = productsUpdatesGQL;
        this.onlineCheckService = onlineCheckService;
    }
    GraphqlSubscriptionsService.prototype.init = function (customerCode) {
        // In case is a reinitialization (probably on customer change)
        this.skipNextOrderUpdateBecauseItWasByUser = false;
        // tslint:disable-next-line: no-unused-expression
        this.currentOrdersSubscription &&
            this.currentOrdersSubscription.unsubscribe();
        // tslint:disable-next-line: no-unused-expression
        this.currentProductsSubscription &&
            this.currentProductsSubscription.unsubscribe();
        this.subscribeToOrdersUpdates(customerCode);
        this.subscribeToProductsUpdates(customerCode);
    };
    GraphqlSubscriptionsService.prototype.subscribeToOrdersUpdates = function (customerCode) {
        var _this = this;
        this.currentOrdersSubscription = this.ordersUpdatesGQL
            .subscribe({ customerCode: customerCode })
            .pipe(skip(1), // First emission is the same data present in the core query
        tap(function (_a) {
            var data = _a.data;
            if (!_this.skipNextOrderUpdateBecauseItWasByUser)
                _this.store.dispatch(new OrdersUpdateList(data.view_orders_headers));
            _this.skipNextOrderUpdateBecauseItWasByUser = false;
        }), catchError(function (error) {
            _this.subscribeToOrdersUpdates(customerCode);
            return _this.refreshPageIfIsKnownJwtError(error);
        }))
            .subscribe();
        // No need for unsubscribe, as it is application wide,
        // we are saving the reference to the subscription to eventually unsubscribe
        // on reinitialization
    };
    GraphqlSubscriptionsService.prototype.subscribeToProductsUpdates = function (customerCode) {
        var _this = this;
        this.currentProductsSubscription = this.productsUpdatesGQL
            .subscribe({ customerCode: customerCode })
            .pipe(skip(1), // First emission is the same data present in the core query
        tap(function (_a) {
            var data = _a.data;
            return _this.store.dispatch(new ProductsSetList(data.products));
        }), catchError(function (error) {
            _this.subscribeToProductsUpdates(customerCode);
            return _this.refreshPageIfIsKnownJwtError(error);
        }))
            .subscribe();
        // No need for unsubscribe, as it is application wide,
        // we are saving the reference to the subscription to eventually unsubscribe
        // on reinitialization
    };
    GraphqlSubscriptionsService.prototype.refreshPageIfIsKnownJwtError = function (error) {
        // Very unlucky scenario, when a user went offline, the token expired
        // and the subscription isn't able to use the new one because of a race condition
        // We will just reload the page in this case
        if (isKnownJwtError(error))
            this.onlineCheckService
                .getOnlineNotifier$()
                .toPromise()
                .then(function (_) { return window.location.reload(); });
        return throwError(error);
    };
    GraphqlSubscriptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GraphqlSubscriptionsService_Factory() { return new GraphqlSubscriptionsService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.OrdersUpdatesGQL), i0.ɵɵinject(i2.ProductsUpdatesGQL), i0.ɵɵinject(i3.OnlineCheckService)); }, token: GraphqlSubscriptionsService, providedIn: "root" });
    return GraphqlSubscriptionsService;
}());
export { GraphqlSubscriptionsService };
