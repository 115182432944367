import * as i0 from "@angular/core";
var ProductsListFilterService = /** @class */ (function () {
    function ProductsListFilterService() {
    }
    ProductsListFilterService.prototype.filter = function (_a) {
        var list = _a.list, _b = _a.codeKey, codeKey = _b === void 0 ? 'code' : _b, _c = _a.descriptionKey, descriptionKey = _c === void 0 ? 'description' : _c, _d = _a.groupCodeKey, groupCodeKey = _d === void 0 ? 'code_group' : _d, groupsCodes = _a.groupsCodes, partialProductCode = _a.partialProductCode, partialDescription = _a.partialDescription;
        var listFilteredByGroupsCodes = this.getListFilteredByGroupsCodes(list, groupsCodes, groupCodeKey);
        var listFilteredByGroupCodesAndProductCode = this.getListFilteredByProductCode(listFilteredByGroupsCodes, partialProductCode, codeKey);
        var listFilteredByGroupCodesAndProductCodeAndDescription = this.getListFilteredByDescription(listFilteredByGroupCodesAndProductCode, partialDescription, descriptionKey);
        return listFilteredByGroupCodesAndProductCodeAndDescription;
    };
    ProductsListFilterService.prototype.getCalculatedFilters = function (filters, originalFilteringGroupsCodes, originalFilteringPartialProductCode, originalFilteringPartialDescription) {
        var filteringGroupsCodes = filters.filteringGroupsCodes, filteringPartialProductCode = filters.filteringPartialProductCode, filteringPartialDescription = filters.filteringPartialDescription, forceFilteringPartialProductCodeValue = filters.forceFilteringPartialProductCodeValue, forceFilteringPartialDescriptionValue = filters.forceFilteringPartialDescriptionValue;
        var groupsCodes = filteringGroupsCodes || originalFilteringGroupsCodes;
        var partialProductCode = forceFilteringPartialProductCodeValue
            ? filteringPartialProductCode
            : filteringPartialProductCode || originalFilteringPartialProductCode;
        var partialDescription = forceFilteringPartialDescriptionValue
            ? filteringPartialDescription
            : filteringPartialDescription || originalFilteringPartialDescription;
        return {
            filteringGroupsCodes: groupsCodes,
            filteringPartialProductCode: partialProductCode,
            filteringPartialDescription: partialDescription
        };
    };
    ProductsListFilterService.prototype.getListFilteredByGroupsCodes = function (list, codes, groupCodeKey) {
        return list.filter(function (product) {
            return !codes || !codes.length || codes.includes(product[groupCodeKey]);
        });
    };
    ProductsListFilterService.prototype.getListFilteredByProductCode = function (list, partialCode, codeKey) {
        return list.filter(function (product) {
            return product[codeKey].toString().startsWith((partialCode || '').toString());
        });
    };
    ProductsListFilterService.prototype.getListFilteredByDescription = function (list, partialDescription, descriptionKey) {
        return list.filter(function (product) {
            return (product[descriptionKey] || '')
                .toLowerCase()
                .includes((partialDescription || '').toLowerCase());
        });
    };
    ProductsListFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductsListFilterService_Factory() { return new ProductsListFilterService(); }, token: ProductsListFilterService, providedIn: "root" });
    return ProductsListFilterService;
}());
export { ProductsListFilterService };
