import { HttpClient } from '@angular/common/http';
import { interval, of } from 'rxjs';
import { catchError, concatMap, delay, map, takeWhile, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OnlineCheckService = /** @class */ (function () {
    function OnlineCheckService(httpClient) {
        this.httpClient = httpClient;
    }
    OnlineCheckService.prototype.getOnlineNotifier$ = function () {
        var onlineCheckHttpRequest$ = this.httpClient
            .get("/assets/online-check.json?" + Date.now())
            .pipe(map(function (_) { return true; }), catchError(function (_) { return of(false); }));
        var delayValue = 50;
        return interval(50).pipe(
        // Every call gets an increasing (to a max of 1800 ms) flat delay + a random small delay
        concatMap(function (i) { return of(i).pipe(delay(delayValue + Math.random() * 100)); }), tap(function (_) { return (delayValue = Math.min(delayValue * 2, 1800)); }), concatMap(function (_) { return onlineCheckHttpRequest$; }), takeWhile(function (succeed) { return !succeed; }));
    };
    OnlineCheckService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnlineCheckService_Factory() { return new OnlineCheckService(i0.ɵɵinject(i1.HttpClient)); }, token: OnlineCheckService, providedIn: "root" });
    return OnlineCheckService;
}());
export { OnlineCheckService };
