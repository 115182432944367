import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { Observable, Subscription } from 'rxjs';
import { CartState } from 'src/app/features/cart/state/cart.state';
import { OrdersUpdateNotification } from 'src/app/features/orders/state/orders.actions';
import { OrdersState } from 'src/app/features/orders/state/orders.state';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { TranslateManagerService } from 'src/app/shared/services/translate-manager.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { CoreChangeCustomer } from '../../state/core.actions';
import { CoreState } from '../../state/core.state';
import { NotificationHandler } from '../../state/handlers/notification.handler';
import { CustomerModel } from '../../types/customer.model';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.less']
})
export class MainContainerComponent
  implements OnInit, AfterViewInit, OnDestroy {
  logoUrl = environment.storageConfig.commonAssetsUrl + 'frascheri-400.png';

  minContentHeight: number;

  @Select(CoreState.isCustomerChangeInProgress)
  isCustomerChangeInProgress$: Observable<boolean>;

  @Select(CoreState.userEmail)
  userEmail$: Observable<string>;

  @Select(CoreState.currentCustomer)
  currentCustomer$: Observable<CustomerModel>;

  @Select(CoreState.otherAvailableCustomers)
  otherAvailableCustomers$: Observable<CustomerModel[]>;

  @Select(CartState.productsCount)
  productsCount$: Observable<number>;

  @Select(OrdersState.ordersCount)
  ordersCount$: Observable<number>;

  @ViewChild('ordersUpdateNotificationTemplate', { static: true })
  ordersUpdateNotificationTemplate: TemplateRef<any>;

  private isMobile = isMobileCheck();

  private subscriptions: Subscription[] = [];

  constructor(
    public authenticationService: AuthenticationService,
    public notificationHandler: NotificationHandler,
    public translateManagerService: TranslateManagerService,
    private gtag: Gtag,
    private store: Store
  ) {
    this.authenticationService.scheduleRenewal();
  }

  ngOnInit(): void {
    this.calculateContentMinHeight();
  }

  ngAfterViewInit(): void {
    this.registerOrderUpdateNotificationHandling();

    this.gtag.event('accesso', {
      event_category: 'traces',
      event_label: `Login customer: ${this.store.selectSnapshot(
        CoreState.currentCustomerCode
      )}`
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe);
  }

  @HostListener('window:resize')
  calculateContentMinHeight() {
    this.minContentHeight = Math.max(
      this.isMobile ? screen.availHeight - 124 : window.innerHeight - 124,
      613
    );
  }

  handleChangeLanguageClicked() {
    // No need for unsubscribe, it completes after one emission
    this.translateManagerService.changeLanguage().subscribe();
  }

  handleChangeCustomerClicked(customerCode: string) {
    this.store.dispatch(new CoreChangeCustomer(customerCode));
  }

  private registerOrderUpdateNotificationHandling(): void {
    this.subscriptions.push(
      this.translateManagerService.translateService
        .stream('orders_update_notification_success_title')
        .subscribe(title => {
          this.notificationHandler.notifications.find(
            notification => notification.action === OrdersUpdateNotification
          ).successMessage = {
            title,
            template: this.ordersUpdateNotificationTemplate,
            isInfiniteDuration: true
          };
        })
    );
  }
}
