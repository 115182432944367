import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { merge, Observable, Subject, Subscription, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  delayWhen,
  distinctUntilChanged,
  first,
  map,
  skip,
} from 'rxjs/operators';
import { OrdersNewOrderFromCart } from 'src/app/features/orders/state/orders.actions';
import { OrdersState } from 'src/app/features/orders/state/orders.state';
import { ProductsState } from 'src/app/features/products/state/products.state';
import { ProductGroupModel } from 'src/app/features/products/types/product-group.model';
import { ProductOverviewModel } from 'src/app/features/products/types/product-overview.model';
import { ProductQuantityModel } from 'src/app/features/products/types/product-quantity.model';
import { ProductsQuantitiesModel } from 'src/app/features/products/types/products-quantities.model';
import { TranslateManagerService } from 'src/app/shared/services/translate-manager.service';
import { environment } from 'src/environments/environment';
import {
  CartChangeCustomerOrderNumber,
  CartChangeDateFor,
  CartChangeFilters,
  CartChangeNote,
  CartClearFilters,
  CartClearProducts,
  CartRemoveProduct,
  CartSetOrderCourtesyModalOpening,
  CartSetOrderSubmitModalOpening,
  CartSetProductsQuantities,
} from '../../state/cart.actions';
import { CartState } from '../../state/cart.state';
import { CartProductQuantitiesTotalModel } from '../../types/cart-product-quantities-total.model';
import { ProductsBeingAddedCodesMap } from '../../types/products-being-added-codes.map';

@Component({
  selector: 'app-cart-container',
  templateUrl: './cart-container.component.html',
  styleUrls: ['./cart-container.component.less'],
})
export class CartContainerComponent implements OnInit, OnDestroy {
  imageBaseUrl = environment.storageConfig.productsImagesMediumUrl;

  @Select(ProductsState.groups)
  groups$: Observable<ProductGroupModel[]>;

  @Select(CartState.listFiltered)
  listFiltered$: Observable<ProductOverviewModel[]>;

  @Select(CartState.quantities)
  quantities$: Observable<ProductsQuantitiesModel>;

  @Select(CartState.productQuantitiesTotal)
  productQuantitiesTotal$: Observable<CartProductQuantitiesTotalModel>;

  @Select(CartState.combinedTotal)
  combinedTotal$: Observable<number>;

  @Select(CartState.filteringGroupsCodes)
  filteringGroupsCodes$: Observable<string[]>;

  @Select(CartState.filteringPartialProductCode)
  filteringPartialProductCode$: Observable<number>;

  @Select(CartState.filteringPartialDescription)
  filteringPartialDescription$: Observable<string>;

  @Select(CartState.dateFor)
  dateFor$: Observable<Date>;

  @Select(CartState.note)
  note$: Observable<string>;

  @Select(CartState.customerOrderNumber)
  customerOrderNumber$: Observable<string>;

  @Select(CartState.productsCount)
  productsCount$: Observable<number>;

  @Select(CartState.isInCartRowsHttpProcessing)
  isInCartRowsHttpProcessing$: Observable<boolean>;
  actionsDisabled$: Observable<boolean>;

  @Select(CartState.productsBeingAddedCodes)
  productsBeingAddedCodes$: Observable<ProductsBeingAddedCodesMap>;
  addProductDisabledCodes$: Observable<ProductsBeingAddedCodesMap>;

  @Select(CartState.isOrderSubmitModalOpen)
  isOrderSubmitModalOpen$: Observable<boolean>;

  @Select(CartState.isOrderCourtesyModalOpen)
  isOrderCourtesyModalOpen$: Observable<boolean>;

  @Select(OrdersState.isInHttpProcessing)
  isOrderLoading$: Observable<boolean>;

  private noteSubject = new Subject<string>();
  private customerOrderNumberSubject = new Subject<string>();
  private productQuantitiesSubject = new Subject<ProductsQuantitiesModel>();

  private accumulatedQuantityChanges: ProductsQuantitiesModel = {};

  private subscriptions: Subscription[] = [];

  private readonly subscribeToProductQuantitiesSubjectDebounceTime = 1200;

  constructor(
    private store: Store,
    public translateManageService: TranslateManagerService
  ) {}

  ngOnInit(): void {
    // Other than the http request itself,
    // since we have a debounce time before the http request start
    // and we want the order confirm to be disabled meanwhile
    // we add a value of true meanwhile
    this.actionsDisabled$ = merge(
      this.isInCartRowsHttpProcessing$.pipe(debounceTime(100)),
      this.productQuantitiesSubject.pipe(map((_) => true))
    );

    // Take the first immediatly otherwise we don't
    // have an inital state to render.
    // Debounce after that
    this.addProductDisabledCodes$ = merge(
      this.productsBeingAddedCodes$.pipe(first()),
      this.productsBeingAddedCodes$.pipe(
        skip(1),
        debounceTime(100)
      )
    );

    this.subscribeToNoteSubject();
    this.subscribeToCustomerOrderNumberSubject();
    this.subscribeToProductQuantitiesSubject();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.store.dispatch(new CartClearFilters());
  }

  handleQuantityChange({ productCode, quantity }: ProductQuantityModel): void {
    this.accumulatedQuantityChanges = {
      ...this.accumulatedQuantityChanges,
      [productCode]: quantity,
    };

    this.productQuantitiesSubject.next(this.accumulatedQuantityChanges);
  }

  handleProductRemove(productCode: number): void {
    this.store.dispatch(new CartRemoveProduct(productCode));
  }

  handleDateForChange(dateFor: Date): void {
    this.store.dispatch(new CartChangeDateFor(dateFor));
  }

  handleNoteChange(note: string): void {
    this.noteSubject.next(note);
  }

  handleCustomerOrderNumberChange(customerOrderNumber: string): void {
    this.customerOrderNumberSubject.next(customerOrderNumber);
  }

  handleOrderSubmit(): void {
    this.store.dispatch(new OrdersNewOrderFromCart());
  }

  handleCartClearSubmit(): void {
    this.store.dispatch(new CartClearProducts());
  }

  handleOrderSubmitModalOpeningRequest(value: boolean): void {
    this.store.dispatch(new CartSetOrderSubmitModalOpening(value));
  }

  handleOrderCourtesyModalOpeningRequest(value: boolean): void {
    this.store.dispatch(new CartSetOrderCourtesyModalOpening(value));
    if (value === false) this.store.dispatch(new Navigate(['/orders']));
  }

  handleGoToProductsListRequest(): void {
    this.store.dispatch(new CartSetOrderCourtesyModalOpening(false));
    this.store.dispatch(new Navigate(['/products']));
  }

  handleGoToOrdersRequest(): void {
    this.store.dispatch(new CartSetOrderCourtesyModalOpening(false));
    this.store.dispatch(new Navigate(['/orders']));
  }

  handleGroupsFilterChange(filteringGroupsCodes: string[]): void {
    this.store.dispatch(new CartChangeFilters({ filteringGroupsCodes }));
  }

  handleProductCodeFilterChange(filteringPartialProductCode?: number): void {
    this.store.dispatch(
      new CartChangeFilters({
        filteringPartialProductCode,
        forceFilteringPartialProductCodeValue: true,
      })
    );
  }

  handleDescriptionFilterChange(filteringPartialDescription?: string): void {
    this.store.dispatch(
      new CartChangeFilters({
        filteringPartialDescription,
        forceFilteringPartialDescriptionValue: true,
      })
    );
  }

  private subscribeToProductQuantitiesSubject(): void {
    this.subscriptions.push(
      this.productQuantitiesSubject
        .pipe(
          debounceTime(this.subscribeToProductQuantitiesSubjectDebounceTime),
          // Can't distinct anymore, as we need the isInCartRowsHttpProcessing$
          // to emit to have the submit order enabled back
          // distinctUntilChanged(this.productQuantitiesAreEqual()),
          delayWhen((productQuantities) => {
            this.accumulatedQuantityChanges = {};
            return this.store.dispatch(
              new CartSetProductsQuantities(productQuantities)
            );
          }),
          catchError((error) => {
            this.subscribeToProductQuantitiesSubject();
            return throwError(error);
          })
        )
        .subscribe()
    );
  }

  private subscribeToNoteSubject(): void {
    this.subscriptions.push(
      this.noteSubject
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          delayWhen((note) => this.store.dispatch(new CartChangeNote(note))),
          catchError((error) => {
            this.subscribeToNoteSubject();
            return throwError(error);
          })
        )
        .subscribe()
    );
  }

  private subscribeToCustomerOrderNumberSubject(): void {
    this.subscriptions.push(
      this.customerOrderNumberSubject
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          delayWhen((customerOrderNumber) =>
            this.store.dispatch(
              new CartChangeCustomerOrderNumber(customerOrderNumber)
            )
          ),
          catchError((error) => {
            this.subscribeToCustomerOrderNumberSubject();
            return throwError(error);
          })
        )
        .subscribe()
    );
  }

  private productQuantitiesAreEqual(): (
    x: ProductsQuantitiesModel,
    y: ProductsQuantitiesModel
  ) => boolean {
    return (x, y) => {
      const productCodes = Object.keys(x);

      return (
        productCodes.length !== Object.keys(y).length &&
        productCodes.every((k) => x[k] === y[k])
      );
    };
  }
}
