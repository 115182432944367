import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-critical-error',
  templateUrl: './critical-error.component.html',
  styleUrls: ['./critical-error.component.less']
})
export class CriticalErrorComponent {
  logoUrl = environment.storageConfig.commonAssetsUrl + 'frascheri-400.png';

  constructor(public authenticationService: AuthenticationService) {}
}
