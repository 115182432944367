
    <i nz-icon>
      <svg viewBox="214 -92.1 1024 1024">
        <g>
          <path
            fill="#595959"
            d="M958.2,729.6H445l25.8-52.5l428-0.8c14.5,0,26.9-10.3,29.4-24.6l59.2-331.8c1.6-8.7-0.8-17.6-6.5-24.5
        c-5.7-6.8-14.1-10.7-22.9-10.7l-544.5-1.8l-4.6-21.8c-3-14-15.6-24.1-29.8-24.1h-133c-16.8,0-30.4,13.6-30.4,30.4
        s13.6,30.4,30.4,30.4h108.5l20.3,96.7L425,636.8L360.5,742c-6.8,9.2-7.9,21.5-2.6,31.7c5.2,10.2,15.6,16.7,27.2,16.7h54.1
        c-11.5,15.3-17.7,34-17.7,53.1c0,48.7,39.7,88.4,88.4,88.4s88.4-39.7,88.4-88.4c0-19.2-6.4-37.9-17.7-53.1h138.8
        c-11.5,15.3-17.7,34-17.7,53.1c0,48.7,39.7,88.4,88.4,88.4s88.4-39.6,88.4-88.4c0-19.2-6.4-37.9-17.7-53.1h97.6
        c16.7,0,30.4-13.6,30.4-30.4C988.5,743.2,975,729.6,958.2,729.6L958.2,729.6z M426.4,342.9l496,1.7l-48.6,272l-389.6,0.7
        L426.4,342.9z M509.9,870.7c-15,0-27.3-12.2-27.3-27.3s12.2-27.3,27.3-27.3c15.1,0,27.3,12.2,27.3,27.3
        C537,858.6,525,870.7,509.9,870.7z M790,870.7c-15,0-27.3-12.2-27.3-27.3s12.2-27.3,27.3-27.3s27.3,12.2,27.3,27.3
        C817.2,858.6,805,870.7,790,870.7z"
          />
          <g>
            <path
              [ngClass]="{
                'add-outer-alternative': isAlternativeColor,
                'add-outer-main': !isAlternativeColor
              }"
              d="M907.2-92.1c-181.7,0-329,147.4-329,329s147.4,329,329,329s329-147.4,329-329S1089.1-92.1,907.2-92.1z
           M907.2,510.2c-150.9,0-271.9-122.3-271.9-273.3s121-273.3,271.9-273.3S1180.6,86,1180.6,236.9S1058.2,510.2,907.2,510.2z"
            />
            <path
              [ngClass]="{
                'add-inner-alternative': isAlternativeColor,
                'add-inner-main': !isAlternativeColor
              }"
              d="M907.2-36.3C756.3-36.3,634,86,634,236.9s122.3,273.3,273.3,273.3s273.3-122.5,273.3-273.3
          S1058.2-36.3,907.2-36.3z"
            />
          </g>
          <path
            [ngClass]="{
              'add-outer-alternative': isAlternativeColor,
              'add-outer-main': !isAlternativeColor,
              'add-outer-alternative-stroke': isAlternativeColor,
              'add-outer-main-stroke': !isAlternativeColor
            }"
            stroke-width="18"
            stroke-miterlimit="10"
            d="M802.1,335c-13.2-13.2-23.5-28.6-30.5-45.8c-6.7-16.6-10.1-34.2-10.1-52.2c0-18,3.4-35.6,10.1-52.2
        c7-17.2,17.2-32.6,30.5-45.9s28.6-23.5,45.8-30.5c16.6-6.7,34.2-10.1,52.2-10.1c18,0,35.6,3.4,52.2,10.1
        c17.2,7,32.6,17.2,45.8,30.5c13.2,13.2,23.5,28.6,30.5,45.8c6.7,16.6,10.2,34.3,10.1,52.2c0,5.7-0.3,11.4-1,16.9l-55.4-3.5
        c-1.9-0.2-3.6,1.1-3.9,3c-0.1,0.8,0.1,1.6,0.5,2.2l64.6,88.8c1.2,1.9,3.8,2.2,5.4,0.7l78.4-77.9c2.1-2.1,0.9-5.6-2-5.9l-53.1-3.2
        l0,0c6.2-51.1-10.2-104.5-49.4-143.7c-67.7-67.7-177.1-67.7-244.9-0.2c-67.9,67.7-67.9,177.5-0.2,245.2"
          />
        </g>
      </svg>
    </i>
  