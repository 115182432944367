import { ProductGroupModel } from '../types/product-group.model';
import { ProductOverviewModel } from '../types/product-overview.model';
import { ProductQuantityModel } from '../types/product-quantity.model';
import { ProductsFiltersExtendedModel } from '../types/products-filters.model';

export class ProductsReset {
  static readonly type = '[Products] Reset';
}

export class ProductsSetList {
  static readonly type = '[Products] Set List';

  constructor(public list: ProductOverviewModel[]) {}
}

export class ProductsSetGroups {
  static readonly type = '[Products] Set Groups';

  constructor(public groups: ProductGroupModel[]) {}
}

export class ProductsSetMissingQuantities {
  static readonly type = '[Products] Set Missing Quantities';
}

export class ProductsSetQuantity {
  static readonly type = '[Products] Set Quantity';

  constructor(public productQuantity: ProductQuantityModel) {}
}

export class ProductsChangeFilters {
  static readonly type = '[Products] Change Filters';

  constructor(public filters: ProductsFiltersExtendedModel) {}
}
