import { Injectable } from '@angular/core';
import { OrderModel } from '../types/order.model';

@Injectable({ providedIn: 'root' })
export class OrdersListFilterService {
  filter(
    list: OrderModel[],
    filteringPartialOrderNumberId: number,
    filteringOrderStates: number[]
  ): OrderModel[] {
    const listFilteredByPartialOrderNumberId = this.getListFilteredByNumberId(
      list,
      filteringPartialOrderNumberId
    );

    const listFilteredByPartialOrderNumberIdAndOrderState = this.getListFilteredByOrderState(
      listFilteredByPartialOrderNumberId,
      filteringOrderStates
    );

    return listFilteredByPartialOrderNumberIdAndOrderState;
  }

  private getListFilteredByNumberId(
    list: OrderModel[],
    partialOrderNumberId: number
  ): OrderModel[] {
    return list.filter(order =>
      (order.number || '')
        .toString()
        .startsWith((partialOrderNumberId || '').toString())
    );
  }

  private getListFilteredByOrderState(list: OrderModel[], states: number[]) {
    return list.filter(
      order => !states || !states.length || states.includes(order.state)
    );
  }
}
