import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-cart-extras',
  templateUrl: './cart-extras.component.html',
  styleUrls: ['./cart-extras.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartExtrasComponent {
  @Input() dateFor: Date;
  @Input() note: string;
  @Input() customerOrderNumber: string;
  @Input() productsCount: number;
  @Input() totalPrice: number;
  @Input() actionsDisabled: boolean;
  @Input() isOrderLoading: boolean;
  @Input() isOrderSubmitModalVisible: boolean;
  @Input() isOrderCourtesyModalVisible: boolean;
  @Input() showSummaryActionsDividerOnSmallBreakpoint: boolean = true;

  @Output() dateForChange = new EventEmitter<Date>();
  @Output() noteChange = new EventEmitter<string>();
  @Output() customerOrderNumberChange = new EventEmitter<string>();
  @Output() orderSubmitModalOpeningRequest = new EventEmitter<boolean>();
  @Output() orderCourtesyModalOpeningRequest = new EventEmitter<boolean>();
  @Output() orderSubmit = new EventEmitter<void>();
  @Output() clearCartSubmit = new EventEmitter<void>();
  @Output() goToProductsListRequest = new EventEmitter<void>();
  @Output() goToOrdersRequest = new EventEmitter<void>();

  isPastDate = (current: Date): boolean =>
    // tslint:disable-next-line: semicolon
    current.getTime() <= new Date().setUTCHours(0, 0, 0, 0);
}
