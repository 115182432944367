import { TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { actionMatcher, Actions, ofActionCompleted } from '@ngxs/store';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { merge } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { CartAddProduct, CartChangeDateFor, CartChangeNote, CartClearProducts, CartCloneOrder, CartRemoveProduct, CartSetProductsQuantities } from 'src/app/features/cart/state/cart.actions';
import { OrdersNewOrderFromCart, OrdersUpdateNotification } from 'src/app/features/orders/state/orders.actions';
import { CoreChangeCustomer } from '../core.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "ng-zorro-antd/notification";
import * as i3 from "ng-zorro-antd/message";
import * as i4 from "@ngx-translate/core";
var NotificationHandler = /** @class */ (function () {
    function NotificationHandler(actions$, notificationService, messageService, translateService) {
        var _this = this;
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.messageService = messageService;
        this.translateService = translateService;
        this.notifications = [
            // Need to declare here because for this action a template is set
            // by the main container component after view init.
            {
                action: OrdersUpdateNotification
            }
        ];
        merge(this.translateService.getTranslation(this.translateService.currentLang), this.translateService.onLangChange)
            .pipe(tap(function (data) {
            var translate = data.translate || data.translations.translate;
            _this.setNotificationMessagesByTranslate(translate);
        }), switchMap(function (_) {
            var actions = _this.notifications.map(function (notification) { return notification.action; });
            return _this.registerActionsNotifications(actions);
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    NotificationHandler.prototype.closeNotifications = function () {
        this.notificationService.remove();
    };
    NotificationHandler.prototype.registerActionsNotifications = function (actions) {
        var _this = this;
        return this.actions$.pipe(ofActionCompleted.apply(void 0, actions), debounceTime(200), tap(function (_a) {
            var result = _a.result, action = _a.action;
            var matchingNotification = _this.notifications.find(function (notification) {
                return actionMatcher(notification.action)(action);
            });
            _this.handleNotification(matchingNotification.successMessage, matchingNotification.errorMessage, result);
        }));
    };
    NotificationHandler.prototype.setNotificationMessagesByTranslate = function (translate) {
        this.notifications = this.notifications
            .filter(function (notification) { return notification.action === OrdersUpdateNotification; })
            .concat([
            // Only title -> message
            // Also content -> notification
            {
                action: CartAddProduct,
                successMessage: {
                    title: translate.cart_add_product_success_title
                    // content: 'Troverai il prodotto nel tuo carrello.'
                },
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_add_product_error_content
                }
            },
            {
                action: CartSetProductsQuantities,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_set_product_quantities_error_content
                }
            },
            {
                action: CartChangeDateFor,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_change_date_for_error_content
                }
            },
            {
                action: CartChangeNote,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_change_note_error_content
                }
            },
            {
                action: CartRemoveProduct,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_remove_product_error_content
                }
            },
            {
                action: CartClearProducts,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_clear_products_error_content
                }
            },
            {
                action: CartCloneOrder,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.cart_clone_order_error_content
                }
            },
            {
                action: OrdersNewOrderFromCart,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.orders_new_order_from_cart_error_content
                }
            },
            {
                action: CoreChangeCustomer,
                errorMessage: {
                    title: translate.action_error_title,
                    content: translate.core_change_customer_error_content
                }
            }
        ]);
    };
    NotificationHandler.prototype.handleNotification = function (successMessage, errorMessage, result) {
        var successful = result.successful, error = result.error;
        if (!successful && !error)
            return;
        var message = successful ? successMessage : errorMessage;
        if (!message)
            return;
        // Removes other infinite duration messages that may
        // be pending before pushing this one
        if (message.isInfiniteDuration) {
            this.notificationService.remove();
            this.messageService.remove();
        }
        if (message.template) {
            this.notificationService.template(message.template, message.isInfiniteDuration ? { nzDuration: 0 } : {});
            return;
        }
        var service = message.content
            ? this.notificationService
            : this.messageService;
        var notificationMethod = successful
            ? service.success.bind(service)
            : service.error.bind(service);
        if (message.content) {
            notificationMethod(message.title, message.content, message.isInfiniteDuration ? { nzDuration: 0 } : {});
        }
        else {
            notificationMethod(message.title, message.isInfiniteDuration ? { nzDuration: 0 } : {});
        }
    };
    NotificationHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationHandler_Factory() { return new NotificationHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.NzNotificationService), i0.ɵɵinject(i3.NzMessageService), i0.ɵɵinject(i4.TranslateService)); }, token: NotificationHandler, providedIn: "root" });
    return NotificationHandler;
}());
export { NotificationHandler };
