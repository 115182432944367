import { OrderViewModel } from '../../orders/types/order.view-model';
import { ProductQuantityModel } from '../../products/types/product-quantity.model';
import { ProductsFiltersExtendedModel } from '../../products/types/products-filters.model';
import { ProductsQuantitiesModel } from '../../products/types/products-quantities.model';
import { CartModel } from '../types/cart.model';

export class CartReset {
  static readonly type = '[Cart] Reset';
}

export class CartSetCart {
  static readonly type = '[Cart] Set Cart';

  constructor(public cart: CartModel) {}
}

export class CartCheckEmptyCart {
  static readonly type = '[Cart] Check Empty Cart';
}

export class CartAddProduct {
  static readonly type = '[Cart] Add Product';

  constructor(public productQuantity: ProductQuantityModel) {}
}

export class CartSetProductsQuantities {
  static readonly type = '[Cart] Set Products Quantities';

  constructor(public productsQuantities: ProductsQuantitiesModel) {}
}

export class CartChangeFilters {
  static readonly type = '[Cart] Change Filters';

  constructor(public filters: ProductsFiltersExtendedModel) {}
}

export class CartClearFilters {
  static readonly type = '[Cart] Clear Filters';
}

export class CartChangeDateFor {
  static readonly type = '[Cart] Change Date For';

  constructor(public dateFor: Date) {}
}

export class CartChangeNote {
  static readonly type = '[Cart] Change Note';

  constructor(public note: string) {}
}

export class CartChangeCustomerOrderNumber {
  static readonly type = '[Cart] Change Customer Order Number';

  constructor(public customerOrderNumber: string) {}
}

export class CartRemoveProduct {
  static readonly type = '[Cart] Remove Product';

  constructor(public productCode: number) {}
}

export class CartClearProducts {
  static readonly type = '[Cart] Clear Products';
}

export class CartSetOrderSubmitModalOpening {
  static readonly type = '[Cart] Set Order Submit Modal Opening';

  constructor(public value: boolean) {}
}

export class CartSetOrderCourtesyModalOpening {
  static readonly type = '[Cart] Set Order Courtesy Modal Opening';

  constructor(public value: boolean) {}
}

export class CartCloneOrder {
  static readonly type = '[Cart] Clone Order';

  constructor(public order: OrderViewModel) {}
}
