import * as tslib_1 from "tslib";
import * as auth0 from 'auth0-js';
import { timer } from 'rxjs';
import { OnlineCheckService } from 'src/app/shared/services/online-check.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/online-check.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(onlineCheckService) {
        this.onlineCheckService = onlineCheckService;
        this.auth0 = new auth0.WebAuth(environment.authenticationConfig);
        this.AuthenticationBrowserStorage = localStorage;
        this.AuthenticationBrowserStorageDataKey = 'authenticationData';
        this.AuthenticationBrowserCallbackPathStorage = sessionStorage;
        this.AuthenticationCallbackPathBrowserStorageDataKey = 'authenticationCallbackPath';
        this.refreshSafetyMilliseconds = 60000;
        this.forceRenewTokenRequestsResolves = [];
    }
    Object.defineProperty(AuthenticationService.prototype, "callbackPath", {
        get: function () {
            return this.AuthenticationBrowserCallbackPathStorage.getItem(this.AuthenticationCallbackPathBrowserStorageDataKey);
        },
        set: function (value) {
            this.AuthenticationBrowserCallbackPathStorage.setItem(this.AuthenticationCallbackPathBrowserStorageDataKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "accessToken", {
        get: function () {
            return this.authenticationData && this.authenticationData.accessToken;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "authenticationData", {
        get: function () {
            return JSON.parse(this.AuthenticationBrowserStorage.getItem(this.AuthenticationBrowserStorageDataKey));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "expiresAt", {
        get: function () {
            return this.authenticationData && this.authenticationData.expiresAt;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "idToken", {
        get: function () {
            return this.authenticationData && this.authenticationData.idToken;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.getProfile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.accessToken) {
                    throw new Error('Access Token must exist to fetch profile');
                }
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.auth0.client.userInfo(_this.accessToken, function (err, profile) {
                            if (err)
                                reject(err);
                            if (!profile)
                                reject(new Error("auth0.client.userInfo couldn't retrieve profile"));
                            resolve(profile);
                        });
                    })];
            });
        });
    };
    AuthenticationService.prototype.handleAuthentication = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.auth0.parseHash(function (err, authResult) {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    _this.localLogin(authResult);
                    resolve();
                }
                else
                    reject(err || new Error('Unknown error in auth0.parseHash'));
            });
        });
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        return this.accessToken && Date.now() < this.expiresAt;
    };
    AuthenticationService.prototype.login = function () {
        this.callbackPath = window.location.pathname;
        this.auth0.authorize();
    };
    AuthenticationService.prototype.logout = function () {
        this.AuthenticationBrowserStorage.removeItem(this.AuthenticationBrowserStorageDataKey);
        this.unscheduleRenewal();
        this.auth0.logout({
            returnTo: window.location.origin
        });
    };
    AuthenticationService.prototype.forceRenewTokens = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.forceRenewTokenRequestsResolves.push(resolve);
            // The first one wins the race condition and will do the real request
            if (_this.forceRenewTokenRequestsResolves[0] !== resolve)
                return;
            _this.renewTokens().then(function () {
                _this.forceRenewTokenRequestsResolves.forEach(function (r) { return r(); });
                _this.forceRenewTokenRequestsResolves = [];
                _this.scheduleRenewal();
            });
        });
    };
    AuthenticationService.prototype.renewTokens = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.auth0.checkSession({}, function (err, authResult) {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    _this.localLogin(authResult);
                    resolve();
                }
                else {
                    var error = err || {
                        error: 'Unkown error',
                        errorDescription: 'in auth0.checkSession'
                    };
                    console.error("Could not get a new token (error: " + error.error + " - description: " + error.errorDescription + ").");
                    _this.onlineCheckService
                        .getOnlineNotifier$()
                        .toPromise()
                        .then(function (_) { return _this.logout(); });
                }
            });
        });
    };
    AuthenticationService.prototype.scheduleRenewal = function () {
        var _this = this;
        if (!this.isAuthenticated())
            return;
        this.unscheduleRenewal();
        this.refreshSubscription = timer(Math.max(1, this.getExpireSafetyTimeRemaining(this.expiresAt))).subscribe(function () {
            _this.renewTokens();
            _this.scheduleRenewal();
        });
    };
    AuthenticationService.prototype.getExpireSafetyTimeRemaining = function (expiresAt) {
        return expiresAt - Date.now() - this.refreshSafetyMilliseconds;
    };
    AuthenticationService.prototype.localLogin = function (authResult) {
        var authenticationData = {
            accessToken: authResult.accessToken,
            idToken: authResult.idToken,
            expiresAt: authResult.expiresIn * 1000 + Date.now()
        };
        this.AuthenticationBrowserStorage.setItem(this.AuthenticationBrowserStorageDataKey, JSON.stringify(authenticationData));
    };
    AuthenticationService.prototype.unscheduleRenewal = function () {
        // tslint:disable-next-line: no-unused-expression
        this.refreshSubscription && this.refreshSubscription.unsubscribe();
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.OnlineCheckService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
