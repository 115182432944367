import { TranslateService } from '@ngx-translate/core';
import { en_US, it_IT, NzI18nService } from 'ng-zorro-antd';
import { merge, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "ng-zorro-antd/i18n";
var TranslateManagerService = /** @class */ (function () {
    function TranslateManagerService(translateService, nzI18nService) {
        this.translateService = translateService;
        this.nzI18nService = nzI18nService;
        this.language$ = merge(of({ lang: this.getCurrentLanguage() }), this.translateService.onLangChange);
        this.languageLabel$ = this.language$.pipe(switchMap(function (_a) {
            var lang = _a.lang;
            return of(lang === 'en' ? 'Italiano' : 'English');
        }));
        this.isEnglishLanguage$ = this.language$.pipe(map(function (_a) {
            var lang = _a.lang;
            return lang === 'en';
        }));
    }
    TranslateManagerService.prototype.setLanguage = function () {
        var language = this.getCurrentLanguage();
        localStorage.setItem('language', language);
        this.nzI18nService.setLocale(language === 'en' ? en_US : it_IT);
        return this.translateService.use(language);
    };
    TranslateManagerService.prototype.changeLanguage = function () {
        var currentLanguage = localStorage.getItem('language');
        localStorage.setItem('language', currentLanguage === 'en' ? 'it' : 'en');
        return this.setLanguage();
    };
    TranslateManagerService.prototype.getCurrentLanguage = function () {
        return (localStorage.getItem('language') ||
            this.translateService.getBrowserLang() ||
            'it');
    };
    TranslateManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslateManagerService_Factory() { return new TranslateManagerService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.NzI18nService)); }, token: TranslateManagerService, providedIn: "root" });
    return TranslateManagerService;
}());
export { TranslateManagerService };
