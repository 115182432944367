import { Injector } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CoreState } from 'src/app/core/state/core.state';
import {
  Cart_Rows,
  ClearCartAndBulkInsertGQL,
  ClearCartRowsGQL,
  RemoveCartRowGQL,
  UpdateCartHeaderCustomerOrderNumberGQL,
  UpdateCartHeaderDateForGQL,
  UpdateCartHeaderNoteGQL,
  UpsertCartRowGQL,
} from 'src/app/graphql/graphql';
import { OrderViewModel } from '../../orders/types/order.view-model';
import { ProductsListFilterService } from '../../products/services/products-list-filter.service';
import {
  ProductsState,
  ProductsStateModel,
} from '../../products/state/products.state';
import { ProductOverviewModel } from '../../products/types/product-overview.model';
import { ProductQuantityModel } from '../../products/types/product-quantity.model';
import { ProductsQuantitiesModel } from '../../products/types/products-quantities.model';
import { CartProductQuantitiesTotalModel } from '../types/cart-product-quantities-total.model';
import { CartModel } from '../types/cart.model';
import { ProductsBeingAddedCodesMap } from '../types/products-being-added-codes.map';
import {
  CartAddProduct,
  CartChangeCustomerOrderNumber,
  CartChangeDateFor,
  CartChangeFilters,
  CartChangeNote,
  CartCheckEmptyCart,
  CartClearFilters,
  CartClearProducts,
  CartCloneOrder,
  CartRemoveProduct,
  CartReset,
  CartSetCart,
  CartSetOrderCourtesyModalOpening,
  CartSetOrderSubmitModalOpening,
  CartSetProductsQuantities,
} from './cart.actions';

export interface CartStateModel {
  quantities: ProductsQuantitiesModel;
  dateFor: Date;
  note: string;
  customerOrderNumber: string;
  filteringGroupsCodes: string[];
  filteringPartialProductCode: number;
  filteringPartialDescription: string;
  isInCartRowsHttpProcessing: boolean;
  productsBeingAddedCodes: ProductsBeingAddedCodesMap;
  isOrderSubmitModalOpen: boolean;
  isOrderCourtesyModalOpen: boolean;
}

const cartStateModelDefaults: CartStateModel = {
  quantities: {},
  dateFor: null,
  note: null,
  customerOrderNumber: null,
  filteringGroupsCodes: [],
  filteringPartialProductCode: null,
  filteringPartialDescription: null,
  isInCartRowsHttpProcessing: false,
  productsBeingAddedCodes: {},
  isOrderSubmitModalOpen: false,
  isOrderCourtesyModalOpen: false,
};

@State<CartStateModel>({
  name: 'cart',
  defaults: cartStateModelDefaults,
})
export class CartState {
  private static productsListFilterService: ProductsListFilterService;

  constructor(
    injector: Injector,
    private store: Store,
    private upsertCartRowGQL: UpsertCartRowGQL,
    private removeCartRowGQL: RemoveCartRowGQL,
    private clearCartRowsGQL: ClearCartRowsGQL,
    private updateCartHeaderDateForGQL: UpdateCartHeaderDateForGQL,
    private updateCartHeaderNoteGQL: UpdateCartHeaderNoteGQL,
    private updateCartHeaderCustomerOrderNumberGQL: UpdateCartHeaderCustomerOrderNumberGQL,
    private clearCartAndBulkInsertGQL: ClearCartAndBulkInsertGQL
  ) {
    CartState.productsListFilterService = injector.get(
      ProductsListFilterService
    );
  }

  @Selector()
  static isInCartRowsHttpProcessing({
    isInCartRowsHttpProcessing,
  }: CartStateModel): boolean {
    return isInCartRowsHttpProcessing;
  }

  @Selector()
  static productsBeingAddedCodes({
    productsBeingAddedCodes,
  }: CartStateModel): ProductsBeingAddedCodesMap {
    return productsBeingAddedCodes;
  }

  @Selector()
  static isOrderSubmitModalOpen({
    isOrderSubmitModalOpen,
  }: CartStateModel): boolean {
    return isOrderSubmitModalOpen;
  }

  @Selector()
  static isOrderCourtesyModalOpen({
    isOrderCourtesyModalOpen,
  }: CartStateModel): boolean {
    return isOrderCourtesyModalOpen;
  }

  @Selector([ProductsState])
  static list(
    { quantities }: CartStateModel,
    { list: productsStateList }: ProductsStateModel
  ): ProductOverviewModel[] {
    return productsStateList.filter((product) =>
      Object.keys(quantities).includes(product.code.toString())
    );
  }

  @Selector([ProductsState])
  static listFiltered(
    state: CartStateModel,
    productsState: ProductsStateModel
  ): ProductOverviewModel[] {
    const cartProductsList = CartState.list(state, productsState);

    const {
      filteringGroupsCodes,
      filteringPartialProductCode,
      filteringPartialDescription,
    } = state;

    return this.productsListFilterService.filter({
      list: cartProductsList,
      groupsCodes: filteringGroupsCodes,
      partialProductCode: filteringPartialProductCode,
      partialDescription: filteringPartialDescription,
    });
  }

  @Selector([ProductsState])
  static quantities(
    state: CartStateModel,
    productsState: ProductsStateModel
  ): ProductsQuantitiesModel {
    const cartProductsList = CartState.list(state, productsState);
    const { quantities } = state;

    return CartState.filterQuantitiesByProductsList(
      quantities,
      cartProductsList
    );
  }

  @Selector([ProductsState])
  static productQuantitiesTotal(
    state: CartStateModel,
    productsState: ProductsStateModel
  ): CartProductQuantitiesTotalModel {
    const cartProductsList = CartState.list(state, productsState);
    const { quantities } = state;

    return cartProductsList.reduce(CartState.toQuantitiesTotal(quantities), {});
  }

  @Selector([ProductsState])
  static combinedTotal(
    state: CartStateModel,
    productsState: ProductsStateModel
  ): number {
    return Object.values(
      CartState.productQuantitiesTotal(state, productsState)
    ).reduce((sum, current) => sum + current, 0);
  }

  @Selector([ProductsState])
  static productsCount(
    state: CartStateModel,
    productsState: ProductsStateModel
  ): number {
    return Object.keys(CartState.quantities(state, productsState)).length;
  }

  @Selector()
  static filteringPartialProductCode({
    filteringPartialProductCode,
  }: CartStateModel): number {
    return filteringPartialProductCode;
  }

  @Selector()
  static filteringPartialDescription({
    filteringPartialDescription,
  }: CartStateModel): string {
    return filteringPartialDescription;
  }

  @Selector()
  static filteringGroupsCodes({
    filteringGroupsCodes,
  }: CartStateModel): string[] {
    return filteringGroupsCodes;
  }

  @Selector()
  static dateFor({ dateFor }: CartStateModel): Date {
    return dateFor;
  }

  @Selector()
  static note({ note }: CartStateModel): string {
    return note;
  }

  @Selector()
  static customerOrderNumber({ customerOrderNumber }: CartStateModel): string {
    return customerOrderNumber;
  }

  private static filterQuantitiesByProductsList(
    quantities: ProductsQuantitiesModel,
    cartProductsList: ProductOverviewModel[]
  ): ProductsQuantitiesModel {
    return Object.keys(quantities)
      .map((productCode) => parseInt(productCode, 10))
      .filter((productCode) =>
        cartProductsList.some((product) => product.code === productCode)
      )
      .reduce(
        (quantitiesAccumulator, currentProductCode) => ({
          ...quantitiesAccumulator,
          [currentProductCode]: quantities[currentProductCode],
        }),
        {}
      );
  }

  private static toQuantitiesTotal(
    quantities: ProductsQuantitiesModel
  ): (
    previousValue: ProductOverviewModel,
    currentValue: ProductOverviewModel,
    currentIndex: number,
    array: ProductOverviewModel[]
  ) => CartProductQuantitiesTotalModel {
    return (quantitiesTotalAccumulator, currentProduct) => ({
      ...quantitiesTotalAccumulator,
      [currentProduct.code]: quantities[currentProduct.code]
        ? currentProduct.price_rel[0].price_discount *
          quantities[currentProduct.code]
        : null,
    });
  }

  @Action(CartReset)
  reset({ setState }: StateContext<CartStateModel>): void {
    setState(cartStateModelDefaults);
  }

  @Action(CartCheckEmptyCart)
  checkEmptyCart({ getState }: StateContext<CartStateModel>): Observable<any> {
    const cartProductsCount = CartState.productsCount(
      getState(),
      this.store.selectSnapshot(ProductsState)
    );

    if (cartProductsCount < 1) return this.store.dispatch(new Navigate(['/']));
    return of(null);
  }

  @Action(CartSetOrderSubmitModalOpening)
  setOrderSubmitModalOpening(
    { patchState }: StateContext<CartStateModel>,
    { value }: CartSetOrderSubmitModalOpening
  ): void {
    patchState({
      isOrderSubmitModalOpen: value,
    });
  }

  @Action(CartSetOrderCourtesyModalOpening)
  setOrderCourtesyModalOpening(
    { patchState }: StateContext<CartStateModel>,
    { value }: CartSetOrderCourtesyModalOpening
  ): void {
    patchState({
      isOrderCourtesyModalOpen: value,
    });
  }

  @Action(CartClearFilters)
  clearFilters({ patchState }: StateContext<CartStateModel>): void {
    patchState({
      filteringGroupsCodes: [],
      filteringPartialProductCode: null,
      filteringPartialDescription: null,
    });
  }

  @Action(CartChangeFilters)
  changeFilters(
    { getState, patchState }: StateContext<CartStateModel>,
    { filters }: CartChangeFilters
  ): void {
    const {
      filteringGroupsCodes: originalFilteringGroupsCodes,
      filteringPartialProductCode: originalFilteringPartialProductCode,
      filteringPartialDescription: originalFilteringPartialDescription,
    } = getState();

    patchState(
      CartState.productsListFilterService.getCalculatedFilters(
        filters,
        originalFilteringGroupsCodes,
        originalFilteringPartialProductCode,
        originalFilteringPartialDescription
      )
    );
  }

  @Action(CartCloneOrder)
  cloneOrder(
    { patchState }: StateContext<CartStateModel>,
    { order }: CartCloneOrder
  ): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    const newCart = this.getCartFromOrder(customerCode, order);

    patchState({ isInCartRowsHttpProcessing: true });

    return this.clearCartAndBulkInsertGQL
      .mutate({
        customerCode,
        note: newCart.note,
        rows: newCart.cart_row_rel.map((entry) => ({
          ...entry,
          code_customer: customerCode,
        })),
      })
      .pipe(
        tap((_) =>
          patchState({
            note: newCart.note,
            quantities: this.getProductsQuantitiesFromCartRows(
              newCart.cart_row_rel
            ),
            dateFor: newCart.date_for,
            isInCartRowsHttpProcessing: false,
          })
        ),
        catchError((error) => {
          patchState({ isInCartRowsHttpProcessing: false });
          return throwError(error);
        })
      );
  }

  @Action(CartSetCart)
  setCart(
    { patchState }: StateContext<CartStateModel>,
    { cart }: CartSetCart
  ): void {
    const {
      cart_row_rel,
      date_for: dateFor,
      note,
      customer_order_number: customerOrderNumber,
    } = cart;

    const quantities = this.getProductsQuantitiesFromCartRows(cart_row_rel);

    patchState({
      quantities,
      dateFor,
      note,
      customerOrderNumber,
    });
  }

  @Action(CartAddProduct)
  addProduct(
    { getState, patchState }: StateContext<CartStateModel>,
    { productQuantity }: CartAddProduct
  ): Observable<any> {
    const { productCode, quantity } = productQuantity;

    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    const { quantities, productsBeingAddedCodes } = getState();
    const isUpdate = !!quantities[productCode] || quantities[productCode] === 0;

    const effectiveQuantity = isUpdate
      ? quantities[productCode] + quantity
      : quantity;

    patchState({
      isInCartRowsHttpProcessing: true,
      productsBeingAddedCodes: {
        ...productsBeingAddedCodes,
        [productCode]: true,
      },
    });

    return this.upsertCartRowGQL
      .mutate({
        customerCode,
        productCode,
        quantity: effectiveQuantity,
      })
      .pipe(
        tap((_) => {
          // We retrieve the quantities again because those may have changed
          // during the time of the http call
          const {
            quantities: currentQuantities,
            productsBeingAddedCodes: currentProductsBeingAddedCodes,
          } = getState();

          const newQuantities = {
            ...currentQuantities,
            [productCode]: effectiveQuantity,
          };

          patchState({
            quantities: newQuantities,
            isInCartRowsHttpProcessing: false,
            productsBeingAddedCodes: {
              ...currentProductsBeingAddedCodes,
              [productCode]: false,
            },
          });
        }),
        catchError((error) => {
          const {
            productsBeingAddedCodes: currentProductsBeingAddedCodes,
          } = getState();

          patchState({
            isInCartRowsHttpProcessing: false,
            productsBeingAddedCodes: {
              ...currentProductsBeingAddedCodes,
              [productCode]: false,
            },
          });

          return throwError(error);
        })
      );
  }

  @Action(CartSetProductsQuantities)
  setProductsQuantities(
    { getState, patchState }: StateContext<CartStateModel>,
    { productsQuantities }: CartSetProductsQuantities
  ): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    const {
      quantities: originalProductsQuantities,
      productsBeingAddedCodes,
    } = getState();

    const setQuantitiesObservables = this.productQuantitiesToSetQuantitiesObservablesIfQuantityChanged(
      productsQuantities,
      originalProductsQuantities,
      customerCode
    );

    // Awful, I hope momentary trick to accomodate
    // the order confirm that is waiting for this flow to
    // go to enabled again
    if (!setQuantitiesObservables.length) {
      patchState({
        isInCartRowsHttpProcessing: true,
      });

      patchState({
        isInCartRowsHttpProcessing: false,
      });

      return of(null);
    }

    patchState({
      isInCartRowsHttpProcessing: true,
      productsBeingAddedCodes: {
        ...productsBeingAddedCodes,
        ...this.getProductsBeingAddedCodeMapFromProductsQuantities(
          productsQuantities,
          true
        ),
      },
    });

    return forkJoin(setQuantitiesObservables).pipe(
      tap((_) => {
        // We retrieve the quantities again because those may have changed
        // during the time of the http call
        const { quantities: currentQuantities } = getState();

        patchState({
          quantities: { ...currentQuantities, ...productsQuantities },
          productsBeingAddedCodes: {
            ...productsBeingAddedCodes,
            ...this.getProductsBeingAddedCodeMapFromProductsQuantities(
              productsQuantities,
              false
            ),
          },
          isInCartRowsHttpProcessing: false,
        });
      }),
      catchError((error) => {
        const { quantities: currentProductsQuantities } = getState();

        // Trick to force NGXS to resend the new model for the quantities
        patchState({
          quantities: this.getAlmostIdenticalQuantities(
            currentProductsQuantities
          ),
          isInCartRowsHttpProcessing: false,
          productsBeingAddedCodes: {
            ...productsBeingAddedCodes,
            ...this.getProductsBeingAddedCodeMapFromProductsQuantities(
              productsQuantities,
              false
            ),
          },
        });

        return throwError(error);
      })
    );
  }

  @Action(CartRemoveProduct)
  removeProduct(
    { getState, patchState }: StateContext<CartStateModel>,
    { productCode }: CartRemoveProduct
  ): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );
    patchState({ isInCartRowsHttpProcessing: true });

    return this.removeCartRowGQL.mutate({ customerCode, productCode }).pipe(
      tap((_) =>
        patchState({
          quantities: this.removeProductCodeFromQuantities(
            getState().quantities,
            productCode
          ),
          isInCartRowsHttpProcessing: false,
        })
      ),
      catchError((error) => {
        patchState({ isInCartRowsHttpProcessing: false });
        return throwError(error);
      })
    );
  }

  @Action(CartChangeDateFor)
  changeDateFor(
    { patchState, getState }: StateContext<CartStateModel>,
    { dateFor }: CartChangeDateFor
  ): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    return this.updateCartHeaderDateForGQL
      .mutate({ customerCode, dateFor })
      .pipe(
        tap((_) =>
          patchState({
            dateFor,
          })
        ),
        catchError((error) => {
          const { dateFor: originalDateFor } = getState();

          // Trick to force NGXS to resend the new model for the date
          patchState({
            dateFor:
              originalDateFor === null
                ? undefined
                : originalDateFor === undefined
                ? null
                : new Date(originalDateFor.getTime() + 1),
          });

          return throwError(error);
        })
      );
  }

  @Action(CartChangeNote)
  changeNote(
    { patchState, getState }: StateContext<CartStateModel>,
    { note }: CartChangeNote
  ): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    return this.updateCartHeaderNoteGQL.mutate({ customerCode, note }).pipe(
      tap((_) =>
        patchState({
          note,
        })
      ),
      catchError((error) => {
        const { note: originalNote } = getState();

        // Trick to force NGXS to resend the new model for the notes
        patchState({
          note:
            originalNote === undefined
              ? null
              : originalNote === null
              ? undefined
              : originalNote === ''
              ? undefined
              : originalNote + ' ',
        });

        return throwError(error);
      })
    );
  }

  @Action(CartChangeCustomerOrderNumber)
  changeCustomerOrderNumber(
    { patchState, getState }: StateContext<CartStateModel>,
    { customerOrderNumber }: CartChangeCustomerOrderNumber
  ): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    return this.updateCartHeaderCustomerOrderNumberGQL
      .mutate({ customerCode, customerOrderNumber })
      .pipe(
        tap((_) =>
          patchState({
            customerOrderNumber,
          })
        ),
        catchError((error) => {
          const {
            customerOrderNumber: originalCustomerOrderNumber,
          } = getState();

          // Trick to force NGXS to resend the new model for the customerOrderNumber
          patchState({
            customerOrderNumber:
              originalCustomerOrderNumber === undefined
                ? null
                : originalCustomerOrderNumber === null
                ? undefined
                : originalCustomerOrderNumber === ''
                ? undefined
                : originalCustomerOrderNumber + ' ',
          });

          return throwError(error);
        })
      );
  }

  @Action(CartClearProducts)
  clearProducts({ patchState }: StateContext<CartStateModel>): Observable<any> {
    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    patchState({ isInCartRowsHttpProcessing: true });

    return this.clearCartRowsGQL.mutate({ customerCode }).pipe(
      tap((_) =>
        patchState({
          quantities: {},
          isInCartRowsHttpProcessing: false,
        })
      ),
      catchError((error) => {
        patchState({ isInCartRowsHttpProcessing: false });
        return throwError(error);
      })
    );
  }

  private getProductsBeingAddedCodeMapFromProductsQuantities(
    productsQuantities: ProductsQuantitiesModel,
    value: boolean
  ): ProductsBeingAddedCodesMap {
    return Object.keys(productsQuantities)
      .map((productCode) => parseInt(productCode, 10))
      .reduce(
        (currentProductGoingToBeAddedCodes, currentProductCode) => ({
          ...currentProductGoingToBeAddedCodes,
          [currentProductCode]: value,
        }),
        {}
      );
  }

  private getAlmostIdenticalQuantities(
    currentProductsQuantities: ProductsQuantitiesModel
  ): ProductsQuantitiesModel {
    return Object.keys(currentProductsQuantities)
      .map((key) => parseInt(key, 10))
      .reduce(
        (quantitiesAccumulator, currentQuantityKey) => ({
          ...quantitiesAccumulator,
          [currentQuantityKey]:
            currentProductsQuantities[currentQuantityKey] + 0.000000001,
        }),
        {}
      );
  }

  private getProductsQuantitiesFromCartRows(
    // tslint:disable-next-line: variable-name
    cart_row_rel: (Pick<Cart_Rows, 'code_product' | 'quantity'>)[]
  ): ProductsQuantitiesModel {
    return cart_row_rel.reduce(
      (quantitiesAccumulator, { code_product, quantity }) => ({
        ...quantitiesAccumulator,
        [code_product]: quantity,
      }),
      {}
    );
  }

  private getCartFromOrder(
    customerCode: string,
    matchingOrder: OrderViewModel
  ): CartModel {
    return {
      code_customer: customerCode,
      note: matchingOrder.note,
      cart_row_rel: matchingOrder.orders_rows_rel
        .filter((orderRow: any) => orderRow.isAvailable)
        .map((orderRow) => ({
          code_product: orderRow.code_product,
          quantity: orderRow.quantity,
        })),
    };
  }

  private productQuantitiesToSetQuantitiesObservablesIfQuantityChanged(
    productsQuantities: ProductsQuantitiesModel,
    originalProductsQuantities: ProductsQuantitiesModel,
    customerCode: string
  ) {
    return Object.keys(productsQuantities)
      .map((productCode) => parseInt(productCode, 10))
      .filter(
        (productCode) =>
          originalProductsQuantities[productCode] !==
          productsQuantities[productCode]
      )
      .map((productCode) => ({
        productCode,
        quantity: productsQuantities[productCode],
      }))
      .map((productQuantity) =>
        this.setQuantity(customerCode, productQuantity)
      );
  }

  private setQuantity(
    customerCode: string,
    productQuantity: ProductQuantityModel
  ): Observable<any> {
    const { productCode, quantity } = productQuantity;

    return this.upsertCartRowGQL.mutate({
      customerCode,
      productCode,
      quantity,
    });
  }

  private removeProductCodeFromQuantities(
    quantities: ProductsQuantitiesModel,
    productCode: number
  ): ProductsQuantitiesModel {
    return Object.keys(quantities)
      .map((quantityProductCode) => parseInt(quantityProductCode, 10))
      .reduce(
        (quantitiesAccumulator, currentProductCode) => ({
          ...quantitiesAccumulator,
          ...(currentProductCode !== productCode
            ? { [currentProductCode]: quantities[currentProductCode] }
            : {}),
        }),
        {}
      );
  }

  // private validateAffectedRows({ data }: CartAddProductMutation) {
  //   const { affected_rows } = data.insert_cart_rows;

  //   if (affected_rows !== 1)
  //     throw new Error(
  //       `Mutation returned ${affected_rows} affected rows instead of 1`
  //     );
  // }
}

// interface CartAddProductMutation {
//   data: UpsertCartRowMutation;
//   // // Is responsibility of the developer to check which of the 2 types is actually used
//   // data: UpsertCartRowMutation & UpdateCartRowQuantityMutation;
// }
