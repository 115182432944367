<div class="ant-table__wrapper order-list--mobile" *ngIf="isMobile">
  <nz-table
    nzSize="small"
    [nzData]="list"
    [nzNoResult]="noOrdersInfoTemplate"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    nzVirtualScroll
    [nzVirtualItemSize]="45"
    [nzVirtualMinBufferPx]="1200"
    [nzVirtualMaxBufferPx]="1200"
    [nzScroll]="{ y: virtualScrollHeight + 'px' }"
    [class.is-mobile]="isMobile"
  >
    <tbody>
      <ng-template nz-virtual-scroll let-order>
        <tr
          [class.selected]="order.id === selectedId"
          class="order-row"
          (click)="orderRowClicked.emit(order.id)"
        >
          <td>
            <div class="order">
              <div class="order__id">
                <label class="order__label">{{
                  'translate.numero' | translate
                }}</label>
                <ng-container
                  *ngTemplateOutlet="
                    order.ispdfavailable
                      ? pdfIconTemplate
                      : pdfIconDisabledTemplate;
                    context: { $implicit: order }
                  "
                >
                </ng-container>

                {{ order.number || '--' }}
              </div>
              <div class="order__date">
                <label class="order__label">
                  {{ 'translate.inserito' | translate }}</label
                >
                <span class="order__date--insert-date">
                  {{ order.date | date: 'dd/MM/yy' }}
                </span>
                <div class="order__date--insert-time">
                  <small>{{ order.date | date: 'HH:mm' }}</small>
                </div>
              </div>
              <div class="order__state">
                <label class="order__label">{{
                  'translate.stato' | translate
                }}</label>
                <span>
                  {{ orderStateLabelMap[order.state] | translate }}
                </span>
              </div>

              <div class="order__total">
                <label class="order__label">{{
                  'translate.totale' | translate
                }}</label>
                <span class="order__total--value">
                  {{ order.total | currency: 'EUR':'symbol':'1.2-2' }}</span
                >

                <app-shopping-cart-clone-icon
                  nz-tooltip
                  nzPlacement="left"
                  [nzTitle]="
                    (isMobile
                      ? null
                      : order.isClonable
                      ? 'translate.duplica_ordine'
                      : 'translate.ordine_non_duplicabile') | translate
                  "
                  [class.disabled]="addDisabled || !order.isClonable"
                  [isAlternativeColor]="
                    !addProductDisabled &&
                    order.isClonable &&
                    lastHoveredCloneOrderAddIconOrderId === order.id
                  "
                  (mouseover)="lastHoveredCloneOrderAddIconOrderId = order.id"
                  (mouseleave)="lastHoveredCloneOrderAddIconOrderId = null"
                  (click)="
                    !addDisabled && order.isClonable
                      ? orderCloneModalOpeningRequest.emit(order.id)
                      : null
                  "
                >
                </app-shopping-cart-clone-icon>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>

<div class="ant-table__wrapper" *ngIf="!isMobile">
  <nz-table
    nzSize="small"
    [nzData]="list"
    [nzNoResult]="noOrdersInfoTemplate"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    nzVirtualScroll
    [nzVirtualItemSize]="45"
    [nzVirtualMinBufferPx]="1200"
    [nzVirtualMaxBufferPx]="1200"
    [nzScroll]="{ y: virtualScrollHeight + 'px' }"
    [class.is-mobile]="isMobile"
  >
    <thead>
      <tr>
        <th class="order-id">
          <span class="order-label">{{ 'translate.numero' | translate }}</span>
        </th>
        <th class="date-insert">{{ 'translate.inserito' | translate }}</th>
        <th class="order-state">{{ 'translate.stato' | translate }}</th>
        <!-- <th class="order-total">
          <span class="order-total-label">{{
            'translate.totale' | translate
          }}</span>
        </th> -->
      </tr>
    </thead>

    <tbody>
      <ng-template nz-virtual-scroll let-order>
        <tr
          [class.selected]="order.id === selectedId"
          class="order-row"
          (click)="orderRowClicked.emit(order.id)"
        >
          <td class="order-id-cell">
            <ng-container
              *ngTemplateOutlet="
                order.ispdfavailable
                  ? pdfIconTemplate
                  : pdfIconDisabledTemplate;
                context: { $implicit: order }
              "
            >
            </ng-container>

            <nz-divider nzType="vertical"></nz-divider>

            {{ order.number || '--' }}
          </td>

          <td class="date-insert-cell">
            <span class="date-insert-date">
              {{ order.date | date: 'dd/MM/yy' }}
            </span>
            <span class="date-insert-time">
              {{ order.date | date: 'HH:mm' }}
            </span>
          </td>

          <td class="order-state-cell">
            {{ orderStateLabelMap[order.state] | translate }}
          </td>

          <td class="order-total-cell">
            <!-- <span class="total-value">
              {{ order.total | currency: 'EUR':'symbol':'1.2-2' }}</span
            > -->

            <nz-divider nzType="vertical" class="total-divider"></nz-divider>

            <app-shopping-cart-clone-icon
              nz-tooltip
              nzPlacement="left"
              [nzTitle]="
                (isMobile
                  ? null
                  : order.isClonable
                  ? 'translate.duplica_ordine'
                  : 'translate.ordine_non_duplicabile') | translate
              "
              [class.disabled]="addDisabled || !order.isClonable"
              [isAlternativeColor]="
                !addProductDisabled &&
                order.isClonable &&
                lastHoveredCloneOrderAddIconOrderId === order.id
              "
              (mouseover)="lastHoveredCloneOrderAddIconOrderId = order.id"
              (mouseleave)="lastHoveredCloneOrderAddIconOrderId = null"
              (click)="
                !addDisabled && order.isClonable
                  ? orderCloneModalOpeningRequest.emit(order.id)
                  : null
              "
            >
            </app-shopping-cart-clone-icon>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>

<ng-template #noOrdersInfoTemplate>
  <nz-empty
    [nzNotFoundContent]="'translate.nessun_ordine_trovato' | translate"
  ></nz-empty>
</ng-template>

<ng-template #pdfIconTemplate let-order>
  <a (click)="pdfDownloadRequest.emit(order)">
    <i
      nz-tooltip
      [nzTitle]="'translate.visualizza_documento' | translate"
      nz-icon
      nzType="file-pdf"
      class="pdf-icon"
      nzTheme="twotone"
      [nzTwotoneColor]="'#D93025'"
    >
    </i>
  </a>
</ng-template>

<ng-template #pdfIconDisabledTemplate>
  <i
    nz-icon
    nz-tooltip
    [nzTitle]="'translate.documento_non_disponibile' | translate"
    nzType="file-pdf"
    class="pdf-icon disabled"
    nzTheme="outline"
  >
  </i>
</ng-template>

<nz-modal
  class="order-clone-modal"
  [nzVisible]="isOrderCloneModalVisible"
  [nzTitle]="cloneOrderModalTitleTemplate"
  [nzContent]="cloneOrderModalContentTemplate"
  [nzCancelText]="null"
  [nzOkLoading]="isCartLoading"
  [nzClosable]="!isCartLoading"
  [nzMaskClosable]="!isCartLoading"
  nzOkText="{{ 'translate.prosegui' | translate }}"
  (nzOnCancel)="orderCloneModalOpeningRequest.emit(null)"
  (nzOnOk)="orderCloneRequest.emit()"
>
</nz-modal>

<nz-modal
  class="order-clone-courtesy-modal"
  [nzVisible]="isOrderCloneCourtesyModalVisible"
  [nzTitle]="cloneOrderCourtesyModalTitleTemplate"
  [nzContent]="cloneOrderCourtesyModalContentTemplate"
  [nzFooter]="cloneOrderCourtesyModalFooterTemplate"
  (nzOnCancel)="orderCloneCourtesyModalOpeningRequest.emit(false)"
>
</nz-modal>

<ng-template #cloneOrderModalTitleTemplate>
  <h2 class="order-clone-modal-title">
    {{ 'translate.duplica_ordine' | translate }}
  </h2>
</ng-template>

<ng-template #cloneOrderModalContentTemplate>
  <p class="order-clone-description">
    {{ 'translate.duplica_descrizione' | translate }}
  </p>

  <nz-divider
    nzType="horizontal"
    [nzText]="'translate.attenzione' | translate"
    nzDashed
  ></nz-divider>

  <p class="order-clone-notice">
    {{ 'translate.duplica_descrizione_attenzione' | translate }}
  </p>
</ng-template>

<ng-template #cloneOrderCourtesyModalTitleTemplate>
  <h2 class="order-clone-modal-title">
    {{ 'translate.ordine_duplicato' | translate }}
  </h2>
</ng-template>

<ng-template #cloneOrderCourtesyModalContentTemplate>
  <p class="order-clone-courtesy-message">
    {{ 'translate.ordine_duplicato_messaggio' | translate }}
  </p>
</ng-template>

<ng-template #cloneOrderCourtesyModalFooterTemplate>
  <button nz-button nzType="primary" (click)="goToProductsListRequest.emit()">
    {{ 'translate.vai_alla_lista_dei_prodotti' | translate }}
  </button>
  <button nz-button nzType="primary" (click)="goToCartRequest.emit()">
    {{ 'translate.vai_al_carrello' | translate }}
  </button>
</ng-template>
