/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-container.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/header/header.component.ngfactory";
import * as i3 from "../../components/header/header.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "ng-zorro-antd/icon";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./main-container.component";
import * as i10 from "../../../authentication/services/authentication.service";
import * as i11 from "../../state/handlers/notification.handler";
import * as i12 from "../../../shared/services/translate-manager.service";
import * as i13 from "angular-gtag";
import * as i14 from "@ngxs/store";
var styles_MainContainerComponent = [i0.styles];
var RenderType_MainContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainContainerComponent, data: {} });
export { RenderType_MainContainerComponent as RenderType_MainContainerComponent };
function View_MainContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-header", [], null, [[null, "logoutClicked"], [null, "changeLanguageClicked"], [null, "changeCustomerClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logoutClicked" === en)) {
        var pd_0 = (_co.authenticationService.logout() !== false);
        ad = (pd_0 && ad);
    } if (("changeLanguageClicked" === en)) {
        var pd_1 = (_co.handleChangeLanguageClicked() !== false);
        ad = (pd_1 && ad);
    } if (("changeCustomerClicked" === en)) {
        var pd_2 = (_co.handleChangeCustomerClicked($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderComponent, [], { logoUrl: [0, "logoUrl"], currentCustomer: [1, "currentCustomer"], otherAvailableCustomers: [2, "otherAvailableCustomers"], userEmail: [3, "userEmail"], productsCount: [4, "productsCount"], ordersCount: [5, "ordersCount"], languageLabel: [6, "languageLabel"] }, { logoutClicked: "logoutClicked", changeLanguageClicked: "changeLanguageClicked", changeCustomerClicked: "changeCustomerClicked" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoUrl; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.currentCustomer$)); var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 3).transform(_co.otherAvailableCustomers$)); var currVal_3 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 4).transform(_co.userEmail$)); var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 5).transform(_co.productsCount$)); var currVal_5 = i1.ɵunv(_v, 1, 5, i1.ɵnov(_v, 6).transform(_co.ordersCount$)); var currVal_6 = i1.ɵunv(_v, 1, 6, i1.ɵnov(_v, 7).transform(_co.translateManagerService.languageLabel$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_MainContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content"]], [[4, "min-height", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.minContentHeight + "px"); _ck(_v, 0, 0, currVal_0); }); }
function View_MainContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "ant-notification-notice-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "ant-notification-notice-content ant-notification-notice-with-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "ant-notification-notice-with-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "ant-notification-notice-icon ant-notification-notice-icon-success"], ["nz-icon", ""], ["type", "check-circle"]], null, null, null, null, null)), i1.ɵdid(4, 2834432, null, 0, i6.NzIconDirective, [i6.NzIconService, i1.ElementRef, i1.Renderer2, i7.Platform], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "ant-notification-notice-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "ant-notification-notice-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "a", [["style", "text-decoration: underline;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.notificationHandler.closeNotifications() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(14, null, [" ", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [". "]))], function (_ck, _v) { var currVal_0 = "check-circle"; _ck(_v, 4, 0, currVal_0); var currVal_5 = _ck(_v, 13, 0, "/"); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("translate.orders_update_notification_success_title")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("translate.orders_update_notification_success_content_1")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 12).target; var currVal_4 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("translate.orders_update_notification_success_content_2")); _ck(_v, 14, 0, currVal_6); }); }
export function View_MainContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { ordersUpdateNotificationTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainContainerComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainContainerComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [[1, 2], ["ordersUpdateNotificationTemplate", 2]], null, 0, null, View_MainContainerComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isCustomerChangeInProgress$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.isCustomerChangeInProgress$)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_MainContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-container", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).calculateContentMinHeight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MainContainerComponent_0, RenderType_MainContainerComponent)), i1.ɵdid(1, 4440064, null, 0, i9.MainContainerComponent, [i10.AuthenticationService, i11.NotificationHandler, i12.TranslateManagerService, i13.Gtag, i14.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainContainerComponentNgFactory = i1.ɵccf("app-main-container", i9.MainContainerComponent, View_MainContainerComponent_Host_0, {}, {}, []);
export { MainContainerComponentNgFactory as MainContainerComponentNgFactory };
