<nz-card class="info-card">
  <h2>{{ 'translate.informazioni_dell_ordine' | translate }}</h2>

  <div class="info-container">
    <div class="note-container">
      <h4>{{ 'translate.customer_order_number' | translate }}</h4>
      <input
        nz-input
        readonly
        [value]="
          (order.customer_order_number && order.customer_order_number.trim()) ||
          '--'
        "
        style="margin-bottom: 6px;"
      />

      <h4>{{ 'translate.note' | translate }}</h4>
      <textarea
        nz-input
        readonly
        rows="3"
        [value]="(order.note && order.note.trim()) || '--'"
      ></textarea>
    </div>

    <div class="dates-container">
      <div class="date-for-container">
        <h4>{{ 'translate.data_di_consegna_desiderata' | translate }}</h4>
        <input
          nz-input
          readonly
          [value]="
            (order.date_for && formatDate(order.date_for, 'dd/MM/yy')) || '--'
          "
        />
      </div>

      <div class="date-delivery-container">
        <h4>{{ 'translate.data_di_consegna_prevista' | translate }}</h4>
        <input
          nz-input
          readonly
          [value]="
            (order.date_delivery &&
              formatDate(order.date_delivery, 'dd/MM/yy')) ||
            '--'
          "
        />
      </div>
    </div>
  </div>
</nz-card>

<nz-card class="products-list-card" *ngIf="order.orders_rows_rel as products">
  <h2>{{ 'translate.prodotti_dell_ordine' | translate }}</h2>
  <app-products-filters
    [list]="productsFiltersList"
    [groups]="productsGroups"
    [filteringGroupsCodes]="productsFilters.filteringGroupsCodes"
    [filteringPartialProductCode]="productsFilters.filteringPartialProductCode"
    [filteringPartialDescription]="productsFilters.filteringPartialDescription"
    [areExtraSmallFilters]="true"
    (groupsFilterChange)="
      productsGroupsFilterChange.emit({
        orderId: order.id,
        productsGroups: $event
      })
    "
    (productCodeFilterChange)="
      productCodeFilterChange.emit({ orderId: order.id, productCode: $event })
    "
    (descriptionFilterChange)="
      productDescriptionFilterChange.emit({
        orderId: order.id,
        productDescription: $event
      })
    "
  ></app-products-filters>

  <cdk-virtual-scroll-viewport
    #orderProductsList
    [class.empty]="!products.length"
    [itemSize]="itemSize"
    [minBufferPx]="1200"
    [maxBufferPx]="1200"
    [style.height]="virtualScrollHeight + 'px'"
  >
    <nz-list>
      <nz-list-item
        *cdkVirtualFor="let product of products"
        [nzActions]="[addProductActionTemplate]"
      >
        <div class="picture-wrapper">
          <ng-container
            *ngTemplateOutlet="
              product.image_medium
                ? pictureTemplate
                : pictureIconDisabledTemplate;
              context: { $implicit: product.image_medium }
            "
          ></ng-container>
        </div>

        <nz-list-item-meta
          [nzTitle]="product.code_product + ' - ' + product.description_product"
          [nzDescription]="pricesTemplate"
        >
        </nz-list-item-meta>

        <ng-container
          #addProductActionTemplate
          *ngTemplateOutlet="
            cartQuantities[product.code_product]
              ? checkIconTemplate
              : addIconTemplate
          "
        ></ng-container>

        <ng-template #addIconTemplate>
          <app-shopping-cart-add-icon
            nz-tooltip
            [nzTitle]="
              (product.isAvailable
                ? 'translate.aggiungi_lo_stesso_al_carrello'
                : 'translate.prodotto_non_piu_presente_nel_listino') | translate
            "
            [class.disabled]="addProductDisabled || !product.isAvailable"
            [isAlternativeColor]="
              !addProductDisabled &&
              product.isAvailable &&
              lastHoveredAddIconProductCode === product.code_product
            "
            (mouseover)="lastHoveredAddIconProductCode = product.code_product"
            (mouseleave)="lastHoveredAddIconProductCode = null"
            (click)="
              addProductClicked.emit({
                productCode: product.code_product,
                quantity: product.quantity
              })
            "
          >
          </app-shopping-cart-add-icon>
        </ng-template>

        <ng-template #pricesTemplate>
          <ng-container>
            <!-- <span
              *ngIf="product.discount || product.offer"
              class="initial-price-value"
              >{{ product.price | currency: 'EUR':'symbol':'1.5-5' }}</span
            >
            <div
              *ngIf="product.discount"
              class="tag-blue"
              nz-tooltip
              nzTitle="Sconto"
            >
              <span>-{{ product.discount }}%</span>
            </div>

            <div
              *ngIf="product.offer"
              class="tag-green"
              nz-tooltip
              nzTitle="Offerta"
            >
              <span>-{{ product.offer }}%</span>
            </div>

            <span class="price-value">{{
              (product.discount || product.offer ? ': ' : '') +
                (product.price_discount | currency: 'EUR':'symbol':'1.5-5')
            }}</span> -->

            <div class="total-info">
              <span class="quantity"
                >{{ 'translate.quantita' | translate }}:
                {{ product.quantity.toFixed(3).replace('.', ',') }}
                {{ product.unit_of_measure }}</span
              >

              <!-- <nz-divider nzType="vertical"></nz-divider>

              <label class="product-quantities-total"
                >{{ 'translate.totale' | translate }}:
                {{
                  product.price_discount * product.quantity
                    | currency: 'EUR':'symbol':'1.2-2'
                }}
              </label> -->
            </div>
          </ng-container>
        </ng-template>
      </nz-list-item>
    </nz-list>

    <nz-empty
      *ngIf="!products.length"
      nzNotFoundContent="{{
        'translate.nessun_prodotto_presente_nell_ordine' | translate
      }}"
    >
    </nz-empty>
  </cdk-virtual-scroll-viewport>
</nz-card>

<ng-template #pictureTemplate let-imagePath>
  <img
    alt="{{ 'translate.immagine_del_prodotto' | translate }}"
    class="loading-background"
    [src]="imageBaseUrl + imagePath"
  />
</ng-template>

<ng-template #pictureIconDisabledTemplate>
  <i
    nz-icon
    nz-tooltip
    nzTitle="{{ 'translate.immagine_non_disponibile' | translate }}"
    nzType="picture"
    class="picture-icon-disabled"
    nzTheme="outline"
  >
  </i>
</ng-template>

<ng-template #checkIconTemplate>
  <app-shopping-cart-check-icon
    nz-tooltip
    nzPlacement="left"
    nzTitle="{{ 'translate.prodotto_gia_presente' | translate }}"
  >
  </app-shopping-cart-check-icon>
</ng-template>
