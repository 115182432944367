import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { CustomerModel } from '../../types/customer.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input() logoUrl: string;

  @Input() currentCustomer: CustomerModel;
  @Input() otherAvailableCustomers: CustomerModel[] = [];
  @Input() userEmail: string;
  @Input() productsCount: number;
  @Input() ordersCount: number;
  @Input() languageLabel: string;

  @Output() logoutClicked = new EventEmitter<void>();
  @Output() changeLanguageClicked = new EventEmitter<void>();
  @Output() changeCustomerClicked = new EventEmitter<string>();

  isMobile = isMobileCheck();
}
