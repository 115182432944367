import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-products-cart-cta',
  templateUrl: './products-cart-cta.component.html',
  styleUrls: ['./products-cart-cta.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsCartCtaComponent {
  @Input() enabled: boolean;
}
