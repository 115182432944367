import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { actionMatcher, Actions, ofActionCompleted } from '@ngxs/store';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { merge, Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import {
  CartAddProduct,
  CartChangeDateFor,
  CartChangeNote,
  CartClearProducts,
  CartCloneOrder,
  CartRemoveProduct,
  CartSetProductsQuantities
} from 'src/app/features/cart/state/cart.actions';
import {
  OrdersNewOrderFromCart,
  OrdersUpdateNotification
} from 'src/app/features/orders/state/orders.actions';
import { CoreChangeCustomer } from '../core.actions';

export interface NotificationMessage {
  title: string;
  content?: string;
  template?: TemplateRef<any>;
  isInfiniteDuration?: boolean;
}

export interface Notification {
  action: any;
  successMessage?: NotificationMessage;
  errorMessage?: NotificationMessage;
}

@Injectable({ providedIn: 'root' })
export class NotificationHandler {
  notifications: Notification[] = [
    // Need to declare here because for this action a template is set
    // by the main container component after view init.
    {
      action: OrdersUpdateNotification
    }
  ];

  constructor(
    private actions$: Actions,
    private notificationService: NzNotificationService,
    private messageService: NzMessageService,
    private translateService: TranslateService
  ) {
    merge(
      this.translateService.getTranslation(this.translateService.currentLang),
      this.translateService.onLangChange
    )
      .pipe(
        tap(data => {
          const translate = data.translate || data.translations.translate;
          this.setNotificationMessagesByTranslate(translate);
        }),
        switchMap(_ => {
          const actions = this.notifications.map(
            notification => notification.action
          );

          return this.registerActionsNotifications(actions);
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }

  closeNotifications(): void {
    this.notificationService.remove();
  }

  private registerActionsNotifications(actions: any[]): Observable<any> {
    return this.actions$.pipe(
      ofActionCompleted(...actions),
      debounceTime(200),
      tap(({ result, action }) => {
        const matchingNotification = this.notifications.find(notification =>
          actionMatcher(notification.action)(action)
        );

        this.handleNotification(
          matchingNotification.successMessage,
          matchingNotification.errorMessage,
          result
        );
      })
    );
  }

  private setNotificationMessagesByTranslate(translate: any): void {
    this.notifications = this.notifications
      .filter(notification => notification.action === OrdersUpdateNotification)
      .concat([
        // Only title -> message
        // Also content -> notification
        {
          action: CartAddProduct,
          successMessage: {
            title: translate.cart_add_product_success_title
            // content: 'Troverai il prodotto nel tuo carrello.'
          },
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_add_product_error_content
          }
        },
        {
          action: CartSetProductsQuantities,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_set_product_quantities_error_content
          }
        },
        {
          action: CartChangeDateFor,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_change_date_for_error_content
          }
        },
        {
          action: CartChangeNote,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_change_note_error_content
          }
        },
        {
          action: CartRemoveProduct,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_remove_product_error_content
          }
        },
        {
          action: CartClearProducts,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_clear_products_error_content
          }
        },
        {
          action: CartCloneOrder,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.cart_clone_order_error_content
          }
        },
        {
          action: OrdersNewOrderFromCart,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.orders_new_order_from_cart_error_content
          }
        },
        {
          action: CoreChangeCustomer,
          errorMessage: {
            title: translate.action_error_title,
            content: translate.core_change_customer_error_content
          }
        }
      ]);
  }

  private handleNotification(
    successMessage: NotificationMessage,
    errorMessage: NotificationMessage,
    result: {
      successful: boolean;
      canceled: boolean;
      error?: any;
    }
  ): void {
    const { successful, error } = result;
    if (!successful && !error) return;

    const message = successful ? successMessage : errorMessage;
    if (!message) return;

    // Removes other infinite duration messages that may
    // be pending before pushing this one
    if (message.isInfiniteDuration) {
      this.notificationService.remove();
      this.messageService.remove();
    }

    if (message.template) {
      this.notificationService.template(
        message.template,
        message.isInfiniteDuration ? { nzDuration: 0 } : {}
      );
      return;
    }

    const service = message.content
      ? this.notificationService
      : this.messageService;

    const notificationMethod = successful
      ? service.success.bind(service)
      : service.error.bind(service);

    if (message.content) {
      notificationMethod(
        message.title,
        message.content,
        message.isInfiniteDuration ? { nzDuration: 0 } : {}
      );
    } else {
      notificationMethod(
        message.title,
        message.isInfiniteDuration ? { nzDuration: 0 } : {}
      );
    }
  }
}
