import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClientOptions } from 'apollo-client';
import { ApolloLink, from, split } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { isKnownJwtError } from '../shared/helpers/is-known-jwt-error';

export const getApolloOptions = (
  httpLink: HttpLink,
  authenticationService: AuthenticationService
): ApolloClientOptions<any> => {
  const http = getHttpLink(httpLink);
  const ws = getWebSocketLink(authenticationService);
  const link = getWsHttpSplitLink(ws, http);
  const authMiddleware = getAuthMiddleware(authenticationService);
  const retryLink = getJwtErrorRetryLink(authenticationService);

  return {
    link: from([retryLink, authMiddleware, link]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      watchQuery: { fetchPolicy: 'no-cache' }
    }
  };
};

@NgModule({
  exports: [BrowserModule, HttpClientModule, ApolloModule, HttpLinkModule]
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    authenticationService: AuthenticationService
  ) {
    apollo.create(getApolloOptions(httpLink, authenticationService));
  }
}

function getJwtErrorRetryLink(authenticationService: AuthenticationService) {
  return new RetryLink({
    // tslint:disable-next-line: variable-name
    attempts: async (count, _operation, error) => {
      if (count > 1) return false;

      if (isKnownJwtError(error)) {
        await authenticationService.forceRenewTokens();
        return true;
      }

      return false;
    },
    delay: {
      initial: 0
    }
  });
}

function getAuthMiddleware(authenticationService: AuthenticationService) {
  return new ApolloLink((operation, forward) => {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${authenticationService.idToken}`
    );

    operation.setContext({
      headers
    });

    return forward(operation);
  });
}

function getWsHttpSplitLink(ws: WebSocketLink, http) {
  return split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    ws,
    http
  );
}

function getWebSocketLink(authenticationService: AuthenticationService) {
  return new WebSocketLink({
    uri: 'wss://' + environment.graphqlUri,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => ({
        headers: {
          Authorization: `Bearer ${authenticationService.idToken}`
        }
      })
    }
  });
}

function getHttpLink(httpLink: HttpLink) {
  return httpLink.create({ uri: 'https://' + environment.graphqlUri });
}
