import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgArrayPipesModule } from 'angular-pipes';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CommaInputNumberComponent } from './components/comma-input-number/comma-input-number.component';
import { ShoppingCartAddIconComponent } from './components/icons/shopping-cart-add-icon.component';
import { ShoppingCartCheckIconComponent } from './components/icons/shopping-cart-check-icon.component';
import { ShoppingCartCloneIconComponent } from './components/icons/shopping-cart-clone-icon.component';
import { DigitOnlyDirective } from './directives/digit-only.directive';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    NgZorroAntdModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ScrollingModule,
    NgArrayPipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    NgZorroAntdModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ScrollingModule,
    NgArrayPipesModule,
    DigitOnlyDirective,
    CommaInputNumberComponent,
    ShoppingCartCheckIconComponent,
    ShoppingCartAddIconComponent,
    ShoppingCartCloneIconComponent,
    TranslateModule
  ],
  declarations: [
    DigitOnlyDirective,
    CommaInputNumberComponent,
    ShoppingCartCheckIconComponent,
    ShoppingCartAddIconComponent,
    ShoppingCartCloneIconComponent
  ]
})
export class SharedModule {}
