import { Injector } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProductsListFilterService } from '../services/products-list-filter.service';
import { ProductGroupModel } from '../types/product-group.model';
import { ProductOverviewModel } from '../types/product-overview.model';
import { ProductsQuantitiesModel } from '../types/products-quantities.model';
import {
  ProductsChangeFilters,
  ProductsReset,
  ProductsSetGroups,
  ProductsSetList,
  ProductsSetMissingQuantities,
  ProductsSetQuantity
} from './products.actions';

export interface ProductsStateModel {
  list: ProductOverviewModel[];
  groups: ProductGroupModel[];
  filteringGroupsCodes: string[];
  filteringPartialProductCode: number;
  filteringPartialDescription: string;
  quantities: ProductsQuantitiesModel;
}

const productsStateModelDefaults: ProductsStateModel = {
  list: [],
  groups: [],
  filteringGroupsCodes: [],
  filteringPartialProductCode: null,
  filteringPartialDescription: null,
  quantities: {}
};

@State<ProductsStateModel>({
  name: 'products',
  defaults: productsStateModelDefaults
})
export class ProductsState {
  private static productsListFilterService: ProductsListFilterService;

  constructor(injector: Injector) {
    ProductsState.productsListFilterService = injector.get(
      ProductsListFilterService
    );
  }

  @Selector()
  static list({ list }: ProductsStateModel): ProductOverviewModel[] {
    return list;
  }

  @Selector()
  static listFiltered({
    list,
    filteringGroupsCodes,
    filteringPartialProductCode,
    filteringPartialDescription
  }: ProductsStateModel): ProductOverviewModel[] {
    return ProductsState.productsListFilterService.filter({
      list,
      groupsCodes: filteringGroupsCodes,
      partialProductCode: filteringPartialProductCode,
      partialDescription: filteringPartialDescription
    });
  }

  @Selector()
  static groups({ groups }: ProductsStateModel): ProductGroupModel[] {
    return groups;
  }

  @Selector()
  static filteringPartialProductCode({
    filteringPartialProductCode
  }: ProductsStateModel): number {
    return filteringPartialProductCode;
  }

  @Selector()
  static filteringPartialDescription({
    filteringPartialDescription
  }: ProductsStateModel): string {
    return filteringPartialDescription;
  }

  @Selector()
  static filteringGroupsCodes({
    filteringGroupsCodes
  }: ProductsStateModel): string[] {
    return filteringGroupsCodes;
  }

  @Selector()
  static quantities({
    quantities
  }: ProductsStateModel): ProductsQuantitiesModel {
    return quantities;
  }

  @Action(ProductsReset)
  reset({ setState }: StateContext<ProductsStateModel>): void {
    setState(productsStateModelDefaults);
  }

  @Action(ProductsSetGroups)
  setGroups(
    { patchState, getState }: StateContext<ProductsStateModel>,
    { groups }: ProductsSetGroups
  ): void {
    const { filteringGroupsCodes } = getState();

    const normalizedFilteringGroupsCodes = filteringGroupsCodes.filter(code =>
      groups.some(group => group.code === code)
    );

    patchState({
      groups,
      filteringGroupsCodes: normalizedFilteringGroupsCodes
    });
  }

  @Action(ProductsSetList)
  setList(
    { patchState }: StateContext<ProductsStateModel>,
    { list }: ProductsSetList
  ): void {
    const listWithOnlyPricedProducts = list.filter(
      product => product.price_rel[0]
    );

    const listWithMinQuantities = this.processMinQuantities(
      listWithOnlyPricedProducts
    );

    const listWithMinQuantitiesAndUnitOfMeasurePrettify = this.processUnitOfMeasure(
      listWithMinQuantities
    );

    const todayPastMidnightDate = new Date().setUTCHours(0, 0, 0, 0);

    const listWithMinQuantitiesAndUnitOfMeasurePrettifyAndOfferIsValidProcessing = this.processOfferValidity(
      listWithMinQuantitiesAndUnitOfMeasurePrettify,
      todayPastMidnightDate
    );

    // const {
    //   filteringGroupsCodes,
    //   filteringPartialProductCode,
    //   filteringPartialDescription
    // } = getState();

    patchState({
      list: listWithMinQuantitiesAndUnitOfMeasurePrettifyAndOfferIsValidProcessing
    });
  }

  @Action(ProductsSetMissingQuantities)
  setMissingQuantities({
    patchState,
    getState
  }: StateContext<ProductsStateModel>): void {
    const { list, quantities } = getState();

    const defaultQuantities = list.reduce(
      (accumulator, { code, minQuantity }) => ({
        ...accumulator,
        [code]: minQuantity
      }),
      {}
    );

    patchState({ quantities: { ...defaultQuantities, ...quantities } });
  }

  @Action(ProductsSetQuantity)
  setQuantity(
    { patchState, getState }: StateContext<ProductsStateModel>,
    { productQuantity }: ProductsSetQuantity
  ): void {
    const { productCode, quantity } = productQuantity;
    const { quantities } = getState();
    patchState({ quantities: { ...quantities, [productCode]: quantity } });
  }

  @Action(ProductsChangeFilters)
  changeFilters(
    { getState, patchState }: StateContext<ProductsStateModel>,
    { filters }: ProductsChangeFilters
  ): void {
    const {
      filteringGroupsCodes: originalFilteringGroupsCodes,
      filteringPartialProductCode: originalFilteringPartialProductCode,
      filteringPartialDescription: originalFilteringPartialDescription
    } = getState();

    patchState(
      ProductsState.productsListFilterService.getCalculatedFilters(
        filters,
        originalFilteringGroupsCodes,
        originalFilteringPartialProductCode,
        originalFilteringPartialDescription
      )
    );
  }

  private processUnitOfMeasure(listWithMinQuantities: ProductOverviewModel[]) {
    return listWithMinQuantities.reduce(
      (accumulator, currentProduct) => [
        ...accumulator,
        {
          ...currentProduct,
          unit_of_measure:
            !currentProduct.unit_of_measure ||
            currentProduct.unit_of_measure === 'NR'
              ? 'N'
              : currentProduct.unit_of_measure
        }
      ],
      []
    );
  }

  private processMinQuantities(
    list: ProductOverviewModel[]
  ): ProductOverviewModel[] {
    return list.reduce(
      (accumulator, currentProduct) => [
        ...accumulator,
        {
          ...currentProduct,
          minQuantity:
            !currentProduct.multiple_value ||
            currentProduct.multiple_value === 0.001
              ? 1
              : currentProduct.multiple_value
        }
      ],
      []
    );
  }

  private processOfferValidity(
    list: ProductOverviewModel[],
    todayPastMidnightDate: number
  ): ProductOverviewModel[] {
    return list.map(product => {
      const offerTo = product.price_rel[0].offer_to;

      product.price_rel[0].offerIsValid =
        offerTo && todayPastMidnightDate <= Date.parse(offerTo);

      return product;
    });
  }
}
