import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CartState } from '../state/cart.state';

@Injectable({ providedIn: 'root' })
export class EmptyCartCheckResolve implements Resolve<boolean> {
  constructor(private store: Store) {}

  async resolve() {
    const cartProductsCount = this.store.selectSnapshot(
      CartState.productsCount
    );

    if (cartProductsCount < 1) {
      this.store.dispatch(new Navigate(['/']));
      return false;
    }

    return true;
  }
}
