import { Actions, ofActionErrored, ofActionSuccessful, Store } from '@ngxs/store';
import { delay, tap } from 'rxjs/operators';
import { CartCloneOrder, CartSetOrderCourtesyModalOpening, CartSetOrderSubmitModalOpening } from 'src/app/features/cart/state/cart.actions';
import { OrdersNewOrderFromCart, OrdersSetOrderCloneCourtesyModalOpening, OrdersSetOrderCloneModalOpening } from 'src/app/features/orders/state/orders.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var ModalsHandler = /** @class */ (function () {
    function ModalsHandler(actions$, store) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.actions$
            .pipe(ofActionSuccessful(OrdersNewOrderFromCart), tap(function (_) {
            _this.store.dispatch(new CartSetOrderSubmitModalOpening(false));
        }), delay(200), tap(function (_) {
            return _this.store.dispatch(new CartSetOrderCourtesyModalOpening(true));
        }))
            .subscribe();
        this.actions$
            .pipe(ofActionErrored(OrdersNewOrderFromCart), tap(function (_) {
            _this.store.dispatch(new CartSetOrderSubmitModalOpening(false));
        }))
            .subscribe();
        this.actions$
            .pipe(ofActionSuccessful(CartCloneOrder), tap(function (_) {
            _this.store.dispatch(new OrdersSetOrderCloneModalOpening(null));
        }), delay(200), tap(function (_) {
            return _this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(true));
        }))
            .subscribe();
        this.actions$
            .pipe(ofActionErrored(CartCloneOrder), tap(function (_) {
            _this.store.dispatch(new OrdersSetOrderCloneModalOpening(null));
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    ModalsHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalsHandler_Factory() { return new ModalsHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i1.Store)); }, token: ModalsHandler, providedIn: "root" });
    return ModalsHandler;
}());
export { ModalsHandler };
