import { Injectable } from '@angular/core';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  CartClearProducts,
  CartRemoveProduct
} from 'src/app/features/cart/state/cart.actions';
import { CartState } from 'src/app/features/cart/state/cart.state';

@Injectable({ providedIn: 'root' })
export class EmptyCartHandler {
  constructor(private actions$: Actions, private store: Store) {
    this.actions$
      .pipe(
        ofActionSuccessful(CartRemoveProduct, CartClearProducts),
        tap(_ => {
          const cartProductsCount = this.store.selectSnapshot(
            CartState.productsCount
          );

          if (
            cartProductsCount < 1 &&
            this.store.selectSnapshot(RouterState.url) === '/cart'
          )
            this.store.dispatch(new Navigate(['/products']));
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }
}
