import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { Action, Selector, State } from '@ngxs/store';
import { ProductsListFilterService } from '../services/products-list-filter.service';
import { ProductsChangeFilters, ProductsReset, ProductsSetGroups, ProductsSetList, ProductsSetMissingQuantities, ProductsSetQuantity } from './products.actions';
var productsStateModelDefaults = {
    list: [],
    groups: [],
    filteringGroupsCodes: [],
    filteringPartialProductCode: null,
    filteringPartialDescription: null,
    quantities: {}
};
var ProductsState = /** @class */ (function () {
    function ProductsState(injector) {
        ProductsState_1.productsListFilterService = injector.get(ProductsListFilterService);
    }
    ProductsState_1 = ProductsState;
    ProductsState.list = function (_a) {
        var list = _a.list;
        return list;
    };
    ProductsState.listFiltered = function (_a) {
        var list = _a.list, filteringGroupsCodes = _a.filteringGroupsCodes, filteringPartialProductCode = _a.filteringPartialProductCode, filteringPartialDescription = _a.filteringPartialDescription;
        return ProductsState_1.productsListFilterService.filter({
            list: list,
            groupsCodes: filteringGroupsCodes,
            partialProductCode: filteringPartialProductCode,
            partialDescription: filteringPartialDescription
        });
    };
    ProductsState.groups = function (_a) {
        var groups = _a.groups;
        return groups;
    };
    ProductsState.filteringPartialProductCode = function (_a) {
        var filteringPartialProductCode = _a.filteringPartialProductCode;
        return filteringPartialProductCode;
    };
    ProductsState.filteringPartialDescription = function (_a) {
        var filteringPartialDescription = _a.filteringPartialDescription;
        return filteringPartialDescription;
    };
    ProductsState.filteringGroupsCodes = function (_a) {
        var filteringGroupsCodes = _a.filteringGroupsCodes;
        return filteringGroupsCodes;
    };
    ProductsState.quantities = function (_a) {
        var quantities = _a.quantities;
        return quantities;
    };
    ProductsState.prototype.reset = function (_a) {
        var setState = _a.setState;
        setState(productsStateModelDefaults);
    };
    ProductsState.prototype.setGroups = function (_a, _b) {
        var patchState = _a.patchState, getState = _a.getState;
        var groups = _b.groups;
        var filteringGroupsCodes = getState().filteringGroupsCodes;
        var normalizedFilteringGroupsCodes = filteringGroupsCodes.filter(function (code) {
            return groups.some(function (group) { return group.code === code; });
        });
        patchState({
            groups: groups,
            filteringGroupsCodes: normalizedFilteringGroupsCodes
        });
    };
    ProductsState.prototype.setList = function (_a, _b) {
        var patchState = _a.patchState;
        var list = _b.list;
        var listWithOnlyPricedProducts = list.filter(function (product) { return product.price_rel[0]; });
        var listWithMinQuantities = this.processMinQuantities(listWithOnlyPricedProducts);
        var listWithMinQuantitiesAndUnitOfMeasurePrettify = this.processUnitOfMeasure(listWithMinQuantities);
        var todayPastMidnightDate = new Date().setUTCHours(0, 0, 0, 0);
        var listWithMinQuantitiesAndUnitOfMeasurePrettifyAndOfferIsValidProcessing = this.processOfferValidity(listWithMinQuantitiesAndUnitOfMeasurePrettify, todayPastMidnightDate);
        // const {
        //   filteringGroupsCodes,
        //   filteringPartialProductCode,
        //   filteringPartialDescription
        // } = getState();
        patchState({
            list: listWithMinQuantitiesAndUnitOfMeasurePrettifyAndOfferIsValidProcessing
        });
    };
    ProductsState.prototype.setMissingQuantities = function (_a) {
        var patchState = _a.patchState, getState = _a.getState;
        var _b = getState(), list = _b.list, quantities = _b.quantities;
        var defaultQuantities = list.reduce(function (accumulator, _a) {
            var _b;
            var code = _a.code, minQuantity = _a.minQuantity;
            return (tslib_1.__assign({}, accumulator, (_b = {}, _b[code] = minQuantity, _b)));
        }, {});
        patchState({ quantities: tslib_1.__assign({}, defaultQuantities, quantities) });
    };
    ProductsState.prototype.setQuantity = function (_a, _b) {
        var _c;
        var patchState = _a.patchState, getState = _a.getState;
        var productQuantity = _b.productQuantity;
        var productCode = productQuantity.productCode, quantity = productQuantity.quantity;
        var quantities = getState().quantities;
        patchState({ quantities: tslib_1.__assign({}, quantities, (_c = {}, _c[productCode] = quantity, _c)) });
    };
    ProductsState.prototype.changeFilters = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var filters = _b.filters;
        var _c = getState(), originalFilteringGroupsCodes = _c.filteringGroupsCodes, originalFilteringPartialProductCode = _c.filteringPartialProductCode, originalFilteringPartialDescription = _c.filteringPartialDescription;
        patchState(ProductsState_1.productsListFilterService.getCalculatedFilters(filters, originalFilteringGroupsCodes, originalFilteringPartialProductCode, originalFilteringPartialDescription));
    };
    ProductsState.prototype.processUnitOfMeasure = function (listWithMinQuantities) {
        return listWithMinQuantities.reduce(function (accumulator, currentProduct) { return accumulator.concat([
            tslib_1.__assign({}, currentProduct, { unit_of_measure: !currentProduct.unit_of_measure ||
                    currentProduct.unit_of_measure === 'NR'
                    ? 'N'
                    : currentProduct.unit_of_measure })
        ]); }, []);
    };
    ProductsState.prototype.processMinQuantities = function (list) {
        return list.reduce(function (accumulator, currentProduct) { return accumulator.concat([
            tslib_1.__assign({}, currentProduct, { minQuantity: !currentProduct.multiple_value ||
                    currentProduct.multiple_value === 0.001
                    ? 1
                    : currentProduct.multiple_value })
        ]); }, []);
    };
    ProductsState.prototype.processOfferValidity = function (list, todayPastMidnightDate) {
        return list.map(function (product) {
            var offerTo = product.price_rel[0].offer_to;
            product.price_rel[0].offerIsValid =
                offerTo && todayPastMidnightDate <= Date.parse(offerTo);
            return product;
        });
    };
    var ProductsState_1;
    tslib_1.__decorate([
        Action(ProductsReset),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProductsState.prototype, "reset", null);
    tslib_1.__decorate([
        Action(ProductsSetGroups),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProductsSetGroups]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProductsState.prototype, "setGroups", null);
    tslib_1.__decorate([
        Action(ProductsSetList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProductsSetList]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProductsState.prototype, "setList", null);
    tslib_1.__decorate([
        Action(ProductsSetMissingQuantities),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProductsState.prototype, "setMissingQuantities", null);
    tslib_1.__decorate([
        Action(ProductsSetQuantity),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProductsSetQuantity]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProductsState.prototype, "setQuantity", null);
    tslib_1.__decorate([
        Action(ProductsChangeFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProductsChangeFilters]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProductsState.prototype, "changeFilters", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], ProductsState, "list", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], ProductsState, "listFiltered", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], ProductsState, "groups", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], ProductsState, "filteringPartialProductCode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], ProductsState, "filteringPartialDescription", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], ProductsState, "filteringGroupsCodes", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], ProductsState, "quantities", null);
    ProductsState = ProductsState_1 = tslib_1.__decorate([
        State({
            name: 'products',
            defaults: productsStateModelDefaults
        }),
        tslib_1.__metadata("design:paramtypes", [Injector])
    ], ProductsState);
    return ProductsState;
}());
export { ProductsState };
