import { FocusMonitor } from '@angular/cdk/a11y';
import { DOWN_ARROW, ENTER, UP_ARROW } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBoolean, NzInputNumberComponent } from 'ng-zorro-antd';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types/size';

// Tweaked version of NzInputNumberComponent, used to accomodate commas instead of dots,
// formatting with 3 decimal places,
// autoselect on focus,
// accepts an id to be referentiable,
// add event of enter being pressed,
// put the minimum when blur on empty input,
// skip focus on step input,
// go for multiple of nzStep,
// adding unit of measure,
// and to accept only digit, comma and minus character
@Component({
  selector: 'app-comma-input-number',
  templateUrl: './comma-input-number.component.html',
  styleUrls: ['./comma-input-number.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommaInputNumberComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '[class.ant-input-number-focused]': 'isFocused',
    '[class.ant-input-number-lg]': `nzSize === 'large'`,
    '[class.ant-input-number-sm]': `nzSize === 'small'`,
    '[class.ant-input-number-disabled]': 'nzDisabled'
  }
})
export class CommaInputNumberComponent extends NzInputNumberComponent {
  @Input() skipFocusOnStep: boolean;
  @Input() id: number;
  @Input() unitOfMeasure: string;
  @Input() fixHeight: boolean;
  @Input() nzSize: NzSizeLDSType = 'default';
  @Input() nzMin = -Infinity;
  @Input() nzMax = Infinity;
  @Input() nzPrecision: number;
  @Input() nzPlaceHolder = '';
  @Input() nzStep = 1;
  @Input() @InputBoolean() nzDisabled = false;
  @Input() @InputBoolean() nzAutoFocus = false;
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  @Output() enterPressed = new EventEmitter<void>();

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor
  ) {
    super(elementRef, renderer, cdr, focusMonitor);
  }

  onFocus() {
    this.isFocused = true;

    // Thank you Safari
    setTimeout(() => {
      this.inputElement.nativeElement.setSelectionRange(0, 99);
    }, 5);
  }

  onModelChange(value: string): void {
    // value = !value && value !== '0' ? this.nzMin.toString() : value;
    (this as any).actualValue = value
      .trim()
      .replace(/\./g, ',')
      .replace(/[^\d\,-]+/g, '');
    this.inputElement.nativeElement.value = (this as any).actualValue;
  }

  // '1,'  => not complete numbers
  isNotCompleteNumber(num: string | number): boolean {
    return (
      isNaN(this.replaceCommaToDotIfString(num) as number) ||
      num === '' ||
      num === null ||
      !!(num && num.toString().indexOf(',') === num.toString().length - 1)
    );
  }

  getValidValue(value?: string | number): string | number | undefined {
    value = this.replaceCommaToDotIfString(value);
    let val = parseFloat(value as string);
    // https://github.com/ant-design/ant-design/issues/7358
    if (isNaN(val)) {
      return value;
    }
    if (val < this.nzMin) {
      val = this.nzMin;
    }
    if (val > this.nzMax) {
      val = this.nzMax;
    }
    return val;
  }

  setValue(value: number, emit: boolean): void {
    if (!value && value !== 0) value = this.nzMin;

    value = this.getAsThreeDigitsNumber(value);
    const valueMinDifference = value - this.nzMin;

    // Avoid the floating point number precision JS problem
    // with 3 fractions digits precision
    const unNormalizedPart = parseFloat(
      (
        (Math.ceil(valueMinDifference * 1000) % Math.ceil(this.nzStep * 1000)) /
        1000
      ).toFixed(3)
    );

    value = value - unNormalizedPart;

    if (emit && `${(this as any).value}` !== `${value}`) {
      this.onChange(value);
    }
    (this as any).value = value;
    (this as any).actualValue = value;

    const valueToFixed = value || value === 0 ? value.toFixed(3) : '';
    const displayValue = valueToFixed.replace(/\./g, ',');
    this.displayValue = displayValue;
    this.inputElement.nativeElement.value = displayValue;
    this.disabledUp = this.disabledDown = false;
    if (value || value === 0) {
      const val = Number(value);
      if (val >= this.nzMax) {
        this.disabledUp = true;
      }
      if (val <= this.nzMin) {
        this.disabledDown = true;
      }
    }
  }

  onKeyDown(e: KeyboardEvent): void {
    // tslint:disable-next-line: deprecation
    if (e.code === 'ArrowUp' || e.keyCode === UP_ARROW) {
      const ratio = this.getRatio(e);
      this.up(e, ratio);
      this.stop();
      // tslint:disable-next-line: deprecation
    } else if (e.code === 'ArrowDown' || e.keyCode === DOWN_ARROW) {
      const ratio = this.getRatio(e);
      this.down(e, ratio);
      this.stop();
      // tslint:disable-next-line: deprecation
    } else if (e.keyCode === ENTER) {
      this.setValidateValue();
      this.enterPressed.emit();
    }
  }

  downWithoutFocus(e: MouseEvent | KeyboardEvent, ratio?: number): void {
    this.step('down', e, ratio);
  }

  upWithoutFocus(e: MouseEvent | KeyboardEvent, ratio?: number): void {
    this.step('up', e, ratio);
  }

  private getAsThreeDigitsNumber(num: number): number {
    const numAsString = num.toString();
    const pointIndex = numAsString.indexOf('.');
    if (pointIndex < 0) return num;

    const numAsThreeDigitsString = numAsString.slice(0, pointIndex + 4);
    return parseFloat(numAsThreeDigitsString);
  }

  private replaceCommaToDotIfString(value: string | number) {
    if (typeof value === 'string') value = value.replace(/,/g, '.');
    return value;
  }
}
