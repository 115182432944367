import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, from, split } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { isKnownJwtError } from '../shared/helpers/is-known-jwt-error';
export var getApolloOptions = function (httpLink, authenticationService) {
    var http = getHttpLink(httpLink);
    var ws = getWebSocketLink(authenticationService);
    var link = getWsHttpSplitLink(ws, http);
    var authMiddleware = getAuthMiddleware(authenticationService);
    var retryLink = getJwtErrorRetryLink(authenticationService);
    return {
        link: from([retryLink, authMiddleware, link]),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: { fetchPolicy: 'no-cache' },
            watchQuery: { fetchPolicy: 'no-cache' }
        }
    };
};
var GraphQLModule = /** @class */ (function () {
    function GraphQLModule(apollo, httpLink, authenticationService) {
        apollo.create(getApolloOptions(httpLink, authenticationService));
    }
    return GraphQLModule;
}());
export { GraphQLModule };
function getJwtErrorRetryLink(authenticationService) {
    var _this = this;
    return new RetryLink({
        // tslint:disable-next-line: variable-name
        attempts: function (count, _operation, error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (count > 1)
                            return [2 /*return*/, false];
                        if (!isKnownJwtError(error)) return [3 /*break*/, 2];
                        return [4 /*yield*/, authenticationService.forceRenewTokens()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2: return [2 /*return*/, false];
                }
            });
        }); },
        delay: {
            initial: 0
        }
    });
}
function getAuthMiddleware(authenticationService) {
    return new ApolloLink(function (operation, forward) {
        var headers = new HttpHeaders().set('Authorization', "Bearer " + authenticationService.idToken);
        operation.setContext({
            headers: headers
        });
        return forward(operation);
    });
}
function getWsHttpSplitLink(ws, http) {
    return split(function (_a) {
        var query = _a.query;
        var _b = getMainDefinition(query), kind = _b.kind, operation = _b.operation;
        return kind === 'OperationDefinition' && operation === 'subscription';
    }, ws, http);
}
function getWebSocketLink(authenticationService) {
    return new WebSocketLink({
        uri: 'wss://' + environment.graphqlUri,
        options: {
            lazy: true,
            reconnect: true,
            connectionParams: function () { return ({
                headers: {
                    Authorization: "Bearer " + authenticationService.idToken
                }
            }); }
        }
    });
}
function getHttpLink(httpLink) {
    return httpLink.create({ uri: 'https://' + environment.graphqlUri });
}
