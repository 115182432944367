/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comma-input-number.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/icon";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/forms";
import * as i5 from "./comma-input-number.component";
import * as i6 from "@angular/cdk/a11y";
var styles_CommaInputNumberComponent = [i0.styles];
var RenderType_CommaInputNumberComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CommaInputNumberComponent, data: {} });
export { RenderType_CommaInputNumberComponent as RenderType_CommaInputNumberComponent };
export function View_CommaInputNumberComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { inputElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "minus-wrapper"]], [[2, "disabled", null]], [[null, "mousedown"], [null, "mouseup"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = ((_co.skipFocusOnStep ? _co.downWithoutFocus($event) : _co.down($event)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.stop() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.stop() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "minus"], ["nz-icon", ""], ["nzType", "minus"]], null, null, null, null, null)), i1.ɵdid(3, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzType: [0, "nzType"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "plus-wrapper"]], [[2, "disabled", null]], [[null, "mousedown"], [null, "mouseup"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = ((_co.skipFocusOnStep ? _co.upWithoutFocus($event) : _co.up($event)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.stop() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.stop() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i", [["class", "plus"], ["nz-icon", ""], ["nzType", "plus"]], null, null, null, null, null)), i1.ɵdid(6, 2834432, null, 0, i2.NzIconDirective, [i2.NzIconService, i1.ElementRef, i1.Renderer2, i3.Platform], { nzType: [0, "nzType"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "ant-input-number-input-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["inputElement", 1]], null, 5, "input", [["autocomplete", "off"], ["class", "ant-input-number-input"]], [[1, "min", 0], [1, "max", 0], [8, "placeholder", 0], [1, "step", 0], [2, "fix-height", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown"], [null, "keyup"], [null, "blur"], [null, "focus"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.onModelChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown" === en)) {
        var pd_5 = (_co.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } if (("keyup" === en)) {
        var pd_6 = (_co.onKeyUp() !== false);
        ad = (pd_6 && ad);
    } if (("blur" === en)) {
        var pd_7 = (_co.onBlur() !== false);
        ad = (pd_7 && ad);
    } if (("focus" === en)) {
        var pd_8 = (_co.onFocus() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(13, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "unit-of-measure"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "minus"; _ck(_v, 3, 0, currVal_1); var currVal_3 = "plus"; _ck(_v, 6, 0, currVal_3); var currVal_16 = _co.nzDisabled; var currVal_17 = _co.displayValue; _ck(_v, 11, 0, currVal_16, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabledDown || _co.nzDisabled); _ck(_v, 1, 0, currVal_0); var currVal_2 = (_co.disabledUp || _co.nzDisabled); _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.nzMin; var currVal_5 = _co.nzMax; var currVal_6 = _co.nzPlaceHolder; var currVal_7 = _co.nzStep; var currVal_8 = _co.fixHeight; var currVal_9 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 13).ngClassValid; var currVal_14 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 1, [currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); var currVal_18 = _co.unitOfMeasure; _ck(_v, 15, 0, currVal_18); }); }
export function View_CommaInputNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-comma-input-number", [], [[2, "ant-input-number-focused", null], [2, "ant-input-number-lg", null], [2, "ant-input-number-sm", null], [2, "ant-input-number-disabled", null]], null, null, View_CommaInputNumberComponent_0, RenderType_CommaInputNumberComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CommaInputNumberComponent]), i1.ɵdid(2, 4964352, null, 0, i5.CommaInputNumberComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i6.FocusMonitor], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isFocused; var currVal_1 = (i1.ɵnov(_v, 2).nzSize === "large"); var currVal_2 = (i1.ɵnov(_v, 2).nzSize === "small"); var currVal_3 = i1.ɵnov(_v, 2).nzDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var CommaInputNumberComponentNgFactory = i1.ɵccf("app-comma-input-number", i5.CommaInputNumberComponent, View_CommaInputNumberComponent_Host_0, { nzSize: "nzSize", nzMin: "nzMin", nzMax: "nzMax", nzParser: "nzParser", nzPrecision: "nzPrecision", nzPlaceHolder: "nzPlaceHolder", nzStep: "nzStep", nzId: "nzId", nzDisabled: "nzDisabled", nzAutoFocus: "nzAutoFocus", nzFormatter: "nzFormatter", skipFocusOnStep: "skipFocusOnStep", id: "id", unitOfMeasure: "unitOfMeasure", fixHeight: "fixHeight" }, { nzBlur: "nzBlur", nzFocus: "nzFocus", enterPressed: "enterPressed" }, []);
export { CommaInputNumberComponentNgFactory as CommaInputNumberComponentNgFactory };
