import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { OrdersState } from '../state/orders.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var NoOrdersCheckResolve = /** @class */ (function () {
    function NoOrdersCheckResolve(store) {
        this.store = store;
    }
    NoOrdersCheckResolve.prototype.resolve = function () {
        if (this.store.selectSnapshot(OrdersState.list).length < 1) {
            this.store.dispatch(new Navigate(['/products']));
            return false;
        }
        return true;
    };
    NoOrdersCheckResolve.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoOrdersCheckResolve_Factory() { return new NoOrdersCheckResolve(i0.ɵɵinject(i1.Store)); }, token: NoOrdersCheckResolve, providedIn: "root" });
    return NoOrdersCheckResolve;
}());
export { NoOrdersCheckResolve };
