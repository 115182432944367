import { Injectable } from '@angular/core';
import {
  Actions,
  ofActionErrored,
  ofActionSuccessful,
  Store
} from '@ngxs/store';
import { delay, tap } from 'rxjs/operators';
import {
  CartCloneOrder,
  CartSetOrderCourtesyModalOpening,
  CartSetOrderSubmitModalOpening
} from 'src/app/features/cart/state/cart.actions';
import {
  OrdersNewOrderFromCart,
  OrdersSetOrderCloneCourtesyModalOpening,
  OrdersSetOrderCloneModalOpening
} from 'src/app/features/orders/state/orders.actions';

@Injectable({ providedIn: 'root' })
export class ModalsHandler {
  constructor(private actions$: Actions, private store: Store) {
    this.actions$
      .pipe(
        ofActionSuccessful(OrdersNewOrderFromCart),
        tap(_ => {
          this.store.dispatch(new CartSetOrderSubmitModalOpening(false));
        }),
        delay(200),
        tap(_ =>
          this.store.dispatch(new CartSetOrderCourtesyModalOpening(true))
        )
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionErrored(OrdersNewOrderFromCart),
        tap(_ => {
          this.store.dispatch(new CartSetOrderSubmitModalOpening(false));
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionSuccessful(CartCloneOrder),
        tap(_ => {
          this.store.dispatch(new OrdersSetOrderCloneModalOpening(null));
        }),
        delay(200),
        tap(_ =>
          this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(true))
        )
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionErrored(CartCloneOrder),
        tap(_ => {
          this.store.dispatch(new OrdersSetOrderCloneModalOpening(null));
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }
}
