import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { OrderStateLabelMap } from "../../types/order-state-label.map";
import { OrderModel } from "../../types/order.model";

@Component({
  selector: "app-orders-filters",
  templateUrl: "./orders-filters.component.html",
  styleUrls: ["./orders-filters.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersFiltersComponent {
  isCollapsed = true;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  @Input() filteringPartialOrderNumberId: number;
  @Input() filteringOrderState: number;
  @Input() orderStateLabelMap: OrderStateLabelMap;
  @Input() list: OrderModel[];

  @Output() orderNumberIdFilterChange = new EventEmitter<number>();
  @Output() orderStatesFilterChange = new EventEmitter<number[]>();

  objectKeys = Object.keys;
  parseInt = parseInt;

  isTruthy(item: any): boolean {
    return !!item;
  }
}
