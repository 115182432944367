import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { OrdersState } from '../state/orders.state';

@Injectable({ providedIn: 'root' })
export class NoOrdersCheckResolve implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve() {
    if (this.store.selectSnapshot(OrdersState.list).length < 1) {
      this.store.dispatch(new Navigate(['/products']));
      return false;
    }

    return true;
  }
}
