import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ProductGroupModel } from "../../types/product-group.model";
import { ProductOverviewModel } from "../../types/product-overview.model";

@Component({
  selector: "app-products-filters",
  templateUrl: "./products-filters.component.html",
  styleUrls: ["./products-filters.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsFiltersComponent implements OnInit {
  isCollapsed = true;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  @Input() filteringGroupsCodes: string[];
  @Input() filteringPartialProductCode: number;
  @Input() filteringPartialDescription: string;

  @Input() haveSmallDescriptionFilter: boolean;
  @Input() areExtraSmallFilters: boolean;

  @Input() list: Pick<ProductOverviewModel, "code" | "description">[];
  @Input() groups: ProductGroupModel[];

  @Output() groupsFilterChange = new EventEmitter<string[]>();
  @Output() productCodeFilterChange = new EventEmitter<number>();
  @Output() descriptionFilterChange = new EventEmitter<string>();

  maxTagCount = 1;

  ngOnInit(): void {
    this.calculateMaxTagCount();
  }

  @HostListener("window:resize")
  calculateMaxTagCount() {
    const mediaQuery = this.areExtraSmallFilters
      ? "(min-width: 1600px)"
      : "(min-width: 1320px)";

    this.maxTagCount = window.matchMedia(mediaQuery).matches ? 1 : 0;
  }
}
