import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ProductGroupModel } from 'src/app/features/products/types/product-group.model';
import { ProductOverviewModel } from 'src/app/features/products/types/product-overview.model';
import { ProductQuantityModel } from 'src/app/features/products/types/product-quantity.model';
import { ProductsFiltersModel } from 'src/app/features/products/types/products-filters.model';
import { ProductsQuantitiesModel } from 'src/app/features/products/types/products-quantities.model';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { OrderViewModel } from '../../types/order.view-model';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailComponent implements OnInit, OnChanges {
  @Input() order: OrderViewModel;
  @Input() imageBaseUrl: string;
  @Input() addProductDisabled: boolean;
  @Input() cartQuantities: ProductsQuantitiesModel;
  @Input() productsGroups: ProductGroupModel[];
  @Input() productsFilters: ProductsFiltersModel;

  @Output() addProductClicked = new EventEmitter<ProductQuantityModel>();

  @Output() productsGroupsFilterChange = new EventEmitter<{
    orderId: string;
    productsGroups: string[];
  }>();

  @Output() productCodeFilterChange = new EventEmitter<{
    orderId: string;
    productCode: number;
  }>();

  @Output() productDescriptionFilterChange = new EventEmitter<{
    orderId: string;
    productDescription: string;
  }>();

  @ViewChild('orderProductsList', { static: false })
  orderProductsListCdkCompontent: CdkVirtualScrollViewport;

  lastHoveredAddIconProductCode: string;

  virtualScrollHeight: number;
  itemSize = 105;

  private isMobile = isMobileCheck();

  get productsFiltersList(): Pick<
    ProductOverviewModel,
    'code' | 'description'
  >[] {
    return this.order.orders_rows_rel.map(entry => ({
      code: entry.code_product,
      description: entry.description_product
    }));
  }

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  formatDate = (value: string | number | Date, format: string) =>
    // tslint:disable-next-line: semicolon
    formatDate(value, format, this.locale);

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.order || !changes.order.isFirstChange()) {
      this.orderProductsListCdkCompontent.scrollToIndex(0);
      this.handleResize();
    }
  }

  ngOnInit(): void {
    this.handleResize();
  }

  @HostListener('window:resize')
  handleResize() {
    // const isSmallWidth = window.matchMedia('(max-width: 1199px)').matches;

    // const fixedHeight = isSmallWidth ? 157 : 176;

    // const heightMediaQuery = isSmallWidth
    //   ? '(max-height: 792px)'
    //   : '(max-height: 739px)';

    // const maxHeight = window.matchMedia(heightMediaQuery).matches
    //   ? fixedHeight
    //   : (this.isMobile ? screen.availHeight : window.innerHeight) -
    //     (isSmallWidth ? 635 : 562);

    if (
      this.productsFilters.filteringGroupsCodes.length &&
      window.matchMedia('(max-width: 1199px)').matches
    )
      this.productsGroupsFilterChange.emit({
        orderId: this.order.id,
        productsGroups: []
      });

    const fixedHeight = 176;
    const heightMediaQuery = '(max-height: 739px)';

    const maxHeight = window.matchMedia(heightMediaQuery).matches
      ? fixedHeight
      : (this.isMobile ? screen.availHeight : window.innerHeight) - 562;

    const heightBasedOnMaxHeightAndNumberOfItems = Math.min(
      maxHeight,
      this.itemSize * this.order.orders_rows_rel.length + 5
    );

    const heightBasedOnMinFourItemsDisplayedIfItemsAreAvailable =
      Math.min(this.order.orders_rows_rel.length, 4) * this.itemSize + 5;

    this.virtualScrollHeight = Math.max(
      heightBasedOnMaxHeightAndNumberOfItems,
      heightBasedOnMinFourItemsDisplayedIfItemsAreAvailable
    );
  }
}
