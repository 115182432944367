import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { Observable } from 'rxjs';
import { CartState } from 'src/app/features/cart/state/cart.state';
import { OrdersUpdateNotification } from 'src/app/features/orders/state/orders.actions';
import { OrdersState } from 'src/app/features/orders/state/orders.state';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { TranslateManagerService } from 'src/app/shared/services/translate-manager.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { CoreChangeCustomer } from '../../state/core.actions';
import { CoreState } from '../../state/core.state';
import { NotificationHandler } from '../../state/handlers/notification.handler';
var MainContainerComponent = /** @class */ (function () {
    function MainContainerComponent(authenticationService, notificationHandler, translateManagerService, gtag, store) {
        this.authenticationService = authenticationService;
        this.notificationHandler = notificationHandler;
        this.translateManagerService = translateManagerService;
        this.gtag = gtag;
        this.store = store;
        this.logoUrl = environment.storageConfig.commonAssetsUrl + 'frascheri-400.png';
        this.isMobile = isMobileCheck();
        this.subscriptions = [];
        this.authenticationService.scheduleRenewal();
    }
    MainContainerComponent.prototype.ngOnInit = function () {
        this.calculateContentMinHeight();
    };
    MainContainerComponent.prototype.ngAfterViewInit = function () {
        this.registerOrderUpdateNotificationHandling();
        this.gtag.event('accesso', {
            event_category: 'traces',
            event_label: "Login customer: " + this.store.selectSnapshot(CoreState.currentCustomerCode)
        });
    };
    MainContainerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe; });
    };
    MainContainerComponent.prototype.calculateContentMinHeight = function () {
        this.minContentHeight = Math.max(this.isMobile ? screen.availHeight - 124 : window.innerHeight - 124, 613);
    };
    MainContainerComponent.prototype.handleChangeLanguageClicked = function () {
        // No need for unsubscribe, it completes after one emission
        this.translateManagerService.changeLanguage().subscribe();
    };
    MainContainerComponent.prototype.handleChangeCustomerClicked = function (customerCode) {
        this.store.dispatch(new CoreChangeCustomer(customerCode));
    };
    MainContainerComponent.prototype.registerOrderUpdateNotificationHandling = function () {
        var _this = this;
        this.subscriptions.push(this.translateManagerService.translateService
            .stream('orders_update_notification_success_title')
            .subscribe(function (title) {
            _this.notificationHandler.notifications.find(function (notification) { return notification.action === OrdersUpdateNotification; }).successMessage = {
                title: title,
                template: _this.ordersUpdateNotificationTemplate,
                isInfiniteDuration: true
            };
        }));
    };
    tslib_1.__decorate([
        Select(CoreState.isCustomerChangeInProgress),
        tslib_1.__metadata("design:type", Observable)
    ], MainContainerComponent.prototype, "isCustomerChangeInProgress$", void 0);
    tslib_1.__decorate([
        Select(CoreState.userEmail),
        tslib_1.__metadata("design:type", Observable)
    ], MainContainerComponent.prototype, "userEmail$", void 0);
    tslib_1.__decorate([
        Select(CoreState.currentCustomer),
        tslib_1.__metadata("design:type", Observable)
    ], MainContainerComponent.prototype, "currentCustomer$", void 0);
    tslib_1.__decorate([
        Select(CoreState.otherAvailableCustomers),
        tslib_1.__metadata("design:type", Observable)
    ], MainContainerComponent.prototype, "otherAvailableCustomers$", void 0);
    tslib_1.__decorate([
        Select(CartState.productsCount),
        tslib_1.__metadata("design:type", Observable)
    ], MainContainerComponent.prototype, "productsCount$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.ordersCount),
        tslib_1.__metadata("design:type", Observable)
    ], MainContainerComponent.prototype, "ordersCount$", void 0);
    return MainContainerComponent;
}());
export { MainContainerComponent };
