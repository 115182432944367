import { TranslateService } from '@ngx-translate/core';
import { Actions } from '@ngxs/store';
import { NzNotificationService } from 'ng-zorro-antd';
import { filter, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "ng-zorro-antd/notification";
import * as i3 from "@ngx-translate/core";
var OfflineErrorNotificationHandler = /** @class */ (function () {
    function OfflineErrorNotificationHandler(actions$, notificationService, translateService) {
        var _this = this;
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.actions$
            .pipe(filter(function (_a) {
            var status = _a.status;
            return status === "ERRORED" /* Errored */;
        }), filter(function (_) { return !navigator.onLine; }), tap(function (_) {
            _this.notificationService.error(_this.translateService.instant('translate.offline_error_title'), _this.translateService.instant('translate.offline_error_content'), { nzDuration: 5000 });
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    OfflineErrorNotificationHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfflineErrorNotificationHandler_Factory() { return new OfflineErrorNotificationHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.NzNotificationService), i0.ɵɵinject(i3.TranslateService)); }, token: OfflineErrorNotificationHandler, providedIn: "root" });
    return OfflineErrorNotificationHandler;
}());
export { OfflineErrorNotificationHandler };
