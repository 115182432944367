type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  date: any;
  timestamptz: any;
  uuid: any;
};

export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

export type Cart_Headers = {
  cart_row_rel: Array<Cart_Rows>;
  cart_row_rel_aggregate: Cart_Rows_Aggregate;
  code_customer: Scalars["String"];
  customer_order_number?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers>;
  date_for?: Maybe<Scalars["date"]>;
  note?: Maybe<Scalars["String"]>;
};

export type Cart_HeadersCart_Row_RelArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type Cart_HeadersCart_Row_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type Cart_Headers_Aggregate = {
  aggregate?: Maybe<Cart_Headers_Aggregate_Fields>;
  nodes: Array<Cart_Headers>;
};

export type Cart_Headers_Aggregate_Fields = {
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Cart_Headers_Max_Fields>;
  min?: Maybe<Cart_Headers_Min_Fields>;
};

export type Cart_Headers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Headers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Cart_Headers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Cart_Headers_Max_Order_By>;
  min?: Maybe<Cart_Headers_Min_Order_By>;
};

export type Cart_Headers_Arr_Rel_Insert_Input = {
  data: Array<Cart_Headers_Insert_Input>;
  on_conflict?: Maybe<Cart_Headers_On_Conflict>;
};

export type Cart_Headers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cart_Headers_Bool_Exp>>>;
  _not?: Maybe<Cart_Headers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cart_Headers_Bool_Exp>>>;
  cart_row_rel?: Maybe<Cart_Rows_Bool_Exp>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  customer_order_number?: Maybe<Text_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  date_for?: Maybe<Date_Comparison_Exp>;
  note?: Maybe<Text_Comparison_Exp>;
};

export enum Cart_Headers_Constraint {
  CartHeadersPkey = "cart_headers_pkey"
}

export type Cart_Headers_Insert_Input = {
  cart_row_rel?: Maybe<Cart_Rows_Arr_Rel_Insert_Input>;
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  date_for?: Maybe<Scalars["date"]>;
  note?: Maybe<Scalars["String"]>;
};

export type Cart_Headers_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date_for?: Maybe<Scalars["date"]>;
  note?: Maybe<Scalars["String"]>;
};

export type Cart_Headers_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
};

export type Cart_Headers_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date_for?: Maybe<Scalars["date"]>;
  note?: Maybe<Scalars["String"]>;
};

export type Cart_Headers_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
};

export type Cart_Headers_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Cart_Headers>;
};

export type Cart_Headers_Obj_Rel_Insert_Input = {
  data: Cart_Headers_Insert_Input;
  on_conflict?: Maybe<Cart_Headers_On_Conflict>;
};

export type Cart_Headers_On_Conflict = {
  constraint: Cart_Headers_Constraint;
  update_columns: Array<Cart_Headers_Update_Column>;
};

export type Cart_Headers_Order_By = {
  cart_row_rel_aggregate?: Maybe<Cart_Rows_Aggregate_Order_By>;
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
};

export enum Cart_Headers_Select_Column {
  CodeCustomer = "code_customer",
  CustomerOrderNumber = "customer_order_number",
  DateFor = "date_for",
  Note = "note"
}

export type Cart_Headers_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date_for?: Maybe<Scalars["date"]>;
  note?: Maybe<Scalars["String"]>;
};

export enum Cart_Headers_Update_Column {
  CodeCustomer = "code_customer",
  CustomerOrderNumber = "customer_order_number",
  DateFor = "date_for",
  Note = "note"
}

export type Cart_Rows = {
  cart_header_rel?: Maybe<Cart_Headers>;
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
  product_rel?: Maybe<Products>;
  quantity: Scalars["numeric"];
};

export type Cart_Rows_Aggregate = {
  aggregate?: Maybe<Cart_Rows_Aggregate_Fields>;
  nodes: Array<Cart_Rows>;
};

export type Cart_Rows_Aggregate_Fields = {
  avg?: Maybe<Cart_Rows_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Cart_Rows_Max_Fields>;
  min?: Maybe<Cart_Rows_Min_Fields>;
  stddev?: Maybe<Cart_Rows_Stddev_Fields>;
  stddev_pop?: Maybe<Cart_Rows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cart_Rows_Stddev_Samp_Fields>;
  sum?: Maybe<Cart_Rows_Sum_Fields>;
  var_pop?: Maybe<Cart_Rows_Var_Pop_Fields>;
  var_samp?: Maybe<Cart_Rows_Var_Samp_Fields>;
  variance?: Maybe<Cart_Rows_Variance_Fields>;
};

export type Cart_Rows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Rows_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Cart_Rows_Aggregate_Order_By = {
  avg?: Maybe<Cart_Rows_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cart_Rows_Max_Order_By>;
  min?: Maybe<Cart_Rows_Min_Order_By>;
  stddev?: Maybe<Cart_Rows_Stddev_Order_By>;
  stddev_pop?: Maybe<Cart_Rows_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cart_Rows_Stddev_Samp_Order_By>;
  sum?: Maybe<Cart_Rows_Sum_Order_By>;
  var_pop?: Maybe<Cart_Rows_Var_Pop_Order_By>;
  var_samp?: Maybe<Cart_Rows_Var_Samp_Order_By>;
  variance?: Maybe<Cart_Rows_Variance_Order_By>;
};

export type Cart_Rows_Arr_Rel_Insert_Input = {
  data: Array<Cart_Rows_Insert_Input>;
  on_conflict?: Maybe<Cart_Rows_On_Conflict>;
};

export type Cart_Rows_Avg_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Avg_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cart_Rows_Bool_Exp>>>;
  _not?: Maybe<Cart_Rows_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cart_Rows_Bool_Exp>>>;
  cart_header_rel?: Maybe<Cart_Headers_Bool_Exp>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  code_product?: Maybe<Integer_Comparison_Exp>;
  product_rel?: Maybe<Products_Bool_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
};

export enum Cart_Rows_Constraint {
  CartRowsPkey = "cart_rows_pkey"
}

export type Cart_Rows_Inc_Input = {
  code_product?: Maybe<Scalars["Int"]>;
};

export type Cart_Rows_Insert_Input = {
  cart_header_rel?: Maybe<Cart_Headers_Obj_Rel_Insert_Input>;
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  product_rel?: Maybe<Products_Obj_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["numeric"]>;
};

export type Cart_Rows_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["numeric"]>;
};

export type Cart_Rows_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["numeric"]>;
};

export type Cart_Rows_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Cart_Rows>;
};

export type Cart_Rows_Obj_Rel_Insert_Input = {
  data: Cart_Rows_Insert_Input;
  on_conflict?: Maybe<Cart_Rows_On_Conflict>;
};

export type Cart_Rows_On_Conflict = {
  constraint: Cart_Rows_Constraint;
  update_columns: Array<Cart_Rows_Update_Column>;
};

export type Cart_Rows_Order_By = {
  cart_header_rel?: Maybe<Cart_Headers_Order_By>;
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  product_rel?: Maybe<Products_Order_By>;
  quantity?: Maybe<Order_By>;
};

export enum Cart_Rows_Select_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product",
  Quantity = "quantity"
}

export type Cart_Rows_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["numeric"]>;
};

export type Cart_Rows_Stddev_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Stddev_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Stddev_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Stddev_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Stddev_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Stddev_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Sum_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["numeric"]>;
};

export type Cart_Rows_Sum_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export enum Cart_Rows_Update_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product",
  Quantity = "quantity"
}

export type Cart_Rows_Var_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Var_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Var_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Var_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export type Cart_Rows_Variance_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

export type Cart_Rows_Variance_Order_By = {
  code_product?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

export enum Conflict_Action {
  Ignore = "ignore",
  Update = "update"
}

export type Customers = {
  address?: Maybe<Scalars["String"]>;
  cap?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  code: Scalars["String"];
  description: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  price_rel: Array<View_Prices>;
  price_rel_aggregate: View_Prices_Aggregate;
  province?: Maybe<Scalars["String"]>;
  tel?: Maybe<Scalars["String"]>;
  user_rel?: Maybe<User_Customer>;
  vat?: Maybe<Scalars["String"]>;
};

export type CustomersPrice_RelArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type CustomersPrice_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type Customers_Aggregate = {
  aggregate?: Maybe<Customers_Aggregate_Fields>;
  nodes: Array<Customers>;
};

export type Customers_Aggregate_Fields = {
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Customers_Max_Fields>;
  min?: Maybe<Customers_Min_Fields>;
};

export type Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Customers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Customers_Max_Order_By>;
  min?: Maybe<Customers_Min_Order_By>;
};

export type Customers_Arr_Rel_Insert_Input = {
  data: Array<Customers_Insert_Input>;
  on_conflict?: Maybe<Customers_On_Conflict>;
};

export type Customers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Customers_Bool_Exp>>>;
  _not?: Maybe<Customers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Customers_Bool_Exp>>>;
  address?: Maybe<Text_Comparison_Exp>;
  cap?: Maybe<Text_Comparison_Exp>;
  city?: Maybe<Text_Comparison_Exp>;
  code?: Maybe<Text_Comparison_Exp>;
  description?: Maybe<Text_Comparison_Exp>;
  email?: Maybe<Text_Comparison_Exp>;
  price_rel?: Maybe<View_Prices_Bool_Exp>;
  province?: Maybe<Text_Comparison_Exp>;
  tel?: Maybe<Text_Comparison_Exp>;
  user_rel?: Maybe<User_Customer_Bool_Exp>;
  vat?: Maybe<Text_Comparison_Exp>;
};

export enum Customers_Constraint {
  CustomersCodeKey = "customers_code_key",
  CustomersPkey = "customers_pkey"
}

export type Customers_Insert_Input = {
  address?: Maybe<Scalars["String"]>;
  cap?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  price_rel?: Maybe<View_Prices_Arr_Rel_Insert_Input>;
  province?: Maybe<Scalars["String"]>;
  tel?: Maybe<Scalars["String"]>;
  user_rel?: Maybe<User_Customer_Obj_Rel_Insert_Input>;
  vat?: Maybe<Scalars["String"]>;
};

export type Customers_Max_Fields = {
  address?: Maybe<Scalars["String"]>;
  cap?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  tel?: Maybe<Scalars["String"]>;
  vat?: Maybe<Scalars["String"]>;
};

export type Customers_Max_Order_By = {
  address?: Maybe<Order_By>;
  cap?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  province?: Maybe<Order_By>;
  tel?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type Customers_Min_Fields = {
  address?: Maybe<Scalars["String"]>;
  cap?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  tel?: Maybe<Scalars["String"]>;
  vat?: Maybe<Scalars["String"]>;
};

export type Customers_Min_Order_By = {
  address?: Maybe<Order_By>;
  cap?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  province?: Maybe<Order_By>;
  tel?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type Customers_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Customers>;
};

export type Customers_Obj_Rel_Insert_Input = {
  data: Customers_Insert_Input;
  on_conflict?: Maybe<Customers_On_Conflict>;
};

export type Customers_On_Conflict = {
  constraint: Customers_Constraint;
  update_columns: Array<Customers_Update_Column>;
};

export type Customers_Order_By = {
  address?: Maybe<Order_By>;
  cap?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  price_rel_aggregate?: Maybe<View_Prices_Aggregate_Order_By>;
  province?: Maybe<Order_By>;
  tel?: Maybe<Order_By>;
  user_rel?: Maybe<User_Customer_Order_By>;
  vat?: Maybe<Order_By>;
};

export type Customers_Products = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
  customer_rel?: Maybe<Customers>;
  product_rel?: Maybe<Products>;
  user_rel?: Maybe<User_Customer>;
};

export type Customers_Products_Aggregate = {
  aggregate?: Maybe<Customers_Products_Aggregate_Fields>;
  nodes: Array<Customers_Products>;
};

export type Customers_Products_Aggregate_Fields = {
  avg?: Maybe<Customers_Products_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Customers_Products_Max_Fields>;
  min?: Maybe<Customers_Products_Min_Fields>;
  stddev?: Maybe<Customers_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Customers_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customers_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Customers_Products_Sum_Fields>;
  var_pop?: Maybe<Customers_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Customers_Products_Var_Samp_Fields>;
  variance?: Maybe<Customers_Products_Variance_Fields>;
};

export type Customers_Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customers_Products_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Customers_Products_Aggregate_Order_By = {
  avg?: Maybe<Customers_Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Customers_Products_Max_Order_By>;
  min?: Maybe<Customers_Products_Min_Order_By>;
  stddev?: Maybe<Customers_Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Customers_Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Customers_Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Customers_Products_Sum_Order_By>;
  var_pop?: Maybe<Customers_Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Customers_Products_Var_Samp_Order_By>;
  variance?: Maybe<Customers_Products_Variance_Order_By>;
};

export type Customers_Products_Arr_Rel_Insert_Input = {
  data: Array<Customers_Products_Insert_Input>;
  on_conflict?: Maybe<Customers_Products_On_Conflict>;
};

export type Customers_Products_Avg_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Avg_Order_By = {
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Customers_Products_Bool_Exp>>>;
  _not?: Maybe<Customers_Products_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Customers_Products_Bool_Exp>>>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  code_product?: Maybe<Integer_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  product_rel?: Maybe<Products_Bool_Exp>;
  user_rel?: Maybe<User_Customer_Bool_Exp>;
};

export enum Customers_Products_Constraint {
  CustomersProductsPkey = "customers_products_pkey"
}

export type Customers_Products_Inc_Input = {
  code_product?: Maybe<Scalars["Int"]>;
};

export type Customers_Products_Insert_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  product_rel?: Maybe<Products_Obj_Rel_Insert_Input>;
  user_rel?: Maybe<User_Customer_Obj_Rel_Insert_Input>;
};

export type Customers_Products_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
};

export type Customers_Products_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
};

export type Customers_Products_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Customers_Products>;
};

export type Customers_Products_Obj_Rel_Insert_Input = {
  data: Customers_Products_Insert_Input;
  on_conflict?: Maybe<Customers_Products_On_Conflict>;
};

export type Customers_Products_On_Conflict = {
  constraint: Customers_Products_Constraint;
  update_columns: Array<Customers_Products_Update_Column>;
};

export type Customers_Products_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  product_rel?: Maybe<Products_Order_By>;
  user_rel?: Maybe<User_Customer_Order_By>;
};

export enum Customers_Products_Select_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product"
}

export type Customers_Products_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
};

export type Customers_Products_Stddev_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Stddev_Order_By = {
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Stddev_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Stddev_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Stddev_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Stddev_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Sum_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
};

export type Customers_Products_Sum_Order_By = {
  code_product?: Maybe<Order_By>;
};

export enum Customers_Products_Update_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product"
}

export type Customers_Products_Var_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Var_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Var_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Var_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
};

export type Customers_Products_Variance_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
};

export type Customers_Products_Variance_Order_By = {
  code_product?: Maybe<Order_By>;
};

export enum Customers_Select_Column {
  Address = "address",
  Cap = "cap",
  City = "city",
  Code = "code",
  Description = "description",
  Email = "email",
  Province = "province",
  Tel = "tel",
  Vat = "vat"
}

export type Customers_Set_Input = {
  address?: Maybe<Scalars["String"]>;
  cap?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  tel?: Maybe<Scalars["String"]>;
  vat?: Maybe<Scalars["String"]>;
};

export enum Customers_Update_Column {
  Address = "address",
  Cap = "cap",
  City = "city",
  Code = "code",
  Description = "description",
  Email = "email",
  Province = "province",
  Tel = "tel",
  Vat = "vat"
}

export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

export type Integer_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

export type Mutation_Root = {
  delete_cart_headers?: Maybe<Cart_Headers_Mutation_Response>;
  delete_cart_rows?: Maybe<Cart_Rows_Mutation_Response>;
  delete_customers?: Maybe<Customers_Mutation_Response>;
  delete_customers_products?: Maybe<Customers_Products_Mutation_Response>;
  delete_orders_headers?: Maybe<Orders_Headers_Mutation_Response>;
  delete_orders_rows?: Maybe<Orders_Rows_Mutation_Response>;
  delete_prices?: Maybe<Prices_Mutation_Response>;
  delete_products?: Maybe<Products_Mutation_Response>;
  delete_products_groups?: Maybe<Products_Groups_Mutation_Response>;
  delete_user_customer?: Maybe<User_Customer_Mutation_Response>;
  delete_vats?: Maybe<Vats_Mutation_Response>;
  delete_view_orders_headers?: Maybe<View_Orders_Headers_Mutation_Response>;
  delete_view_prices?: Maybe<View_Prices_Mutation_Response>;
  insert_cart_headers?: Maybe<Cart_Headers_Mutation_Response>;
  insert_cart_rows?: Maybe<Cart_Rows_Mutation_Response>;
  insert_customers?: Maybe<Customers_Mutation_Response>;
  insert_customers_products?: Maybe<Customers_Products_Mutation_Response>;
  insert_orders_headers?: Maybe<Orders_Headers_Mutation_Response>;
  insert_orders_rows?: Maybe<Orders_Rows_Mutation_Response>;
  insert_prices?: Maybe<Prices_Mutation_Response>;
  insert_products?: Maybe<Products_Mutation_Response>;
  insert_products_groups?: Maybe<Products_Groups_Mutation_Response>;
  insert_user_customer?: Maybe<User_Customer_Mutation_Response>;
  insert_vats?: Maybe<Vats_Mutation_Response>;
  insert_view_orders_headers?: Maybe<View_Orders_Headers_Mutation_Response>;
  insert_view_prices?: Maybe<View_Prices_Mutation_Response>;
  update_cart_headers?: Maybe<Cart_Headers_Mutation_Response>;
  update_cart_rows?: Maybe<Cart_Rows_Mutation_Response>;
  update_customers?: Maybe<Customers_Mutation_Response>;
  update_customers_products?: Maybe<Customers_Products_Mutation_Response>;
  update_orders_headers?: Maybe<Orders_Headers_Mutation_Response>;
  update_orders_rows?: Maybe<Orders_Rows_Mutation_Response>;
  update_prices?: Maybe<Prices_Mutation_Response>;
  update_products?: Maybe<Products_Mutation_Response>;
  update_products_groups?: Maybe<Products_Groups_Mutation_Response>;
  update_user_customer?: Maybe<User_Customer_Mutation_Response>;
  update_vats?: Maybe<Vats_Mutation_Response>;
  update_view_orders_headers?: Maybe<View_Orders_Headers_Mutation_Response>;
  update_view_prices?: Maybe<View_Prices_Mutation_Response>;
};

export type Mutation_RootDelete_Cart_HeadersArgs = {
  where: Cart_Headers_Bool_Exp;
};

export type Mutation_RootDelete_Cart_RowsArgs = {
  where: Cart_Rows_Bool_Exp;
};

export type Mutation_RootDelete_CustomersArgs = {
  where: Customers_Bool_Exp;
};

export type Mutation_RootDelete_Customers_ProductsArgs = {
  where: Customers_Products_Bool_Exp;
};

export type Mutation_RootDelete_Orders_HeadersArgs = {
  where: Orders_Headers_Bool_Exp;
};

export type Mutation_RootDelete_Orders_RowsArgs = {
  where: Orders_Rows_Bool_Exp;
};

export type Mutation_RootDelete_PricesArgs = {
  where: Prices_Bool_Exp;
};

export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};

export type Mutation_RootDelete_Products_GroupsArgs = {
  where: Products_Groups_Bool_Exp;
};

export type Mutation_RootDelete_User_CustomerArgs = {
  where: User_Customer_Bool_Exp;
};

export type Mutation_RootDelete_VatsArgs = {
  where: Vats_Bool_Exp;
};

export type Mutation_RootDelete_View_Orders_HeadersArgs = {
  where: View_Orders_Headers_Bool_Exp;
};

export type Mutation_RootDelete_View_PricesArgs = {
  where: View_Prices_Bool_Exp;
};

export type Mutation_RootInsert_Cart_HeadersArgs = {
  objects: Array<Cart_Headers_Insert_Input>;
  on_conflict?: Maybe<Cart_Headers_On_Conflict>;
};

export type Mutation_RootInsert_Cart_RowsArgs = {
  objects: Array<Cart_Rows_Insert_Input>;
  on_conflict?: Maybe<Cart_Rows_On_Conflict>;
};

export type Mutation_RootInsert_CustomersArgs = {
  objects: Array<Customers_Insert_Input>;
  on_conflict?: Maybe<Customers_On_Conflict>;
};

export type Mutation_RootInsert_Customers_ProductsArgs = {
  objects: Array<Customers_Products_Insert_Input>;
  on_conflict?: Maybe<Customers_Products_On_Conflict>;
};

export type Mutation_RootInsert_Orders_HeadersArgs = {
  objects: Array<Orders_Headers_Insert_Input>;
  on_conflict?: Maybe<Orders_Headers_On_Conflict>;
};

export type Mutation_RootInsert_Orders_RowsArgs = {
  objects: Array<Orders_Rows_Insert_Input>;
  on_conflict?: Maybe<Orders_Rows_On_Conflict>;
};

export type Mutation_RootInsert_PricesArgs = {
  objects: Array<Prices_Insert_Input>;
  on_conflict?: Maybe<Prices_On_Conflict>;
};

export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: Maybe<Products_On_Conflict>;
};

export type Mutation_RootInsert_Products_GroupsArgs = {
  objects: Array<Products_Groups_Insert_Input>;
  on_conflict?: Maybe<Products_Groups_On_Conflict>;
};

export type Mutation_RootInsert_User_CustomerArgs = {
  objects: Array<User_Customer_Insert_Input>;
  on_conflict?: Maybe<User_Customer_On_Conflict>;
};

export type Mutation_RootInsert_VatsArgs = {
  objects: Array<Vats_Insert_Input>;
  on_conflict?: Maybe<Vats_On_Conflict>;
};

export type Mutation_RootInsert_View_Orders_HeadersArgs = {
  objects: Array<View_Orders_Headers_Insert_Input>;
};

export type Mutation_RootInsert_View_PricesArgs = {
  objects: Array<View_Prices_Insert_Input>;
};

export type Mutation_RootUpdate_Cart_HeadersArgs = {
  _set?: Maybe<Cart_Headers_Set_Input>;
  where: Cart_Headers_Bool_Exp;
};

export type Mutation_RootUpdate_Cart_RowsArgs = {
  _inc?: Maybe<Cart_Rows_Inc_Input>;
  _set?: Maybe<Cart_Rows_Set_Input>;
  where: Cart_Rows_Bool_Exp;
};

export type Mutation_RootUpdate_CustomersArgs = {
  _set?: Maybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};

export type Mutation_RootUpdate_Customers_ProductsArgs = {
  _inc?: Maybe<Customers_Products_Inc_Input>;
  _set?: Maybe<Customers_Products_Set_Input>;
  where: Customers_Products_Bool_Exp;
};

export type Mutation_RootUpdate_Orders_HeadersArgs = {
  _inc?: Maybe<Orders_Headers_Inc_Input>;
  _set?: Maybe<Orders_Headers_Set_Input>;
  where: Orders_Headers_Bool_Exp;
};

export type Mutation_RootUpdate_Orders_RowsArgs = {
  _inc?: Maybe<Orders_Rows_Inc_Input>;
  _set?: Maybe<Orders_Rows_Set_Input>;
  where: Orders_Rows_Bool_Exp;
};

export type Mutation_RootUpdate_PricesArgs = {
  _inc?: Maybe<Prices_Inc_Input>;
  _set?: Maybe<Prices_Set_Input>;
  where: Prices_Bool_Exp;
};

export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: Maybe<Products_Inc_Input>;
  _set?: Maybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};

export type Mutation_RootUpdate_Products_GroupsArgs = {
  _set?: Maybe<Products_Groups_Set_Input>;
  where: Products_Groups_Bool_Exp;
};

export type Mutation_RootUpdate_User_CustomerArgs = {
  _set?: Maybe<User_Customer_Set_Input>;
  where: User_Customer_Bool_Exp;
};

export type Mutation_RootUpdate_VatsArgs = {
  _inc?: Maybe<Vats_Inc_Input>;
  _set?: Maybe<Vats_Set_Input>;
  where: Vats_Bool_Exp;
};

export type Mutation_RootUpdate_View_Orders_HeadersArgs = {
  _inc?: Maybe<View_Orders_Headers_Inc_Input>;
  _set?: Maybe<View_Orders_Headers_Set_Input>;
  where: View_Orders_Headers_Bool_Exp;
};

export type Mutation_RootUpdate_View_PricesArgs = {
  _inc?: Maybe<View_Prices_Inc_Input>;
  _set?: Maybe<View_Prices_Set_Input>;
  where: View_Prices_Bool_Exp;
};

export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
};

export enum Order_By {
  Asc = "asc",
  AscNullsFirst = "asc_nulls_first",
  AscNullsLast = "asc_nulls_last",
  Desc = "desc",
  DescNullsFirst = "desc_nulls_first",
  DescNullsLast = "desc_nulls_last"
}

export type Orders_Headers = {
  code_customer: Scalars["String"];
  customer_order_number?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers>;
  date: Scalars["timestamptz"];
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  orders_rows_rel: Array<Orders_Rows>;
  orders_rows_rel_aggregate: Orders_Rows_Aggregate;
  pdf?: Maybe<Scalars["String"]>;
  state: Scalars["Int"];
  view_orders_row_rel: Array<View_Orders_Rows>;
  view_orders_row_rel_aggregate: View_Orders_Rows_Aggregate;
};

export type Orders_HeadersOrders_Rows_RelArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type Orders_HeadersOrders_Rows_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type Orders_HeadersView_Orders_Row_RelArgs = {
  distinct_on?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Rows_Order_By>>;
  where?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export type Orders_HeadersView_Orders_Row_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Rows_Order_By>>;
  where?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export type Orders_Headers_Aggregate = {
  aggregate?: Maybe<Orders_Headers_Aggregate_Fields>;
  nodes: Array<Orders_Headers>;
};

export type Orders_Headers_Aggregate_Fields = {
  avg?: Maybe<Orders_Headers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Orders_Headers_Max_Fields>;
  min?: Maybe<Orders_Headers_Min_Fields>;
  stddev?: Maybe<Orders_Headers_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Headers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Headers_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Headers_Sum_Fields>;
  var_pop?: Maybe<Orders_Headers_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Headers_Var_Samp_Fields>;
  variance?: Maybe<Orders_Headers_Variance_Fields>;
};

export type Orders_Headers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Headers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Orders_Headers_Aggregate_Order_By = {
  avg?: Maybe<Orders_Headers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Headers_Max_Order_By>;
  min?: Maybe<Orders_Headers_Min_Order_By>;
  stddev?: Maybe<Orders_Headers_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Headers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Headers_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Headers_Sum_Order_By>;
  var_pop?: Maybe<Orders_Headers_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Headers_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Headers_Variance_Order_By>;
};

export type Orders_Headers_Arr_Rel_Insert_Input = {
  data: Array<Orders_Headers_Insert_Input>;
  on_conflict?: Maybe<Orders_Headers_On_Conflict>;
};

export type Orders_Headers_Avg_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Avg_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Orders_Headers_Bool_Exp>>>;
  _not?: Maybe<Orders_Headers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Orders_Headers_Bool_Exp>>>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  customer_order_number?: Maybe<Text_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  date_delivery?: Maybe<Timestamptz_Comparison_Exp>;
  date_for?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<Text_Comparison_Exp>;
  number?: Maybe<Integer_Comparison_Exp>;
  orders_rows_rel?: Maybe<Orders_Rows_Bool_Exp>;
  pdf?: Maybe<Text_Comparison_Exp>;
  state?: Maybe<Integer_Comparison_Exp>;
  view_orders_row_rel?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export enum Orders_Headers_Constraint {
  OrdersHeadersNumberKey = "orders_headers_number_key",
  OrdersPkey = "orders_pkey"
}

export type Orders_Headers_Inc_Input = {
  number?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type Orders_Headers_Insert_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  orders_rows_rel?: Maybe<Orders_Rows_Arr_Rel_Insert_Input>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type Orders_Headers_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type Orders_Headers_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  date_delivery?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  pdf?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type Orders_Headers_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  date_delivery?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  pdf?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Orders_Headers>;
};

export type Orders_Headers_Obj_Rel_Insert_Input = {
  data: Orders_Headers_Insert_Input;
  on_conflict?: Maybe<Orders_Headers_On_Conflict>;
};

export type Orders_Headers_On_Conflict = {
  constraint: Orders_Headers_Constraint;
  update_columns: Array<Orders_Headers_Update_Column>;
};

export type Orders_Headers_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  date?: Maybe<Order_By>;
  date_delivery?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  orders_rows_rel_aggregate?: Maybe<Orders_Rows_Aggregate_Order_By>;
  pdf?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  view_orders_row_rel_aggregate?: Maybe<View_Orders_Rows_Aggregate_Order_By>;
};

export enum Orders_Headers_Select_Column {
  CodeCustomer = "code_customer",
  CustomerOrderNumber = "customer_order_number",
  Date = "date",
  DateDelivery = "date_delivery",
  DateFor = "date_for",
  Id = "id",
  Note = "note",
  Number = "number",
  Pdf = "pdf",
  State = "state"
}

export type Orders_Headers_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type Orders_Headers_Stddev_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Stddev_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Stddev_Pop_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Stddev_Pop_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Stddev_Samp_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Stddev_Samp_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Sum_Fields = {
  number?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type Orders_Headers_Sum_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export enum Orders_Headers_Update_Column {
  CodeCustomer = "code_customer",
  CustomerOrderNumber = "customer_order_number",
  Date = "date",
  DateDelivery = "date_delivery",
  DateFor = "date_for",
  Id = "id",
  Note = "note",
  Number = "number",
  Pdf = "pdf",
  State = "state"
}

export type Orders_Headers_Var_Pop_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Var_Pop_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Var_Samp_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Var_Samp_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Headers_Variance_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type Orders_Headers_Variance_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type Orders_Rows = {
  code_product: Scalars["Int"];
  code_vat: Scalars["Int"];
  description_product: Scalars["String"];
  discount?: Maybe<Scalars["numeric"]>;
  id_order_header: Scalars["uuid"];
  offer?: Maybe<Scalars["numeric"]>;
  orders_header_rel: Orders_Headers;
  price: Scalars["numeric"];
  product_rel?: Maybe<Products>;
  quantity: Scalars["numeric"];
  row_number: Scalars["Int"];
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Orders_Rows_Aggregate = {
  aggregate?: Maybe<Orders_Rows_Aggregate_Fields>;
  nodes: Array<Orders_Rows>;
};

export type Orders_Rows_Aggregate_Fields = {
  avg?: Maybe<Orders_Rows_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Orders_Rows_Max_Fields>;
  min?: Maybe<Orders_Rows_Min_Fields>;
  stddev?: Maybe<Orders_Rows_Stddev_Fields>;
  stddev_pop?: Maybe<Orders_Rows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Orders_Rows_Stddev_Samp_Fields>;
  sum?: Maybe<Orders_Rows_Sum_Fields>;
  var_pop?: Maybe<Orders_Rows_Var_Pop_Fields>;
  var_samp?: Maybe<Orders_Rows_Var_Samp_Fields>;
  variance?: Maybe<Orders_Rows_Variance_Fields>;
};

export type Orders_Rows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Rows_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Orders_Rows_Aggregate_Order_By = {
  avg?: Maybe<Orders_Rows_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Rows_Max_Order_By>;
  min?: Maybe<Orders_Rows_Min_Order_By>;
  stddev?: Maybe<Orders_Rows_Stddev_Order_By>;
  stddev_pop?: Maybe<Orders_Rows_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Orders_Rows_Stddev_Samp_Order_By>;
  sum?: Maybe<Orders_Rows_Sum_Order_By>;
  var_pop?: Maybe<Orders_Rows_Var_Pop_Order_By>;
  var_samp?: Maybe<Orders_Rows_Var_Samp_Order_By>;
  variance?: Maybe<Orders_Rows_Variance_Order_By>;
};

export type Orders_Rows_Arr_Rel_Insert_Input = {
  data: Array<Orders_Rows_Insert_Input>;
  on_conflict?: Maybe<Orders_Rows_On_Conflict>;
};

export type Orders_Rows_Avg_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Avg_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Orders_Rows_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Orders_Rows_Bool_Exp>>>;
  _not?: Maybe<Orders_Rows_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Orders_Rows_Bool_Exp>>>;
  code_product?: Maybe<Integer_Comparison_Exp>;
  code_vat?: Maybe<Integer_Comparison_Exp>;
  description_product?: Maybe<Text_Comparison_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  id_order_header?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Numeric_Comparison_Exp>;
  orders_header_rel?: Maybe<Orders_Headers_Bool_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  product_rel?: Maybe<Products_Bool_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
  row_number?: Maybe<Integer_Comparison_Exp>;
  unit_of_measure?: Maybe<Text_Comparison_Exp>;
};

export enum Orders_Rows_Constraint {
  OrdersRowsPkey = "orders_rows_pkey"
}

export type Orders_Rows_Inc_Input = {
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  row_number?: Maybe<Scalars["Int"]>;
};

export type Orders_Rows_Insert_Input = {
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  id_order_header?: Maybe<Scalars["uuid"]>;
  offer?: Maybe<Scalars["numeric"]>;
  orders_header_rel?: Maybe<Orders_Headers_Obj_Rel_Insert_Input>;
  price?: Maybe<Scalars["numeric"]>;
  product_rel?: Maybe<Products_Obj_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Orders_Rows_Max_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Orders_Rows_Max_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  description_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  unit_of_measure?: Maybe<Order_By>;
};

export type Orders_Rows_Min_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Orders_Rows_Min_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  description_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  unit_of_measure?: Maybe<Order_By>;
};

export type Orders_Rows_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Orders_Rows>;
};

export type Orders_Rows_Obj_Rel_Insert_Input = {
  data: Orders_Rows_Insert_Input;
  on_conflict?: Maybe<Orders_Rows_On_Conflict>;
};

export type Orders_Rows_On_Conflict = {
  constraint: Orders_Rows_Constraint;
  update_columns: Array<Orders_Rows_Update_Column>;
};

export type Orders_Rows_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  description_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  id_order_header?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  orders_header_rel?: Maybe<Orders_Headers_Order_By>;
  price?: Maybe<Order_By>;
  product_rel?: Maybe<Products_Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  unit_of_measure?: Maybe<Order_By>;
};

export enum Orders_Rows_Select_Column {
  CodeProduct = "code_product",
  CodeVat = "code_vat",
  DescriptionProduct = "description_product",
  Discount = "discount",
  IdOrderHeader = "id_order_header",
  Offer = "offer",
  Price = "price",
  Quantity = "quantity",
  RowNumber = "row_number",
  UnitOfMeasure = "unit_of_measure"
}

export type Orders_Rows_Set_Input = {
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  id_order_header?: Maybe<Scalars["uuid"]>;
  offer?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Orders_Rows_Stddev_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Stddev_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Orders_Rows_Stddev_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Stddev_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Orders_Rows_Stddev_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Stddev_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Orders_Rows_Sum_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
};

export type Orders_Rows_Sum_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export enum Orders_Rows_Update_Column {
  CodeProduct = "code_product",
  CodeVat = "code_vat",
  DescriptionProduct = "description_product",
  Discount = "discount",
  IdOrderHeader = "id_order_header",
  Offer = "offer",
  Price = "price",
  Quantity = "quantity",
  RowNumber = "row_number",
  UnitOfMeasure = "unit_of_measure"
}

export type Orders_Rows_Var_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Var_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Orders_Rows_Var_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Var_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Orders_Rows_Variance_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
};

export type Orders_Rows_Variance_Order_By = {
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

export type Prices = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
  customer_rel?: Maybe<Customers>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price: Scalars["numeric"];
  product_rel?: Maybe<Products>;
};

export type Prices_Aggregate = {
  aggregate?: Maybe<Prices_Aggregate_Fields>;
  nodes: Array<Prices>;
};

export type Prices_Aggregate_Fields = {
  avg?: Maybe<Prices_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Prices_Max_Fields>;
  min?: Maybe<Prices_Min_Fields>;
  stddev?: Maybe<Prices_Stddev_Fields>;
  stddev_pop?: Maybe<Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prices_Stddev_Samp_Fields>;
  sum?: Maybe<Prices_Sum_Fields>;
  var_pop?: Maybe<Prices_Var_Pop_Fields>;
  var_samp?: Maybe<Prices_Var_Samp_Fields>;
  variance?: Maybe<Prices_Variance_Fields>;
};

export type Prices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Prices_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Prices_Aggregate_Order_By = {
  avg?: Maybe<Prices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Prices_Max_Order_By>;
  min?: Maybe<Prices_Min_Order_By>;
  stddev?: Maybe<Prices_Stddev_Order_By>;
  stddev_pop?: Maybe<Prices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Prices_Stddev_Samp_Order_By>;
  sum?: Maybe<Prices_Sum_Order_By>;
  var_pop?: Maybe<Prices_Var_Pop_Order_By>;
  var_samp?: Maybe<Prices_Var_Samp_Order_By>;
  variance?: Maybe<Prices_Variance_Order_By>;
};

export type Prices_Arr_Rel_Insert_Input = {
  data: Array<Prices_Insert_Input>;
  on_conflict?: Maybe<Prices_On_Conflict>;
};

export type Prices_Avg_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Avg_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Prices_Bool_Exp>>>;
  _not?: Maybe<Prices_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Prices_Bool_Exp>>>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  code_product?: Maybe<Integer_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  offer?: Maybe<Numeric_Comparison_Exp>;
  offer_from?: Maybe<Date_Comparison_Exp>;
  offer_to?: Maybe<Date_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  product_rel?: Maybe<Products_Bool_Exp>;
};

export enum Prices_Constraint {
  PricesPkey = "prices_pkey"
}

export type Prices_Inc_Input = {
  code_product?: Maybe<Scalars["Int"]>;
};

export type Prices_Insert_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
  product_rel?: Maybe<Products_Obj_Rel_Insert_Input>;
};

export type Prices_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
};

export type Prices_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  offer_from?: Maybe<Order_By>;
  offer_to?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
};

export type Prices_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  offer_from?: Maybe<Order_By>;
  offer_to?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Prices>;
};

export type Prices_Obj_Rel_Insert_Input = {
  data: Prices_Insert_Input;
  on_conflict?: Maybe<Prices_On_Conflict>;
};

export type Prices_On_Conflict = {
  constraint: Prices_Constraint;
  update_columns: Array<Prices_Update_Column>;
};

export type Prices_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  offer_from?: Maybe<Order_By>;
  offer_to?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_rel?: Maybe<Products_Order_By>;
};

export enum Prices_Select_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product",
  Discount = "discount",
  Offer = "offer",
  OfferFrom = "offer_from",
  OfferTo = "offer_to",
  Price = "price"
}

export type Prices_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
};

export type Prices_Stddev_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Stddev_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Stddev_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Stddev_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Stddev_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Stddev_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Sum_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
};

export type Prices_Sum_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export enum Prices_Update_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product",
  Discount = "discount",
  Offer = "offer",
  OfferFrom = "offer_from",
  OfferTo = "offer_to",
  Price = "price"
}

export type Prices_Var_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Var_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Var_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Var_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Prices_Variance_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type Prices_Variance_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Products = {
  cart_rows_rel: Array<Cart_Rows>;
  cart_rows_rel_aggregate: Cart_Rows_Aggregate;
  code: Scalars["Int"];
  code_group: Scalars["String"];
  code_vat?: Maybe<Scalars["Int"]>;
  customer_rel: Array<Customers_Products>;
  customer_rel_aggregate: Customers_Products_Aggregate;
  data_sheet?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  group_rel: Array<Products_Groups>;
  group_rel_aggregate: Products_Groups_Aggregate;
  image_large?: Maybe<Scalars["String"]>;
  image_medium?: Maybe<Scalars["String"]>;
  image_small?: Maybe<Scalars["String"]>;
  multiple_value?: Maybe<Scalars["numeric"]>;
  price_rel: Array<View_Prices>;
  price_rel_aggregate: View_Prices_Aggregate;
  unit_of_measure?: Maybe<Scalars["String"]>;
  vat_rel?: Maybe<Vats>;
};

export type ProductsCart_Rows_RelArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type ProductsCart_Rows_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type ProductsCustomer_RelArgs = {
  distinct_on?: Maybe<Array<Customers_Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Products_Order_By>>;
  where?: Maybe<Customers_Products_Bool_Exp>;
};

export type ProductsCustomer_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Products_Order_By>>;
  where?: Maybe<Customers_Products_Bool_Exp>;
};

export type ProductsGroup_RelArgs = {
  distinct_on?: Maybe<Array<Products_Groups_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Groups_Order_By>>;
  where?: Maybe<Products_Groups_Bool_Exp>;
};

export type ProductsGroup_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Groups_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Groups_Order_By>>;
  where?: Maybe<Products_Groups_Bool_Exp>;
};

export type ProductsPrice_RelArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type ProductsPrice_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type Products_Aggregate = {
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Fields = {
  avg?: Maybe<Products_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};

export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Products_Aggregate_Order_By = {
  avg?: Maybe<Products_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Max_Order_By>;
  min?: Maybe<Products_Min_Order_By>;
  stddev?: Maybe<Products_Stddev_Order_By>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Order_By>;
  sum?: Maybe<Products_Sum_Order_By>;
  var_pop?: Maybe<Products_Var_Pop_Order_By>;
  var_samp?: Maybe<Products_Var_Samp_Order_By>;
  variance?: Maybe<Products_Variance_Order_By>;
};

export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  on_conflict?: Maybe<Products_On_Conflict>;
};

export type Products_Avg_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Avg_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Products_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Products_Bool_Exp>>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Products_Bool_Exp>>>;
  cart_rows_rel?: Maybe<Cart_Rows_Bool_Exp>;
  code?: Maybe<Integer_Comparison_Exp>;
  code_group?: Maybe<Text_Comparison_Exp>;
  code_vat?: Maybe<Integer_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Products_Bool_Exp>;
  data_sheet?: Maybe<Text_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<Text_Comparison_Exp>;
  group_rel?: Maybe<Products_Groups_Bool_Exp>;
  image_large?: Maybe<Text_Comparison_Exp>;
  image_medium?: Maybe<Text_Comparison_Exp>;
  image_small?: Maybe<Text_Comparison_Exp>;
  multiple_value?: Maybe<Numeric_Comparison_Exp>;
  price_rel?: Maybe<View_Prices_Bool_Exp>;
  unit_of_measure?: Maybe<Text_Comparison_Exp>;
  vat_rel?: Maybe<Vats_Bool_Exp>;
};

export enum Products_Constraint {
  ProductsPkey = "products_pkey"
}

export type Products_Groups = {
  code: Scalars["String"];
  deleted?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  products_rel: Array<Products>;
  products_rel_aggregate: Products_Aggregate;
};

export type Products_GroupsProducts_RelArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type Products_GroupsProducts_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type Products_Groups_Aggregate = {
  aggregate?: Maybe<Products_Groups_Aggregate_Fields>;
  nodes: Array<Products_Groups>;
};

export type Products_Groups_Aggregate_Fields = {
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Products_Groups_Max_Fields>;
  min?: Maybe<Products_Groups_Min_Fields>;
};

export type Products_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Groups_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Products_Groups_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Groups_Max_Order_By>;
  min?: Maybe<Products_Groups_Min_Order_By>;
};

export type Products_Groups_Arr_Rel_Insert_Input = {
  data: Array<Products_Groups_Insert_Input>;
  on_conflict?: Maybe<Products_Groups_On_Conflict>;
};

export type Products_Groups_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Products_Groups_Bool_Exp>>>;
  _not?: Maybe<Products_Groups_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Products_Groups_Bool_Exp>>>;
  code?: Maybe<Text_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<Text_Comparison_Exp>;
  products_rel?: Maybe<Products_Bool_Exp>;
};

export enum Products_Groups_Constraint {
  GroupsPkey = "groups_pkey"
}

export type Products_Groups_Insert_Input = {
  code?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  products_rel?: Maybe<Products_Arr_Rel_Insert_Input>;
};

export type Products_Groups_Max_Fields = {
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type Products_Groups_Max_Order_By = {
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
};

export type Products_Groups_Min_Fields = {
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type Products_Groups_Min_Order_By = {
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
};

export type Products_Groups_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Products_Groups>;
};

export type Products_Groups_Obj_Rel_Insert_Input = {
  data: Products_Groups_Insert_Input;
  on_conflict?: Maybe<Products_Groups_On_Conflict>;
};

export type Products_Groups_On_Conflict = {
  constraint: Products_Groups_Constraint;
  update_columns: Array<Products_Groups_Update_Column>;
};

export type Products_Groups_Order_By = {
  code?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  products_rel_aggregate?: Maybe<Products_Aggregate_Order_By>;
};

export enum Products_Groups_Select_Column {
  Code = "code",
  Deleted = "deleted",
  Description = "description"
}

export type Products_Groups_Set_Input = {
  code?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
};

export enum Products_Groups_Update_Column {
  Code = "code",
  Deleted = "deleted",
  Description = "description"
}

export type Products_Inc_Input = {
  code?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
};

export type Products_Insert_Input = {
  cart_rows_rel?: Maybe<Cart_Rows_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars["Int"]>;
  code_group?: Maybe<Scalars["String"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  customer_rel?: Maybe<Customers_Products_Arr_Rel_Insert_Input>;
  data_sheet?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  group_rel?: Maybe<Products_Groups_Arr_Rel_Insert_Input>;
  image_large?: Maybe<Scalars["String"]>;
  image_medium?: Maybe<Scalars["String"]>;
  image_small?: Maybe<Scalars["String"]>;
  multiple_value?: Maybe<Scalars["numeric"]>;
  price_rel?: Maybe<View_Prices_Arr_Rel_Insert_Input>;
  unit_of_measure?: Maybe<Scalars["String"]>;
  vat_rel?: Maybe<Vats_Obj_Rel_Insert_Input>;
};

export type Products_Max_Fields = {
  code?: Maybe<Scalars["Int"]>;
  code_group?: Maybe<Scalars["String"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  data_sheet?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image_large?: Maybe<Scalars["String"]>;
  image_medium?: Maybe<Scalars["String"]>;
  image_small?: Maybe<Scalars["String"]>;
  multiple_value?: Maybe<Scalars["numeric"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Products_Max_Order_By = {
  code?: Maybe<Order_By>;
  code_group?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  data_sheet?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  image_large?: Maybe<Order_By>;
  image_medium?: Maybe<Order_By>;
  image_small?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
  unit_of_measure?: Maybe<Order_By>;
};

export type Products_Min_Fields = {
  code?: Maybe<Scalars["Int"]>;
  code_group?: Maybe<Scalars["String"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  data_sheet?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image_large?: Maybe<Scalars["String"]>;
  image_medium?: Maybe<Scalars["String"]>;
  image_small?: Maybe<Scalars["String"]>;
  multiple_value?: Maybe<Scalars["numeric"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Products_Min_Order_By = {
  code?: Maybe<Order_By>;
  code_group?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  data_sheet?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  image_large?: Maybe<Order_By>;
  image_medium?: Maybe<Order_By>;
  image_small?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
  unit_of_measure?: Maybe<Order_By>;
};

export type Products_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Products>;
};

export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  on_conflict?: Maybe<Products_On_Conflict>;
};

export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns: Array<Products_Update_Column>;
};

export type Products_Order_By = {
  cart_rows_rel_aggregate?: Maybe<Cart_Rows_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  code_group?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  customer_rel_aggregate?: Maybe<Customers_Products_Aggregate_Order_By>;
  data_sheet?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  group_rel_aggregate?: Maybe<Products_Groups_Aggregate_Order_By>;
  image_large?: Maybe<Order_By>;
  image_medium?: Maybe<Order_By>;
  image_small?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
  price_rel_aggregate?: Maybe<View_Prices_Aggregate_Order_By>;
  unit_of_measure?: Maybe<Order_By>;
  vat_rel?: Maybe<Vats_Order_By>;
};

export enum Products_Select_Column {
  Code = "code",
  CodeGroup = "code_group",
  CodeVat = "code_vat",
  DataSheet = "data_sheet",
  Deleted = "deleted",
  Description = "description",
  ImageLarge = "image_large",
  ImageMedium = "image_medium",
  ImageSmall = "image_small",
  MultipleValue = "multiple_value",
  UnitOfMeasure = "unit_of_measure"
}

export type Products_Set_Input = {
  code?: Maybe<Scalars["Int"]>;
  code_group?: Maybe<Scalars["String"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  data_sheet?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  image_large?: Maybe<Scalars["String"]>;
  image_medium?: Maybe<Scalars["String"]>;
  image_small?: Maybe<Scalars["String"]>;
  multiple_value?: Maybe<Scalars["numeric"]>;
  unit_of_measure?: Maybe<Scalars["String"]>;
};

export type Products_Stddev_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Stddev_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Products_Stddev_Pop_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Stddev_Pop_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Products_Stddev_Samp_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Stddev_Samp_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Products_Sum_Fields = {
  code?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  multiple_value?: Maybe<Scalars["numeric"]>;
};

export type Products_Sum_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export enum Products_Update_Column {
  Code = "code",
  CodeGroup = "code_group",
  CodeVat = "code_vat",
  DataSheet = "data_sheet",
  Deleted = "deleted",
  Description = "description",
  ImageLarge = "image_large",
  ImageMedium = "image_medium",
  ImageSmall = "image_small",
  MultipleValue = "multiple_value",
  UnitOfMeasure = "unit_of_measure"
}

export type Products_Var_Pop_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Var_Pop_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Products_Var_Samp_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Var_Samp_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Products_Variance_Fields = {
  code?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  multiple_value?: Maybe<Scalars["Float"]>;
};

export type Products_Variance_Order_By = {
  code?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  multiple_value?: Maybe<Order_By>;
};

export type Query_Root = {
  cart_headers: Array<Cart_Headers>;
  cart_headers_aggregate: Cart_Headers_Aggregate;
  cart_headers_by_pk?: Maybe<Cart_Headers>;
  cart_rows: Array<Cart_Rows>;
  cart_rows_aggregate: Cart_Rows_Aggregate;
  cart_rows_by_pk?: Maybe<Cart_Rows>;
  customers: Array<Customers>;
  customers_aggregate: Customers_Aggregate;
  customers_by_pk?: Maybe<Customers>;
  customers_products: Array<Customers_Products>;
  customers_products_aggregate: Customers_Products_Aggregate;
  customers_products_by_pk?: Maybe<Customers_Products>;
  orders_headers: Array<Orders_Headers>;
  orders_headers_aggregate: Orders_Headers_Aggregate;
  orders_headers_by_pk?: Maybe<Orders_Headers>;
  orders_rows: Array<Orders_Rows>;
  orders_rows_aggregate: Orders_Rows_Aggregate;
  orders_rows_by_pk?: Maybe<Orders_Rows>;
  prices: Array<Prices>;
  prices_aggregate: Prices_Aggregate;
  prices_by_pk?: Maybe<Prices>;
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
  products_by_pk?: Maybe<Products>;
  products_groups: Array<Products_Groups>;
  products_groups_aggregate: Products_Groups_Aggregate;
  products_groups_by_pk?: Maybe<Products_Groups>;
  user_customer: Array<User_Customer>;
  user_customer_aggregate: User_Customer_Aggregate;
  user_customer_by_pk?: Maybe<User_Customer>;
  vats: Array<Vats>;
  vats_aggregate: Vats_Aggregate;
  vats_by_pk?: Maybe<Vats>;
  view_orders_headers: Array<View_Orders_Headers>;
  view_orders_headers_aggregate: View_Orders_Headers_Aggregate;
  view_orders_rows: Array<View_Orders_Rows>;
  view_orders_rows_aggregate: View_Orders_Rows_Aggregate;
  view_prices: Array<View_Prices>;
  view_prices_aggregate: View_Prices_Aggregate;
};

export type Query_RootCart_HeadersArgs = {
  distinct_on?: Maybe<Array<Cart_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Headers_Order_By>>;
  where?: Maybe<Cart_Headers_Bool_Exp>;
};

export type Query_RootCart_Headers_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Headers_Order_By>>;
  where?: Maybe<Cart_Headers_Bool_Exp>;
};

export type Query_RootCart_Headers_By_PkArgs = {
  code_customer: Scalars["String"];
};

export type Query_RootCart_RowsArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type Query_RootCart_Rows_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type Query_RootCart_Rows_By_PkArgs = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
};

export type Query_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};

export type Query_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};

export type Query_RootCustomers_By_PkArgs = {
  code: Scalars["String"];
};

export type Query_RootCustomers_ProductsArgs = {
  distinct_on?: Maybe<Array<Customers_Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Products_Order_By>>;
  where?: Maybe<Customers_Products_Bool_Exp>;
};

export type Query_RootCustomers_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Products_Order_By>>;
  where?: Maybe<Customers_Products_Bool_Exp>;
};

export type Query_RootCustomers_Products_By_PkArgs = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
};

export type Query_RootOrders_HeadersArgs = {
  distinct_on?: Maybe<Array<Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Headers_Order_By>>;
  where?: Maybe<Orders_Headers_Bool_Exp>;
};

export type Query_RootOrders_Headers_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Headers_Order_By>>;
  where?: Maybe<Orders_Headers_Bool_Exp>;
};

export type Query_RootOrders_Headers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootOrders_RowsArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type Query_RootOrders_Rows_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type Query_RootOrders_Rows_By_PkArgs = {
  id_order_header: Scalars["uuid"];
  row_number: Scalars["Int"];
};

export type Query_RootPricesArgs = {
  distinct_on?: Maybe<Array<Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Prices_Order_By>>;
  where?: Maybe<Prices_Bool_Exp>;
};

export type Query_RootPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Prices_Order_By>>;
  where?: Maybe<Prices_Bool_Exp>;
};

export type Query_RootPrices_By_PkArgs = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
};

export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type Query_RootProducts_By_PkArgs = {
  code: Scalars["Int"];
};

export type Query_RootProducts_GroupsArgs = {
  distinct_on?: Maybe<Array<Products_Groups_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Groups_Order_By>>;
  where?: Maybe<Products_Groups_Bool_Exp>;
};

export type Query_RootProducts_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Groups_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Groups_Order_By>>;
  where?: Maybe<Products_Groups_Bool_Exp>;
};

export type Query_RootProducts_Groups_By_PkArgs = {
  code: Scalars["String"];
};

export type Query_RootUser_CustomerArgs = {
  distinct_on?: Maybe<Array<User_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Customer_Order_By>>;
  where?: Maybe<User_Customer_Bool_Exp>;
};

export type Query_RootUser_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Customer_Order_By>>;
  where?: Maybe<User_Customer_Bool_Exp>;
};

export type Query_RootUser_Customer_By_PkArgs = {
  code_customer: Scalars["String"];
  user_id: Scalars["String"];
};

export type Query_RootVatsArgs = {
  distinct_on?: Maybe<Array<Vats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vats_Order_By>>;
  where?: Maybe<Vats_Bool_Exp>;
};

export type Query_RootVats_AggregateArgs = {
  distinct_on?: Maybe<Array<Vats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vats_Order_By>>;
  where?: Maybe<Vats_Bool_Exp>;
};

export type Query_RootVats_By_PkArgs = {
  code: Scalars["Int"];
};

export type Query_RootView_Orders_HeadersArgs = {
  distinct_on?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Headers_Order_By>>;
  where?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type Query_RootView_Orders_Headers_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Headers_Order_By>>;
  where?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type Query_RootView_Orders_RowsArgs = {
  distinct_on?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Rows_Order_By>>;
  where?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export type Query_RootView_Orders_Rows_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Rows_Order_By>>;
  where?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export type Query_RootView_PricesArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type Query_RootView_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type Subscription_Root = {
  cart_headers: Array<Cart_Headers>;
  cart_headers_aggregate: Cart_Headers_Aggregate;
  cart_headers_by_pk?: Maybe<Cart_Headers>;
  cart_rows: Array<Cart_Rows>;
  cart_rows_aggregate: Cart_Rows_Aggregate;
  cart_rows_by_pk?: Maybe<Cart_Rows>;
  customers: Array<Customers>;
  customers_aggregate: Customers_Aggregate;
  customers_by_pk?: Maybe<Customers>;
  customers_products: Array<Customers_Products>;
  customers_products_aggregate: Customers_Products_Aggregate;
  customers_products_by_pk?: Maybe<Customers_Products>;
  orders_headers: Array<Orders_Headers>;
  orders_headers_aggregate: Orders_Headers_Aggregate;
  orders_headers_by_pk?: Maybe<Orders_Headers>;
  orders_rows: Array<Orders_Rows>;
  orders_rows_aggregate: Orders_Rows_Aggregate;
  orders_rows_by_pk?: Maybe<Orders_Rows>;
  prices: Array<Prices>;
  prices_aggregate: Prices_Aggregate;
  prices_by_pk?: Maybe<Prices>;
  products: Array<Products>;
  products_aggregate: Products_Aggregate;
  products_by_pk?: Maybe<Products>;
  products_groups: Array<Products_Groups>;
  products_groups_aggregate: Products_Groups_Aggregate;
  products_groups_by_pk?: Maybe<Products_Groups>;
  user_customer: Array<User_Customer>;
  user_customer_aggregate: User_Customer_Aggregate;
  user_customer_by_pk?: Maybe<User_Customer>;
  vats: Array<Vats>;
  vats_aggregate: Vats_Aggregate;
  vats_by_pk?: Maybe<Vats>;
  view_orders_headers: Array<View_Orders_Headers>;
  view_orders_headers_aggregate: View_Orders_Headers_Aggregate;
  view_orders_rows: Array<View_Orders_Rows>;
  view_orders_rows_aggregate: View_Orders_Rows_Aggregate;
  view_prices: Array<View_Prices>;
  view_prices_aggregate: View_Prices_Aggregate;
};

export type Subscription_RootCart_HeadersArgs = {
  distinct_on?: Maybe<Array<Cart_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Headers_Order_By>>;
  where?: Maybe<Cart_Headers_Bool_Exp>;
};

export type Subscription_RootCart_Headers_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Headers_Order_By>>;
  where?: Maybe<Cart_Headers_Bool_Exp>;
};

export type Subscription_RootCart_Headers_By_PkArgs = {
  code_customer: Scalars["String"];
};

export type Subscription_RootCart_RowsArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type Subscription_RootCart_Rows_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Cart_Rows_Order_By>>;
  where?: Maybe<Cart_Rows_Bool_Exp>;
};

export type Subscription_RootCart_Rows_By_PkArgs = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
};

export type Subscription_RootCustomersArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};

export type Subscription_RootCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Order_By>>;
  where?: Maybe<Customers_Bool_Exp>;
};

export type Subscription_RootCustomers_By_PkArgs = {
  code: Scalars["String"];
};

export type Subscription_RootCustomers_ProductsArgs = {
  distinct_on?: Maybe<Array<Customers_Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Products_Order_By>>;
  where?: Maybe<Customers_Products_Bool_Exp>;
};

export type Subscription_RootCustomers_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Customers_Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Customers_Products_Order_By>>;
  where?: Maybe<Customers_Products_Bool_Exp>;
};

export type Subscription_RootCustomers_Products_By_PkArgs = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
};

export type Subscription_RootOrders_HeadersArgs = {
  distinct_on?: Maybe<Array<Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Headers_Order_By>>;
  where?: Maybe<Orders_Headers_Bool_Exp>;
};

export type Subscription_RootOrders_Headers_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Headers_Order_By>>;
  where?: Maybe<Orders_Headers_Bool_Exp>;
};

export type Subscription_RootOrders_Headers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootOrders_RowsArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type Subscription_RootOrders_Rows_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type Subscription_RootOrders_Rows_By_PkArgs = {
  id_order_header: Scalars["uuid"];
  row_number: Scalars["Int"];
};

export type Subscription_RootPricesArgs = {
  distinct_on?: Maybe<Array<Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Prices_Order_By>>;
  where?: Maybe<Prices_Bool_Exp>;
};

export type Subscription_RootPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Prices_Order_By>>;
  where?: Maybe<Prices_Bool_Exp>;
};

export type Subscription_RootPrices_By_PkArgs = {
  code_customer: Scalars["String"];
  code_product: Scalars["Int"];
};

export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};

export type Subscription_RootProducts_By_PkArgs = {
  code: Scalars["Int"];
};

export type Subscription_RootProducts_GroupsArgs = {
  distinct_on?: Maybe<Array<Products_Groups_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Groups_Order_By>>;
  where?: Maybe<Products_Groups_Bool_Exp>;
};

export type Subscription_RootProducts_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Groups_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Products_Groups_Order_By>>;
  where?: Maybe<Products_Groups_Bool_Exp>;
};

export type Subscription_RootProducts_Groups_By_PkArgs = {
  code: Scalars["String"];
};

export type Subscription_RootUser_CustomerArgs = {
  distinct_on?: Maybe<Array<User_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Customer_Order_By>>;
  where?: Maybe<User_Customer_Bool_Exp>;
};

export type Subscription_RootUser_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Customer_Order_By>>;
  where?: Maybe<User_Customer_Bool_Exp>;
};

export type Subscription_RootUser_Customer_By_PkArgs = {
  code_customer: Scalars["String"];
  user_id: Scalars["String"];
};

export type Subscription_RootVatsArgs = {
  distinct_on?: Maybe<Array<Vats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vats_Order_By>>;
  where?: Maybe<Vats_Bool_Exp>;
};

export type Subscription_RootVats_AggregateArgs = {
  distinct_on?: Maybe<Array<Vats_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vats_Order_By>>;
  where?: Maybe<Vats_Bool_Exp>;
};

export type Subscription_RootVats_By_PkArgs = {
  code: Scalars["Int"];
};

export type Subscription_RootView_Orders_HeadersArgs = {
  distinct_on?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Headers_Order_By>>;
  where?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type Subscription_RootView_Orders_Headers_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Headers_Order_By>>;
  where?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type Subscription_RootView_Orders_RowsArgs = {
  distinct_on?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Rows_Order_By>>;
  where?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export type Subscription_RootView_Orders_Rows_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Rows_Order_By>>;
  where?: Maybe<View_Orders_Rows_Bool_Exp>;
};

export type Subscription_RootView_PricesArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type Subscription_RootView_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Prices_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Prices_Order_By>>;
  where?: Maybe<View_Prices_Bool_Exp>;
};

export type Text_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  _nlike?: Maybe<Scalars["String"]>;
  _nsimilar?: Maybe<Scalars["String"]>;
  _similar?: Maybe<Scalars["String"]>;
};

export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

export type User_Customer = {
  code_customer: Scalars["String"];
  customer_rel?: Maybe<Customers>;
  email?: Maybe<Scalars["String"]>;
  user_id: Scalars["String"];
};

export type User_Customer_Aggregate = {
  aggregate?: Maybe<User_Customer_Aggregate_Fields>;
  nodes: Array<User_Customer>;
};

export type User_Customer_Aggregate_Fields = {
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<User_Customer_Max_Fields>;
  min?: Maybe<User_Customer_Min_Fields>;
};

export type User_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Customer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type User_Customer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Customer_Max_Order_By>;
  min?: Maybe<User_Customer_Min_Order_By>;
};

export type User_Customer_Arr_Rel_Insert_Input = {
  data: Array<User_Customer_Insert_Input>;
  on_conflict?: Maybe<User_Customer_On_Conflict>;
};

export type User_Customer_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Customer_Bool_Exp>>>;
  _not?: Maybe<User_Customer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Customer_Bool_Exp>>>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  email?: Maybe<Text_Comparison_Exp>;
  user_id?: Maybe<Text_Comparison_Exp>;
};

export enum User_Customer_Constraint {
  UserCustomerPkey = "user_customer_pkey"
}

export type User_Customer_Insert_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  email?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type User_Customer_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type User_Customer_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export type User_Customer_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export type User_Customer_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export type User_Customer_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<User_Customer>;
};

export type User_Customer_Obj_Rel_Insert_Input = {
  data: User_Customer_Insert_Input;
  on_conflict?: Maybe<User_Customer_On_Conflict>;
};

export type User_Customer_On_Conflict = {
  constraint: User_Customer_Constraint;
  update_columns: Array<User_Customer_Update_Column>;
};

export type User_Customer_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  email?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

export enum User_Customer_Select_Column {
  CodeCustomer = "code_customer",
  Email = "email",
  UserId = "user_id"
}

export type User_Customer_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

export enum User_Customer_Update_Column {
  CodeCustomer = "code_customer",
  Email = "email",
  UserId = "user_id"
}

export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

export type Vats = {
  code: Scalars["Int"];
  description: Scalars["String"];
  percentage: Scalars["numeric"];
};

export type Vats_Aggregate = {
  aggregate?: Maybe<Vats_Aggregate_Fields>;
  nodes: Array<Vats>;
};

export type Vats_Aggregate_Fields = {
  avg?: Maybe<Vats_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Vats_Max_Fields>;
  min?: Maybe<Vats_Min_Fields>;
  stddev?: Maybe<Vats_Stddev_Fields>;
  stddev_pop?: Maybe<Vats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vats_Stddev_Samp_Fields>;
  sum?: Maybe<Vats_Sum_Fields>;
  var_pop?: Maybe<Vats_Var_Pop_Fields>;
  var_samp?: Maybe<Vats_Var_Samp_Fields>;
  variance?: Maybe<Vats_Variance_Fields>;
};

export type Vats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vats_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type Vats_Aggregate_Order_By = {
  avg?: Maybe<Vats_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vats_Max_Order_By>;
  min?: Maybe<Vats_Min_Order_By>;
  stddev?: Maybe<Vats_Stddev_Order_By>;
  stddev_pop?: Maybe<Vats_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vats_Stddev_Samp_Order_By>;
  sum?: Maybe<Vats_Sum_Order_By>;
  var_pop?: Maybe<Vats_Var_Pop_Order_By>;
  var_samp?: Maybe<Vats_Var_Samp_Order_By>;
  variance?: Maybe<Vats_Variance_Order_By>;
};

export type Vats_Arr_Rel_Insert_Input = {
  data: Array<Vats_Insert_Input>;
  on_conflict?: Maybe<Vats_On_Conflict>;
};

export type Vats_Avg_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Avg_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Vats_Bool_Exp>>>;
  _not?: Maybe<Vats_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Vats_Bool_Exp>>>;
  code?: Maybe<Integer_Comparison_Exp>;
  description?: Maybe<Text_Comparison_Exp>;
  percentage?: Maybe<Numeric_Comparison_Exp>;
};

export enum Vats_Constraint {
  VatsPkey = "vats_pkey"
}

export type Vats_Inc_Input = {
  code?: Maybe<Scalars["Int"]>;
};

export type Vats_Insert_Input = {
  code?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["numeric"]>;
};

export type Vats_Max_Fields = {
  code?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["numeric"]>;
};

export type Vats_Max_Order_By = {
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Min_Fields = {
  code?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["numeric"]>;
};

export type Vats_Min_Order_By = {
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<Vats>;
};

export type Vats_Obj_Rel_Insert_Input = {
  data: Vats_Insert_Input;
  on_conflict?: Maybe<Vats_On_Conflict>;
};

export type Vats_On_Conflict = {
  constraint: Vats_Constraint;
  update_columns: Array<Vats_Update_Column>;
};

export type Vats_Order_By = {
  code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export enum Vats_Select_Column {
  Code = "code",
  Description = "description",
  Percentage = "percentage"
}

export type Vats_Set_Input = {
  code?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["numeric"]>;
};

export type Vats_Stddev_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Stddev_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Stddev_Pop_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Stddev_Pop_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Stddev_Samp_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Stddev_Samp_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Sum_Fields = {
  code?: Maybe<Scalars["Int"]>;
  percentage?: Maybe<Scalars["numeric"]>;
};

export type Vats_Sum_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export enum Vats_Update_Column {
  Code = "code",
  Description = "description",
  Percentage = "percentage"
}

export type Vats_Var_Pop_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Var_Pop_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Var_Samp_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Var_Samp_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type Vats_Variance_Fields = {
  code?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export type Vats_Variance_Order_By = {
  code?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
};

export type View_Orders_Headers = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ispdfavailable?: Maybe<Scalars["Boolean"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  orders_rows_rel: Array<Orders_Rows>;
  orders_rows_rel_aggregate: Orders_Rows_Aggregate;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
  view_orders_rows_rel: Array<View_Orders_Headers>;
  view_orders_rows_rel_aggregate: View_Orders_Headers_Aggregate;
};

export type View_Orders_HeadersOrders_Rows_RelArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type View_Orders_HeadersOrders_Rows_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Rows_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Orders_Rows_Order_By>>;
  where?: Maybe<Orders_Rows_Bool_Exp>;
};

export type View_Orders_HeadersView_Orders_Rows_RelArgs = {
  distinct_on?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Headers_Order_By>>;
  where?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type View_Orders_HeadersView_Orders_Rows_Rel_AggregateArgs = {
  distinct_on?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<View_Orders_Headers_Order_By>>;
  where?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type View_Orders_Headers_Aggregate = {
  aggregate?: Maybe<View_Orders_Headers_Aggregate_Fields>;
  nodes: Array<View_Orders_Headers>;
};

export type View_Orders_Headers_Aggregate_Fields = {
  avg?: Maybe<View_Orders_Headers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<View_Orders_Headers_Max_Fields>;
  min?: Maybe<View_Orders_Headers_Min_Fields>;
  stddev?: Maybe<View_Orders_Headers_Stddev_Fields>;
  stddev_pop?: Maybe<View_Orders_Headers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Orders_Headers_Stddev_Samp_Fields>;
  sum?: Maybe<View_Orders_Headers_Sum_Fields>;
  var_pop?: Maybe<View_Orders_Headers_Var_Pop_Fields>;
  var_samp?: Maybe<View_Orders_Headers_Var_Samp_Fields>;
  variance?: Maybe<View_Orders_Headers_Variance_Fields>;
};

export type View_Orders_Headers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Orders_Headers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type View_Orders_Headers_Aggregate_Order_By = {
  avg?: Maybe<View_Orders_Headers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<View_Orders_Headers_Max_Order_By>;
  min?: Maybe<View_Orders_Headers_Min_Order_By>;
  stddev?: Maybe<View_Orders_Headers_Stddev_Order_By>;
  stddev_pop?: Maybe<View_Orders_Headers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<View_Orders_Headers_Stddev_Samp_Order_By>;
  sum?: Maybe<View_Orders_Headers_Sum_Order_By>;
  var_pop?: Maybe<View_Orders_Headers_Var_Pop_Order_By>;
  var_samp?: Maybe<View_Orders_Headers_Var_Samp_Order_By>;
  variance?: Maybe<View_Orders_Headers_Variance_Order_By>;
};

export type View_Orders_Headers_Arr_Rel_Insert_Input = {
  data: Array<View_Orders_Headers_Insert_Input>;
};

export type View_Orders_Headers_Avg_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Avg_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<View_Orders_Headers_Bool_Exp>>>;
  _not?: Maybe<View_Orders_Headers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<View_Orders_Headers_Bool_Exp>>>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  customer_order_number?: Maybe<Text_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  date_delivery?: Maybe<Timestamptz_Comparison_Exp>;
  date_for?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ispdfavailable?: Maybe<Boolean_Comparison_Exp>;
  note?: Maybe<Text_Comparison_Exp>;
  number?: Maybe<Integer_Comparison_Exp>;
  orders_rows_rel?: Maybe<Orders_Rows_Bool_Exp>;
  pdf?: Maybe<Text_Comparison_Exp>;
  state?: Maybe<Integer_Comparison_Exp>;
  view_orders_rows_rel?: Maybe<View_Orders_Headers_Bool_Exp>;
};

export type View_Orders_Headers_Inc_Input = {
  number?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type View_Orders_Headers_Insert_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ispdfavailable?: Maybe<Scalars["Boolean"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  orders_rows_rel?: Maybe<Orders_Rows_Arr_Rel_Insert_Input>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
  view_orders_rows_rel?: Maybe<View_Orders_Headers_Arr_Rel_Insert_Input>;
};

export type View_Orders_Headers_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type View_Orders_Headers_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  date_delivery?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  pdf?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type View_Orders_Headers_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  date_delivery?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  pdf?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<View_Orders_Headers>;
};

export type View_Orders_Headers_Obj_Rel_Insert_Input = {
  data: View_Orders_Headers_Insert_Input;
};

export type View_Orders_Headers_Order_By = {
  code_customer?: Maybe<Order_By>;
  customer_order_number?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  date?: Maybe<Order_By>;
  date_delivery?: Maybe<Order_By>;
  date_for?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ispdfavailable?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
  orders_rows_rel_aggregate?: Maybe<Orders_Rows_Aggregate_Order_By>;
  pdf?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  view_orders_rows_rel_aggregate?: Maybe<
    View_Orders_Headers_Aggregate_Order_By
  >;
};

export enum View_Orders_Headers_Select_Column {
  CodeCustomer = "code_customer",
  CustomerOrderNumber = "customer_order_number",
  Date = "date",
  DateDelivery = "date_delivery",
  DateFor = "date_for",
  Id = "id",
  Ispdfavailable = "ispdfavailable",
  Note = "note",
  Number = "number",
  Pdf = "pdf",
  State = "state"
}

export type View_Orders_Headers_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  customer_order_number?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["timestamptz"]>;
  date_delivery?: Maybe<Scalars["timestamptz"]>;
  date_for?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  ispdfavailable?: Maybe<Scalars["Boolean"]>;
  note?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  pdf?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type View_Orders_Headers_Stddev_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Stddev_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Stddev_Pop_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Stddev_Pop_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Stddev_Samp_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Stddev_Samp_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Sum_Fields = {
  number?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["Int"]>;
};

export type View_Orders_Headers_Sum_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Var_Pop_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Var_Pop_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Var_Samp_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Var_Samp_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Headers_Variance_Fields = {
  number?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Headers_Variance_Order_By = {
  number?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

export type View_Orders_Rows = {
  amount?: Maybe<Scalars["numeric"]>;
  amount_vat?: Maybe<Scalars["numeric"]>;
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  id_order_header?: Maybe<Scalars["uuid"]>;
  offer?: Maybe<Scalars["numeric"]>;
  orders_headers_rel?: Maybe<Orders_Headers>;
  percentage?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  product_rel?: Maybe<Products>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  vat?: Maybe<Scalars["numeric"]>;
};

export type View_Orders_Rows_Aggregate = {
  aggregate?: Maybe<View_Orders_Rows_Aggregate_Fields>;
  nodes: Array<View_Orders_Rows>;
};

export type View_Orders_Rows_Aggregate_Fields = {
  avg?: Maybe<View_Orders_Rows_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<View_Orders_Rows_Max_Fields>;
  min?: Maybe<View_Orders_Rows_Min_Fields>;
  stddev?: Maybe<View_Orders_Rows_Stddev_Fields>;
  stddev_pop?: Maybe<View_Orders_Rows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Orders_Rows_Stddev_Samp_Fields>;
  sum?: Maybe<View_Orders_Rows_Sum_Fields>;
  var_pop?: Maybe<View_Orders_Rows_Var_Pop_Fields>;
  var_samp?: Maybe<View_Orders_Rows_Var_Samp_Fields>;
  variance?: Maybe<View_Orders_Rows_Variance_Fields>;
};

export type View_Orders_Rows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Orders_Rows_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type View_Orders_Rows_Aggregate_Order_By = {
  avg?: Maybe<View_Orders_Rows_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<View_Orders_Rows_Max_Order_By>;
  min?: Maybe<View_Orders_Rows_Min_Order_By>;
  stddev?: Maybe<View_Orders_Rows_Stddev_Order_By>;
  stddev_pop?: Maybe<View_Orders_Rows_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<View_Orders_Rows_Stddev_Samp_Order_By>;
  sum?: Maybe<View_Orders_Rows_Sum_Order_By>;
  var_pop?: Maybe<View_Orders_Rows_Var_Pop_Order_By>;
  var_samp?: Maybe<View_Orders_Rows_Var_Samp_Order_By>;
  variance?: Maybe<View_Orders_Rows_Variance_Order_By>;
};

export type View_Orders_Rows_Avg_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Bool_Exp = {
  _and?: Maybe<Array<Maybe<View_Orders_Rows_Bool_Exp>>>;
  _not?: Maybe<View_Orders_Rows_Bool_Exp>;
  _or?: Maybe<Array<Maybe<View_Orders_Rows_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  amount_vat?: Maybe<Numeric_Comparison_Exp>;
  code_product?: Maybe<Integer_Comparison_Exp>;
  code_vat?: Maybe<Integer_Comparison_Exp>;
  description?: Maybe<Text_Comparison_Exp>;
  description_product?: Maybe<Text_Comparison_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  id_order_header?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Numeric_Comparison_Exp>;
  orders_headers_rel?: Maybe<Orders_Headers_Bool_Exp>;
  percentage?: Maybe<Numeric_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  product_rel?: Maybe<Products_Bool_Exp>;
  quantity?: Maybe<Numeric_Comparison_Exp>;
  row_number?: Maybe<Integer_Comparison_Exp>;
  vat?: Maybe<Numeric_Comparison_Exp>;
};

export type View_Orders_Rows_Max_Fields = {
  amount?: Maybe<Scalars["numeric"]>;
  amount_vat?: Maybe<Scalars["numeric"]>;
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  percentage?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  vat?: Maybe<Scalars["numeric"]>;
};

export type View_Orders_Rows_Max_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  description_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Min_Fields = {
  amount?: Maybe<Scalars["numeric"]>;
  amount_vat?: Maybe<Scalars["numeric"]>;
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  description_product?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  percentage?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  vat?: Maybe<Scalars["numeric"]>;
};

export type View_Orders_Rows_Min_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  description_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  description_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  id_order_header?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  orders_headers_rel?: Maybe<Orders_Headers_Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  product_rel?: Maybe<Products_Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export enum View_Orders_Rows_Select_Column {
  Amount = "amount",
  AmountVat = "amount_vat",
  CodeProduct = "code_product",
  CodeVat = "code_vat",
  Description = "description",
  DescriptionProduct = "description_product",
  Discount = "discount",
  IdOrderHeader = "id_order_header",
  Offer = "offer",
  Percentage = "percentage",
  Price = "price",
  Quantity = "quantity",
  RowNumber = "row_number",
  Vat = "vat"
}

export type View_Orders_Rows_Stddev_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Sum_Fields = {
  amount?: Maybe<Scalars["numeric"]>;
  amount_vat?: Maybe<Scalars["numeric"]>;
  code_product?: Maybe<Scalars["Int"]>;
  code_vat?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  percentage?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  quantity?: Maybe<Scalars["numeric"]>;
  row_number?: Maybe<Scalars["Int"]>;
  vat?: Maybe<Scalars["numeric"]>;
};

export type View_Orders_Rows_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Var_Pop_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Var_Samp_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Orders_Rows_Variance_Fields = {
  amount?: Maybe<Scalars["Float"]>;
  amount_vat?: Maybe<Scalars["Float"]>;
  code_product?: Maybe<Scalars["Float"]>;
  code_vat?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  percentage?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  row_number?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
};

export type View_Orders_Rows_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  amount_vat?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  code_vat?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  vat?: Maybe<Order_By>;
};

export type View_Prices = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  customer_rel?: Maybe<Customers>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
  price_discount?: Maybe<Scalars["numeric"]>;
  product_rel?: Maybe<Products>;
};

export type View_Prices_Aggregate = {
  aggregate?: Maybe<View_Prices_Aggregate_Fields>;
  nodes: Array<View_Prices>;
};

export type View_Prices_Aggregate_Fields = {
  avg?: Maybe<View_Prices_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<View_Prices_Max_Fields>;
  min?: Maybe<View_Prices_Min_Fields>;
  stddev?: Maybe<View_Prices_Stddev_Fields>;
  stddev_pop?: Maybe<View_Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Prices_Stddev_Samp_Fields>;
  sum?: Maybe<View_Prices_Sum_Fields>;
  var_pop?: Maybe<View_Prices_Var_Pop_Fields>;
  var_samp?: Maybe<View_Prices_Var_Samp_Fields>;
  variance?: Maybe<View_Prices_Variance_Fields>;
};

export type View_Prices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<View_Prices_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

export type View_Prices_Aggregate_Order_By = {
  avg?: Maybe<View_Prices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<View_Prices_Max_Order_By>;
  min?: Maybe<View_Prices_Min_Order_By>;
  stddev?: Maybe<View_Prices_Stddev_Order_By>;
  stddev_pop?: Maybe<View_Prices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<View_Prices_Stddev_Samp_Order_By>;
  sum?: Maybe<View_Prices_Sum_Order_By>;
  var_pop?: Maybe<View_Prices_Var_Pop_Order_By>;
  var_samp?: Maybe<View_Prices_Var_Samp_Order_By>;
  variance?: Maybe<View_Prices_Variance_Order_By>;
};

export type View_Prices_Arr_Rel_Insert_Input = {
  data: Array<View_Prices_Insert_Input>;
};

export type View_Prices_Avg_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Avg_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Bool_Exp = {
  _and?: Maybe<Array<Maybe<View_Prices_Bool_Exp>>>;
  _not?: Maybe<View_Prices_Bool_Exp>;
  _or?: Maybe<Array<Maybe<View_Prices_Bool_Exp>>>;
  code_customer?: Maybe<Text_Comparison_Exp>;
  code_product?: Maybe<Integer_Comparison_Exp>;
  customer_rel?: Maybe<Customers_Bool_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  offer?: Maybe<Numeric_Comparison_Exp>;
  offer_from?: Maybe<Date_Comparison_Exp>;
  offer_to?: Maybe<Date_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  price_discount?: Maybe<Numeric_Comparison_Exp>;
  product_rel?: Maybe<Products_Bool_Exp>;
};

export type View_Prices_Inc_Input = {
  code_product?: Maybe<Scalars["Int"]>;
};

export type View_Prices_Insert_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  customer_rel?: Maybe<Customers_Obj_Rel_Insert_Input>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
  price_discount?: Maybe<Scalars["numeric"]>;
  product_rel?: Maybe<Products_Obj_Rel_Insert_Input>;
};

export type View_Prices_Max_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
  price_discount?: Maybe<Scalars["numeric"]>;
};

export type View_Prices_Max_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  offer_from?: Maybe<Order_By>;
  offer_to?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Min_Fields = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
  price_discount?: Maybe<Scalars["numeric"]>;
};

export type View_Prices_Min_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  offer_from?: Maybe<Order_By>;
  offer_to?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Mutation_Response = {
  affected_rows: Scalars["Int"];
  returning: Array<View_Prices>;
};

export type View_Prices_Obj_Rel_Insert_Input = {
  data: View_Prices_Insert_Input;
};

export type View_Prices_Order_By = {
  code_customer?: Maybe<Order_By>;
  code_product?: Maybe<Order_By>;
  customer_rel?: Maybe<Customers_Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  offer_from?: Maybe<Order_By>;
  offer_to?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
  product_rel?: Maybe<Products_Order_By>;
};

export enum View_Prices_Select_Column {
  CodeCustomer = "code_customer",
  CodeProduct = "code_product",
  Discount = "discount",
  Offer = "offer",
  OfferFrom = "offer_from",
  OfferTo = "offer_to",
  Price = "price",
  PriceDiscount = "price_discount"
}

export type View_Prices_Set_Input = {
  code_customer?: Maybe<Scalars["String"]>;
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  offer_from?: Maybe<Scalars["date"]>;
  offer_to?: Maybe<Scalars["date"]>;
  price?: Maybe<Scalars["numeric"]>;
  price_discount?: Maybe<Scalars["numeric"]>;
};

export type View_Prices_Stddev_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Stddev_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Stddev_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Stddev_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Stddev_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Stddev_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Sum_Fields = {
  code_product?: Maybe<Scalars["Int"]>;
  discount?: Maybe<Scalars["numeric"]>;
  offer?: Maybe<Scalars["numeric"]>;
  price?: Maybe<Scalars["numeric"]>;
  price_discount?: Maybe<Scalars["numeric"]>;
};

export type View_Prices_Sum_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Var_Pop_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Var_Pop_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Var_Samp_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Var_Samp_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};

export type View_Prices_Variance_Fields = {
  code_product?: Maybe<Scalars["Float"]>;
  discount?: Maybe<Scalars["Float"]>;
  offer?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
  price_discount?: Maybe<Scalars["Float"]>;
};

export type View_Prices_Variance_Order_By = {
  code_product?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_discount?: Maybe<Order_By>;
};
export type OrdersFragmentFragment = {
  __typename?: "view_orders_headers";
} & Pick<
  View_Orders_Headers,
  | "date"
  | "date_delivery"
  | "date_for"
  | "id"
  | "note"
  | "customer_order_number"
  | "number"
  | "ispdfavailable"
  | "state"
> & {
    orders_rows_rel: Array<
      { __typename?: "orders_rows" } & Pick<
        Orders_Rows,
        | "code_product"
        | "code_vat"
        | "description_product"
        | "discount"
        | "offer"
        | "price"
        | "quantity"
        | "row_number"
        | "unit_of_measure"
      >
    >;
  };

export type PricesFragmentFragment = { __typename?: "view_prices" } & Pick<
  View_Prices,
  "price" | "price_discount" | "offer" | "offer_from" | "offer_to" | "discount"
>;

export type ProductsFragmentFragment = { __typename?: "products" } & Pick<
  Products,
  | "code"
  | "code_group"
  | "description"
  | "image_medium"
  | "data_sheet"
  | "multiple_value"
  | "unit_of_measure"
  | "code_vat"
>;

export type ClearCartAndBulkInsertMutationVariables = {
  customerCode: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  rows: Array<Cart_Rows_Insert_Input>;
};

export type ClearCartAndBulkInsertMutation = {
  __typename?: "mutation_root";
} & {
  delete_cart_rows: Maybe<
    { __typename?: "cart_rows_mutation_response" } & Pick<
      Cart_Rows_Mutation_Response,
      "affected_rows"
    >
  >;
  update_cart_headers: Maybe<
    { __typename?: "cart_headers_mutation_response" } & Pick<
      Cart_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
  insert_cart_rows: Maybe<
    { __typename?: "cart_rows_mutation_response" } & Pick<
      Cart_Rows_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type ClearCartRowsMutationVariables = {
  customerCode: Scalars["String"];
};

export type ClearCartRowsMutation = { __typename?: "mutation_root" } & {
  update_cart_headers: Maybe<
    { __typename?: "cart_headers_mutation_response" } & Pick<
      Cart_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
  delete_cart_rows: Maybe<
    { __typename?: "cart_rows_mutation_response" } & Pick<
      Cart_Rows_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type InsertOrdersHeaderAndRowsAndClearCartMutationVariables = {
  customerCode: Scalars["String"];
  date: Scalars["timestamptz"];
  dateFor?: Maybe<Scalars["timestamptz"]>;
  note?: Maybe<Scalars["String"]>;
  customerOrderNumber?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  rows: Array<Orders_Rows_Insert_Input>;
};

export type InsertOrdersHeaderAndRowsAndClearCartMutation = {
  __typename?: "mutation_root";
} & {
  insert_orders_headers: Maybe<
    { __typename?: "orders_headers_mutation_response" } & Pick<
      Orders_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
  delete_cart_rows: Maybe<
    { __typename?: "cart_rows_mutation_response" } & Pick<
      Cart_Rows_Mutation_Response,
      "affected_rows"
    >
  >;
  update_cart_headers: Maybe<
    { __typename?: "cart_headers_mutation_response" } & Pick<
      Cart_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type RemoveCartRowMutationVariables = {
  customerCode: Scalars["String"];
  productCode: Scalars["Int"];
};

export type RemoveCartRowMutation = { __typename?: "mutation_root" } & {
  delete_cart_rows: Maybe<
    { __typename?: "cart_rows_mutation_response" } & Pick<
      Cart_Rows_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpdateCartHeaderCustomerOrderNumberMutationVariables = {
  customerCode: Scalars["String"];
  customerOrderNumber: Scalars["String"];
};

export type UpdateCartHeaderCustomerOrderNumberMutation = {
  __typename?: "mutation_root";
} & {
  update_cart_headers: Maybe<
    { __typename?: "cart_headers_mutation_response" } & Pick<
      Cart_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpdateCartHeaderDateForMutationVariables = {
  customerCode: Scalars["String"];
  dateFor: Scalars["date"];
};

export type UpdateCartHeaderDateForMutation = {
  __typename?: "mutation_root";
} & {
  update_cart_headers: Maybe<
    { __typename?: "cart_headers_mutation_response" } & Pick<
      Cart_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpdateCartHeaderNoteMutationVariables = {
  customerCode: Scalars["String"];
  note: Scalars["String"];
};

export type UpdateCartHeaderNoteMutation = { __typename?: "mutation_root" } & {
  update_cart_headers: Maybe<
    { __typename?: "cart_headers_mutation_response" } & Pick<
      Cart_Headers_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpsertCartRowMutationVariables = {
  customerCode: Scalars["String"];
  productCode: Scalars["Int"];
  quantity: Scalars["numeric"];
};

export type UpsertCartRowMutation = { __typename?: "mutation_root" } & {
  insert_cart_rows: Maybe<
    { __typename?: "cart_rows_mutation_response" } & Pick<
      Cart_Rows_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type CoreQueryVariables = {
  customerCode: Scalars["String"];
};

export type CoreQuery = { __typename?: "query_root" } & {
  user_customer: Array<
    { __typename?: "user_customer" } & Pick<User_Customer, "email">
  >;
  cart_headers: Array<
    { __typename?: "cart_headers" } & Pick<
      Cart_Headers,
      "date_for" | "note" | "customer_order_number" | "code_customer"
    > & {
        cart_row_rel: Array<
          { __typename?: "cart_rows" } & Pick<
            Cart_Rows,
            "code_product" | "quantity"
          >
        >;
      }
  >;
  products: Array<
    { __typename?: "products" } & {
      price_rel: Array<{ __typename?: "view_prices" } & PricesFragmentFragment>;
    } & ProductsFragmentFragment
  >;
  products_groups: Array<
    { __typename?: "products_groups" } & Pick<
      Products_Groups,
      "code" | "description"
    >
  >;
  view_orders_headers: Array<
    { __typename?: "view_orders_headers" } & OrdersFragmentFragment
  >;
};

export type CustomersQueryVariables = {};

export type CustomersQuery = { __typename?: "query_root" } & {
  customers: Array<
    { __typename?: "customers" } & Pick<
      Customers,
      "address" | "code" | "description" | "city"
    >
  >;
};

export type OrderPdfQueryVariables = {
  orderId: Scalars["uuid"];
};

export type OrderPdfQuery = { __typename?: "query_root" } & {
  orders_headers_by_pk: Maybe<
    { __typename?: "orders_headers" } & Pick<Orders_Headers, "pdf">
  >;
};

export type OrdersUpdatesSubscriptionVariables = {
  customerCode: Scalars["String"];
};

export type OrdersUpdatesSubscription = { __typename?: "subscription_root" } & {
  view_orders_headers: Array<
    { __typename?: "view_orders_headers" } & OrdersFragmentFragment
  >;
};

export type ProductsUpdatesSubscriptionVariables = {
  customerCode: Scalars["String"];
};

export type ProductsUpdatesSubscription = {
  __typename?: "subscription_root";
} & {
  products: Array<
    { __typename?: "products" } & {
      price_rel: Array<{ __typename?: "view_prices" } & PricesFragmentFragment>;
    } & ProductsFragmentFragment
  >;
};

import gql from "graphql-tag";
import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
export const OrdersFragmentFragmentDoc = gql`
  fragment OrdersFragment on view_orders_headers {
    date
    date_delivery
    date_for
    id
    note
    customer_order_number
    number
    ispdfavailable
    state
    orders_rows_rel {
      code_product
      code_vat
      description_product
      discount
      offer
      price
      quantity
      row_number
      unit_of_measure
    }
  }
`;
export const PricesFragmentFragmentDoc = gql`
  fragment PricesFragment on view_prices {
    price
    price_discount
    offer
    offer_from
    offer_to
    discount
  }
`;
export const ProductsFragmentFragmentDoc = gql`
  fragment ProductsFragment on products {
    code
    code_group
    description
    image_medium
    data_sheet
    multiple_value
    unit_of_measure
    code_vat
  }
`;
export const ClearCartAndBulkInsertDocument = gql`
  mutation ClearCartAndBulkInsert(
    $customerCode: String!
    $note: String
    $rows: [cart_rows_insert_input!]!
  ) {
    delete_cart_rows(where: { code_customer: { _eq: $customerCode } }) {
      affected_rows
    }
    update_cart_headers(
      where: { code_customer: { _eq: $customerCode } }
      _set: { note: $note, date_for: null }
    ) {
      affected_rows
    }
    insert_cart_rows(objects: $rows) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class ClearCartAndBulkInsertGQL extends Apollo.Mutation<
  ClearCartAndBulkInsertMutation,
  ClearCartAndBulkInsertMutationVariables
> {
  document = ClearCartAndBulkInsertDocument;
}
export const ClearCartRowsDocument = gql`
  mutation ClearCartRows($customerCode: String!) {
    update_cart_headers(
      where: { code_customer: { _eq: $customerCode } }
      _set: { note: null, date_for: null }
    ) {
      affected_rows
    }
    delete_cart_rows(where: { code_customer: { _eq: $customerCode } }) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class ClearCartRowsGQL extends Apollo.Mutation<
  ClearCartRowsMutation,
  ClearCartRowsMutationVariables
> {
  document = ClearCartRowsDocument;
}
export const InsertOrdersHeaderAndRowsAndClearCartDocument = gql`
  mutation InsertOrdersHeaderAndRowsAndClearCart(
    $customerCode: String!
    $date: timestamptz!
    $dateFor: timestamptz
    $note: String
    $customerOrderNumber: String
    $id: uuid!
    $rows: [orders_rows_insert_input!]!
  ) {
    insert_orders_headers(
      objects: {
        code_customer: $customerCode
        date: $date
        date_for: $dateFor
        note: $note
        customer_order_number: $customerOrderNumber
        id: $id
        orders_rows_rel: { data: $rows }
      }
    ) {
      affected_rows
    }
    delete_cart_rows(where: { code_customer: { _eq: $customerCode } }) {
      affected_rows
    }
    update_cart_headers(
      where: { code_customer: { _eq: $customerCode } }
      _set: { date_for: null, note: null }
    ) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class InsertOrdersHeaderAndRowsAndClearCartGQL extends Apollo.Mutation<
  InsertOrdersHeaderAndRowsAndClearCartMutation,
  InsertOrdersHeaderAndRowsAndClearCartMutationVariables
> {
  document = InsertOrdersHeaderAndRowsAndClearCartDocument;
}
export const RemoveCartRowDocument = gql`
  mutation RemoveCartRow($customerCode: String!, $productCode: Int!) {
    delete_cart_rows(
      where: {
        _and: {
          code_customer: { _eq: $customerCode }
          code_product: { _eq: $productCode }
        }
      }
    ) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class RemoveCartRowGQL extends Apollo.Mutation<
  RemoveCartRowMutation,
  RemoveCartRowMutationVariables
> {
  document = RemoveCartRowDocument;
}
export const UpdateCartHeaderCustomerOrderNumberDocument = gql`
  mutation UpdateCartHeaderCustomerOrderNumber(
    $customerCode: String!
    $customerOrderNumber: String!
  ) {
    update_cart_headers(
      where: { code_customer: { _eq: $customerCode } }
      _set: { customer_order_number: $customerOrderNumber }
    ) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UpdateCartHeaderCustomerOrderNumberGQL extends Apollo.Mutation<
  UpdateCartHeaderCustomerOrderNumberMutation,
  UpdateCartHeaderCustomerOrderNumberMutationVariables
> {
  document = UpdateCartHeaderCustomerOrderNumberDocument;
}
export const UpdateCartHeaderDateForDocument = gql`
  mutation UpdateCartHeaderDateFor($customerCode: String!, $dateFor: date!) {
    update_cart_headers(
      where: { code_customer: { _eq: $customerCode } }
      _set: { date_for: $dateFor }
    ) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UpdateCartHeaderDateForGQL extends Apollo.Mutation<
  UpdateCartHeaderDateForMutation,
  UpdateCartHeaderDateForMutationVariables
> {
  document = UpdateCartHeaderDateForDocument;
}
export const UpdateCartHeaderNoteDocument = gql`
  mutation UpdateCartHeaderNote($customerCode: String!, $note: String!) {
    update_cart_headers(
      where: { code_customer: { _eq: $customerCode } }
      _set: { note: $note }
    ) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UpdateCartHeaderNoteGQL extends Apollo.Mutation<
  UpdateCartHeaderNoteMutation,
  UpdateCartHeaderNoteMutationVariables
> {
  document = UpdateCartHeaderNoteDocument;
}
export const UpsertCartRowDocument = gql`
  mutation UpsertCartRow(
    $customerCode: String!
    $productCode: Int!
    $quantity: numeric!
  ) {
    insert_cart_rows(
      objects: {
        code_customer: $customerCode
        code_product: $productCode
        quantity: $quantity
      }
      on_conflict: { constraint: cart_rows_pkey, update_columns: quantity }
    ) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class UpsertCartRowGQL extends Apollo.Mutation<
  UpsertCartRowMutation,
  UpsertCartRowMutationVariables
> {
  document = UpsertCartRowDocument;
}
export const CoreDocument = gql`
  query Core($customerCode: String!) {
    user_customer(where: { code_customer: { _eq: $customerCode } }) {
      email
    }
    cart_headers(where: { code_customer: { _eq: $customerCode } }) {
      date_for
      note
      customer_order_number
      cart_row_rel {
        code_product
        quantity
      }
      code_customer
    }
    products(
      where: {
        _and: {
          _or: [
            { _not: { customer_rel: {} } }
            { customer_rel: { code_customer: { _eq: $customerCode } } }
          ]
        }
        price_rel: {}
      }
      order_by: { code_group: asc, code: asc }
    ) {
      ...ProductsFragment
      price_rel(where: { code_customer: { _eq: $customerCode } }) {
        ...PricesFragment
      }
    }
    products_groups(order_by: { description: asc }) {
      code
      description
    }
    view_orders_headers(
      where: { code_customer: { _eq: $customerCode } }
      order_by: { date: desc }
    ) {
      ...OrdersFragment
    }
  }
  ${ProductsFragmentFragmentDoc}
  ${PricesFragmentFragmentDoc}
  ${OrdersFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class CoreGQL extends Apollo.Query<CoreQuery, CoreQueryVariables> {
  document = CoreDocument;
}
export const CustomersDocument = gql`
  query Customers {
    customers {
      address
      code
      description
      city
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class CustomersGQL extends Apollo.Query<
  CustomersQuery,
  CustomersQueryVariables
> {
  document = CustomersDocument;
}
export const OrderPdfDocument = gql`
  query OrderPdf($orderId: uuid!) {
    orders_headers_by_pk(id: $orderId) {
      pdf
    }
  }
`;

@Injectable({
  providedIn: "root"
})
export class OrderPdfGQL extends Apollo.Query<
  OrderPdfQuery,
  OrderPdfQueryVariables
> {
  document = OrderPdfDocument;
}
export const OrdersUpdatesDocument = gql`
  subscription OrdersUpdates($customerCode: String!) {
    view_orders_headers(
      where: { code_customer: { _eq: $customerCode } }
      order_by: { date: desc }
    ) {
      ...OrdersFragment
    }
  }
  ${OrdersFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class OrdersUpdatesGQL extends Apollo.Subscription<
  OrdersUpdatesSubscription,
  OrdersUpdatesSubscriptionVariables
> {
  document = OrdersUpdatesDocument;
}
export const ProductsUpdatesDocument = gql`
  subscription ProductsUpdates($customerCode: String!) {
    products(
      where: {
        _and: {
          _or: [
            { _not: { customer_rel: {} } }
            { customer_rel: { code_customer: { _eq: $customerCode } } }
          ]
        }
        price_rel: {}
      }
      order_by: { code_group: asc, code: asc }
    ) {
      ...ProductsFragment
      price_rel(where: { code_customer: { _eq: $customerCode } }) {
        ...PricesFragment
      }
    }
  }
  ${ProductsFragmentFragmentDoc}
  ${PricesFragmentFragmentDoc}
`;

@Injectable({
  providedIn: "root"
})
export class ProductsUpdatesGQL extends Apollo.Subscription<
  ProductsUpdatesSubscription,
  ProductsUpdatesSubscriptionVariables
> {
  document = ProductsUpdatesDocument;
}
