/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./critical-error.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/layout/ng-zorro-antd-layout.ngfactory";
import * as i3 from "ng-zorro-antd/layout";
import * as i4 from "../../../../../node_modules/ng-zorro-antd/card/ng-zorro-antd-card.ngfactory";
import * as i5 from "ng-zorro-antd/card";
import * as i6 from "ng-zorro-antd/core";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../../node_modules/ng-zorro-antd/button/ng-zorro-antd-button.ngfactory";
import * as i9 from "ng-zorro-antd/button";
import * as i10 from "@angular/cdk/observers";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/router";
import * as i13 from "./critical-error.component";
import * as i14 from "../../../authentication/services/authentication.service";
var styles_CriticalErrorComponent = [i0.styles];
var RenderType_CriticalErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CriticalErrorComponent, data: {} });
export { RenderType_CriticalErrorComponent as RenderType_CriticalErrorComponent };
function View_CriticalErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "card-cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["width", "180"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_CriticalErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "nz-layout", [], null, null, null, i2.View_NzLayoutComponent_0, i2.RenderType_NzLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.NzLayoutComponent, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 24, "nz-content", [], null, null, null, i2.View_NzContentComponent_0, i2.RenderType_NzContentComponent)), i1.ɵdid(3, 49152, null, 0, i3.NzContentComponent, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 22, "nz-card", [], [[2, "ant-card-loading", null], [2, "ant-card-bordered", null], [2, "ant-card-hoverable", null], [2, "ant-card-small", null], [2, "ant-card-contain-grid", null], [2, "ant-card-type-inner", null], [2, "ant-card-contain-tabs", null]], null, null, i4.View_NzCardComponent_0, i4.RenderType_NzCardComponent)), i1.ɵdid(5, 49152, null, 2, i5.NzCardComponent, [i6.NzConfigService, i1.Renderer2, i1.ElementRef], { nzCover: [0, "nzCover"] }, null), i1.ɵqud(603979776, 1, { tab: 0 }), i1.ɵqud(603979776, 2, { grids: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "div", [["class", "section__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h1", [["class", "section__title card-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, 0, 6, "button", [["nz-button", ""], ["nzBlock", ""], ["nzType", "primary"]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NzButtonComponent_0, i8.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i6.NzUpdateHostClassService, i6.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(17, 1818624, null, 1, i9.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i10.ContentObserver, i6.NzUpdateHostClassService, i1.NgZone, i6.NzConfigService, [2, i6.NZ_WAVE_GLOBAL_CONFIG], [2, i11.ANIMATION_MODULE_TYPE]], { nzBlock: [0, "nzBlock"], nzType: [1, "nzType"] }, null), i1.ɵqud(603979776, 3, { listOfIconElement: 1 }), i1.ɵdid(19, 16384, null, 0, i12.RouterLink, [i12.Router, i12.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 1), (_l()(), i1.ɵted(-1, 0, [" Home "])), (_l()(), i1.ɵeld(22, 0, null, 0, 4, "button", [["nz-button", ""], ["nzBlock", ""]], [[1, "nz-wave", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.authenticationService.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NzButtonComponent_0, i8.RenderType_NzButtonComponent)), i1.ɵprd(512, null, i6.NzUpdateHostClassService, i6.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(24, 1818624, null, 1, i9.NzButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i10.ContentObserver, i6.NzUpdateHostClassService, i1.NgZone, i6.NzConfigService, [2, i6.NZ_WAVE_GLOBAL_CONFIG], [2, i11.ANIMATION_MODULE_TYPE]], { nzBlock: [0, "nzBlock"] }, null), i1.ɵqud(603979776, 4, { listOfIconElement: 1 }), (_l()(), i1.ɵted(-1, 0, [" Logout "])), (_l()(), i1.ɵand(0, [["coverTemplate", 2]], null, 0, null, View_CriticalErrorComponent_1))], function (_ck, _v) { var currVal_7 = i1.ɵnov(_v, 27); _ck(_v, 5, 0, currVal_7); var currVal_11 = ""; var currVal_12 = "primary"; _ck(_v, 17, 0, currVal_11, currVal_12); var currVal_13 = _ck(_v, 20, 0, "/"); _ck(_v, 19, 0, currVal_13); var currVal_15 = ""; _ck(_v, 24, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).nzLoading; var currVal_1 = i1.ɵnov(_v, 5).nzBordered; var currVal_2 = i1.ɵnov(_v, 5).nzHoverable; var currVal_3 = (i1.ɵnov(_v, 5).nzSize === "small"); var currVal_4 = (i1.ɵnov(_v, 5).grids && i1.ɵnov(_v, 5).grids.length); var currVal_5 = (i1.ɵnov(_v, 5).nzType === "inner"); var currVal_6 = !!i1.ɵnov(_v, 5).tab; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("translate.errore")); _ck(_v, 10, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("translate.errore_descrizione")); _ck(_v, 13, 0, currVal_9); var currVal_10 = i1.ɵnov(_v, 17).nzWave; _ck(_v, 15, 0, currVal_10); var currVal_14 = i1.ɵnov(_v, 24).nzWave; _ck(_v, 22, 0, currVal_14); }); }
export function View_CriticalErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-critical-error", [], null, null, null, View_CriticalErrorComponent_0, RenderType_CriticalErrorComponent)), i1.ɵdid(1, 49152, null, 0, i13.CriticalErrorComponent, [i14.AuthenticationService], null, null)], null, null); }
var CriticalErrorComponentNgFactory = i1.ɵccf("app-critical-error", i13.CriticalErrorComponent, View_CriticalErrorComponent_Host_0, {}, {}, []);
export { CriticalErrorComponentNgFactory as CriticalErrorComponentNgFactory };
