import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { OrderStateLabelMap } from '../../types/order-state-label.map';
import { OrderModel } from '../../types/order.model';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersListComponent implements OnInit, OnChanges {
  @Input() list: OrderModel[];
  @Input() selectedId: string;
  @Input() addDisabled: boolean;
  @Input() isCartLoading: boolean;
  @Input() isOrderCloneModalVisible: boolean;
  @Input() isOrderCloneCourtesyModalVisible: boolean;
  @Input() orderStateLabelMap: OrderStateLabelMap;

  @Output() orderRowClicked = new EventEmitter<string>();
  @Output() pdfDownloadRequest = new EventEmitter<string>();
  @Output() orderCloneModalOpeningRequest = new EventEmitter<string>();
  @Output() orderCloneCourtesyModalOpeningRequest = new EventEmitter<boolean>();
  @Output() orderCloneRequest = new EventEmitter<void>();
  @Output() goToCartRequest = new EventEmitter<void>();
  @Output() goToProductsListRequest = new EventEmitter<void>();

  lastHoveredCloneOrderAddIconOrderId: string;
  virtualScrollHeight: number;

  isMobile = isMobileCheck();

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.list || !changes.list.isFirstChange()) this.handleResize();
  }

  ngOnInit(): void {
    this.handleResize();
  }

  @HostListener('window:resize')
  handleResize() {
    const maxHeight =
      (this.isMobile ? screen.availHeight : window.innerHeight) - 396;

    this.virtualScrollHeight = Math.min(this.list.length * 46, maxHeight);
  }
}
