import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  CartAddProduct,
  CartCloneOrder
} from 'src/app/features/cart/state/cart.actions';
import { CartState } from 'src/app/features/cart/state/cart.state';
import { ProductsState } from 'src/app/features/products/state/products.state';
import { ProductGroupModel } from 'src/app/features/products/types/product-group.model';
import { ProductQuantityModel } from 'src/app/features/products/types/product-quantity.model';
import { ProductsFiltersModel } from 'src/app/features/products/types/products-filters.model';
import { ProductsQuantitiesModel } from 'src/app/features/products/types/products-quantities.model';
import { FilesService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import {
  OrdersChangeListFilters,
  OrdersChangeOrderFilters,
  OrdersClearAllOrdersFilters,
  OrdersDownloadPdf,
  OrdersSetOrderCloneCourtesyModalOpening,
  OrdersSetOrderCloneModalOpening,
  OrdersSetSelectedDetailOrder
} from '../../state/orders.actions';
import { OrdersState } from '../../state/orders.state';
import { OrderStateLabelMap } from '../../types/order-state-label.map';
import { OrderModel } from '../../types/order.model';
import { OrderViewModel } from '../../types/order.view-model';

@Component({
  selector: 'app-orders-container',
  templateUrl: './orders-container.component.html',
  styleUrls: ['./orders-container.component.less']
})
export class OrdersContainerComponent implements OnInit, OnDestroy {
  imageBaseUrl = environment.storageConfig.productsImagesMediumUrl;

  @Select(OrdersState.listViewModelFiltered)
  listViewModelFiltered$: Observable<OrderModel[]>;

  @Select(OrdersState.filteringOrderStates)
  filteringOrderStates$: Observable<number[]>;

  @Select(OrdersState.filteringPartialOrderNumberId)
  filteringPartialOrderNumberId$: Observable<number>;

  @Select(OrdersState.selectedDetailOrderWithFilteredProducts)
  selectedDetailOrderWithFilteredProducts$: Observable<OrderViewModel>;

  @Select(OrdersState.isOrderCloneModalOpen)
  isOrderCloneModalOpen$: Observable<boolean>;

  @Select(OrdersState.isOrderCloneCourtesyModalOpen)
  isOrderCloneCourtesyModalOpen$: Observable<boolean>;

  @Select(OrdersState.selectedDetailOrderProductsFilter)
  selectedDetailOrderProductsFilter$: Observable<ProductsFiltersModel>;

  @Select(CartState.quantities)
  cartQuantities$: Observable<ProductsQuantitiesModel>;

  @Select(CartState.isInCartRowsHttpProcessing)
  isCartLoading$: Observable<boolean>;
  addProductDisabled$: Observable<boolean>;

  @Select(ProductsState.groups)
  productsGroups$: Observable<ProductGroupModel[]>;

  orderStateLabelMap: OrderStateLabelMap = {
    1: 'translate.inviato',
    10: 'translate.inserito',
    20: 'translate.confermato',
    30: 'translate.chiuso',
    50: 'translate.annullato'
  };

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.store.dispatch(new OrdersClearAllOrdersFilters());
  }

  ngOnInit(): void {
    this.addProductDisabled$ = this.isCartLoading$.pipe(debounceTime(100));
  }

  handleOrderRowClicked(orderId: string): void {
    this.store.dispatch(new OrdersSetSelectedDetailOrder(orderId));
  }

  handleAddProductClicked(productQuantity: ProductQuantityModel): void {
    this.store.dispatch(new CartAddProduct(productQuantity));
  }

  handlePdfDownloadRequest(order: OrderModel): void {
    this.store.dispatch(new OrdersDownloadPdf(order));
  }

  handleOrderNumberIdFilterChange(
    filteringPartialOrderNumberId?: number
  ): void {
    this.store.dispatch(
      new OrdersChangeListFilters({
        filteringPartialOrderNumberId,
        forceFilteringPartialOrderNumberIdValue: true
      })
    );
  }

  handleOrderStatesFilterChange(filteringOrderStates: number[]): void {
    this.store.dispatch(
      new OrdersChangeListFilters({
        filteringOrderStates
      })
    );
  }

  handleOrderCloneRequest(): void {
    const orderCloneCandidateId = this.store.selectSnapshot(
      OrdersState.orderCloneCandidateId
    );
    const order = this.store
      .selectSnapshot(OrdersState.listViewModel)
      .find(o => o.id === orderCloneCandidateId);

    if (!order)
      throw new Error(`Cannot find order with id: ${orderCloneCandidateId}`);

    this.store.dispatch(new CartCloneOrder(order));
  }

  handleGoToProductsListRequest(): void {
    this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(false));
    this.store.dispatch(new Navigate(['/products']));
  }

  handleGoToCartRequest(): void {
    this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(false));
    this.store.dispatch(new Navigate(['/cart']));
  }

  handleOrderCloneModalOpeningRequest(value: string): void {
    this.store.dispatch(new OrdersSetOrderCloneModalOpening(value));
  }

  handleOrderCloneCourtesyModalOpeningRequest(value: boolean): void {
    this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(value));
  }

  handleDetailOrderGroupsFilterChange({
    orderId,
    productsGroups
  }: {
    orderId: string;
    productsGroups: string[];
  }): void {
    this.store.dispatch(
      new OrdersChangeOrderFilters(orderId, {
        filteringGroupsCodes: productsGroups
      })
    );
  }

  handleDetailOrderProductCodeFilterChange({
    orderId,
    productCode
  }: {
    orderId: string;
    productCode: number;
  }): void {
    this.store.dispatch(
      new OrdersChangeOrderFilters(orderId, {
        filteringPartialProductCode: productCode,
        forceFilteringPartialProductCodeValue: true
      })
    );
  }

  handleDetailOrderDescriptionFilterChange({
    orderId,
    productDescription
  }: {
    orderId: string;
    productDescription: string;
  }): void {
    this.store.dispatch(
      new OrdersChangeOrderFilters(orderId, {
        filteringPartialDescription: productDescription,
        forceFilteringPartialDescriptionValue: true
      })
    );
  }
}
