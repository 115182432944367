import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FilesService {
  constructor(private httpClient: HttpClient) {}

  // getFileAsPdfBlob(url: string): Observable<Blob> {
  //   return this.httpClient
  //     .get(url, {
  //       responseType: 'blob'
  //     })
  //     .pipe(map(res => new Blob([res], { type: 'application/pdf' })));
  // }

  getPdfB64asBlob(b64Data: string, sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'application/pdf' });
  }

  openPdfBlobInNewTab(pdfBlob: Blob, filename: string): void {
    // IE/Edge, will save the file instead of opening in a new tab
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(pdfBlob, filename);
      return;
    }

    const fileUrl = URL.createObjectURL(pdfBlob);
    window.open(fileUrl, '_blank');
  }
}
