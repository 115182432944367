import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CartClearProducts, CartRemoveProduct } from 'src/app/features/cart/state/cart.actions';
import { CartState } from 'src/app/features/cart/state/cart.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var EmptyCartHandler = /** @class */ (function () {
    function EmptyCartHandler(actions$, store) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.actions$
            .pipe(ofActionSuccessful(CartRemoveProduct, CartClearProducts), tap(function (_) {
            var cartProductsCount = _this.store.selectSnapshot(CartState.productsCount);
            if (cartProductsCount < 1 &&
                _this.store.selectSnapshot(RouterState.url) === '/cart')
                _this.store.dispatch(new Navigate(['/products']));
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    EmptyCartHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmptyCartHandler_Factory() { return new EmptyCartHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i1.Store)); }, token: EmptyCartHandler, providedIn: "root" });
    return EmptyCartHandler;
}());
export { EmptyCartHandler };
