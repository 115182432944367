import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, Store } from '@ngxs/store';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CoreState } from 'src/app/core/state/core.state';
import { ClearCartAndBulkInsertGQL, ClearCartRowsGQL, RemoveCartRowGQL, UpdateCartHeaderCustomerOrderNumberGQL, UpdateCartHeaderDateForGQL, UpdateCartHeaderNoteGQL, UpsertCartRowGQL, } from 'src/app/graphql/graphql';
import { ProductsListFilterService } from '../../products/services/products-list-filter.service';
import { ProductsState, } from '../../products/state/products.state';
import { CartAddProduct, CartChangeCustomerOrderNumber, CartChangeDateFor, CartChangeFilters, CartChangeNote, CartCheckEmptyCart, CartClearFilters, CartClearProducts, CartCloneOrder, CartRemoveProduct, CartReset, CartSetCart, CartSetOrderCourtesyModalOpening, CartSetOrderSubmitModalOpening, CartSetProductsQuantities, } from './cart.actions';
var cartStateModelDefaults = {
    quantities: {},
    dateFor: null,
    note: null,
    customerOrderNumber: null,
    filteringGroupsCodes: [],
    filteringPartialProductCode: null,
    filteringPartialDescription: null,
    isInCartRowsHttpProcessing: false,
    productsBeingAddedCodes: {},
    isOrderSubmitModalOpen: false,
    isOrderCourtesyModalOpen: false,
};
var CartState = /** @class */ (function () {
    function CartState(injector, store, upsertCartRowGQL, removeCartRowGQL, clearCartRowsGQL, updateCartHeaderDateForGQL, updateCartHeaderNoteGQL, updateCartHeaderCustomerOrderNumberGQL, clearCartAndBulkInsertGQL) {
        this.store = store;
        this.upsertCartRowGQL = upsertCartRowGQL;
        this.removeCartRowGQL = removeCartRowGQL;
        this.clearCartRowsGQL = clearCartRowsGQL;
        this.updateCartHeaderDateForGQL = updateCartHeaderDateForGQL;
        this.updateCartHeaderNoteGQL = updateCartHeaderNoteGQL;
        this.updateCartHeaderCustomerOrderNumberGQL = updateCartHeaderCustomerOrderNumberGQL;
        this.clearCartAndBulkInsertGQL = clearCartAndBulkInsertGQL;
        CartState_1.productsListFilterService = injector.get(ProductsListFilterService);
    }
    CartState_1 = CartState;
    CartState.isInCartRowsHttpProcessing = function (_a) {
        var isInCartRowsHttpProcessing = _a.isInCartRowsHttpProcessing;
        return isInCartRowsHttpProcessing;
    };
    CartState.productsBeingAddedCodes = function (_a) {
        var productsBeingAddedCodes = _a.productsBeingAddedCodes;
        return productsBeingAddedCodes;
    };
    CartState.isOrderSubmitModalOpen = function (_a) {
        var isOrderSubmitModalOpen = _a.isOrderSubmitModalOpen;
        return isOrderSubmitModalOpen;
    };
    CartState.isOrderCourtesyModalOpen = function (_a) {
        var isOrderCourtesyModalOpen = _a.isOrderCourtesyModalOpen;
        return isOrderCourtesyModalOpen;
    };
    CartState.list = function (_a, _b) {
        var quantities = _a.quantities;
        var productsStateList = _b.list;
        return productsStateList.filter(function (product) {
            return Object.keys(quantities).includes(product.code.toString());
        });
    };
    CartState.listFiltered = function (state, productsState) {
        var cartProductsList = CartState_1.list(state, productsState);
        var filteringGroupsCodes = state.filteringGroupsCodes, filteringPartialProductCode = state.filteringPartialProductCode, filteringPartialDescription = state.filteringPartialDescription;
        return this.productsListFilterService.filter({
            list: cartProductsList,
            groupsCodes: filteringGroupsCodes,
            partialProductCode: filteringPartialProductCode,
            partialDescription: filteringPartialDescription,
        });
    };
    CartState.quantities = function (state, productsState) {
        var cartProductsList = CartState_1.list(state, productsState);
        var quantities = state.quantities;
        return CartState_1.filterQuantitiesByProductsList(quantities, cartProductsList);
    };
    CartState.productQuantitiesTotal = function (state, productsState) {
        var cartProductsList = CartState_1.list(state, productsState);
        var quantities = state.quantities;
        return cartProductsList.reduce(CartState_1.toQuantitiesTotal(quantities), {});
    };
    CartState.combinedTotal = function (state, productsState) {
        return Object.values(CartState_1.productQuantitiesTotal(state, productsState)).reduce(function (sum, current) { return sum + current; }, 0);
    };
    CartState.productsCount = function (state, productsState) {
        return Object.keys(CartState_1.quantities(state, productsState)).length;
    };
    CartState.filteringPartialProductCode = function (_a) {
        var filteringPartialProductCode = _a.filteringPartialProductCode;
        return filteringPartialProductCode;
    };
    CartState.filteringPartialDescription = function (_a) {
        var filteringPartialDescription = _a.filteringPartialDescription;
        return filteringPartialDescription;
    };
    CartState.filteringGroupsCodes = function (_a) {
        var filteringGroupsCodes = _a.filteringGroupsCodes;
        return filteringGroupsCodes;
    };
    CartState.dateFor = function (_a) {
        var dateFor = _a.dateFor;
        return dateFor;
    };
    CartState.note = function (_a) {
        var note = _a.note;
        return note;
    };
    CartState.customerOrderNumber = function (_a) {
        var customerOrderNumber = _a.customerOrderNumber;
        return customerOrderNumber;
    };
    CartState.filterQuantitiesByProductsList = function (quantities, cartProductsList) {
        return Object.keys(quantities)
            .map(function (productCode) { return parseInt(productCode, 10); })
            .filter(function (productCode) {
            return cartProductsList.some(function (product) { return product.code === productCode; });
        })
            .reduce(function (quantitiesAccumulator, currentProductCode) {
            var _a;
            return (tslib_1.__assign({}, quantitiesAccumulator, (_a = {}, _a[currentProductCode] = quantities[currentProductCode], _a)));
        }, {});
    };
    CartState.toQuantitiesTotal = function (quantities) {
        return function (quantitiesTotalAccumulator, currentProduct) {
            var _a;
            return (tslib_1.__assign({}, quantitiesTotalAccumulator, (_a = {}, _a[currentProduct.code] = quantities[currentProduct.code]
                ? currentProduct.price_rel[0].price_discount *
                    quantities[currentProduct.code]
                : null, _a)));
        };
    };
    CartState.prototype.reset = function (_a) {
        var setState = _a.setState;
        setState(cartStateModelDefaults);
    };
    CartState.prototype.checkEmptyCart = function (_a) {
        var getState = _a.getState;
        var cartProductsCount = CartState_1.productsCount(getState(), this.store.selectSnapshot(ProductsState));
        if (cartProductsCount < 1)
            return this.store.dispatch(new Navigate(['/']));
        return of(null);
    };
    CartState.prototype.setOrderSubmitModalOpening = function (_a, _b) {
        var patchState = _a.patchState;
        var value = _b.value;
        patchState({
            isOrderSubmitModalOpen: value,
        });
    };
    CartState.prototype.setOrderCourtesyModalOpening = function (_a, _b) {
        var patchState = _a.patchState;
        var value = _b.value;
        patchState({
            isOrderCourtesyModalOpen: value,
        });
    };
    CartState.prototype.clearFilters = function (_a) {
        var patchState = _a.patchState;
        patchState({
            filteringGroupsCodes: [],
            filteringPartialProductCode: null,
            filteringPartialDescription: null,
        });
    };
    CartState.prototype.changeFilters = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var filters = _b.filters;
        var _c = getState(), originalFilteringGroupsCodes = _c.filteringGroupsCodes, originalFilteringPartialProductCode = _c.filteringPartialProductCode, originalFilteringPartialDescription = _c.filteringPartialDescription;
        patchState(CartState_1.productsListFilterService.getCalculatedFilters(filters, originalFilteringGroupsCodes, originalFilteringPartialProductCode, originalFilteringPartialDescription));
    };
    CartState.prototype.cloneOrder = function (_a, _b) {
        var _this = this;
        var patchState = _a.patchState;
        var order = _b.order;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        var newCart = this.getCartFromOrder(customerCode, order);
        patchState({ isInCartRowsHttpProcessing: true });
        return this.clearCartAndBulkInsertGQL
            .mutate({
            customerCode: customerCode,
            note: newCart.note,
            rows: newCart.cart_row_rel.map(function (entry) { return (tslib_1.__assign({}, entry, { code_customer: customerCode })); }),
        })
            .pipe(tap(function (_) {
            return patchState({
                note: newCart.note,
                quantities: _this.getProductsQuantitiesFromCartRows(newCart.cart_row_rel),
                dateFor: newCart.date_for,
                isInCartRowsHttpProcessing: false,
            });
        }), catchError(function (error) {
            patchState({ isInCartRowsHttpProcessing: false });
            return throwError(error);
        }));
    };
    CartState.prototype.setCart = function (_a, _b) {
        var patchState = _a.patchState;
        var cart = _b.cart;
        var cart_row_rel = cart.cart_row_rel, dateFor = cart.date_for, note = cart.note, customerOrderNumber = cart.customer_order_number;
        var quantities = this.getProductsQuantitiesFromCartRows(cart_row_rel);
        patchState({
            quantities: quantities,
            dateFor: dateFor,
            note: note,
            customerOrderNumber: customerOrderNumber,
        });
    };
    CartState.prototype.addProduct = function (_a, _b) {
        var _c;
        var getState = _a.getState, patchState = _a.patchState;
        var productQuantity = _b.productQuantity;
        var productCode = productQuantity.productCode, quantity = productQuantity.quantity;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        var _d = getState(), quantities = _d.quantities, productsBeingAddedCodes = _d.productsBeingAddedCodes;
        var isUpdate = !!quantities[productCode] || quantities[productCode] === 0;
        var effectiveQuantity = isUpdate
            ? quantities[productCode] + quantity
            : quantity;
        patchState({
            isInCartRowsHttpProcessing: true,
            productsBeingAddedCodes: tslib_1.__assign({}, productsBeingAddedCodes, (_c = {}, _c[productCode] = true, _c)),
        });
        return this.upsertCartRowGQL
            .mutate({
            customerCode: customerCode,
            productCode: productCode,
            quantity: effectiveQuantity,
        })
            .pipe(tap(function (_) {
            var _a, _b;
            // We retrieve the quantities again because those may have changed
            // during the time of the http call
            var _c = getState(), currentQuantities = _c.quantities, currentProductsBeingAddedCodes = _c.productsBeingAddedCodes;
            var newQuantities = tslib_1.__assign({}, currentQuantities, (_a = {}, _a[productCode] = effectiveQuantity, _a));
            patchState({
                quantities: newQuantities,
                isInCartRowsHttpProcessing: false,
                productsBeingAddedCodes: tslib_1.__assign({}, currentProductsBeingAddedCodes, (_b = {}, _b[productCode] = false, _b)),
            });
        }), catchError(function (error) {
            var _a;
            var currentProductsBeingAddedCodes = getState().productsBeingAddedCodes;
            patchState({
                isInCartRowsHttpProcessing: false,
                productsBeingAddedCodes: tslib_1.__assign({}, currentProductsBeingAddedCodes, (_a = {}, _a[productCode] = false, _a)),
            });
            return throwError(error);
        }));
    };
    CartState.prototype.setProductsQuantities = function (_a, _b) {
        var _this = this;
        var getState = _a.getState, patchState = _a.patchState;
        var productsQuantities = _b.productsQuantities;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        var _c = getState(), originalProductsQuantities = _c.quantities, productsBeingAddedCodes = _c.productsBeingAddedCodes;
        var setQuantitiesObservables = this.productQuantitiesToSetQuantitiesObservablesIfQuantityChanged(productsQuantities, originalProductsQuantities, customerCode);
        // Awful, I hope momentary trick to accomodate
        // the order confirm that is waiting for this flow to
        // go to enabled again
        if (!setQuantitiesObservables.length) {
            patchState({
                isInCartRowsHttpProcessing: true,
            });
            patchState({
                isInCartRowsHttpProcessing: false,
            });
            return of(null);
        }
        patchState({
            isInCartRowsHttpProcessing: true,
            productsBeingAddedCodes: tslib_1.__assign({}, productsBeingAddedCodes, this.getProductsBeingAddedCodeMapFromProductsQuantities(productsQuantities, true)),
        });
        return forkJoin(setQuantitiesObservables).pipe(tap(function (_) {
            // We retrieve the quantities again because those may have changed
            // during the time of the http call
            var currentQuantities = getState().quantities;
            patchState({
                quantities: tslib_1.__assign({}, currentQuantities, productsQuantities),
                productsBeingAddedCodes: tslib_1.__assign({}, productsBeingAddedCodes, _this.getProductsBeingAddedCodeMapFromProductsQuantities(productsQuantities, false)),
                isInCartRowsHttpProcessing: false,
            });
        }), catchError(function (error) {
            var currentProductsQuantities = getState().quantities;
            // Trick to force NGXS to resend the new model for the quantities
            patchState({
                quantities: _this.getAlmostIdenticalQuantities(currentProductsQuantities),
                isInCartRowsHttpProcessing: false,
                productsBeingAddedCodes: tslib_1.__assign({}, productsBeingAddedCodes, _this.getProductsBeingAddedCodeMapFromProductsQuantities(productsQuantities, false)),
            });
            return throwError(error);
        }));
    };
    CartState.prototype.removeProduct = function (_a, _b) {
        var _this = this;
        var getState = _a.getState, patchState = _a.patchState;
        var productCode = _b.productCode;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        patchState({ isInCartRowsHttpProcessing: true });
        return this.removeCartRowGQL.mutate({ customerCode: customerCode, productCode: productCode }).pipe(tap(function (_) {
            return patchState({
                quantities: _this.removeProductCodeFromQuantities(getState().quantities, productCode),
                isInCartRowsHttpProcessing: false,
            });
        }), catchError(function (error) {
            patchState({ isInCartRowsHttpProcessing: false });
            return throwError(error);
        }));
    };
    CartState.prototype.changeDateFor = function (_a, _b) {
        var patchState = _a.patchState, getState = _a.getState;
        var dateFor = _b.dateFor;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        return this.updateCartHeaderDateForGQL
            .mutate({ customerCode: customerCode, dateFor: dateFor })
            .pipe(tap(function (_) {
            return patchState({
                dateFor: dateFor,
            });
        }), catchError(function (error) {
            var originalDateFor = getState().dateFor;
            // Trick to force NGXS to resend the new model for the date
            patchState({
                dateFor: originalDateFor === null
                    ? undefined
                    : originalDateFor === undefined
                        ? null
                        : new Date(originalDateFor.getTime() + 1),
            });
            return throwError(error);
        }));
    };
    CartState.prototype.changeNote = function (_a, _b) {
        var patchState = _a.patchState, getState = _a.getState;
        var note = _b.note;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        return this.updateCartHeaderNoteGQL.mutate({ customerCode: customerCode, note: note }).pipe(tap(function (_) {
            return patchState({
                note: note,
            });
        }), catchError(function (error) {
            var originalNote = getState().note;
            // Trick to force NGXS to resend the new model for the notes
            patchState({
                note: originalNote === undefined
                    ? null
                    : originalNote === null
                        ? undefined
                        : originalNote === ''
                            ? undefined
                            : originalNote + ' ',
            });
            return throwError(error);
        }));
    };
    CartState.prototype.changeCustomerOrderNumber = function (_a, _b) {
        var patchState = _a.patchState, getState = _a.getState;
        var customerOrderNumber = _b.customerOrderNumber;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        return this.updateCartHeaderCustomerOrderNumberGQL
            .mutate({ customerCode: customerCode, customerOrderNumber: customerOrderNumber })
            .pipe(tap(function (_) {
            return patchState({
                customerOrderNumber: customerOrderNumber,
            });
        }), catchError(function (error) {
            var originalCustomerOrderNumber = getState().customerOrderNumber;
            // Trick to force NGXS to resend the new model for the customerOrderNumber
            patchState({
                customerOrderNumber: originalCustomerOrderNumber === undefined
                    ? null
                    : originalCustomerOrderNumber === null
                        ? undefined
                        : originalCustomerOrderNumber === ''
                            ? undefined
                            : originalCustomerOrderNumber + ' ',
            });
            return throwError(error);
        }));
    };
    CartState.prototype.clearProducts = function (_a) {
        var patchState = _a.patchState;
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        patchState({ isInCartRowsHttpProcessing: true });
        return this.clearCartRowsGQL.mutate({ customerCode: customerCode }).pipe(tap(function (_) {
            return patchState({
                quantities: {},
                isInCartRowsHttpProcessing: false,
            });
        }), catchError(function (error) {
            patchState({ isInCartRowsHttpProcessing: false });
            return throwError(error);
        }));
    };
    CartState.prototype.getProductsBeingAddedCodeMapFromProductsQuantities = function (productsQuantities, value) {
        return Object.keys(productsQuantities)
            .map(function (productCode) { return parseInt(productCode, 10); })
            .reduce(function (currentProductGoingToBeAddedCodes, currentProductCode) {
            var _a;
            return (tslib_1.__assign({}, currentProductGoingToBeAddedCodes, (_a = {}, _a[currentProductCode] = value, _a)));
        }, {});
    };
    CartState.prototype.getAlmostIdenticalQuantities = function (currentProductsQuantities) {
        return Object.keys(currentProductsQuantities)
            .map(function (key) { return parseInt(key, 10); })
            .reduce(function (quantitiesAccumulator, currentQuantityKey) {
            var _a;
            return (tslib_1.__assign({}, quantitiesAccumulator, (_a = {}, _a[currentQuantityKey] = currentProductsQuantities[currentQuantityKey] + 0.000000001, _a)));
        }, {});
    };
    CartState.prototype.getProductsQuantitiesFromCartRows = function (
    // tslint:disable-next-line: variable-name
    cart_row_rel) {
        return cart_row_rel.reduce(function (quantitiesAccumulator, _a) {
            var _b;
            var code_product = _a.code_product, quantity = _a.quantity;
            return (tslib_1.__assign({}, quantitiesAccumulator, (_b = {}, _b[code_product] = quantity, _b)));
        }, {});
    };
    CartState.prototype.getCartFromOrder = function (customerCode, matchingOrder) {
        return {
            code_customer: customerCode,
            note: matchingOrder.note,
            cart_row_rel: matchingOrder.orders_rows_rel
                .filter(function (orderRow) { return orderRow.isAvailable; })
                .map(function (orderRow) { return ({
                code_product: orderRow.code_product,
                quantity: orderRow.quantity,
            }); }),
        };
    };
    CartState.prototype.productQuantitiesToSetQuantitiesObservablesIfQuantityChanged = function (productsQuantities, originalProductsQuantities, customerCode) {
        var _this = this;
        return Object.keys(productsQuantities)
            .map(function (productCode) { return parseInt(productCode, 10); })
            .filter(function (productCode) {
            return originalProductsQuantities[productCode] !==
                productsQuantities[productCode];
        })
            .map(function (productCode) { return ({
            productCode: productCode,
            quantity: productsQuantities[productCode],
        }); })
            .map(function (productQuantity) {
            return _this.setQuantity(customerCode, productQuantity);
        });
    };
    CartState.prototype.setQuantity = function (customerCode, productQuantity) {
        var productCode = productQuantity.productCode, quantity = productQuantity.quantity;
        return this.upsertCartRowGQL.mutate({
            customerCode: customerCode,
            productCode: productCode,
            quantity: quantity,
        });
    };
    CartState.prototype.removeProductCodeFromQuantities = function (quantities, productCode) {
        return Object.keys(quantities)
            .map(function (quantityProductCode) { return parseInt(quantityProductCode, 10); })
            .reduce(function (quantitiesAccumulator, currentProductCode) {
            var _a;
            return (tslib_1.__assign({}, quantitiesAccumulator, (currentProductCode !== productCode
                ? (_a = {}, _a[currentProductCode] = quantities[currentProductCode], _a) : {})));
        }, {});
    };
    var CartState_1;
    tslib_1.__decorate([
        Action(CartReset),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CartState.prototype, "reset", null);
    tslib_1.__decorate([
        Action(CartCheckEmptyCart),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "checkEmptyCart", null);
    tslib_1.__decorate([
        Action(CartSetOrderSubmitModalOpening),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartSetOrderSubmitModalOpening]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CartState.prototype, "setOrderSubmitModalOpening", null);
    tslib_1.__decorate([
        Action(CartSetOrderCourtesyModalOpening),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartSetOrderCourtesyModalOpening]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CartState.prototype, "setOrderCourtesyModalOpening", null);
    tslib_1.__decorate([
        Action(CartClearFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CartState.prototype, "clearFilters", null);
    tslib_1.__decorate([
        Action(CartChangeFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartChangeFilters]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CartState.prototype, "changeFilters", null);
    tslib_1.__decorate([
        Action(CartCloneOrder),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartCloneOrder]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "cloneOrder", null);
    tslib_1.__decorate([
        Action(CartSetCart),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartSetCart]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CartState.prototype, "setCart", null);
    tslib_1.__decorate([
        Action(CartAddProduct),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartAddProduct]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "addProduct", null);
    tslib_1.__decorate([
        Action(CartSetProductsQuantities),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartSetProductsQuantities]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "setProductsQuantities", null);
    tslib_1.__decorate([
        Action(CartRemoveProduct),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartRemoveProduct]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "removeProduct", null);
    tslib_1.__decorate([
        Action(CartChangeDateFor),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartChangeDateFor]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "changeDateFor", null);
    tslib_1.__decorate([
        Action(CartChangeNote),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartChangeNote]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "changeNote", null);
    tslib_1.__decorate([
        Action(CartChangeCustomerOrderNumber),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CartChangeCustomerOrderNumber]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "changeCustomerOrderNumber", null);
    tslib_1.__decorate([
        Action(CartClearProducts),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CartState.prototype, "clearProducts", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], CartState, "isInCartRowsHttpProcessing", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], CartState, "productsBeingAddedCodes", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], CartState, "isOrderSubmitModalOpen", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], CartState, "isOrderCourtesyModalOpen", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], CartState, "list", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], CartState, "listFiltered", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], CartState, "quantities", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], CartState, "productQuantitiesTotal", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], CartState, "combinedTotal", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], CartState, "productsCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], CartState, "filteringPartialProductCode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], CartState, "filteringPartialDescription", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], CartState, "filteringGroupsCodes", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Date)
    ], CartState, "dateFor", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], CartState, "note", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], CartState, "customerOrderNumber", null);
    CartState = CartState_1 = tslib_1.__decorate([
        State({
            name: 'cart',
            defaults: cartStateModelDefaults,
        }),
        tslib_1.__metadata("design:paramtypes", [Injector,
            Store,
            UpsertCartRowGQL,
            RemoveCartRowGQL,
            ClearCartRowsGQL,
            UpdateCartHeaderDateForGQL,
            UpdateCartHeaderNoteGQL,
            UpdateCartHeaderCustomerOrderNumberGQL,
            ClearCartAndBulkInsertGQL])
    ], CartState);
    return CartState;
}());
export { CartState };
// interface CartAddProductMutation {
//   data: UpsertCartRowMutation;
//   // // Is responsibility of the developer to check which of the 2 types is actually used
//   // data: UpsertCartRowMutation & UpdateCartRowQuantityMutation;
// }
