import { Injectable } from '@angular/core';
import { Actions, getActionTypeFromInstance } from '@ngxs/store';
import { ActionContext, ActionStatus } from '@ngxs/store/src/actions-stream';
import { Gtag } from 'angular-gtag';
import { filter, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ErrorLoggingHandler {
  constructor(private actions$: Actions, gtag: Gtag) {
    this.actions$
      .pipe(
        filter(({ status }: ActionContext) => status === ActionStatus.Errored),
        tap(({ action, error }: ActionContext) => {
          console.error(
            getActionTypeFromInstance(action),
            Object.entries(action),
            error
          );
          gtag.event(getActionTypeFromInstance(action), {
            event_category: 'errors',
            event_label:
              JSON.stringify(action) + ' - error: ' + JSON.stringify(error)
          });
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }
}
