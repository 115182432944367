import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductsCartCtaComponent } from './components/products-cart-cta/products-cart-cta.component';
import { ProductsFiltersComponent } from './components/products-filters/products-filters.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ProductsContainerComponent } from './containers/products-container/products-container.component';

@NgModule({
  declarations: [
    ProductsContainerComponent,
    ProductsListComponent,
    ProductsFiltersComponent,
    ProductsCartCtaComponent
  ],
  imports: [SharedModule],
  exports: [ProductsFiltersComponent]
})
export class ProductsModule {}
