import { Injector } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GraphqlSubscriptionsService } from 'src/app/core/services/graphql-subscriptions.service';
import { CoreState } from 'src/app/core/state/core.state';
import {
  InsertOrdersHeaderAndRowsAndClearCartGQL,
  OrderPdfGQL,
  OrderPdfQuery,
  Orders_Rows,
  Orders_Rows_Insert_Input,
} from 'src/app/graphql/graphql';
// import { FilesService } from 'src/app/shared/services/file.service';
import { v4 as uuid } from 'uuid';
import { CartSetCart } from '../../cart/state/cart.actions';
import { CartState } from '../../cart/state/cart.state';
import { ProductsListFilterService } from '../../products/services/products-list-filter.service';
import {
  ProductsState,
  ProductsStateModel,
} from '../../products/state/products.state';
import { ProductOverviewModel } from '../../products/types/product-overview.model';
import { ProductsFiltersModel } from '../../products/types/products-filters.model';
import { ProductsQuantitiesModel } from '../../products/types/products-quantities.model';
import { OrdersListFilterService } from '../services/orders-list-filter.service';
import { OrdersProductsFiltersMap } from '../types/order-filters.map';
import { OrderModel } from '../types/order.model';
import { OrderViewModel } from '../types/order.view-model';
import {
  OrdersChangeListFilters,
  OrdersChangeOrderFilters,
  OrdersCheckNoOrders,
  OrdersClearAllOrdersFilters,
  OrdersDownloadPdf,
  OrdersNewOrderFromCart,
  OrdersReset,
  OrdersSetList,
  OrdersSetOrderCloneCourtesyModalOpening,
  OrdersSetOrderCloneModalOpening,
  OrdersSetSelectedDetailOrder,
  OrdersUpdateList,
  OrdersUpdateNotification,
} from './orders.actions';

// tslint:disable-next-line: no-empty-interface
export interface OrdersStateModel {
  list: OrderModel[];
  filteringPartialOrderNumberId: number;
  filteringOrderStates: number[];
  isInHttpProcessing: boolean;
  selectedDetailOrderId: string;
  isOrderCloneModalOpen: boolean;
  isOrderCloneCourtesyModalOpen: boolean;
  orderCloneCandidateId: string;
  ordersProductsFiltersMap: OrdersProductsFiltersMap;
}

const ordersStateModelDefaults: OrdersStateModel = {
  list: [],
  isInHttpProcessing: false,
  filteringPartialOrderNumberId: null,
  filteringOrderStates: [],
  selectedDetailOrderId: null,
  isOrderCloneModalOpen: false,
  isOrderCloneCourtesyModalOpen: false,
  orderCloneCandidateId: null,
  ordersProductsFiltersMap: {},
};

@State<OrdersStateModel>({
  name: 'orders',
  defaults: ordersStateModelDefaults,
})
export class OrdersState {
  private static ordersListFilterService: OrdersListFilterService;
  private static productsListFilterService: ProductsListFilterService;

  constructor(
    injector: Injector,
    private store: Store,
    // private fileService: FilesService,
    private graphqlSubscriptionsService: GraphqlSubscriptionsService,
    private insertOrdersHeaderAndRowsAndClearCartGQL: InsertOrdersHeaderAndRowsAndClearCartGQL,
    private orderPdfGQL: OrderPdfGQL
  ) {
    OrdersState.ordersListFilterService = injector.get(OrdersListFilterService);
    OrdersState.productsListFilterService = injector.get(
      ProductsListFilterService
    );
  }

  @Selector([ProductsState])
  static selectedDetailOrderWithFilteredProducts(
    state: OrdersStateModel,
    productsState: ProductsStateModel
  ): OrderViewModel {
    const selectedDetailOrderId = OrdersState.selectedDetailOrderId(state);
    const listViewModel = OrdersState.listViewModel(state, productsState);

    const selectedOrder = selectedDetailOrderId
      ? listViewModel.find((order) => order.id === selectedDetailOrderId)
      : listViewModel[0];

    if (!selectedOrder && selectedDetailOrderId)
      throw new Error(`Cannot find order with id: ${selectedDetailOrderId}`);

    if (!selectedOrder) return selectedOrder;

    const {
      filteringGroupsCodes,
      filteringPartialProductCode,
      filteringPartialDescription,
    } = OrdersState.selectedDetailOrderProductsFilter(state);

    const filteredProducts = OrdersState.productsListFilterService.filter({
      list: selectedOrder.orders_rows_rel,
      codeKey: 'code_product',
      descriptionKey: 'description_product',
      groupCodeKey: 'codeGroupProduct',
      groupsCodes: filteringGroupsCodes,
      partialProductCode: filteringPartialProductCode,
      partialDescription: filteringPartialDescription,
    });

    return { ...selectedOrder, orders_rows_rel: filteredProducts };
  }

  @Selector()
  static selectedDetailOrderProductsFilter(
    state: OrdersStateModel
  ): ProductsFiltersModel {
    const selectedDetailOrderId = OrdersState.selectedDetailOrderId(state);

    return (
      (selectedDetailOrderId &&
        state.ordersProductsFiltersMap[selectedDetailOrderId]) || {
        filteringGroupsCodes: [],
        filteringPartialDescription: null,
        filteringPartialProductCode: null,
      }
    );
  }

  @Selector()
  static orderCloneCandidateId({
    orderCloneCandidateId,
  }: OrdersStateModel): string {
    return orderCloneCandidateId;
  }

  @Selector()
  static selectedDetailOrderId({
    selectedDetailOrderId,
    list,
  }: OrdersStateModel): string {
    return selectedDetailOrderId || (list[0] || { id: null }).id;
  }

  @Selector()
  static isOrderCloneModalOpen({
    isOrderCloneModalOpen,
  }: OrdersStateModel): boolean {
    return isOrderCloneModalOpen;
  }

  @Selector()
  static isOrderCloneCourtesyModalOpen({
    isOrderCloneCourtesyModalOpen,
  }: OrdersStateModel): boolean {
    return isOrderCloneCourtesyModalOpen;
  }

  @Selector()
  static ordersCount({ list }: OrdersStateModel): number {
    return list.length;
  }

  @Selector()
  static list({ list }: OrdersStateModel): OrderModel[] {
    return list;
  }

  @Selector([ProductsState])
  static listViewModel(
    { list }: OrdersStateModel,
    { list: productsStateList }: ProductsStateModel
  ): OrderViewModel[] {
    return list.reduce(
      (viewModel, currentOrder) => [
        ...viewModel,
        OrdersState.getOrderViewModel(currentOrder, productsStateList),
      ],
      []
    );
  }

  @Selector()
  static listFiltered({
    list,
    filteringOrderStates,
    filteringPartialOrderNumberId,
  }: OrdersStateModel): OrderModel[] {
    return this.ordersListFilterService.filter(
      list,
      filteringPartialOrderNumberId,
      filteringOrderStates
    );
  }

  @Selector([ProductsState])
  static listViewModelFiltered(
    state: OrdersStateModel,
    productsState: ProductsStateModel
  ): OrderModel[] {
    const listViewModel = OrdersState.listViewModel(state, productsState);

    return this.ordersListFilterService.filter(
      listViewModel,
      state.filteringPartialOrderNumberId,
      state.filteringOrderStates
    );
  }

  @Selector()
  static filteringOrderStates({
    filteringOrderStates,
  }: OrdersStateModel): number[] {
    return filteringOrderStates;
  }

  @Selector()
  static filteringPartialOrderNumberId({
    filteringPartialOrderNumberId,
  }: OrdersStateModel): number {
    return filteringPartialOrderNumberId;
  }

  @Selector()
  static isInHttpProcessing({ isInHttpProcessing }: OrdersStateModel): boolean {
    return isInHttpProcessing;
  }

  private static getOrderViewModel(
    currentOrder: OrderModel,
    productsStateList: ProductOverviewModel[]
  ): OrderViewModel {
    const currentOrderViewModel: OrderViewModel = JSON.parse(
      JSON.stringify(currentOrder)
    );

    const orderRowsWithPriceDiscount = OrdersState.getOrderRowsWithPriceDiscount(
      currentOrderViewModel.orders_rows_rel
    );

    const orderRowsWithPriceDiscountAndProductImage = OrdersState.getOrderRowsWithProductImage(
      orderRowsWithPriceDiscount,
      productsStateList
    );

    const orderRowsWithPriceDiscountAndProductImageAndAvailability = OrdersState.getOrderRowsWithAvailability(
      orderRowsWithPriceDiscountAndProductImage,
      productsStateList
    );

    const orderRowsWithPriceDiscountAndProductImageAndAvailabilityAndProductGroup = OrdersState.getOrderRowsWithProductGroup(
      orderRowsWithPriceDiscountAndProductImageAndAvailability,
      productsStateList
    );

    currentOrderViewModel.orders_rows_rel = orderRowsWithPriceDiscountAndProductImageAndAvailabilityAndProductGroup;

    currentOrderViewModel.isClonable = currentOrderViewModel.orders_rows_rel.some(
      (entry: any) => entry.isAvailable
    );

    currentOrderViewModel.total = OrdersState.getOrderTotal(
      currentOrderViewModel
    );

    return currentOrderViewModel;
  }

  private static getOrderRowsWithProductGroup(
    orderRows: Pick<Orders_Rows, 'code_product'>[],
    productsStateList: ProductOverviewModel[]
  ): any[] {
    return orderRows.map((entry) => ({
      ...entry,
      codeGroupProduct: (
        productsStateList.find(
          (product) => product.code === entry.code_product
        ) || { code_group: null }
      ).code_group,
    }));
  }

  private static getOrderRowsWithAvailability(
    orderRows: Pick<Orders_Rows, 'code_product'>[],
    productsStateList: ProductOverviewModel[]
  ): any[] {
    return orderRows.map((entry) => ({
      ...entry,
      isAvailable: productsStateList.find(
        (product) => product.code === entry.code_product
      )
        ? true
        : false,
    }));
  }

  private static getOrderTotal(orderViewModel: OrderViewModel): number {
    return orderViewModel.orders_rows_rel.reduce(
      (total, order) => total + order.quantity * (order as any).price_discount,
      0
    );
  }

  private static getOrderRowsWithProductImage(
    orderRows: Pick<Orders_Rows, 'code_product'>[],
    productsStateList: ProductOverviewModel[]
  ): any[] {
    return orderRows.reduce(
      (ordersRows, currentOrder) => [
        ...ordersRows,
        OrdersState.getOrderRowWithProductImage(
          currentOrder,
          productsStateList
        ),
      ],
      []
    );
  }

  private static getOrderRowWithProductImage(
    currentOrder: Pick<Orders_Rows, 'code_product'>,
    productsStateList: ProductOverviewModel[]
  ): any {
    return {
      ...currentOrder,
      image_medium: (
        productsStateList.find(
          (product) => product.code === currentOrder.code_product
        ) || { image_medium: null }
      ).image_medium,
    };
  }

  private static getOrderRowsWithPriceDiscount(
    orderRows: Pick<Orders_Rows, 'discount' | 'offer' | 'price'>[]
  ): any[] {
    return orderRows.map((row) => ({
      ...row,
      price_discount: OrdersState.getPriceDiscount(row),
    }));
  }

  private static getPriceDiscount({
    price,
    offer,
    discount,
  }: Pick<Orders_Rows, 'discount' | 'offer' | 'price'>): number {
    return price * (1 - (offer || 0) / 100) * (1 - (discount || 0) / 100);
  }

  @Action(OrdersReset)
  reset({ setState }: StateContext<OrdersStateModel>): void {
    setState(ordersStateModelDefaults);
  }

  @Action(OrdersClearAllOrdersFilters)
  clearAllOrdersFilters({ patchState }: StateContext<OrdersStateModel>): void {
    patchState({
      ordersProductsFiltersMap: {},
    });
  }

  @Action(OrdersChangeOrderFilters)
  changeOrderFilters(
    { patchState, getState }: StateContext<OrdersStateModel>,
    { orderId, filters }: OrdersChangeOrderFilters
  ): void {
    const { list, ordersProductsFiltersMap } = getState();
    if (!list.some((order) => order.id === orderId))
      throw new Error(`Cannot find order with id: ${orderId}`);

    const {
      filteringGroupsCodes: originalFilteringGroupsCodes,
      filteringPartialProductCode: originalFilteringPartialProductCode,
      filteringPartialDescription: originalFilteringPartialDescription,
    } = ordersProductsFiltersMap[orderId] || {
      filteringGroupsCodes: [],
      filteringPartialDescription: null,
      filteringPartialProductCode: null,
    };

    const newFilters = OrdersState.productsListFilterService.getCalculatedFilters(
      filters,
      originalFilteringGroupsCodes,
      originalFilteringPartialProductCode,
      originalFilteringPartialDescription
    );

    patchState({
      ordersProductsFiltersMap: {
        ...ordersProductsFiltersMap,
        [orderId]: newFilters,
      },
    });
  }

  @Action(OrdersSetList)
  setList(
    { patchState }: StateContext<OrdersStateModel>,
    { list }: OrdersSetList
  ): void {
    patchState({
      list,
    });
  }

  @Action(OrdersUpdateList)
  updateList(
    { patchState, getState, dispatch }: StateContext<OrdersStateModel>,
    { list }: OrdersUpdateList
  ): void {
    const { list: originalList } = getState();

    if (this.someOrderKeyFieldDiffers(list, originalList))
      dispatch(new OrdersUpdateNotification());

    patchState({
      list,
    });
  }

  @Action(OrdersSetSelectedDetailOrder)
  setSelectedDetailOrder(
    { patchState }: StateContext<OrdersStateModel>,
    { id }: OrdersSetSelectedDetailOrder
  ): void {
    patchState({
      selectedDetailOrderId: id,
    });
  }

  @Action(OrdersSetOrderCloneModalOpening)
  setOrderCloneModalOpening(
    { patchState }: StateContext<OrdersStateModel>,
    { orderId }: OrdersSetOrderCloneModalOpening
  ): void {
    patchState({
      orderCloneCandidateId: orderId,
      isOrderCloneModalOpen: !!orderId,
    });
  }

  @Action(OrdersCheckNoOrders)
  checkNoOrders({ getState }: StateContext<OrdersStateModel>): Observable<any> {
    if (getState().list.length < 1)
      return this.store.dispatch(new Navigate(['/products']));

    return of(null);
  }

  @Action(OrdersSetOrderCloneCourtesyModalOpening)
  setOrderCloneCourtesyModalOpening(
    { patchState }: StateContext<OrdersStateModel>,
    { value }: OrdersSetOrderCloneCourtesyModalOpening
  ): void {
    patchState({
      isOrderCloneCourtesyModalOpen: value,
    });
  }

  @Action(OrdersChangeListFilters)
  changeListFilters(
    { getState, patchState }: StateContext<OrdersStateModel>,
    { filters }: OrdersChangeListFilters
  ): void {
    const {
      filteringPartialOrderNumberId: originalfilteringPartialOrderNumberId,
      filteringOrderStates: originalFilteringOrderStates,
    } = getState();

    const {
      filteringPartialOrderNumberId,
      filteringOrderStates,
      forceFilteringPartialOrderNumberIdValue,
    } = filters;

    const orderStates = filteringOrderStates || originalFilteringOrderStates;

    const partialOrderNumberId = forceFilteringPartialOrderNumberIdValue
      ? filteringPartialOrderNumberId
      : filteringPartialOrderNumberId || originalfilteringPartialOrderNumberId;

    patchState({
      filteringPartialOrderNumberId: partialOrderNumberId,
      filteringOrderStates: orderStates,
    });
  }

  @Action(OrdersNewOrderFromCart)
  newOrderFromCart({
    getState,
    patchState,
    dispatch,
  }: StateContext<OrdersStateModel>): Observable<any> {
    patchState({ isInHttpProcessing: true });

    const customerCode = this.store.selectSnapshot(
      CoreState.currentCustomerCode
    );

    const cartProductsList = this.store.selectSnapshot(CartState.list);
    const cartQuantities = this.store.selectSnapshot(CartState.quantities);
    const dateFor = this.store.selectSnapshot(CartState.dateFor);
    const note = this.store.selectSnapshot(CartState.note);
    const customerOrderNumber = this.store.selectSnapshot(
      CartState.customerOrderNumber
    );

    const order: OrderModel = {
      id: uuid(),
      date: new Date(),
      date_for: dateFor,
      note,
      customer_order_number: customerOrderNumber,
      orders_rows_rel: cartProductsList.reduce(
        this.toProductsListWithQuantities(cartQuantities),
        []
      ),
      state: 1,
    };

    return this.insertOrdersHeaderAndRowsAndClearCartGQL
      .mutate({
        customerCode,
        id: order.id,
        note: order.note,
        customerOrderNumber: order.customer_order_number,
        dateFor: order.date_for,
        date: order.date,
        rows: order.orders_rows_rel,
      })
      .pipe(
        tap(
          (_) =>
            (this.graphqlSubscriptionsService.skipNextOrderUpdateBecauseItWasByUser = true)
        ),
        tap(() => {
          const { list: currentList } = getState();
          patchState({
            list: [order, ...currentList],
            selectedDetailOrderId: order.id,
            isInHttpProcessing: false,
          });
        }),
        tap((_) =>
          dispatch(
            new CartSetCart({
              // Please notice that we pass code_customer only to be
              // compliant with the gql autogenerated type.
              // The CartSetCart action doesn't actually use it
              code_customer: null,
              cart_row_rel: [],
              date_for: null,
              note: null,
              customer_order_number: null,
            })
          )
        ),
        catchError((error) => {
          patchState({
            isInHttpProcessing: false,
          });
          return throwError(error);
        })
      );
  }

  @Action(OrdersDownloadPdf)
  downloadPdf(
    _: StateContext<OrdersStateModel>,
    { order }: OrdersDownloadPdf
  ): Observable<any> {
    if (!order.number)
      throw new Error(
        `Cannot download pdf for order with id: ${
          order.id
        }, because order number is not present`
      );

    return this.orderPdfGQL
      .fetch({
        orderId: order.id,
      })
      .pipe(
        map((result) => result.data),
        tap(({ orders_headers_by_pk }: OrderPdfQuery) =>
          this.downloadOrderPdfFromBase64(
            orders_headers_by_pk.pdf,
            order.number
          )
        )
      );
  }

  private someOrderKeyFieldDiffers(
    list: OrderModel[],
    originalList: OrderModel[]
  ): boolean {
    return (
      list.length !== originalList.length ||
      originalList.some((originalOrder) => {
        const matchingOrder = list.find(
          (order) => order.id === originalOrder.id
        );
        return this.keyOrderFieldDiffer(matchingOrder, originalOrder);
      })
    );
  }

  private keyOrderFieldDiffer(
    matchingOrder: OrderModel,
    originalOrder: OrderModel
  ): boolean {
    return (
      !matchingOrder ||
      matchingOrder.state !== originalOrder.state ||
      matchingOrder.date_delivery !== originalOrder.date_delivery ||
      matchingOrder.pdf !== originalOrder.pdf ||
      matchingOrder.number !== originalOrder.number
    );
  }

  private downloadOrderPdfFromBase64(base64: string, orderNumber: number) {
    if (!base64)
      throw new Error(
        `Cannot download pdf for order with number: ${orderNumber}, because it is not present`
      );

    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement('a');
    const fileName = `${orderNumber}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = '_blank';
    downloadLink.click();

    // const blob = this.fileService.getPdfB64asBlob(base64);
    // this.fileService.openPdfBlobInNewTab(blob, `${orderNumber}.pdf`);
  }

  private toProductsListWithQuantities(
    cartQuantities: ProductsQuantitiesModel
  ): (
    previousValue: any[],
    currentValue: ProductOverviewModel,
    currentIndex: number,
    array: ProductOverviewModel[]
  ) => ProductOverviewModel[] {
    return (productsListWithQuantities, currentProduct, currentIndex) => [
      ...productsListWithQuantities,
      this.getProductWithQuantity({
        currentProduct,
        cartQuantities,
        currentIndex,
      }),
    ];
  }

  private getProductWithQuantity({
    currentProduct,
    cartQuantities,
    currentIndex,
  }: {
    currentProduct: ProductOverviewModel;
    cartQuantities: ProductsQuantitiesModel;
    currentIndex: number;
  }): Orders_Rows_Insert_Input & {
    quantity: number;
  } {
    const {
      // tslint:disable-next-line: variable-name
      code: code_product,
      code_vat,
      // tslint:disable-next-line: variable-name
      description: description_product,
      price_rel,
      unit_of_measure,
    } = currentProduct;
    const { discount, offerIsValid, offer, price } = price_rel[0];

    return {
      code_product,
      code_vat,
      description_product,
      discount,
      offer: offerIsValid ? offer : null,
      price,
      quantity: cartQuantities[code_product],
      row_number: currentIndex,
      unit_of_measure,
    };
  }
}
