import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../authentication/guards/authentication.guard';
import { CartContainerComponent } from '../features/cart/containers/cart-container/cart-container.component';
import { EmptyCartCheckResolve } from '../features/cart/resolvers/empty-cart-check.resolver';
import { OrdersContainerComponent } from '../features/orders/containers/orders-container/orders-container.component';
import { NoOrdersCheckResolve } from '../features/orders/resolvers/no-orders-check.resolver';
import { ProductsContainerComponent } from '../features/products/containers/products-container/products-container.component';
import { CriticalErrorComponent } from './containers/critical-error/critical-error.component';
import { MainContainerComponent } from './containers/main/main-container.component';
import { CoreResolver } from './resolvers/core.resolver';

const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    canActivate: [AuthenticationGuard],
    resolve: { coreLoaded: CoreResolver },
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      {
        path: 'orders',
        component: OrdersContainerComponent,
        // Angular performs all the guards first, then the resolves,
        // without taking in consideration the parent/child hierarchy.
        // This means that if we did a noOrdersGuard, the CoreResolver
        // would still have to be called, resulting in check at the wrong time.
        // The workaround is to use resolvers instead of guards when there is
        // the need for the parent resolver to be executed before
        resolve: { noOrdersChecked: NoOrdersCheckResolve }
      },
      {
        path: 'products',
        component: ProductsContainerComponent
      },
      {
        path: 'cart',
        component: CartContainerComponent,
        resolve: { emptyCartChecked: EmptyCartCheckResolve }
      }
    ]
  },
  { path: 'error', component: CriticalErrorComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
