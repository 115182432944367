<app-header
  *ngIf="!(isCustomerChangeInProgress$ | async)"
  [logoUrl]="logoUrl"
  [currentCustomer]="currentCustomer$ | async"
  [otherAvailableCustomers]="otherAvailableCustomers$ | async"
  [productsCount]="productsCount$ | async"
  [ordersCount]="ordersCount$ | async"
  [userEmail]="userEmail$ | async"
  [languageLabel]="translateManagerService.languageLabel$ | async"
  (logoutClicked)="authenticationService.logout()"
  (changeLanguageClicked)="handleChangeLanguageClicked()"
  (changeCustomerClicked)="handleChangeCustomerClicked($event)"
></app-header>

<div
  class="content"
  *ngIf="!(isCustomerChangeInProgress$ | async)"
  [style.min-height]="minContentHeight + 'px'"
>
  <router-outlet></router-outlet>
</div>

<ng-template #ordersUpdateNotificationTemplate
  ><div class="ant-notification-notice-content">
    <div
      class="ant-notification-notice-content ant-notification-notice-with-icon"
    >
      <div class="ant-notification-notice-with-icon">
        <i
          nz-icon
          type="check-circle"
          class="ant-notification-notice-icon ant-notification-notice-icon-success"
        ></i>
        <div class="ant-notification-notice-message">
          {{ 'translate.orders_update_notification_success_title' | translate }}
        </div>
        <div class="ant-notification-notice-description">
          {{
            'translate.orders_update_notification_success_content_1' | translate
          }}
          <a
            style="text-decoration: underline;"
            [routerLink]="['/']"
            (click)="notificationHandler.closeNotifications()"
          >
            {{
              'translate.orders_update_notification_success_content_2'
                | translate
            }}</a
          >.
        </div>
      </div>
    </div>
  </div></ng-template
>
