import * as i0 from "@angular/core";
var OrdersListFilterService = /** @class */ (function () {
    function OrdersListFilterService() {
    }
    OrdersListFilterService.prototype.filter = function (list, filteringPartialOrderNumberId, filteringOrderStates) {
        var listFilteredByPartialOrderNumberId = this.getListFilteredByNumberId(list, filteringPartialOrderNumberId);
        var listFilteredByPartialOrderNumberIdAndOrderState = this.getListFilteredByOrderState(listFilteredByPartialOrderNumberId, filteringOrderStates);
        return listFilteredByPartialOrderNumberIdAndOrderState;
    };
    OrdersListFilterService.prototype.getListFilteredByNumberId = function (list, partialOrderNumberId) {
        return list.filter(function (order) {
            return (order.number || '')
                .toString()
                .startsWith((partialOrderNumberId || '').toString());
        });
    };
    OrdersListFilterService.prototype.getListFilteredByOrderState = function (list, states) {
        return list.filter(function (order) { return !states || !states.length || states.includes(order.state); });
    };
    OrdersListFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrdersListFilterService_Factory() { return new OrdersListFilterService(); }, token: OrdersListFilterService, providedIn: "root" });
    return OrdersListFilterService;
}());
export { OrdersListFilterService };
