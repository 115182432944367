import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CartAddProduct, CartCloneOrder } from 'src/app/features/cart/state/cart.actions';
import { CartState } from 'src/app/features/cart/state/cart.state';
import { ProductsState } from 'src/app/features/products/state/products.state';
import { environment } from 'src/environments/environment';
import { OrdersChangeListFilters, OrdersChangeOrderFilters, OrdersClearAllOrdersFilters, OrdersDownloadPdf, OrdersSetOrderCloneCourtesyModalOpening, OrdersSetOrderCloneModalOpening, OrdersSetSelectedDetailOrder } from '../../state/orders.actions';
import { OrdersState } from '../../state/orders.state';
var OrdersContainerComponent = /** @class */ (function () {
    function OrdersContainerComponent(store) {
        this.store = store;
        this.imageBaseUrl = environment.storageConfig.productsImagesMediumUrl;
        this.orderStateLabelMap = {
            1: 'translate.inviato',
            10: 'translate.inserito',
            20: 'translate.confermato',
            30: 'translate.chiuso',
            50: 'translate.annullato'
        };
    }
    OrdersContainerComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new OrdersClearAllOrdersFilters());
    };
    OrdersContainerComponent.prototype.ngOnInit = function () {
        this.addProductDisabled$ = this.isCartLoading$.pipe(debounceTime(100));
    };
    OrdersContainerComponent.prototype.handleOrderRowClicked = function (orderId) {
        this.store.dispatch(new OrdersSetSelectedDetailOrder(orderId));
    };
    OrdersContainerComponent.prototype.handleAddProductClicked = function (productQuantity) {
        this.store.dispatch(new CartAddProduct(productQuantity));
    };
    OrdersContainerComponent.prototype.handlePdfDownloadRequest = function (order) {
        this.store.dispatch(new OrdersDownloadPdf(order));
    };
    OrdersContainerComponent.prototype.handleOrderNumberIdFilterChange = function (filteringPartialOrderNumberId) {
        this.store.dispatch(new OrdersChangeListFilters({
            filteringPartialOrderNumberId: filteringPartialOrderNumberId,
            forceFilteringPartialOrderNumberIdValue: true
        }));
    };
    OrdersContainerComponent.prototype.handleOrderStatesFilterChange = function (filteringOrderStates) {
        this.store.dispatch(new OrdersChangeListFilters({
            filteringOrderStates: filteringOrderStates
        }));
    };
    OrdersContainerComponent.prototype.handleOrderCloneRequest = function () {
        var orderCloneCandidateId = this.store.selectSnapshot(OrdersState.orderCloneCandidateId);
        var order = this.store
            .selectSnapshot(OrdersState.listViewModel)
            .find(function (o) { return o.id === orderCloneCandidateId; });
        if (!order)
            throw new Error("Cannot find order with id: " + orderCloneCandidateId);
        this.store.dispatch(new CartCloneOrder(order));
    };
    OrdersContainerComponent.prototype.handleGoToProductsListRequest = function () {
        this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(false));
        this.store.dispatch(new Navigate(['/products']));
    };
    OrdersContainerComponent.prototype.handleGoToCartRequest = function () {
        this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(false));
        this.store.dispatch(new Navigate(['/cart']));
    };
    OrdersContainerComponent.prototype.handleOrderCloneModalOpeningRequest = function (value) {
        this.store.dispatch(new OrdersSetOrderCloneModalOpening(value));
    };
    OrdersContainerComponent.prototype.handleOrderCloneCourtesyModalOpeningRequest = function (value) {
        this.store.dispatch(new OrdersSetOrderCloneCourtesyModalOpening(value));
    };
    OrdersContainerComponent.prototype.handleDetailOrderGroupsFilterChange = function (_a) {
        var orderId = _a.orderId, productsGroups = _a.productsGroups;
        this.store.dispatch(new OrdersChangeOrderFilters(orderId, {
            filteringGroupsCodes: productsGroups
        }));
    };
    OrdersContainerComponent.prototype.handleDetailOrderProductCodeFilterChange = function (_a) {
        var orderId = _a.orderId, productCode = _a.productCode;
        this.store.dispatch(new OrdersChangeOrderFilters(orderId, {
            filteringPartialProductCode: productCode,
            forceFilteringPartialProductCodeValue: true
        }));
    };
    OrdersContainerComponent.prototype.handleDetailOrderDescriptionFilterChange = function (_a) {
        var orderId = _a.orderId, productDescription = _a.productDescription;
        this.store.dispatch(new OrdersChangeOrderFilters(orderId, {
            filteringPartialDescription: productDescription,
            forceFilteringPartialDescriptionValue: true
        }));
    };
    tslib_1.__decorate([
        Select(OrdersState.listViewModelFiltered),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "listViewModelFiltered$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.filteringOrderStates),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "filteringOrderStates$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.filteringPartialOrderNumberId),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "filteringPartialOrderNumberId$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.selectedDetailOrderWithFilteredProducts),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "selectedDetailOrderWithFilteredProducts$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.isOrderCloneModalOpen),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "isOrderCloneModalOpen$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.isOrderCloneCourtesyModalOpen),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "isOrderCloneCourtesyModalOpen$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.selectedDetailOrderProductsFilter),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "selectedDetailOrderProductsFilter$", void 0);
    tslib_1.__decorate([
        Select(CartState.quantities),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "cartQuantities$", void 0);
    tslib_1.__decorate([
        Select(CartState.isInCartRowsHttpProcessing),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "isCartLoading$", void 0);
    tslib_1.__decorate([
        Select(ProductsState.groups),
        tslib_1.__metadata("design:type", Observable)
    ], OrdersContainerComponent.prototype, "productsGroups$", void 0);
    return OrdersContainerComponent;
}());
export { OrdersContainerComponent };
