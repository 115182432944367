import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { formatDate } from '@angular/common';
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
var OrderDetailComponent = /** @class */ (function () {
    function OrderDetailComponent(locale) {
        var _this = this;
        this.locale = locale;
        this.addProductClicked = new EventEmitter();
        this.productsGroupsFilterChange = new EventEmitter();
        this.productCodeFilterChange = new EventEmitter();
        this.productDescriptionFilterChange = new EventEmitter();
        this.itemSize = 105;
        this.isMobile = isMobileCheck();
        this.formatDate = function (value, format) {
            // tslint:disable-next-line: semicolon
            return formatDate(value, format, _this.locale);
        };
    }
    Object.defineProperty(OrderDetailComponent.prototype, "productsFiltersList", {
        get: function () {
            return this.order.orders_rows_rel.map(function (entry) { return ({
                code: entry.code_product,
                description: entry.description_product
            }); });
        },
        enumerable: true,
        configurable: true
    });
    OrderDetailComponent.prototype.ngOnChanges = function (changes) {
        if (!changes.order || !changes.order.isFirstChange()) {
            this.orderProductsListCdkCompontent.scrollToIndex(0);
            this.handleResize();
        }
    };
    OrderDetailComponent.prototype.ngOnInit = function () {
        this.handleResize();
    };
    OrderDetailComponent.prototype.handleResize = function () {
        // const isSmallWidth = window.matchMedia('(max-width: 1199px)').matches;
        // const fixedHeight = isSmallWidth ? 157 : 176;
        // const heightMediaQuery = isSmallWidth
        //   ? '(max-height: 792px)'
        //   : '(max-height: 739px)';
        // const maxHeight = window.matchMedia(heightMediaQuery).matches
        //   ? fixedHeight
        //   : (this.isMobile ? screen.availHeight : window.innerHeight) -
        //     (isSmallWidth ? 635 : 562);
        if (this.productsFilters.filteringGroupsCodes.length &&
            window.matchMedia('(max-width: 1199px)').matches)
            this.productsGroupsFilterChange.emit({
                orderId: this.order.id,
                productsGroups: []
            });
        var fixedHeight = 176;
        var heightMediaQuery = '(max-height: 739px)';
        var maxHeight = window.matchMedia(heightMediaQuery).matches
            ? fixedHeight
            : (this.isMobile ? screen.availHeight : window.innerHeight) - 562;
        var heightBasedOnMaxHeightAndNumberOfItems = Math.min(maxHeight, this.itemSize * this.order.orders_rows_rel.length + 5);
        var heightBasedOnMinFourItemsDisplayedIfItemsAreAvailable = Math.min(this.order.orders_rows_rel.length, 4) * this.itemSize + 5;
        this.virtualScrollHeight = Math.max(heightBasedOnMaxHeightAndNumberOfItems, heightBasedOnMinFourItemsDisplayedIfItemsAreAvailable);
    };
    return OrderDetailComponent;
}());
export { OrderDetailComponent };
