import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { interval, Subscription } from 'rxjs';
import { CoreState } from './state/core.state';
var AppComponent = /** @class */ (function () {
    function AppComponent(gtag, router, store, swUpdate) {
        var _this = this;
        this.gtag = gtag;
        this.router = router;
        this.store = store;
        this.swUpdate = swUpdate;
        this.subscription = new Subscription();
        var updatesAvailable = this.swUpdate.available;
        if (this.swUpdate.isEnabled) {
            // Required to enable updates on Windows and ios.
            this.swUpdate.activateUpdate().then();
            interval(30000).subscribe(function () {
                _this.swUpdate.checkForUpdate().then(function () {
                    //console.log('checking for updates');
                });
            });
        }
        this.subscription.add(updatesAvailable.subscribe(function (evt) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('UpdateService: versionUpdates', evt);
                        return [4 /*yield*/, this.swUpdate.activateUpdate()];
                    case 1:
                        _a.sent();
                        if (confirm("Nuova versione disponibile. Caricare l'ultima versione?")) {
                            window.location.reload();
                        }
                        return [2 /*return*/];
                }
            });
        }); }));
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.gtag.event(event.urlAfterRedirects, {
                    event_category: 'traces',
                    event_label: "Customer: " + _this.store.selectSnapshot(CoreState.currentCustomerCode) + " - " + JSON.stringify(event),
                });
            }
        }));
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return AppComponent;
}());
export { AppComponent };
