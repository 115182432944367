<nz-layout>
  <nz-content>
    <nz-card [nzCover]="coverTemplate">
      <div class="section__header">
        <h1 class="section__title card-message">{{ 'translate.errore' | translate }}</h1>
      </div>

      <p>{{ 'translate.errore_descrizione' | translate }}</p>
      <button [routerLink]="['/']" nz-button nzType="primary" nzBlock>
        Home
      </button>
      <button (click)="authenticationService.logout()" nz-button nzBlock>
        Logout
      </button>
    </nz-card>
  </nz-content>
</nz-layout>

<ng-template #coverTemplate>
  <div class="card-cover">
    <img [src]="logoUrl" width="180" />
  </div>
</ng-template>