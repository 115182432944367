import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import {
  Actions,
  getActionTypeFromInstance,
  ofActionCompleted,
  Store
} from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { tap } from 'rxjs/operators';
import {
  CartCloneOrder,
  CartSetOrderSubmitModalOpening
} from 'src/app/features/cart/state/cart.actions';
import { OrdersNewOrderFromCart } from 'src/app/features/orders/state/orders.actions';
import { CoreInitialize } from '../core.actions';
import { CoreState } from '../core.state';

@Injectable({ providedIn: 'root' })
export class AnalyticsLoggingHandler {
  constructor(
    private actions$: Actions,
    gtag: Gtag,
    private store: Store,
    private router: Router
  ) {
    this.actions$
      .pipe(
        ofActionCompleted(
          CartSetOrderSubmitModalOpening,
          CartCloneOrder,
          OrdersNewOrderFromCart
        ),
        tap(({ result, action }) => {
          // Errors are already logged in the analytics
          // from the error-logging handler,
          // while for canceled actions (usually because of compulsive click)
          // we are not interested in logging
          if (result.error || result.canceled) return;

          gtag.event(getActionTypeFromInstance(action), {
            event_category: 'traces',
            event_label: `Customer: ${this.store.selectSnapshot(
              CoreState.currentCustomerCode
            )} - ${JSON.stringify(action)}`
          });
        })
      )
      .subscribe();

    // No need for unsubscribe, as handlers are application wide
  }
}
