import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CoreInitialize, CoreRetrieveCustomers } from '../core.actions';

@Injectable({ providedIn: 'root' })
export class CriticalErrorHandler {
  constructor(private actions$: Actions, private store: Store) {
    this.actions$
      .pipe(
        ofActionCompleted(CoreInitialize, CoreRetrieveCustomers),
        tap(({ result }) => {
          if (result.error) this.store.dispatch(new Navigate(['/error']));
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }
}
