import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private store: Store
  ) {}

  async canActivate() {
    if (this.authenticationService.isAuthenticated()) return true;

    // callback from auth0
    if (window.location.hash) {
      try {
        await this.authenticationService.handleAuthentication();
        this.store.dispatch(
          new Navigate([this.authenticationService.callbackPath || '/'])
        );

        this.authenticationService.callbackPath = null;
      } catch (error) {
        console.error('handleAuthentication error', error);
        this.authenticationService.login();
      }

      return false;
    }

    this.authenticationService.login();
    return false;
  }
}
