<section class="filters">
  <button class="filters__toggle" nz-button [ngClass]="!isCollapsed ? '-active' : null" (click)="toggleCollapsed()">
    <span class="filters__toggle-label">{{ 'translate.filtri' | translate }}</span>
    <span nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
  </button>
  <div class="filters__content" [ngClass]="!isCollapsed ? '-active' : null">

    <div class="order-states-filter">
      <label class="filter-label">{{'translate.stato' | translate}}</label>
      <nz-select class="order-states-select" nzMode="multiple"
        nzPlaceHolder="{{'translate.tutti_gli_stati' | translate}}" nzAllowClear [nzMaxTagCount]="2"
        [ngModel]="filteringOrderState" (ngModelChange)="orderStatesFilterChange.emit($event)">
        <nz-option *ngFor="let state of objectKeys(orderStateLabelMap)"
          [nzLabel]="orderStateLabelMap[state] | translate" [nzValue]="parseInt(state, 10)"></nz-option>
      </nz-select>
    </div>

    <div class="order-id-filter">
      <label class="filter-label">{{'translate.cerca_per_numero' | translate}}</label>
      <input nz-input placeholder="{{'translate.numero_ordine' | translate}}" appDigitOnly
        [nzAutocomplete]="numberIdFilterAutocomplete" [class.clearable]="filteringPartialOrderNumberId"
        [ngModel]="filteringPartialOrderNumberId" (ngModelChange)="orderNumberIdFilterChange.emit($event)"
        (input)="orderNumberIdFilterChange.emit($event.target?.value)" />
      <i *ngIf="filteringPartialOrderNumberId" nz-icon class="clear" nzType="close-circle" nzTheme="fill"
        (click)="orderNumberIdFilterChange.emit(null)"></i>
      <nz-autocomplete [nzDataSource]="list | pluck: 'number' | where: isTruthy" nzBackfill #numberIdFilterAutocomplete>
      </nz-autocomplete>
    </div>

  </div>

</section>