import * as tslib_1 from "tslib";
import { RouterState } from '@ngxs/router-plugin';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { CartCheckEmptyCart, CartReset, CartSetCart } from 'src/app/features/cart/state/cart.actions';
import { OrdersCheckNoOrders, OrdersReset, OrdersSetList } from 'src/app/features/orders/state/orders.actions';
import { ProductsReset, ProductsSetGroups, ProductsSetList, ProductsSetMissingQuantities } from 'src/app/features/products/state/products.actions';
import { CoreGQL, CustomersGQL } from 'src/app/graphql/graphql';
import { GraphqlSubscriptionsService } from '../services/graphql-subscriptions.service';
import { CoreChangeCustomer, CoreDecrementHttpRequestsInProgressCount, CoreIncrementHttpRequestsInProgressCount, CoreInitialize, CoreRetrieveCustomers, CoreSetCurrentCustomerCode, CoreSetIsShowingLoading } from './core.actions';
var currentCustomerStorage = localStorage;
var currentCustomerKey = 'currentCustomer';
var CoreState = /** @class */ (function () {
    function CoreState(store, coreGQL, customersGQL, graphqlSubscriptionsService) {
        this.store = store;
        this.coreGQL = coreGQL;
        this.customersGQL = customersGQL;
        this.graphqlSubscriptionsService = graphqlSubscriptionsService;
    }
    CoreState_1 = CoreState;
    CoreState.httpRequestsInProgressCount = function (_a) {
        var httpRequestsInProgressCount = _a.httpRequestsInProgressCount;
        return httpRequestsInProgressCount;
    };
    CoreState.isCustomerChangeInProgress = function (_a) {
        var isCustomerChangeInProgress = _a.isCustomerChangeInProgress;
        return isCustomerChangeInProgress;
    };
    CoreState.userEmail = function (_a) {
        var userEmail = _a.userEmail;
        return userEmail;
    };
    CoreState.isShowingLoading = function (_a) {
        var isShowingLoading = _a.isShowingLoading;
        return isShowingLoading;
    };
    CoreState.isInitialized = function (_a) {
        var isInitialized = _a.isInitialized;
        return isInitialized;
    };
    CoreState.availableCustomers = function (_a) {
        var availableCustomers = _a.availableCustomers;
        return availableCustomers;
    };
    CoreState.otherAvailableCustomers = function (_a) {
        var availableCustomers = _a.availableCustomers, currentCustomerCode = _a.currentCustomerCode;
        return availableCustomers.filter(function (customer) { return customer.code !== currentCustomerCode; });
    };
    CoreState.currentCustomerCode = function (_a) {
        var currentCustomerCode = _a.currentCustomerCode;
        return currentCustomerCode;
    };
    CoreState.currentCustomer = function (state) {
        var availableCustomers = CoreState_1.availableCustomers(state);
        var currentCustomerCode = CoreState_1.currentCustomerCode(state);
        var currentCustomer = availableCustomers.filter(function (customer) { return customer.code === currentCustomerCode; })[0];
        return currentCustomer;
    };
    CoreState.prototype.setIsShowingLoading = function (_a, _b) {
        var patchState = _a.patchState;
        var value = _b.value;
        patchState({
            isShowingLoading: value
        });
    };
    CoreState.prototype.incrementHttpRequestsInProgressCount = function (_a) {
        var patchState = _a.patchState, getState = _a.getState;
        var httpRequestsInProgressCount = getState().httpRequestsInProgressCount;
        patchState({
            httpRequestsInProgressCount: httpRequestsInProgressCount + 1
        });
    };
    CoreState.prototype.decrementHttpRequestsInProgressCount = function (_a) {
        var patchState = _a.patchState, getState = _a.getState;
        var httpRequestsInProgressCount = getState().httpRequestsInProgressCount;
        var newCount = httpRequestsInProgressCount - 1;
        if (newCount < 0) {
            console.error("Decrement of Http Request in Progress count was going to set the count to: " + newCount + ". Setting it to 0 instead.");
            newCount = 0;
        }
        patchState({
            httpRequestsInProgressCount: newCount
        });
    };
    CoreState.prototype.initialize = function (ctx) {
        var _this = this;
        var isInitialized = ctx.getState().isInitialized;
        if (isInitialized)
            throw new Error("Core state is already initialized");
        var currentCustomerCode$ = ctx
            .dispatch(new CoreRetrieveCustomers())
            .pipe(map(function (_) { return ctx.getState().currentCustomerCode; }));
        return currentCustomerCode$.pipe(switchMap(function (customerCode) {
            return _this.initializeOtherStates(_this.coreGQL.fetch({ customerCode: customerCode }), ctx);
        }), tap(function (_a) {
            var user_customer = _a.user_customer;
            return ctx.patchState({
                userEmail: user_customer[0].email,
                isInitialized: true
            });
        }));
    };
    CoreState.prototype.changeCustomer = function (ctx, _a) {
        var _this = this;
        var customerCode = _a.customerCode;
        var currentGlobalState = this.store.snapshot();
        ctx.patchState({ isCustomerChangeInProgress: true });
        return ctx.dispatch(new CoreSetCurrentCustomerCode(customerCode)).pipe(switchMap(function (_) {
            return ctx.dispatch([new CartReset(), new ProductsReset(), new OrdersReset()]);
        }), switchMap(function (_) {
            return _this.initializeOtherStates(_this.coreGQL.fetch({ customerCode: customerCode }), ctx);
        }), switchMap(function (_) { return _this.performChecksBasedOnCurrentUrl(ctx); }), tap(function (_) { return ctx.patchState({ isCustomerChangeInProgress: false }); }), tap(function (_) {
            return currentCustomerStorage.setItem(currentCustomerKey, customerCode);
        }), catchError(function (error) {
            // Pseudo transactional action
            _this.store.reset(currentGlobalState);
            return throwError(error);
        }));
    };
    CoreState.prototype.retrieveCustomers = function (ctx) {
        var _this = this;
        return this.customersGQL.fetch().pipe(map(function (result) { return result.data; }), tap(function (_a) {
            var customers = _a.customers;
            return _this.setAvailableCustomers(customers, ctx);
        }), delayWhen(function (_a) {
            var customers = _a.customers;
            return _this.storeCurrentCustomer(customers, ctx);
        }));
    };
    CoreState.prototype.setCurrentCustomerCode = function (_a, _b) {
        var patchState = _a.patchState, getState = _a.getState;
        var currentCustomerCode = _b.currentCustomerCode;
        if (
        // currentCustomerCode &&
        !getState().availableCustomers.some(function (customer) { return customer.code === currentCustomerCode; }))
            throw new Error("Cannot set current customer code to " + currentCustomerCode + ", since it's not in the available customers list");
        patchState({
            currentCustomerCode: currentCustomerCode
        });
    };
    CoreState.prototype.performChecksBasedOnCurrentUrl = function (ctx) {
        var currentUrl = this.store.selectSnapshot(RouterState.url);
        if (currentUrl.endsWith('/cart'))
            return ctx.dispatch(new CartCheckEmptyCart());
        if (currentUrl.endsWith('/orders'))
            return ctx.dispatch(new OrdersCheckNoOrders());
        return of(null);
    };
    CoreState.prototype.initializeOtherStates = function (coreQueryResult$, ctx) {
        var _this = this;
        return coreQueryResult$.pipe(map(function (result) { return result.data; }), delayWhen(function (_a) {
            var cart_headers = _a.cart_headers;
            return _this.storeCurrentCustomerCart(ctx, cart_headers);
        }), delayWhen(function (_a) {
            var products_groups = _a.products_groups;
            return ctx.dispatch(new ProductsSetGroups(products_groups));
        }), delayWhen(function (_a) {
            var products = _a.products;
            return ctx.dispatch(new ProductsSetList(products));
        }), delayWhen(function (_) { return ctx.dispatch(new ProductsSetMissingQuantities()); }), delayWhen(function (_a) {
            var view_orders_headers = _a.view_orders_headers;
            return ctx.dispatch(new OrdersSetList(view_orders_headers));
        }), tap(function (_) {
            var customerCode = ctx.getState().currentCustomerCode;
            _this.graphqlSubscriptionsService.init(customerCode);
        }));
    };
    CoreState.prototype.storeCurrentCustomerCart = function (ctx, carts) {
        return ctx.dispatch(new CartSetCart(carts[0]));
    };
    CoreState.prototype.setAvailableCustomers = function (availableCustomers, ctx) {
        var currentCustomerCode = ctx.getState().currentCustomerCode;
        if (currentCustomerCode &&
            !availableCustomers.some(function (customer) { return customer.code === currentCustomerCode; }))
            throw new Error(
            // tslint:disable-next-line: max-line-length
            "Cannot set available customers, since the current customer code: " + currentCustomerCode + " - it's not in the new available customers list. If you want to change both the current customer and the available customer list, please set the current customer to a falsy value before");
        ctx.patchState({
            availableCustomers: availableCustomers
        });
    };
    CoreState.prototype.storeCurrentCustomer = function (availableCustomers, ctx) {
        var defaultCustomer = availableCustomers[0];
        if (!defaultCustomer)
            throw new Error('CoreResolver: Could not retrieve current customer, since there are no available ones');
        var storageCurrentCustomerCode = currentCustomerStorage.getItem(currentCustomerKey);
        var storageCurrentCustomerCodeInAvailableCustomers = availableCustomers.some(function (customer) { return customer.code === storageCurrentCustomerCode; });
        return ctx.dispatch(new CoreSetCurrentCustomerCode((storageCurrentCustomerCodeInAvailableCustomers &&
            storageCurrentCustomerCode) ||
            defaultCustomer.code));
    };
    var CoreState_1;
    tslib_1.__decorate([
        Action(CoreSetIsShowingLoading),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CoreSetIsShowingLoading]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CoreState.prototype, "setIsShowingLoading", null);
    tslib_1.__decorate([
        Action(CoreIncrementHttpRequestsInProgressCount),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CoreState.prototype, "incrementHttpRequestsInProgressCount", null);
    tslib_1.__decorate([
        Action(CoreDecrementHttpRequestsInProgressCount),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CoreState.prototype, "decrementHttpRequestsInProgressCount", null);
    tslib_1.__decorate([
        Action(CoreInitialize, { cancelUncompleted: true }),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CoreState.prototype, "initialize", null);
    tslib_1.__decorate([
        Action(CoreChangeCustomer, { cancelUncompleted: true }),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CoreChangeCustomer]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CoreState.prototype, "changeCustomer", null);
    tslib_1.__decorate([
        Action(CoreRetrieveCustomers, { cancelUncompleted: true }),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Observable)
    ], CoreState.prototype, "retrieveCustomers", null);
    tslib_1.__decorate([
        Action(CoreSetCurrentCustomerCode),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CoreSetCurrentCustomerCode]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CoreState.prototype, "setCurrentCustomerCode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], CoreState, "httpRequestsInProgressCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], CoreState, "isCustomerChangeInProgress", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], CoreState, "userEmail", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], CoreState, "isShowingLoading", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], CoreState, "isInitialized", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], CoreState, "availableCustomers", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], CoreState, "otherAvailableCustomers", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], CoreState, "currentCustomerCode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], CoreState, "currentCustomer", null);
    CoreState = CoreState_1 = tslib_1.__decorate([
        State({
            name: 'core',
            defaults: {
                availableCustomers: [],
                currentCustomerCode: null,
                userEmail: null,
                httpRequestsInProgressCount: 0,
                isShowingLoading: false,
                isInitialized: false,
                isCustomerChangeInProgress: false
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store,
            CoreGQL,
            CustomersGQL,
            GraphqlSubscriptionsService])
    ], CoreState);
    return CoreState;
}());
export { CoreState };
