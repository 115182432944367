import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import {
  CoreDecrementHttpRequestsInProgressCount,
  CoreIncrementHttpRequestsInProgressCount
} from '../state/core.actions';

@Injectable()
export class ActiveRequestsCounterInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store
      .dispatch(new CoreIncrementHttpRequestsInProgressCount())
      .pipe(
        mergeMap(_ => next.handle(req)),
        finalize(() => {
          this.store.dispatch(new CoreDecrementHttpRequestsInProgressCount());
        })
      );
  }
}
