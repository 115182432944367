<div
  class="minus-wrapper"
  (mousedown)="skipFocusOnStep ? downWithoutFocus($event) : down($event)"
  (mouseup)="stop()"
  (mouseleave)="stop()"
  [class.disabled]="disabledDown || nzDisabled"
>
  <i class="minus" nz-icon nzType="minus"> </i>
</div>

<div
  class="plus-wrapper"
  (mousedown)="skipFocusOnStep ? upWithoutFocus($event) : up($event)"
  (mouseup)="stop()"
  (mouseleave)="stop()"
  [class.disabled]="disabledUp || nzDisabled"
>
  <i class="plus" nz-icon nzType="plus"> </i>
</div>

<div class="ant-input-number-input-wrap">
  <input
    #inputElement
    autocomplete="off"
    class="ant-input-number-input"
    [disabled]="nzDisabled"
    [attr.min]="nzMin"
    [attr.max]="nzMax"
    [placeholder]="nzPlaceHolder"
    [attr.step]="nzStep"
    [class.fix-height]="fixHeight"
    [ngModel]="displayValue"
    (ngModelChange)="onModelChange($event)"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp()"
    (blur)="onBlur()"
    (focus)="onFocus()"
  />
  <span class="unit-of-measure">{{ this.unitOfMeasure }}</span>
</div>
