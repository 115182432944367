import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FilesService = /** @class */ (function () {
    function FilesService(httpClient) {
        this.httpClient = httpClient;
    }
    // getFileAsPdfBlob(url: string): Observable<Blob> {
    //   return this.httpClient
    //     .get(url, {
    //       responseType: 'blob'
    //     })
    //     .pipe(map(res => new Blob([res], { type: 'application/pdf' })));
    // }
    FilesService.prototype.getPdfB64asBlob = function (b64Data, sliceSize) {
        if (sliceSize === void 0) { sliceSize = 512; }
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: 'application/pdf' });
    };
    FilesService.prototype.openPdfBlobInNewTab = function (pdfBlob, filename) {
        // IE/Edge, will save the file instead of opening in a new tab
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdfBlob, filename);
            return;
        }
        var fileUrl = URL.createObjectURL(pdfBlob);
        window.open(fileUrl, '_blank');
    };
    FilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilesService_Factory() { return new FilesService(i0.ɵɵinject(i1.HttpClient)); }, token: FilesService, providedIn: "root" });
    return FilesService;
}());
export { FilesService };
