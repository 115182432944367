export class CoreInitialize {
  static readonly type = '[Core] Initialize';
}

export class CoreChangeCustomer {
  static readonly type = '[Core] Change Customer';

  constructor(public customerCode: string) {}
}

export class CoreRetrieveCustomers {
  static readonly type = '[Core] Retrieve Customers';
}

export class CoreSetCurrentCustomerCode {
  static readonly type = '[Core] Set Current Customer Code';

  constructor(public currentCustomerCode: string) {}
}

export class CoreIncrementHttpRequestsInProgressCount {
  static readonly type = '[Core] Increment Http Requests In Progress Count';
}

export class CoreDecrementHttpRequestsInProgressCount {
  static readonly type = '[Core] Decrement Http Requests In Progress Count';
}

export class CoreSetIsShowingLoading {
  static readonly type = '[Core] Set Is Showing Loading';

  constructor(public value: boolean) {}
}
