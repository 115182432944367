import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { ProductOverviewModel } from 'src/app/features/products/types/product-overview.model';
import { ProductQuantityModel } from 'src/app/features/products/types/product-quantity.model';
import { ProductsQuantitiesModel } from 'src/app/features/products/types/products-quantities.model';
import { CommaInputNumberComponent } from 'src/app/shared/components/comma-input-number/comma-input-number.component';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { CartProductQuantitiesTotalModel } from '../../types/cart-product-quantities-total.model';
import { ProductsBeingAddedCodesMap } from '../../types/products-being-added-codes.map';

@Component({
  selector: 'app-cart-products-list',
  templateUrl: './cart-products-list.component.html',
  styleUrls: ['./cart-products-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartProductsListComponent implements OnInit, OnChanges {
  @Input() imageBaseUrl: string;
  @Input() list: ProductOverviewModel[];
  @Input() quantities: ProductsQuantitiesModel;
  @Input() productQuantitiesTotal: CartProductQuantitiesTotalModel;
  @Input() addProductDisabledCodes: ProductsBeingAddedCodesMap;

  @Output() quantityChange = new EventEmitter<ProductQuantityModel>();
  @Output() productRemove = new EventEmitter<number>();

  @ViewChildren(CommaInputNumberComponent)
  commaInputNumberComponents: QueryList<CommaInputNumberComponent>;

  itemSize: number;
  virtualScrollHeight: number;
  isSmallWidth: boolean;

  isMobile = isMobileCheck();

  setFocusOnCommaInputNumber(productCode: number): void {
    const commaInputNumberToFocus = this.commaInputNumberComponents.find(
      commaInputNumber => commaInputNumber.id === productCode
    );

    // tslint:disable-next-line: no-unused-expression
    commaInputNumberToFocus && commaInputNumberToFocus.focus();
  }

  handleProductRemove(productCode: number) {
    if (!this.addProductDisabledCodes[productCode])
      this.productRemove.emit(productCode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list && !changes.list.isFirstChange()) this.handleResize();
  }

  ngOnInit(): void {
    this.handleResize();
  }

  @HostListener('window:resize')
  handleResize() {
    this.isSmallWidth = window.matchMedia('(max-width: 1199px)').matches;
    this.itemSize = this.isSmallWidth ? 90 : 119;
    const fixedHeight = this.isSmallWidth ? 437 : 383;

    const heightMediaQuery = this.isSmallWidth
      ? '(max-height: 792px)'
      : '(max-height: 739px)';

    const maxHeight = window.matchMedia(heightMediaQuery).matches
      ? fixedHeight
      : (this.isMobile ? screen.availHeight : window.innerHeight) - 355;

    this.virtualScrollHeight = this.list.length
      ? Math.min(maxHeight, this.itemSize * this.list.length + 5)
      : fixedHeight;
  }
}
