<div class="cart-container">
  <div class="cart--main">
    <nz-card class="cart-products-card">
      <div class="section__header">
        <h1 class="section__title">
          {{ 'translate.il_mio_carrello' | translate }}
        </h1>
      </div>

      <!-- Please notice that listFiltered is already a shared observable by ngxs, 
      should we extend the observable with pipe, adding side effects, 
      it will be our responsibility to cause the side effects only once -->
      <app-products-filters
        [list]="listFiltered$ | async"
        [groups]="groups$ | async"
        [filteringGroupsCodes]="filteringGroupsCodes$ | async"
        [filteringPartialProductCode]="filteringPartialProductCode$ | async"
        [filteringPartialDescription]="filteringPartialDescription$ | async"
        (productCodeFilterChange)="handleProductCodeFilterChange($event)"
        (groupsFilterChange)="handleGroupsFilterChange($event)"
        (descriptionFilterChange)="handleDescriptionFilterChange($event)"
      ></app-products-filters>

      <app-cart-products-list
        [imageBaseUrl]="imageBaseUrl"
        [list]="listFiltered$ | async"
        [quantities]="quantities$ | async"
        [productQuantitiesTotal]="productQuantitiesTotal$ | async"
        [addProductDisabledCodes]="addProductDisabledCodes$ | async"
        (quantityChange)="handleQuantityChange($event)"
        (productRemove)="handleProductRemove($event)"
      ></app-cart-products-list>
    </nz-card>
  </div>
  <div class="cart--aside">
    <app-cart-extras
      [dateFor]="dateFor$ | async"
      [note]="note$ | async"
      [customerOrderNumber]="customerOrderNumber$ | async"
      [productsCount]="productsCount$ | async"
      [totalPrice]="combinedTotal$ | async"
      [actionsDisabled]="actionsDisabled$ | async"
      [isOrderSubmitModalVisible]="isOrderSubmitModalOpen$ | async"
      [isOrderCourtesyModalVisible]="isOrderCourtesyModalOpen$ | async"
      [isOrderLoading]="isOrderLoading$ | async"
      [showSummaryActionsDividerOnSmallBreakpoint]="
        !(translateManageService.isEnglishLanguage$ | async)
      "
      (dateForChange)="handleDateForChange($event)"
      (noteChange)="handleNoteChange($event)"
      (customerOrderNumberChange)="handleCustomerOrderNumberChange($event)"
      (orderSubmit)="handleOrderSubmit()"
      (clearCartSubmit)="handleCartClearSubmit()"
      (orderSubmitModalOpeningRequest)="
        handleOrderSubmitModalOpeningRequest($event)
      "
      (orderCourtesyModalOpeningRequest)="
        handleOrderCourtesyModalOpeningRequest($event)
      "
      (goToProductsListRequest)="handleGoToProductsListRequest()"
      (goToOrdersRequest)="handleGoToOrdersRequest()"
    >
    </app-cart-extras>
  </div>
</div>
