import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions } from '@ngxs/store';
import { ActionContext, ActionStatus } from '@ngxs/store/src/actions-stream';
import { NzNotificationService } from 'ng-zorro-antd';
import { filter, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OfflineErrorNotificationHandler {
  constructor(
    private actions$: Actions,
    private notificationService: NzNotificationService,
    private translateService: TranslateService
  ) {
    this.actions$
      .pipe(
        filter(({ status }: ActionContext) => status === ActionStatus.Errored),
        filter(_ => !navigator.onLine),
        tap(_ => {
          this.notificationService.error(
            this.translateService.instant('translate.offline_error_title'),
            this.translateService.instant('translate.offline_error_content'),
            { nzDuration: 5000 }
          );
        })
      )
      .subscribe();
    // No need for unsubscribe, as handlers are application wide
  }
}
