import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CoreInitialize } from '../state/core.actions';
import { CoreState } from '../state/core.state';

@Injectable({
  providedIn: 'root'
})
export class CoreResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(): Observable<boolean> {
    if (this.store.selectSnapshot(CoreState.isInitialized)) return of(true);

    return this.store
      .dispatch(new CoreInitialize())
      .pipe(switchMap(_ => of(true)));
  }
}
