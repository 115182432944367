import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CoreInitialize } from '../state/core.actions';
import { CoreState } from '../state/core.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var CoreResolver = /** @class */ (function () {
    function CoreResolver(store) {
        this.store = store;
    }
    CoreResolver.prototype.resolve = function () {
        if (this.store.selectSnapshot(CoreState.isInitialized))
            return of(true);
        return this.store
            .dispatch(new CoreInitialize())
            .pipe(switchMap(function (_) { return of(true); }));
    };
    CoreResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreResolver_Factory() { return new CoreResolver(i0.ɵɵinject(i1.Store)); }, token: CoreResolver, providedIn: "root" });
    return CoreResolver;
}());
export { CoreResolver };
