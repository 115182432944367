import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { GtagModule } from 'angular-gtag';
import {
  it_IT,
  NzConfig,
  NZ_CONFIG,
  NZ_I18N,
  NZ_MESSAGE_CONFIG,
  NZ_NOTIFICATION_CONFIG,
} from 'ng-zorro-antd';

import { CartModule } from '../features/cart/cart.module';
import { CartState } from '../features/cart/state/cart.state';
import { OrdersModule } from '../features/orders/orders.module';
import { OrdersState } from '../features/orders/state/orders.state';
import { ProductsModule } from '../features/products/products.module';
import { ProductsState } from '../features/products/state/products.state';
import { GraphQLModule } from '../graphql/graphql.module';
import { TranslateManagerService } from '../shared/services/translate-manager.service';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { CriticalErrorComponent } from './containers/critical-error/critical-error.component';
import { MainContainerComponent } from './containers/main/main-container.component';
import { ActiveRequestsCounterInterceptor } from './interceptors/active-requests-counter.interceptor';
import { CoreState } from './state/core.state';
import { AnalyticsLoggingHandler } from './state/handlers/analytics-logging.handler';
import { CriticalErrorHandler } from './state/handlers/critical-error.handler';
import { EmptyCartHandler } from './state/handlers/empty-cart.handler';
import { ErrorLoggingHandler } from './state/handlers/error-logging.handler';
import { LoadingBarHandler } from './state/handlers/loading-bar.handler';
import { ModalsHandler } from './state/handlers/modals.handler';
import { OfflineErrorNotificationHandler } from './state/handlers/offline-error-notification.handler';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

registerLocaleData(it);
registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  message: { nzMaxStack: 1, nzDuration: 1800 },
  notification: { nzMaxStack: 2, nzDuration: 2200 },
};

@NgModule({
  declarations: [
    AppComponent,
    MainContainerComponent,
    HeaderComponent,
    CriticalErrorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    SharedModule,

    HttpClientModule,

    GraphQLModule,

    AppRoutingModule,

    LoadingBarModule,

    NgxsModule.forRoot([CoreState, ProductsState, CartState, OrdersState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),

    OrdersModule,
    ProductsModule,
    CartModule,

    GtagModule.forRoot({ trackingId: environment.GA_ID, trackPageviews: true }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
      //registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: it_IT },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [OfflineErrorNotificationHandler],
      multi: true,
    },
    // Notification handler is now a dependency of
    // the main container component,
    // so that it's possible to easily pass templates
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [NotificationHandler],
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [CriticalErrorHandler],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [ErrorLoggingHandler],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [EmptyCartHandler],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [LoadingBarHandler],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [ModalsHandler],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [AnalyticsLoggingHandler],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translateManagerService: TranslateManagerService) => () =>
        translateManagerService.setLanguage().toPromise(),
      deps: [TranslateManagerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveRequestsCounterInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
