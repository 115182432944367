
    <i nz-icon>
      <svg viewBox="214 -92.1 1024 1024">
        <path
          fill="#595959"
          d="M956.4,729.6H443.3l25.8-52.5l428-0.8c14.5,0,26.9-10.3,29.4-24.6l59.2-331.8c1.6-8.7-0.8-17.6-6.5-24.5
          c-5.7-6.8-14.1-10.7-22.9-10.7l-544.5-1.8l-4.6-21.8c-3-14-15.6-24.1-29.8-24.1H244.4c-16.8,0-30.4,13.6-30.4,30.4
          s13.6,30.4,30.4,30.4h108.5l20.3,96.7l50.1,242.3L358.8,742c-6.8,9.2-7.9,21.5-2.6,31.7c5.2,10.2,15.6,16.7,27.2,16.7h54.1
          c-11.5,15.3-17.7,34-17.7,53.1c0,48.7,39.7,88.4,88.4,88.4s88.4-39.7,88.4-88.4c0-19.2-6.4-37.9-17.7-53.1h138.8
          c-11.5,15.3-17.7,34-17.7,53.1c0,48.7,39.7,88.4,88.4,88.4c48.7,0,88.4-39.6,88.4-88.4c0-19.2-6.4-37.9-17.7-53.1h97.6
          c16.7,0,30.4-13.6,30.4-30.4C986.8,743.2,973.2,729.6,956.4,729.6L956.4,729.6z M424.6,342.9l496,1.7l-48.6,272l-389.6,0.7
          L424.6,342.9z M508.1,870.7c-15,0-27.3-12.2-27.3-27.3s12.2-27.3,27.3-27.3s27.3,12.2,27.3,27.3
          C535.3,858.6,523.2,870.7,508.1,870.7z M788.2,870.7c-15,0-27.3-12.2-27.3-27.3s12.2-27.3,27.3-27.3c15,0,27.3,12.2,27.3,27.3
          C815.4,858.6,803.3,870.7,788.2,870.7z"
        />
        <g>
          <path
            [ngClass]="{
              'add-outer-alternative': isAlternativeColor,
              'add-outer-main': !isAlternativeColor
            }"
            d="M907.9-92.1C726.1-92.1,578.8,55.3,578.8,237s147.4,329.1,329.1,329.1S1237,418.7,1237,237
		S1089.6-92.1,907.9-92.1z M907.9,510.3C757,510.3,634.6,387.9,634.6,237S757-36.3,907.9-36.3S1181.2,86.1,1181.2,237
		S1058.7,510.3,907.9,510.3z"
          />
          <path
            [ngClass]="{
              'add-inner-alternative': isAlternativeColor,
              'add-inner-main': !isAlternativeColor
            }"
            d="M907.9-36.3C757-36.3,634.6,86.1,634.6,237S757,510.3,907.9,510.3s273.3-122.3,273.3-273.3
		S1058.7-36.3,907.9-36.3z M1049,254.6c0,3.3-2.7,5.8-5.8,5.8H931.5v111.6c0,3.3-2.7,5.8-5.8,5.8h-35.2c-3.3,0-5.8-2.7-5.8-5.8
		V260.4H773c-3.3,0-5.8-2.7-5.8-5.8v-35.2c0-3.3,2.7-5.8,5.8-5.8h111.6V101.8c0-3.3,2.7-5.8,5.8-5.8h35.2c3.3,0,5.8,2.7,5.8,5.8
		v111.6h111.6c3.3,0,5.8,2.7,5.8,5.8L1049,254.6L1049,254.6z"
          />
          <path
            [ngClass]="{
              'add-outer-alternative': isAlternativeColor,
              'add-outer-main': !isAlternativeColor
            }"
            d="M1043,213.6H931.3V101.8c0-3.3-2.7-5.8-5.8-5.8h-35.2c-3.3,0-5.8,2.7-5.8,5.8v111.6H772.8
            c-3.3,0-5.8,2.7-5.8,5.8v35.2c0,3.3,2.7,5.8,5.8,5.8h111.6v111.6c0,3.3,2.7,5.8,5.8,5.8h35.2c3.3,0,5.8-2.7,5.8-5.8V260.3H1043
            c3.3,0,5.8-2.7,5.8-5.8v-35.2C1049,216.1,1046.2,213.6,1043,213.6z"
          />
        </g>
      </svg>
    </i>
  