import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CoreInitialize, CoreRetrieveCustomers } from '../core.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var CriticalErrorHandler = /** @class */ (function () {
    function CriticalErrorHandler(actions$, store) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.actions$
            .pipe(ofActionCompleted(CoreInitialize, CoreRetrieveCustomers), tap(function (_a) {
            var result = _a.result;
            if (result.error)
                _this.store.dispatch(new Navigate(['/error']));
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    CriticalErrorHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CriticalErrorHandler_Factory() { return new CriticalErrorHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i1.Store)); }, token: CriticalErrorHandler, providedIn: "root" });
    return CriticalErrorHandler;
}());
export { CriticalErrorHandler };
