import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import { debounceTime, first, skip } from 'rxjs/operators';
import { CartAddProduct } from 'src/app/features/cart/state/cart.actions';
import { CartState } from 'src/app/features/cart/state/cart.state';
import { FilesService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import { ProductsChangeFilters, ProductsSetQuantity } from '../../state/products.actions';
import { ProductsState } from '../../state/products.state';
var ProductsContainerComponent = /** @class */ (function () {
    function ProductsContainerComponent(store, filesService) {
        this.store = store;
        this.filesService = filesService;
        this.imageBaseUrl = environment.storageConfig.productsImagesMediumUrl;
        this.dataSheetBaseUrl = environment.storageConfig.productsDataSheetsUrl;
    }
    ProductsContainerComponent.prototype.ngOnInit = function () {
        // this.addProductDisabled$ = this.isInCartRowsHttpProcessing.pipe(
        //   debounceTime(100)
        // );
        // Take the first immediatly otherwise we don't
        // have an inital state to render.
        // Debounce after that
        this.addProductDisabledCodes$ = merge(this.productsBeingAddedCodes$.pipe(first()), this.productsBeingAddedCodes$.pipe(skip(1), debounceTime(100)));
    };
    ProductsContainerComponent.prototype.handleGroupsFilterChange = function (filteringGroupsCodes) {
        this.store.dispatch(new ProductsChangeFilters({ filteringGroupsCodes: filteringGroupsCodes }));
    };
    ProductsContainerComponent.prototype.handleProductCodeFilterChange = function (filteringPartialProductCode) {
        this.store.dispatch(new ProductsChangeFilters({
            filteringPartialProductCode: filteringPartialProductCode,
            forceFilteringPartialProductCodeValue: true
        }));
    };
    ProductsContainerComponent.prototype.handleDescriptionFilterChange = function (filteringPartialDescription) {
        this.store.dispatch(new ProductsChangeFilters({
            filteringPartialDescription: filteringPartialDescription,
            forceFilteringPartialDescriptionValue: true
        }));
    };
    ProductsContainerComponent.prototype.handleQuantityChange = function (productQuantity) {
        this.store.dispatch(new ProductsSetQuantity(productQuantity));
    };
    ProductsContainerComponent.prototype.handleProductAdd = function (productCode) {
        var quantity = this.store.selectSnapshot(ProductsState.quantities)[productCode];
        this.store.dispatch(new CartAddProduct({ productCode: productCode, quantity: quantity }));
    };
    tslib_1.__decorate([
        Select(ProductsState.listFiltered),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "listFiltered$", void 0);
    tslib_1.__decorate([
        Select(ProductsState.groups),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "groups$", void 0);
    tslib_1.__decorate([
        Select(ProductsState.quantities),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "quantities$", void 0);
    tslib_1.__decorate([
        Select(CartState.productsCount),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "productsCount$", void 0);
    tslib_1.__decorate([
        Select(CartState.quantities),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "cartQuantities$", void 0);
    tslib_1.__decorate([
        Select(CartState.productsBeingAddedCodes),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "productsBeingAddedCodes$", void 0);
    tslib_1.__decorate([
        Select(ProductsState.filteringGroupsCodes),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "filteringGroupsCodes$", void 0);
    tslib_1.__decorate([
        Select(ProductsState.filteringPartialProductCode),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "filteringPartialProductCode$", void 0);
    tslib_1.__decorate([
        Select(ProductsState.filteringPartialDescription),
        tslib_1.__metadata("design:type", Observable)
    ], ProductsContainerComponent.prototype, "filteringPartialDescription$", void 0);
    return ProductsContainerComponent;
}());
export { ProductsContainerComponent };
