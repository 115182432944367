import { LoadingBarService } from '@ngx-loading-bar/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CoreDecrementHttpRequestsInProgressCount, CoreIncrementHttpRequestsInProgressCount, CoreSetIsShowingLoading } from '../core.actions';
import { CoreState } from '../core.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@ngx-loading-bar/core";
var LoadingBarHandler = /** @class */ (function () {
    function LoadingBarHandler(actions$, store, loadingBar) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.loadingBar = loadingBar;
        this.actions$
            .pipe(ofActionSuccessful(CoreDecrementHttpRequestsInProgressCount, CoreIncrementHttpRequestsInProgressCount), mergeMap(function (_) {
            var httpRequestsInProgressCount = _this.store.selectSnapshot(CoreState.httpRequestsInProgressCount);
            var isShowingLoading = _this.store.selectSnapshot(CoreState.isShowingLoading);
            if (httpRequestsInProgressCount < 1 && isShowingLoading) {
                _this.loadingBar.complete();
                return _this.store.dispatch(new CoreSetIsShowingLoading(false));
            }
            if (httpRequestsInProgressCount === 1 && !isShowingLoading) {
                _this.loadingBar.start();
                return _this.store.dispatch(new CoreSetIsShowingLoading(true));
            }
            return of(null);
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    LoadingBarHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingBarHandler_Factory() { return new LoadingBarHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.LoadingBarService)); }, token: LoadingBarHandler, providedIn: "root" });
    return LoadingBarHandler;
}());
export { LoadingBarHandler };
