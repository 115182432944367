import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GraphqlSubscriptionsService } from 'src/app/core/services/graphql-subscriptions.service';
import { CoreState } from 'src/app/core/state/core.state';
import { InsertOrdersHeaderAndRowsAndClearCartGQL, OrderPdfGQL, } from 'src/app/graphql/graphql';
// import { FilesService } from 'src/app/shared/services/file.service';
import { v4 as uuid } from 'uuid';
import { CartSetCart } from '../../cart/state/cart.actions';
import { CartState } from '../../cart/state/cart.state';
import { ProductsListFilterService } from '../../products/services/products-list-filter.service';
import { ProductsState, } from '../../products/state/products.state';
import { OrdersListFilterService } from '../services/orders-list-filter.service';
import { OrdersChangeListFilters, OrdersChangeOrderFilters, OrdersCheckNoOrders, OrdersClearAllOrdersFilters, OrdersDownloadPdf, OrdersNewOrderFromCart, OrdersReset, OrdersSetList, OrdersSetOrderCloneCourtesyModalOpening, OrdersSetOrderCloneModalOpening, OrdersSetSelectedDetailOrder, OrdersUpdateList, OrdersUpdateNotification, } from './orders.actions';
var ordersStateModelDefaults = {
    list: [],
    isInHttpProcessing: false,
    filteringPartialOrderNumberId: null,
    filteringOrderStates: [],
    selectedDetailOrderId: null,
    isOrderCloneModalOpen: false,
    isOrderCloneCourtesyModalOpen: false,
    orderCloneCandidateId: null,
    ordersProductsFiltersMap: {},
};
var OrdersState = /** @class */ (function () {
    function OrdersState(injector, store, 
    // private fileService: FilesService,
    graphqlSubscriptionsService, insertOrdersHeaderAndRowsAndClearCartGQL, orderPdfGQL) {
        this.store = store;
        this.graphqlSubscriptionsService = graphqlSubscriptionsService;
        this.insertOrdersHeaderAndRowsAndClearCartGQL = insertOrdersHeaderAndRowsAndClearCartGQL;
        this.orderPdfGQL = orderPdfGQL;
        OrdersState_1.ordersListFilterService = injector.get(OrdersListFilterService);
        OrdersState_1.productsListFilterService = injector.get(ProductsListFilterService);
    }
    OrdersState_1 = OrdersState;
    OrdersState.selectedDetailOrderWithFilteredProducts = function (state, productsState) {
        var selectedDetailOrderId = OrdersState_1.selectedDetailOrderId(state);
        var listViewModel = OrdersState_1.listViewModel(state, productsState);
        var selectedOrder = selectedDetailOrderId
            ? listViewModel.find(function (order) { return order.id === selectedDetailOrderId; })
            : listViewModel[0];
        if (!selectedOrder && selectedDetailOrderId)
            throw new Error("Cannot find order with id: " + selectedDetailOrderId);
        if (!selectedOrder)
            return selectedOrder;
        var _a = OrdersState_1.selectedDetailOrderProductsFilter(state), filteringGroupsCodes = _a.filteringGroupsCodes, filteringPartialProductCode = _a.filteringPartialProductCode, filteringPartialDescription = _a.filteringPartialDescription;
        var filteredProducts = OrdersState_1.productsListFilterService.filter({
            list: selectedOrder.orders_rows_rel,
            codeKey: 'code_product',
            descriptionKey: 'description_product',
            groupCodeKey: 'codeGroupProduct',
            groupsCodes: filteringGroupsCodes,
            partialProductCode: filteringPartialProductCode,
            partialDescription: filteringPartialDescription,
        });
        return tslib_1.__assign({}, selectedOrder, { orders_rows_rel: filteredProducts });
    };
    OrdersState.selectedDetailOrderProductsFilter = function (state) {
        var selectedDetailOrderId = OrdersState_1.selectedDetailOrderId(state);
        return ((selectedDetailOrderId &&
            state.ordersProductsFiltersMap[selectedDetailOrderId]) || {
            filteringGroupsCodes: [],
            filteringPartialDescription: null,
            filteringPartialProductCode: null,
        });
    };
    OrdersState.orderCloneCandidateId = function (_a) {
        var orderCloneCandidateId = _a.orderCloneCandidateId;
        return orderCloneCandidateId;
    };
    OrdersState.selectedDetailOrderId = function (_a) {
        var selectedDetailOrderId = _a.selectedDetailOrderId, list = _a.list;
        return selectedDetailOrderId || (list[0] || { id: null }).id;
    };
    OrdersState.isOrderCloneModalOpen = function (_a) {
        var isOrderCloneModalOpen = _a.isOrderCloneModalOpen;
        return isOrderCloneModalOpen;
    };
    OrdersState.isOrderCloneCourtesyModalOpen = function (_a) {
        var isOrderCloneCourtesyModalOpen = _a.isOrderCloneCourtesyModalOpen;
        return isOrderCloneCourtesyModalOpen;
    };
    OrdersState.ordersCount = function (_a) {
        var list = _a.list;
        return list.length;
    };
    OrdersState.list = function (_a) {
        var list = _a.list;
        return list;
    };
    OrdersState.listViewModel = function (_a, _b) {
        var list = _a.list;
        var productsStateList = _b.list;
        return list.reduce(function (viewModel, currentOrder) { return viewModel.concat([
            OrdersState_1.getOrderViewModel(currentOrder, productsStateList),
        ]); }, []);
    };
    OrdersState.listFiltered = function (_a) {
        var list = _a.list, filteringOrderStates = _a.filteringOrderStates, filteringPartialOrderNumberId = _a.filteringPartialOrderNumberId;
        return this.ordersListFilterService.filter(list, filteringPartialOrderNumberId, filteringOrderStates);
    };
    OrdersState.listViewModelFiltered = function (state, productsState) {
        var listViewModel = OrdersState_1.listViewModel(state, productsState);
        return this.ordersListFilterService.filter(listViewModel, state.filteringPartialOrderNumberId, state.filteringOrderStates);
    };
    OrdersState.filteringOrderStates = function (_a) {
        var filteringOrderStates = _a.filteringOrderStates;
        return filteringOrderStates;
    };
    OrdersState.filteringPartialOrderNumberId = function (_a) {
        var filteringPartialOrderNumberId = _a.filteringPartialOrderNumberId;
        return filteringPartialOrderNumberId;
    };
    OrdersState.isInHttpProcessing = function (_a) {
        var isInHttpProcessing = _a.isInHttpProcessing;
        return isInHttpProcessing;
    };
    OrdersState.getOrderViewModel = function (currentOrder, productsStateList) {
        var currentOrderViewModel = JSON.parse(JSON.stringify(currentOrder));
        var orderRowsWithPriceDiscount = OrdersState_1.getOrderRowsWithPriceDiscount(currentOrderViewModel.orders_rows_rel);
        var orderRowsWithPriceDiscountAndProductImage = OrdersState_1.getOrderRowsWithProductImage(orderRowsWithPriceDiscount, productsStateList);
        var orderRowsWithPriceDiscountAndProductImageAndAvailability = OrdersState_1.getOrderRowsWithAvailability(orderRowsWithPriceDiscountAndProductImage, productsStateList);
        var orderRowsWithPriceDiscountAndProductImageAndAvailabilityAndProductGroup = OrdersState_1.getOrderRowsWithProductGroup(orderRowsWithPriceDiscountAndProductImageAndAvailability, productsStateList);
        currentOrderViewModel.orders_rows_rel = orderRowsWithPriceDiscountAndProductImageAndAvailabilityAndProductGroup;
        currentOrderViewModel.isClonable = currentOrderViewModel.orders_rows_rel.some(function (entry) { return entry.isAvailable; });
        currentOrderViewModel.total = OrdersState_1.getOrderTotal(currentOrderViewModel);
        return currentOrderViewModel;
    };
    OrdersState.getOrderRowsWithProductGroup = function (orderRows, productsStateList) {
        return orderRows.map(function (entry) { return (tslib_1.__assign({}, entry, { codeGroupProduct: (productsStateList.find(function (product) { return product.code === entry.code_product; }) || { code_group: null }).code_group })); });
    };
    OrdersState.getOrderRowsWithAvailability = function (orderRows, productsStateList) {
        return orderRows.map(function (entry) { return (tslib_1.__assign({}, entry, { isAvailable: productsStateList.find(function (product) { return product.code === entry.code_product; })
                ? true
                : false })); });
    };
    OrdersState.getOrderTotal = function (orderViewModel) {
        return orderViewModel.orders_rows_rel.reduce(function (total, order) { return total + order.quantity * order.price_discount; }, 0);
    };
    OrdersState.getOrderRowsWithProductImage = function (orderRows, productsStateList) {
        return orderRows.reduce(function (ordersRows, currentOrder) { return ordersRows.concat([
            OrdersState_1.getOrderRowWithProductImage(currentOrder, productsStateList),
        ]); }, []);
    };
    OrdersState.getOrderRowWithProductImage = function (currentOrder, productsStateList) {
        return tslib_1.__assign({}, currentOrder, { image_medium: (productsStateList.find(function (product) { return product.code === currentOrder.code_product; }) || { image_medium: null }).image_medium });
    };
    OrdersState.getOrderRowsWithPriceDiscount = function (orderRows) {
        return orderRows.map(function (row) { return (tslib_1.__assign({}, row, { price_discount: OrdersState_1.getPriceDiscount(row) })); });
    };
    OrdersState.getPriceDiscount = function (_a) {
        var price = _a.price, offer = _a.offer, discount = _a.discount;
        return price * (1 - (offer || 0) / 100) * (1 - (discount || 0) / 100);
    };
    OrdersState.prototype.reset = function (_a) {
        var setState = _a.setState;
        setState(ordersStateModelDefaults);
    };
    OrdersState.prototype.clearAllOrdersFilters = function (_a) {
        var patchState = _a.patchState;
        patchState({
            ordersProductsFiltersMap: {},
        });
    };
    OrdersState.prototype.changeOrderFilters = function (_a, _b) {
        var _c;
        var patchState = _a.patchState, getState = _a.getState;
        var orderId = _b.orderId, filters = _b.filters;
        var _d = getState(), list = _d.list, ordersProductsFiltersMap = _d.ordersProductsFiltersMap;
        if (!list.some(function (order) { return order.id === orderId; }))
            throw new Error("Cannot find order with id: " + orderId);
        var _e = ordersProductsFiltersMap[orderId] || {
            filteringGroupsCodes: [],
            filteringPartialDescription: null,
            filteringPartialProductCode: null,
        }, originalFilteringGroupsCodes = _e.filteringGroupsCodes, originalFilteringPartialProductCode = _e.filteringPartialProductCode, originalFilteringPartialDescription = _e.filteringPartialDescription;
        var newFilters = OrdersState_1.productsListFilterService.getCalculatedFilters(filters, originalFilteringGroupsCodes, originalFilteringPartialProductCode, originalFilteringPartialDescription);
        patchState({
            ordersProductsFiltersMap: tslib_1.__assign({}, ordersProductsFiltersMap, (_c = {}, _c[orderId] = newFilters, _c)),
        });
    };
    OrdersState.prototype.setList = function (_a, _b) {
        var patchState = _a.patchState;
        var list = _b.list;
        patchState({
            list: list,
        });
    };
    OrdersState.prototype.updateList = function (_a, _b) {
        var patchState = _a.patchState, getState = _a.getState, dispatch = _a.dispatch;
        var list = _b.list;
        var originalList = getState().list;
        if (this.someOrderKeyFieldDiffers(list, originalList))
            dispatch(new OrdersUpdateNotification());
        patchState({
            list: list,
        });
    };
    OrdersState.prototype.setSelectedDetailOrder = function (_a, _b) {
        var patchState = _a.patchState;
        var id = _b.id;
        patchState({
            selectedDetailOrderId: id,
        });
    };
    OrdersState.prototype.setOrderCloneModalOpening = function (_a, _b) {
        var patchState = _a.patchState;
        var orderId = _b.orderId;
        patchState({
            orderCloneCandidateId: orderId,
            isOrderCloneModalOpen: !!orderId,
        });
    };
    OrdersState.prototype.checkNoOrders = function (_a) {
        var getState = _a.getState;
        if (getState().list.length < 1)
            return this.store.dispatch(new Navigate(['/products']));
        return of(null);
    };
    OrdersState.prototype.setOrderCloneCourtesyModalOpening = function (_a, _b) {
        var patchState = _a.patchState;
        var value = _b.value;
        patchState({
            isOrderCloneCourtesyModalOpen: value,
        });
    };
    OrdersState.prototype.changeListFilters = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        var filters = _b.filters;
        var _c = getState(), originalfilteringPartialOrderNumberId = _c.filteringPartialOrderNumberId, originalFilteringOrderStates = _c.filteringOrderStates;
        var filteringPartialOrderNumberId = filters.filteringPartialOrderNumberId, filteringOrderStates = filters.filteringOrderStates, forceFilteringPartialOrderNumberIdValue = filters.forceFilteringPartialOrderNumberIdValue;
        var orderStates = filteringOrderStates || originalFilteringOrderStates;
        var partialOrderNumberId = forceFilteringPartialOrderNumberIdValue
            ? filteringPartialOrderNumberId
            : filteringPartialOrderNumberId || originalfilteringPartialOrderNumberId;
        patchState({
            filteringPartialOrderNumberId: partialOrderNumberId,
            filteringOrderStates: orderStates,
        });
    };
    OrdersState.prototype.newOrderFromCart = function (_a) {
        var _this = this;
        var getState = _a.getState, patchState = _a.patchState, dispatch = _a.dispatch;
        patchState({ isInHttpProcessing: true });
        var customerCode = this.store.selectSnapshot(CoreState.currentCustomerCode);
        var cartProductsList = this.store.selectSnapshot(CartState.list);
        var cartQuantities = this.store.selectSnapshot(CartState.quantities);
        var dateFor = this.store.selectSnapshot(CartState.dateFor);
        var note = this.store.selectSnapshot(CartState.note);
        var customerOrderNumber = this.store.selectSnapshot(CartState.customerOrderNumber);
        var order = {
            id: uuid(),
            date: new Date(),
            date_for: dateFor,
            note: note,
            customer_order_number: customerOrderNumber,
            orders_rows_rel: cartProductsList.reduce(this.toProductsListWithQuantities(cartQuantities), []),
            state: 1,
        };
        return this.insertOrdersHeaderAndRowsAndClearCartGQL
            .mutate({
            customerCode: customerCode,
            id: order.id,
            note: order.note,
            customerOrderNumber: order.customer_order_number,
            dateFor: order.date_for,
            date: order.date,
            rows: order.orders_rows_rel,
        })
            .pipe(tap(function (_) {
            return (_this.graphqlSubscriptionsService.skipNextOrderUpdateBecauseItWasByUser = true);
        }), tap(function () {
            var currentList = getState().list;
            patchState({
                list: [order].concat(currentList),
                selectedDetailOrderId: order.id,
                isInHttpProcessing: false,
            });
        }), tap(function (_) {
            return dispatch(new CartSetCart({
                // Please notice that we pass code_customer only to be
                // compliant with the gql autogenerated type.
                // The CartSetCart action doesn't actually use it
                code_customer: null,
                cart_row_rel: [],
                date_for: null,
                note: null,
                customer_order_number: null,
            }));
        }), catchError(function (error) {
            patchState({
                isInHttpProcessing: false,
            });
            return throwError(error);
        }));
    };
    OrdersState.prototype.downloadPdf = function (_, _a) {
        var _this = this;
        var order = _a.order;
        if (!order.number)
            throw new Error("Cannot download pdf for order with id: " + order.id + ", because order number is not present");
        return this.orderPdfGQL
            .fetch({
            orderId: order.id,
        })
            .pipe(map(function (result) { return result.data; }), tap(function (_a) {
            var orders_headers_by_pk = _a.orders_headers_by_pk;
            return _this.downloadOrderPdfFromBase64(orders_headers_by_pk.pdf, order.number);
        }));
    };
    OrdersState.prototype.someOrderKeyFieldDiffers = function (list, originalList) {
        var _this = this;
        return (list.length !== originalList.length ||
            originalList.some(function (originalOrder) {
                var matchingOrder = list.find(function (order) { return order.id === originalOrder.id; });
                return _this.keyOrderFieldDiffer(matchingOrder, originalOrder);
            }));
    };
    OrdersState.prototype.keyOrderFieldDiffer = function (matchingOrder, originalOrder) {
        return (!matchingOrder ||
            matchingOrder.state !== originalOrder.state ||
            matchingOrder.date_delivery !== originalOrder.date_delivery ||
            matchingOrder.pdf !== originalOrder.pdf ||
            matchingOrder.number !== originalOrder.number);
    };
    OrdersState.prototype.downloadOrderPdfFromBase64 = function (base64, orderNumber) {
        if (!base64)
            throw new Error("Cannot download pdf for order with number: " + orderNumber + ", because it is not present");
        var linkSource = "data:application/pdf;base64," + base64;
        var downloadLink = document.createElement('a');
        var fileName = orderNumber + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = '_blank';
        downloadLink.click();
        // const blob = this.fileService.getPdfB64asBlob(base64);
        // this.fileService.openPdfBlobInNewTab(blob, `${orderNumber}.pdf`);
    };
    OrdersState.prototype.toProductsListWithQuantities = function (cartQuantities) {
        var _this = this;
        return function (productsListWithQuantities, currentProduct, currentIndex) { return productsListWithQuantities.concat([
            _this.getProductWithQuantity({
                currentProduct: currentProduct,
                cartQuantities: cartQuantities,
                currentIndex: currentIndex,
            }),
        ]); };
    };
    OrdersState.prototype.getProductWithQuantity = function (_a) {
        var currentProduct = _a.currentProduct, cartQuantities = _a.cartQuantities, currentIndex = _a.currentIndex;
        var 
        // tslint:disable-next-line: variable-name
        code_product = currentProduct.code, code_vat = currentProduct.code_vat, 
        // tslint:disable-next-line: variable-name
        description_product = currentProduct.description, price_rel = currentProduct.price_rel, unit_of_measure = currentProduct.unit_of_measure;
        var _b = price_rel[0], discount = _b.discount, offerIsValid = _b.offerIsValid, offer = _b.offer, price = _b.price;
        return {
            code_product: code_product,
            code_vat: code_vat,
            description_product: description_product,
            discount: discount,
            offer: offerIsValid ? offer : null,
            price: price,
            quantity: cartQuantities[code_product],
            row_number: currentIndex,
            unit_of_measure: unit_of_measure,
        };
    };
    var OrdersState_1;
    tslib_1.__decorate([
        Action(OrdersReset),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "reset", null);
    tslib_1.__decorate([
        Action(OrdersClearAllOrdersFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "clearAllOrdersFilters", null);
    tslib_1.__decorate([
        Action(OrdersChangeOrderFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersChangeOrderFilters]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "changeOrderFilters", null);
    tslib_1.__decorate([
        Action(OrdersSetList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersSetList]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "setList", null);
    tslib_1.__decorate([
        Action(OrdersUpdateList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersUpdateList]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "updateList", null);
    tslib_1.__decorate([
        Action(OrdersSetSelectedDetailOrder),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersSetSelectedDetailOrder]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "setSelectedDetailOrder", null);
    tslib_1.__decorate([
        Action(OrdersSetOrderCloneModalOpening),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersSetOrderCloneModalOpening]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "setOrderCloneModalOpening", null);
    tslib_1.__decorate([
        Action(OrdersCheckNoOrders),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Observable)
    ], OrdersState.prototype, "checkNoOrders", null);
    tslib_1.__decorate([
        Action(OrdersSetOrderCloneCourtesyModalOpening),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersSetOrderCloneCourtesyModalOpening]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "setOrderCloneCourtesyModalOpening", null);
    tslib_1.__decorate([
        Action(OrdersChangeListFilters),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersChangeListFilters]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrdersState.prototype, "changeListFilters", null);
    tslib_1.__decorate([
        Action(OrdersNewOrderFromCart),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Observable)
    ], OrdersState.prototype, "newOrderFromCart", null);
    tslib_1.__decorate([
        Action(OrdersDownloadPdf),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OrdersDownloadPdf]),
        tslib_1.__metadata("design:returntype", Observable)
    ], OrdersState.prototype, "downloadPdf", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], OrdersState, "selectedDetailOrderWithFilteredProducts", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], OrdersState, "selectedDetailOrderProductsFilter", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], OrdersState, "orderCloneCandidateId", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], OrdersState, "selectedDetailOrderId", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], OrdersState, "isOrderCloneModalOpen", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], OrdersState, "isOrderCloneCourtesyModalOpen", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], OrdersState, "ordersCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], OrdersState, "list", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], OrdersState, "listViewModel", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], OrdersState, "listFiltered", null);
    tslib_1.__decorate([
        Selector([ProductsState]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], OrdersState, "listViewModelFiltered", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Array)
    ], OrdersState, "filteringOrderStates", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Number)
    ], OrdersState, "filteringPartialOrderNumberId", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], OrdersState, "isInHttpProcessing", null);
    OrdersState = OrdersState_1 = tslib_1.__decorate([
        State({
            name: 'orders',
            defaults: ordersStateModelDefaults,
        }),
        tslib_1.__metadata("design:paramtypes", [Injector,
            Store,
            GraphqlSubscriptionsService,
            InsertOrdersHeaderAndRowsAndClearCartGQL,
            OrderPdfGQL])
    ], OrdersState);
    return OrdersState;
}());
export { OrdersState };
