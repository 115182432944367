import * as tslib_1 from "tslib";
import { FocusMonitor } from '@angular/cdk/a11y';
import { DOWN_ARROW, ENTER, UP_ARROW } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import { InputBoolean, NzInputNumberComponent } from 'ng-zorro-antd';
// Tweaked version of NzInputNumberComponent, used to accomodate commas instead of dots,
// formatting with 3 decimal places,
// autoselect on focus,
// accepts an id to be referentiable,
// add event of enter being pressed,
// put the minimum when blur on empty input,
// skip focus on step input,
// go for multiple of nzStep,
// adding unit of measure,
// and to accept only digit, comma and minus character
var CommaInputNumberComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommaInputNumberComponent, _super);
    function CommaInputNumberComponent(elementRef, renderer, cdr, focusMonitor) {
        var _this = _super.call(this, elementRef, renderer, cdr, focusMonitor) || this;
        _this.nzSize = 'default';
        _this.nzMin = -Infinity;
        _this.nzMax = Infinity;
        _this.nzPlaceHolder = '';
        _this.nzStep = 1;
        _this.nzDisabled = false;
        _this.nzAutoFocus = false;
        _this.enterPressed = new EventEmitter();
        return _this;
    }
    CommaInputNumberComponent.prototype.onFocus = function () {
        var _this = this;
        this.isFocused = true;
        // Thank you Safari
        setTimeout(function () {
            _this.inputElement.nativeElement.setSelectionRange(0, 99);
        }, 5);
    };
    CommaInputNumberComponent.prototype.onModelChange = function (value) {
        // value = !value && value !== '0' ? this.nzMin.toString() : value;
        this.actualValue = value
            .trim()
            .replace(/\./g, ',')
            .replace(/[^\d\,-]+/g, '');
        this.inputElement.nativeElement.value = this.actualValue;
    };
    // '1,'  => not complete numbers
    CommaInputNumberComponent.prototype.isNotCompleteNumber = function (num) {
        return (isNaN(this.replaceCommaToDotIfString(num)) ||
            num === '' ||
            num === null ||
            !!(num && num.toString().indexOf(',') === num.toString().length - 1));
    };
    CommaInputNumberComponent.prototype.getValidValue = function (value) {
        value = this.replaceCommaToDotIfString(value);
        var val = parseFloat(value);
        // https://github.com/ant-design/ant-design/issues/7358
        if (isNaN(val)) {
            return value;
        }
        if (val < this.nzMin) {
            val = this.nzMin;
        }
        if (val > this.nzMax) {
            val = this.nzMax;
        }
        return val;
    };
    CommaInputNumberComponent.prototype.setValue = function (value, emit) {
        if (!value && value !== 0)
            value = this.nzMin;
        value = this.getAsThreeDigitsNumber(value);
        var valueMinDifference = value - this.nzMin;
        // Avoid the floating point number precision JS problem
        // with 3 fractions digits precision
        var unNormalizedPart = parseFloat(((Math.ceil(valueMinDifference * 1000) % Math.ceil(this.nzStep * 1000)) /
            1000).toFixed(3));
        value = value - unNormalizedPart;
        if (emit && "" + this.value !== "" + value) {
            this.onChange(value);
        }
        this.value = value;
        this.actualValue = value;
        var valueToFixed = value || value === 0 ? value.toFixed(3) : '';
        var displayValue = valueToFixed.replace(/\./g, ',');
        this.displayValue = displayValue;
        this.inputElement.nativeElement.value = displayValue;
        this.disabledUp = this.disabledDown = false;
        if (value || value === 0) {
            var val = Number(value);
            if (val >= this.nzMax) {
                this.disabledUp = true;
            }
            if (val <= this.nzMin) {
                this.disabledDown = true;
            }
        }
    };
    CommaInputNumberComponent.prototype.onKeyDown = function (e) {
        // tslint:disable-next-line: deprecation
        if (e.code === 'ArrowUp' || e.keyCode === UP_ARROW) {
            var ratio = this.getRatio(e);
            this.up(e, ratio);
            this.stop();
            // tslint:disable-next-line: deprecation
        }
        else if (e.code === 'ArrowDown' || e.keyCode === DOWN_ARROW) {
            var ratio = this.getRatio(e);
            this.down(e, ratio);
            this.stop();
            // tslint:disable-next-line: deprecation
        }
        else if (e.keyCode === ENTER) {
            this.setValidateValue();
            this.enterPressed.emit();
        }
    };
    CommaInputNumberComponent.prototype.downWithoutFocus = function (e, ratio) {
        this.step('down', e, ratio);
    };
    CommaInputNumberComponent.prototype.upWithoutFocus = function (e, ratio) {
        this.step('up', e, ratio);
    };
    CommaInputNumberComponent.prototype.getAsThreeDigitsNumber = function (num) {
        var numAsString = num.toString();
        var pointIndex = numAsString.indexOf('.');
        if (pointIndex < 0)
            return num;
        var numAsThreeDigitsString = numAsString.slice(0, pointIndex + 4);
        return parseFloat(numAsThreeDigitsString);
    };
    CommaInputNumberComponent.prototype.replaceCommaToDotIfString = function (value) {
        if (typeof value === 'string')
            value = value.replace(/,/g, '.');
        return value;
    };
    tslib_1.__decorate([
        InputBoolean(),
        tslib_1.__metadata("design:type", Object)
    ], CommaInputNumberComponent.prototype, "nzDisabled", void 0);
    tslib_1.__decorate([
        InputBoolean(),
        tslib_1.__metadata("design:type", Object)
    ], CommaInputNumberComponent.prototype, "nzAutoFocus", void 0);
    return CommaInputNumberComponent;
}(NzInputNumberComponent));
export { CommaInputNumberComponent };
