<ng-container
  *ngTemplateOutlet="enabled ? enabledTemplate : disabledTemplate"
></ng-container>

<ng-template #disabledTemplate>
  <div
    class="cart-button-container"
    nz-popover
    [nzContent]="disabledPopoverContentTemplate"
    nzPlacement="bottom"
  >
    <button
      [disabled]="true"
      [routerLink]="['/cart']"
      nz-button
      nzSize="large"
      nzType="primary"
      nz-popover
      gtagEvent
      trackOn="click"
      action="procedi_all_acquisto"
      category="ordini"
    >
      <i class="cart-icon" nz-icon nzType="shopping-cart"></i
      ><span class="cart-cta">{{
        'translate.procedi_all_acquisto' | translate
      }}</span>
    </button>
  </div>
</ng-template>

<ng-template #enabledTemplate>
  <div class="cart-button-container">
    <button [routerLink]="['/cart']" nz-button nzSize="large" nzType="primary">
      <i class="cart-icon" nz-icon nzType="shopping-cart"></i
      ><span class="cart-cta">{{
        'translate.procedi_all_acquisto' | translate
      }}</span>
    </button>
  </div>
</ng-template>

<ng-template #disabledPopoverContentTemplate>
  <div class="cart-button-disabled-popover-content">
    <span>{{ 'translate.carrello_vuoto_2' | translate }}</span>
  </div>
</ng-template>
