import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductsModule } from '../products/products.module';
import { CartExtrasComponent } from './components/cart-extras/cart-extras.component';
import { CartProductsListComponent } from './components/cart-products-list/cart-products-list.component';
import { CartContainerComponent } from './containers/cart-container/cart-container.component';

@NgModule({
  declarations: [
    CartContainerComponent,
    CartProductsListComponent,
    CartExtrasComponent
  ],
  imports: [SharedModule, ProductsModule]
})
export class CartModule {}
