import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ProductsBeingAddedCodesMap } from 'src/app/features/cart/types/products-being-added-codes.map';
import { CommaInputNumberComponent } from 'src/app/shared/components/comma-input-number/comma-input-number.component';
import { isMobileCheck } from 'src/app/shared/helpers/is-mobile-check';
import { ProductOverviewModel } from '../../types/product-overview.model';
import { ProductQuantityModel } from '../../types/product-quantity.model';
import { ProductsQuantitiesModel } from '../../types/products-quantities.model';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsListComponent implements OnInit, OnChanges {
  @Input() imageBaseUrl: string;
  @Input() dataSheetBaseUrl: string;
  @Input() list: ProductOverviewModel[];
  @Input() quantities: ProductsQuantitiesModel;
  @Input() cartQuantities: ProductsQuantitiesModel;
  @Input() addProductDisabledCodes: ProductsBeingAddedCodesMap;

  @Output() quantityChange = new EventEmitter<ProductQuantityModel>();
  @Output() productAdd = new EventEmitter<number>();

  @ViewChildren(CommaInputNumberComponent)
  commaInputNumberComponents: QueryList<CommaInputNumberComponent>;

  lastHoveredAddIconProductCode: number;
  virtualScrollHeight: number;

  isMobile = isMobileCheck();

  setFocusOnCommaInputNumber(productCode: number): void {
    const commaInputNumberToFocus = this.commaInputNumberComponents.find(
      (commaInputNumber) => commaInputNumber.id === productCode
    );

    // tslint:disable-next-line: no-unused-expression
    commaInputNumberToFocus && commaInputNumberToFocus.focus();
  }

  handleProductAdd(productCode: number): void {
    if (!this.addProductDisabledCodes[productCode])
      this.productAdd.emit(productCode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.list || !changes.list.isFirstChange()) this.handleResize();
  }

  ngOnInit(): void {
    this.handleResize();
  }

  randomNumber() {
    return Math.floor(Math.random() * 10000 + 1);
  }

  @HostListener('window:resize')
  handleResize() {
    const maxHeight =
      (this.isMobile ? screen.availHeight : window.innerHeight) - 396;

    this.virtualScrollHeight = Math.min(this.list.length * 46, maxHeight);
  }
}
