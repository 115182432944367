import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { en_US, it_IT, NzI18nService } from 'ng-zorro-antd';
import { merge, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslateManagerService {
  public language$ = merge(
    of({ lang: this.getCurrentLanguage() }),
    this.translateService.onLangChange
  );

  public languageLabel$ = this.language$.pipe(
    switchMap(({ lang }: any) => of(lang === 'en' ? 'Italiano' : 'English'))
  );

  public isEnglishLanguage$ = this.language$.pipe(
    map(({ lang }: any) => lang === 'en')
  );

  constructor(
    public translateService: TranslateService,
    private nzI18nService: NzI18nService
  ) {}

  setLanguage(): Observable<any> {
    const language = this.getCurrentLanguage();

    localStorage.setItem('language', language);
    this.nzI18nService.setLocale(language === 'en' ? en_US : it_IT);

    return this.translateService.use(language);
  }

  changeLanguage(): Observable<any> {
    const currentLanguage = localStorage.getItem('language');

    localStorage.setItem('language', currentLanguage === 'en' ? 'it' : 'en');

    return this.setLanguage();
  }

  private getCurrentLanguage(): string {
    return (
      localStorage.getItem('language') ||
      this.translateService.getBrowserLang() ||
      'it'
    );
  }
}
