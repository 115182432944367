import { Router } from '@angular/router';
import { Actions, getActionTypeFromInstance, ofActionCompleted, Store } from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { tap } from 'rxjs/operators';
import { CartCloneOrder, CartSetOrderSubmitModalOpening } from 'src/app/features/cart/state/cart.actions';
import { OrdersNewOrderFromCart } from 'src/app/features/orders/state/orders.actions';
import { CoreState } from '../core.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "angular-gtag";
import * as i3 from "@angular/router";
var AnalyticsLoggingHandler = /** @class */ (function () {
    function AnalyticsLoggingHandler(actions$, gtag, store, router) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.router = router;
        this.actions$
            .pipe(ofActionCompleted(CartSetOrderSubmitModalOpening, CartCloneOrder, OrdersNewOrderFromCart), tap(function (_a) {
            var result = _a.result, action = _a.action;
            // Errors are already logged in the analytics
            // from the error-logging handler,
            // while for canceled actions (usually because of compulsive click)
            // we are not interested in logging
            if (result.error || result.canceled)
                return;
            gtag.event(getActionTypeFromInstance(action), {
                event_category: 'traces',
                event_label: "Customer: " + _this.store.selectSnapshot(CoreState.currentCustomerCode) + " - " + JSON.stringify(action)
            });
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    AnalyticsLoggingHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsLoggingHandler_Factory() { return new AnalyticsLoggingHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.Gtag), i0.ɵɵinject(i1.Store), i0.ɵɵinject(i3.Router)); }, token: AnalyticsLoggingHandler, providedIn: "root" });
    return AnalyticsLoggingHandler;
}());
export { AnalyticsLoggingHandler };
