<cdk-virtual-scroll-viewport
  [itemSize]="itemSize"
  [class.empty]="!list.length"
  [minBufferPx]="1200"
  [maxBufferPx]="1200"
  [style.height]="virtualScrollHeight + 'px'"
>
  <nz-list>
    <nz-list-item
      (click)="isMobile ? null : setFocusOnCommaInputNumber(product.code)"
      [nzActions]="[quantityChangeActionTemplate]"
      *cdkVirtualFor="let product of list; last as isLast"
    >
      <div class="product__image-wrapper">
        <ng-container
          *ngTemplateOutlet="
            product.image_medium
              ? pictureTemplate
              : pictureIconDisabledTemplate;
            context: { $implicit: product.image_medium }
          "
        ></ng-container>
      </div>

      <nz-list-item-meta
        class="product__header"
        [nzTitle]="product.code + ' - ' + product.description"
        [nzDescription]="pricesTemplate"
      >
      </nz-list-item-meta>

      <ng-template #pricesTemplate>
        <ng-container *ngIf="product.price_rel[0] as priceInfos">
          <div class="product__prices">
            <!-- <span *ngIf="priceInfos.discount || priceInfos.offer" class="product__price product__price--initial">{{
              priceInfos.price | currency: 'EUR':'symbol':'1.5-5' }}</span>

            <div *ngIf="priceInfos.discount" class="tag-blue" nz-tooltip nzTitle="{{ 'translate.sconto' | translate }}">
              <span>-{{ priceInfos.discount }}%</span>
            </div> -->

            <!-- <div *ngIf="
                priceInfos.offer &&
                priceInfos.offer_to &&
                priceInfos.offerIsValid
              " class="tag-green" nz-tooltip [nzTitle]="
                ('translate.offerta_valida_fino' | translate) +
                ' ' +
                (priceInfos.offer_to | date: 'd MMMM')
              ">
              <span>-{{ priceInfos.offer }}%</span>
            </div> -->

            <!-- <span class="product__price">{{
              (priceInfos.discount || priceInfos.offer ? ': ' : '') +
              (priceInfos.price_discount | currency: 'EUR':'symbol':'1.5-5')
              }}</span> -->

            <div class="product__remove">
              <button
                class="product__remove-cta"
                [class.disabled]="addProductDisabledCodes[product.code]"
                (click)="handleProductRemove(product.code)"
              >
                <i
                  nz-icon
                  nz-tooltip
                  nzTitle=" {{ 'translate.rimuovi' | translate }}"
                  nzType="close-circle"
                  class="icon icon--remove"
                  nzTheme="outline"
                >
                </i>
                <span>
                  {{ 'translate.rimuovi' | translate }}
                </span>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #quantityChangeActionTemplate>
        <app-comma-input-number
          [id]="product.code"
          [skipFocusOnStep]="isMobile"
          nzSize="small"
          [fixHeight]="isSmallWidth && !isLast"
          [nzDisabled]="addProductDisabledCodes[product.code]"
          [unitOfMeasure]="product.unit_of_measure"
          [ngModel]="quantities[product.code]"
          (ngModelChange)="
            quantityChange.emit({ productCode: product.code, quantity: $event })
          "
          nzPrecision="3"
          [nzMin]="product.minQuantity"
          [nzStep]="product.multiple_value || 1"
        ></app-comma-input-number>
        <!-- <div class="product__price product__price--total">
          <label class="product__price-label">
            {{ 'translate.importo' | translate }}:
          </label>
          <span class="product__price-value">
            {{
            productQuantitiesTotal[product.code]
            ? (productQuantitiesTotal[product.code]
            | currency: 'EUR':'symbol':'1.2-2')
            : '--'
            }}

          </span>

        </div> -->
      </ng-template>
    </nz-list-item>

    <nz-empty
      *ngIf="!list.length"
      nzNotFoundContent="{{
        'translate.nessun_prodotto_presente_nel_carrello' | translate
      }}"
    ></nz-empty>
  </nz-list>
</cdk-virtual-scroll-viewport>

<ng-template #pictureTemplate let-imagePath>
  <img
    alt="{{ 'translate.immagine_del_prodotto' | translate }}"
    class="loading-background"
    [src]="imageBaseUrl + imagePath"
  />
</ng-template>

<ng-template #pictureIconDisabledTemplate>
  <i
    nz-icon
    nz-tooltip
    nzTitle="{{ 'translate.immagine_non_disponibile' | translate }}"
    nzType="picture"
    class="product__image product__image--disabled"
    nzTheme="outline"
  >
  </i>
</ng-template>
