import * as tslib_1 from "tslib";
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CartState } from '../state/cart.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var EmptyCartCheckResolve = /** @class */ (function () {
    function EmptyCartCheckResolve(store) {
        this.store = store;
    }
    EmptyCartCheckResolve.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cartProductsCount;
            return tslib_1.__generator(this, function (_a) {
                cartProductsCount = this.store.selectSnapshot(CartState.productsCount);
                if (cartProductsCount < 1) {
                    this.store.dispatch(new Navigate(['/']));
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, true];
            });
        });
    };
    EmptyCartCheckResolve.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmptyCartCheckResolve_Factory() { return new EmptyCartCheckResolve(i0.ɵɵinject(i1.Store)); }, token: EmptyCartCheckResolve, providedIn: "root" });
    return EmptyCartCheckResolve;
}());
export { EmptyCartCheckResolve };
