import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductsModule } from '../products/products.module';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { OrdersFiltersComponent } from './components/orders-filters/orders-filters.component';
import { OrdersListComponent } from './components/orders-list/orders-list.component';
import { OrdersContainerComponent } from './containers/orders-container/orders-container.component';

@NgModule({
  declarations: [
    OrdersContainerComponent,
    OrdersListComponent,
    OrdersFiltersComponent,
    OrderDetailComponent
  ],
  imports: [SharedModule, ProductsModule]
})
export class OrdersModule {}
