import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { interval, Subscription } from 'rxjs';
import { CoreState } from './state/core.state';

@Component({
  selector: 'app-root',
  template: `
    <ngx-loading-bar color="#00b1e6"></ngx-loading-bar>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private gtag: Gtag,
    private router: Router,
    private store: Store,
    private swUpdate: SwUpdate
  ) {
    const updatesAvailable = this.swUpdate.available;

    if (this.swUpdate.isEnabled) {
      // Required to enable updates on Windows and ios.
      this.swUpdate.activateUpdate().then();

      interval(30000).subscribe(() => {
        this.swUpdate.checkForUpdate().then(() => {
          //console.log('checking for updates');
        });
      });
    }

    this.subscription.add(
      updatesAvailable.subscribe(async (evt: UpdateAvailableEvent) => {
        console.log('UpdateService: versionUpdates', evt);
        await this.swUpdate.activateUpdate();
        if (
          confirm(`Nuova versione disponibile. Caricare l'ultima versione?`)
        ) {
          window.location.reload();
        }
      })
    );
  }

  ngOnInit() {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.gtag.event(event.urlAfterRedirects, {
            event_category: 'traces',
            event_label: `Customer: ${this.store.selectSnapshot(
              CoreState.currentCustomerCode
            )} - ${JSON.stringify(event)}`,
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
