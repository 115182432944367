
    <i nz-icon>
      <svg viewBox="214 -92.1 1024 1024">
        <path
          fill="#595959"
          d="M956.4,729.6H443.3l25.8-52.5l428-0.8c14.5,0,26.9-10.3,29.4-24.6l59.2-331.8c1.6-8.7-0.8-17.6-6.5-24.5
          c-5.7-6.8-14.1-10.7-22.9-10.7l-544.5-1.8l-4.6-21.8c-3-14-15.6-24.1-29.8-24.1H244.4c-16.8,0-30.4,13.6-30.4,30.4
          s13.6,30.4,30.4,30.4h108.5l20.3,96.7l50.1,242.3L358.8,742c-6.8,9.2-7.9,21.5-2.6,31.7c5.2,10.2,15.6,16.7,27.2,16.7h54.1
          c-11.5,15.3-17.7,34-17.7,53.1c0,48.7,39.7,88.4,88.4,88.4c48.7,0,88.4-39.7,88.4-88.4c0-19.2-6.4-37.9-17.7-53.1h138.8
          c-11.5,15.3-17.7,34-17.7,53.1c0,48.7,39.7,88.4,88.4,88.4c48.7,0,88.4-39.6,88.4-88.4c0-19.2-6.4-37.9-17.7-53.1h97.6
          c16.7,0,30.4-13.6,30.4-30.4C986.8,743.2,973.2,729.6,956.4,729.6L956.4,729.6z M424.6,342.9l496,1.7l-48.6,272l-389.6,0.7
          L424.6,342.9z M508.1,870.7c-15,0-27.3-12.2-27.3-27.3s12.2-27.3,27.3-27.3s27.3,12.2,27.3,27.3
          C535.3,858.6,523.2,870.7,508.1,870.7z M788.2,870.7c-15,0-27.3-12.2-27.3-27.3s12.2-27.3,27.3-27.3s27.3,12.2,27.3,27.3
          C815.4,858.6,803.3,870.7,788.2,870.7z"
        />
        <g>
          <path
            fill="#52C41A"
            d="M905.5-92.1c-181.7,0-329,147.4-329,329s147.4,329,329,329s329-147.4,329-329S1087.3-92.1,905.5-92.1z
		 M905.5,510.2c-150.9,0-271.9-122.3-271.9-273.3s121-273.3,271.9-273.3S1178.8,86,1178.8,236.9S1056.4,510.2,905.5,510.2z"
          />
          <path
            fill="#F6FFED"
            d="M905.5-36.3C754.6-36.3,632.2,86,632.2,236.9s122.3,273.3,273.3,273.3s273.3-122.5,273.3-273.3
            S1056.4-36.3,905.5-36.3z M1047.5,129.5L892.8,344c-7.5,10.5-22.1,12.9-32.5,5.3c-2.1-1.5-3.8-3.3-5.3-5.3l-91.5-127
            c-2.7-3.8,0-9.3,4.8-9.3h34.4c7.5,0,14.6,3.7,19,9.7L874,290l115.5-160.1c4.4-6.2,11.5-9.7,19-9.7h34.4
            C1047.7,120.2,1050.4,125.5,1047.5,129.5z"
          />
          <path
            fill="#52C41A"
            d="M1042.9,120.2h-34.4c-7.5,0-14.6,3.6-19,9.7L873.9,290l-52.4-72.6c-4.4-6.2-11.5-9.7-19-9.7h-34.4
		c-4.8,0-7.5,5.5-4.8,9.3l91.5,126.9c7.5,10.5,22.1,12.9,32.5,5.3c2.1-1.5,3.8-3.3,5.3-5.3l154.9-214.4
		C1050.4,125.5,1047.7,120.2,1042.9,120.2z"
          />
        </g>
      </svg>
    </i>
  