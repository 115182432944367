import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, skip, tap } from 'rxjs/operators';
import { OrdersUpdateList } from 'src/app/features/orders/state/orders.actions';
import { ProductsSetList } from 'src/app/features/products/state/products.actions';
import { OrdersUpdatesGQL, ProductsUpdatesGQL } from 'src/app/graphql/graphql';
import { isKnownJwtError } from 'src/app/shared/helpers/is-known-jwt-error';
import { OnlineCheckService } from 'src/app/shared/services/online-check.service';

@Injectable({ providedIn: 'root' })
export class GraphqlSubscriptionsService {
  skipNextOrderUpdateBecauseItWasByUser: boolean;

  currentOrdersSubscription: Subscription;
  currentProductsSubscription: Subscription;

  constructor(
    private store: Store,
    private ordersUpdatesGQL: OrdersUpdatesGQL,
    private productsUpdatesGQL: ProductsUpdatesGQL,
    private onlineCheckService: OnlineCheckService
  ) {}

  init(customerCode: string): void {
    // In case is a reinitialization (probably on customer change)
    this.skipNextOrderUpdateBecauseItWasByUser = false;

    // tslint:disable-next-line: no-unused-expression
    this.currentOrdersSubscription &&
      this.currentOrdersSubscription.unsubscribe();

    // tslint:disable-next-line: no-unused-expression
    this.currentProductsSubscription &&
      this.currentProductsSubscription.unsubscribe();

    this.subscribeToOrdersUpdates(customerCode);
    this.subscribeToProductsUpdates(customerCode);
  }

  private subscribeToOrdersUpdates(customerCode: string): void {
    this.currentOrdersSubscription = this.ordersUpdatesGQL
      .subscribe({ customerCode })
      .pipe(
        skip(1), // First emission is the same data present in the core query
        tap(({ data }) => {
          if (!this.skipNextOrderUpdateBecauseItWasByUser)
            this.store.dispatch(new OrdersUpdateList(data.view_orders_headers));

          this.skipNextOrderUpdateBecauseItWasByUser = false;
        }),
        catchError(error => {
          this.subscribeToOrdersUpdates(customerCode);
          return this.refreshPageIfIsKnownJwtError(error);
        })
      )
      .subscribe();
    // No need for unsubscribe, as it is application wide,
    // we are saving the reference to the subscription to eventually unsubscribe
    // on reinitialization
  }

  private subscribeToProductsUpdates(customerCode: string): void {
    this.currentProductsSubscription = this.productsUpdatesGQL
      .subscribe({ customerCode })
      .pipe(
        skip(1), // First emission is the same data present in the core query
        tap(({ data }) =>
          this.store.dispatch(new ProductsSetList(data.products))
        ),
        catchError(error => {
          this.subscribeToProductsUpdates(customerCode);
          return this.refreshPageIfIsKnownJwtError(error);
        })
      )
      .subscribe();
    // No need for unsubscribe, as it is application wide,
    // we are saving the reference to the subscription to eventually unsubscribe
    // on reinitialization
  }

  private refreshPageIfIsKnownJwtError(error: any): Observable<any> {
    // Very unlucky scenario, when a user went offline, the token expired
    // and the subscription isn't able to use the new one because of a race condition
    // We will just reload the page in this case
    if (isKnownJwtError(error))
      this.onlineCheckService
        .getOnlineNotifier$()
        .toPromise()
        .then(_ => window.location.reload());

    return throwError(error);
  }
}
