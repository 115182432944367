<section class="filters">
  <button class="filters__toggle" nz-button [ngClass]="!isCollapsed ? '-active' : null" (click)="toggleCollapsed()">
    <span class="filters__toggle-label">{{ 'translate.filtri' | translate }}</span>
    <span nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
  </button>
  <div class="filters__content" [ngClass]="!isCollapsed ? '-active' : null">


    <div class="products-filters-container">
      <div class="products-filter products-filter--groups" [class.extra-small]="areExtraSmallFilters">
        <label class="filter-label">{{ 'translate.categorie' | translate }}</label>
        <nz-select class="product-groups-select" nzMode="multiple"
          nzPlaceHolder="{{ 'translate.tutte_le_categorie' | translate }}" [nzMaxTagCount]="maxTagCount"
          [nzMaxTagPlaceholder]="maxTagCount ? null : shortMaxTagPlaceholder" nzAllowClear
          [ngModel]="filteringGroupsCodes" (ngModelChange)="groupsFilterChange.emit($event)">
          <nz-option *ngFor="let group of groups" [nzLabel]="group.description" [nzValue]="group.code"></nz-option>
        </nz-select>
      </div>

      <div class="products-filter products-filter--code" [class.extra-small]="areExtraSmallFilters">
        <label class="filter-label">{{
          'translate.cerca_per_codice' | translate
          }}</label>
        <input nz-input placeholder="{{ 'translate.codice_prodotto' | translate }}" appDigitOnly
          [nzAutocomplete]="codeFilterAutocomplete" [class.clearable]="filteringPartialProductCode"
          [ngModel]="filteringPartialProductCode" (ngModelChange)="productCodeFilterChange.emit($event)"
          (input)="productCodeFilterChange.emit($event.target?.value)" />
        <i *ngIf="filteringPartialProductCode" nz-icon class="clear" nzType="close-circle" nzTheme="fill"
          (click)="productCodeFilterChange.emit(null)"></i>
        <nz-autocomplete [nzDataSource]="list | pluck: 'code'" nzBackfill #codeFilterAutocomplete>
        </nz-autocomplete>
      </div>

      <div class="products-filter products-filter--description" [class.small]="haveSmallDescriptionFilter"
        [class.extra-small]="areExtraSmallFilters">
        <label class="filter-label">{{
          'translate.cerca_per_descrizione' | translate
          }}</label>
        <input nz-input placeholder="{{ 'translate.descrizione_prodotto' | translate }}"
          [nzAutocomplete]="descriptionAutocomplete" [class.clearable]="filteringPartialDescription"
          [ngModel]="filteringPartialDescription" (ngModelChange)="descriptionFilterChange.emit($event)"
          (input)="descriptionFilterChange.emit($event.target?.value)" />
        <i *ngIf="filteringPartialDescription" nz-icon class="clear" nzType="close-circle" nzTheme="fill"
          (click)="descriptionFilterChange.emit(null)"></i>
        <nz-autocomplete [nzDataSource]="list | pluck: 'description'" nzBackfill #descriptionAutocomplete>
        </nz-autocomplete>
      </div>
    </div>

  </div>

</section>



<ng-template #shortMaxTagPlaceholder let-selectedTags>{{ selectedTags.length }}
  {{
  selectedTags.length === 1
  ? ('translate.selezionata' | translate)
  : ('translate.selezionate' | translate)
  }}</ng-template>