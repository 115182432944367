<div class="ant-table__wrapper frascheri-products__wrapper" *ngIf="!isMobile">
  <nz-table
    class="frascheri-products"
    nzSize="small"
    [nzData]="list"
    [nzNoResult]="noProductsInfoTemplate"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    nzVirtualScroll
    [nzVirtualItemSize]="45"
    [nzVirtualMinBufferPx]="1200"
    [nzVirtualMaxBufferPx]="1200"
    [nzScroll]="{ y: virtualScrollHeight + 'px' }"
    [class.is-mobile]="isMobile"
  >
    <thead>
      <tr>
        <th class="product">
          <span class="product-label">{{
            'translate.prodotto' | translate
          }}</span>
        </th>
        <!-- <th class="price">
          <span class="price-label">{{
            'translate.prezzo_finale' | translate
          }}</span>
        </th> -->
        <th class="quantity">
          <span class="quantity__label">{{
            'translate.quantita' | translate
          }}</span>
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-template nz-virtual-scroll let-product>
        <tr
          (click)="isMobile ? null : setFocusOnCommaInputNumber(product.code)"
        >
          <td class="product-cell">
            <ng-container
              *ngTemplateOutlet="
                product.image_medium
                  ? pictureIconTemplate
                  : pictureIconDisabledTemplate
              "
            ></ng-container>

            <ng-template #pictureIconTemplate>
              <i
                nz-popover
                [nzTrigger]="isMobile ? 'click' : 'hover'"
                nzPlacement="topLeft"
                [nzContent]="picturePopoverTemplate"
                nz-icon
                nzType="picture"
                class="picture-icon"
                nzTheme="outline"
              >
              </i>
            </ng-template>

            <ng-template #picturePopoverTemplate>
              <img
                alt="{{ 'translate.immagine_prodotto' | translate }}"
                class="loading-background"
                [src]="
                  imageBaseUrl +
                  product.image_medium +
                  '?cache=' +
                  randomNumber()
                "
              />
            </ng-template>

            <nz-divider nzType="vertical"></nz-divider>

            <ng-container
              *ngTemplateOutlet="
                product.data_sheet
                  ? dataSheetIconTemplate
                  : dataSheetIconDisabledTemplate;
                context: { $implicit: product.data_sheet }
              "
            >
            </ng-container>

            <nz-divider nzType="vertical"></nz-divider>

            <span class="product__title">
              <span class="product__code">{{ product.code }} </span> -
              {{ product.description }}
            </span>
          </td>

          <!-- <td class="price-cell">
            <ng-container *ngIf="product.price_rel[0] as priceInfos">
              <div
                *ngIf="priceInfos.discount"
                class="tag-blue"
                nz-tooltip
                [nzTitle]="'translate.sconto' | translate"
                [class.extra-margin]="
                  priceInfos.offer &&
                  priceInfos.offer_to &&
                  priceInfos.offerIsValid
                "
              >
                <span
                  ><span class="full-discount-description"
                    >{{ 'translate.sconto' | translate }}:</span
                  ><span class="partial-discount-description">-</span
                  >{{ priceInfos.discount }}%</span
                >
              </div>

              <div
                *ngIf="
                  priceInfos.offer &&
                  priceInfos.offer_to &&
                  priceInfos.offerIsValid
                "
                class="tag-green"
                nz-tooltip
                [nzTitle]="
                  ('translate.offerta_valida_fino' | translate) +
                  (priceInfos.offer_to | date: 'd MMMM')
                "
              >
                <span>
                  <span class="full-offer-description"
                    >{{ 'translate.offerta' | translate }}: </span
                  ><span class="partial-discount-description">-</span
                  >{{ priceInfos.offer }}%</span
                >
              </div>
              <span
                class="price-value"
                [class.extended-margin]="priceInfos.price_discount < 10"
                >{{
                  priceInfos.price_discount | currency: 'EUR':'symbol':'1.5-5'
                }}</span
              >
            </ng-container>
          </td> -->

          <td class="quantity-cell">
            <div class="product__cta">
              <div class="product__add-items">
                <ng-container
                  *ngTemplateOutlet="
                    cartQuantities[product.code] ||
                    addProductDisabledCodes[product.code]
                      ? inputNumberDisabledTemplate
                      : inputNumberTemplate;
                    context: { $implicit: product }
                  "
                ></ng-container>
              </div>
              <nz-divider
                nzType="vertical"
                class="quantity-divider"
              ></nz-divider>
              <div class="product__add-to-cart">
                <ng-container
                  *ngTemplateOutlet="
                    cartQuantities[product.code]
                      ? checkIconTemplate
                      : addIconTemplate;
                    context: { $implicit: product.code }
                  "
                ></ng-container>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>

<div
  class="ant-table__wrapper frascheri-products__wrapper -mobile"
  *ngIf="isMobile"
>
  <nz-table
    class="frascheri-products"
    nzSize="small"
    [nzData]="list"
    [nzNoResult]="noProductsInfoTemplate"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [class.is-mobile]="isMobile"
  >
    <thead>
      <tr>
        <th class="product">
          <span>{{ 'translate.prodotto' | translate }}</span>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let product of list"
        (click)="isMobile ? null : setFocusOnCommaInputNumber(product.code)"
      >
        <td class="product-cell">
          <div nz-row class="row-mobile row-mobile--title">
            <div nz-col nzSpan="12">
              <h4 class="product__title">
                <span class="product__code">{{ product.code }} </span> -
                {{ product.description }}
              </h4>
            </div>
          </div>
          <div nz-row class="row-mobile row-mobile--price">
            <div nz-col nzSpan="12">
              <ng-container *ngIf="product.price_rel[0] as priceInfos">
                <span
                  *ngIf="priceInfos.discount"
                  class="tag-blue extra-margin"
                  nz-tooltip
                  [nzTitle]="'translate.sconto' | translate"
                >
                  <span
                    ><span class="full-discount-description"
                      >{{ 'translate.sconto' | translate }}:</span
                    ><span class="partial-discount-description">-</span
                    >{{ priceInfos.discount }}%</span
                  >
                </span>

                <span
                  *ngIf="
                    priceInfos.offer &&
                    priceInfos.offer_to &&
                    priceInfos.offerIsValid
                  "
                  class="tag-green extra-margin"
                  nz-tooltip
                  [nzTitle]="
                    ('translate.offerta_valida_fino' | translate) +
                    (priceInfos.offer_to | date: 'd MMMM')
                  "
                >
                  <span>
                    <span class="full-offer-description"
                      >{{ 'translate.offerta' | translate }}: </span
                    ><span class="partial-discount-description">-</span
                    >{{ priceInfos.offer }}%</span
                  >
                </span>
                <span>{{
                  priceInfos.price_discount | currency: 'EUR':'symbol':'1.5-5'
                }}</span>
              </ng-container>
            </div>
          </div>

          <div nz-row class="row-mobile row-mobile--cta">
            <div nz-col nzSpan="12" class="product__cta">
              <div class="product__add-items">
                <ng-container
                  *ngTemplateOutlet="
                    cartQuantities[product.code] ||
                    addProductDisabledCodes[product.code]
                      ? inputNumberDisabledTemplate
                      : inputNumberTemplate;
                    context: { $implicit: product }
                  "
                ></ng-container>
              </div>
              <div class="product__add-to-cart">
                <ng-container
                  *ngTemplateOutlet="
                    cartQuantities[product.code]
                      ? checkIconTemplate
                      : addIconTemplate;
                    context: { $implicit: product.code }
                  "
                ></ng-container>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #inputNumberTemplate let-product>
  <app-comma-input-number
    [id]="product.code"
    [skipFocusOnStep]="isMobile"
    nzSize="small"
    [fixHeight]="true"
    [unitOfMeasure]="product.unit_of_measure"
    [ngModel]="quantities[product.code]"
    (ngModelChange)="
      quantityChange.emit({
        productCode: product.code,
        quantity: $event
      })
    "
    nzPrecision="3"
    [nzMin]="product.minQuantity"
    [nzStep]="product.multiple_value || 1"
    (enterPressed)="productAdd.emit(product.code)"
    class="product__quantity__mobile"
  ></app-comma-input-number>
</ng-template>

<ng-template #inputNumberDisabledTemplate let-product>
  <app-comma-input-number
    nz-tooltip
    nzPlacement="left"
    [nzTitle]="'translate.prodotto_gia_presente' | translate"
    nzSize="small"
    nzDisabled
    [fixHeight]="true"
    [unitOfMeasure]="product.unit_of_measure"
    [ngModel]="quantities[product.code]"
    nzPrecision="3"
    [nzMin]="product.minQuantity"
    class="product__quantity__mobile"
  ></app-comma-input-number>
</ng-template>

<ng-template #pictureIconDisabledTemplate>
  <i
    nz-icon
    nz-tooltip
    [nzTitle]="'translate.immagine_non_disponibile' | translate"
    nzType="picture"
    class="picture-icon disabled"
    nzTheme="outline"
  >
  </i>
</ng-template>

<ng-template #dataSheetIconTemplate let-dataSheet>
  <a [href]="dataSheetBaseUrl + dataSheet" target="_blank" download>
    <i
      nz-tooltip
      [nzTitle]="'translate.scheda_tecnica' | translate"
      nz-icon
      nzType="file-text"
      class="data-sheet-icon"
      nzTheme="outline"
    >
    </i>
  </a>
</ng-template>

<ng-template #dataSheetIconDisabledTemplate>
  <i
    nz-tooltip
    [nzTitle]="'translate.scheda_tecnica_non_disponibile' | translate"
    nz-icon
    nzType="file-text"
    class="data-sheet-icon disabled"
    nzTheme="outline"
  >
  </i>
</ng-template>

<ng-template #noProductsInfoTemplate>
  <nz-empty
    [nzNotFoundContent]="'translate.nessun_prodotto_disponibile' | translate"
  ></nz-empty>
</ng-template>

<ng-template #addIconTemplate let-productCode>
  <app-shopping-cart-add-icon
    [class.disabled]="addProductDisabledCodes[productCode]"
    [class.app-shopping-cart-add-icon-default]="!isMobile"
    [isAlternativeColor]="
      !addProductDisabledCodes[productCode] &&
      lastHoveredAddIconProductCode === productCode
    "
    (mouseover)="lastHoveredAddIconProductCode = productCode"
    (mouseleave)="lastHoveredAddIconProductCode = null"
    (click)="handleProductAdd(productCode)"
  >
  </app-shopping-cart-add-icon>
</ng-template>

<ng-template #checkIconTemplate>
  <app-shopping-cart-check-icon
    [class.app-shopping-cart-add-icon-default]="!isMobile"
    nz-tooltip
    nzPlacement="left"
    [nzTitle]="'translate.prodotto_gia_presente' | translate"
  >
  </app-shopping-cart-check-icon>
</ng-template>
