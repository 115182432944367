import { Injectable } from '@angular/core';
import {
  ProductsFiltersExtendedModel,
  ProductsFiltersModel
} from '../types/products-filters.model';

@Injectable({ providedIn: 'root' })
export class ProductsListFilterService {
  filter({
    list,
    codeKey = 'code',
    descriptionKey = 'description',
    groupCodeKey = 'code_group',
    groupsCodes,
    partialProductCode,
    partialDescription
  }: {
    list: any[];
    codeKey?: string;
    descriptionKey?: string;
    groupCodeKey?: string;
    groupsCodes: string[];
    partialProductCode: number;
    partialDescription: string;
  }): any[] {
    const listFilteredByGroupsCodes = this.getListFilteredByGroupsCodes(
      list,
      groupsCodes,
      groupCodeKey
    );

    const listFilteredByGroupCodesAndProductCode = this.getListFilteredByProductCode(
      listFilteredByGroupsCodes,
      partialProductCode,
      codeKey
    );

    const listFilteredByGroupCodesAndProductCodeAndDescription = this.getListFilteredByDescription(
      listFilteredByGroupCodesAndProductCode,
      partialDescription,
      descriptionKey
    );

    return listFilteredByGroupCodesAndProductCodeAndDescription;
  }

  getCalculatedFilters(
    filters: ProductsFiltersExtendedModel,
    originalFilteringGroupsCodes: string[],
    originalFilteringPartialProductCode: number,
    originalFilteringPartialDescription: string
  ): ProductsFiltersModel {
    const {
      filteringGroupsCodes,
      filteringPartialProductCode,
      filteringPartialDescription,
      forceFilteringPartialProductCodeValue,
      forceFilteringPartialDescriptionValue
    } = filters;

    const groupsCodes = filteringGroupsCodes || originalFilteringGroupsCodes;

    const partialProductCode = forceFilteringPartialProductCodeValue
      ? filteringPartialProductCode
      : filteringPartialProductCode || originalFilteringPartialProductCode;

    const partialDescription = forceFilteringPartialDescriptionValue
      ? filteringPartialDescription
      : filteringPartialDescription || originalFilteringPartialDescription;

    return {
      filteringGroupsCodes: groupsCodes,
      filteringPartialProductCode: partialProductCode,
      filteringPartialDescription: partialDescription
    };
  }

  private getListFilteredByGroupsCodes(
    list: any[],
    codes: string[],
    groupCodeKey: string
  ): any[] {
    return list.filter(
      product =>
        !codes || !codes.length || codes.includes(product[groupCodeKey])
    );
  }

  private getListFilteredByProductCode(
    list: any[],
    partialCode: number,
    codeKey: string
  ): any[] {
    return list.filter(product =>
      product[codeKey].toString().startsWith((partialCode || '').toString())
    );
  }

  private getListFilteredByDescription(
    list: any[],
    partialDescription: string,
    descriptionKey: string
  ): any[] {
    return list.filter(product =>
      (product[descriptionKey] || '')
        .toLowerCase()
        .includes((partialDescription || '').toLowerCase())
    );
  }
}
