import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { merge, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, delayWhen, distinctUntilChanged, first, map, skip, } from 'rxjs/operators';
import { OrdersNewOrderFromCart } from 'src/app/features/orders/state/orders.actions';
import { OrdersState } from 'src/app/features/orders/state/orders.state';
import { ProductsState } from 'src/app/features/products/state/products.state';
import { TranslateManagerService } from 'src/app/shared/services/translate-manager.service';
import { environment } from 'src/environments/environment';
import { CartChangeCustomerOrderNumber, CartChangeDateFor, CartChangeFilters, CartChangeNote, CartClearFilters, CartClearProducts, CartRemoveProduct, CartSetOrderCourtesyModalOpening, CartSetOrderSubmitModalOpening, CartSetProductsQuantities, } from '../../state/cart.actions';
import { CartState } from '../../state/cart.state';
var CartContainerComponent = /** @class */ (function () {
    function CartContainerComponent(store, translateManageService) {
        this.store = store;
        this.translateManageService = translateManageService;
        this.imageBaseUrl = environment.storageConfig.productsImagesMediumUrl;
        this.noteSubject = new Subject();
        this.customerOrderNumberSubject = new Subject();
        this.productQuantitiesSubject = new Subject();
        this.accumulatedQuantityChanges = {};
        this.subscriptions = [];
        this.subscribeToProductQuantitiesSubjectDebounceTime = 1200;
    }
    CartContainerComponent.prototype.ngOnInit = function () {
        // Other than the http request itself,
        // since we have a debounce time before the http request start
        // and we want the order confirm to be disabled meanwhile
        // we add a value of true meanwhile
        this.actionsDisabled$ = merge(this.isInCartRowsHttpProcessing$.pipe(debounceTime(100)), this.productQuantitiesSubject.pipe(map(function (_) { return true; })));
        // Take the first immediatly otherwise we don't
        // have an inital state to render.
        // Debounce after that
        this.addProductDisabledCodes$ = merge(this.productsBeingAddedCodes$.pipe(first()), this.productsBeingAddedCodes$.pipe(skip(1), debounceTime(100)));
        this.subscribeToNoteSubject();
        this.subscribeToCustomerOrderNumberSubject();
        this.subscribeToProductQuantitiesSubject();
    };
    CartContainerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        this.store.dispatch(new CartClearFilters());
    };
    CartContainerComponent.prototype.handleQuantityChange = function (_a) {
        var _b;
        var productCode = _a.productCode, quantity = _a.quantity;
        this.accumulatedQuantityChanges = tslib_1.__assign({}, this.accumulatedQuantityChanges, (_b = {}, _b[productCode] = quantity, _b));
        this.productQuantitiesSubject.next(this.accumulatedQuantityChanges);
    };
    CartContainerComponent.prototype.handleProductRemove = function (productCode) {
        this.store.dispatch(new CartRemoveProduct(productCode));
    };
    CartContainerComponent.prototype.handleDateForChange = function (dateFor) {
        this.store.dispatch(new CartChangeDateFor(dateFor));
    };
    CartContainerComponent.prototype.handleNoteChange = function (note) {
        this.noteSubject.next(note);
    };
    CartContainerComponent.prototype.handleCustomerOrderNumberChange = function (customerOrderNumber) {
        this.customerOrderNumberSubject.next(customerOrderNumber);
    };
    CartContainerComponent.prototype.handleOrderSubmit = function () {
        this.store.dispatch(new OrdersNewOrderFromCart());
    };
    CartContainerComponent.prototype.handleCartClearSubmit = function () {
        this.store.dispatch(new CartClearProducts());
    };
    CartContainerComponent.prototype.handleOrderSubmitModalOpeningRequest = function (value) {
        this.store.dispatch(new CartSetOrderSubmitModalOpening(value));
    };
    CartContainerComponent.prototype.handleOrderCourtesyModalOpeningRequest = function (value) {
        this.store.dispatch(new CartSetOrderCourtesyModalOpening(value));
        if (value === false)
            this.store.dispatch(new Navigate(['/orders']));
    };
    CartContainerComponent.prototype.handleGoToProductsListRequest = function () {
        this.store.dispatch(new CartSetOrderCourtesyModalOpening(false));
        this.store.dispatch(new Navigate(['/products']));
    };
    CartContainerComponent.prototype.handleGoToOrdersRequest = function () {
        this.store.dispatch(new CartSetOrderCourtesyModalOpening(false));
        this.store.dispatch(new Navigate(['/orders']));
    };
    CartContainerComponent.prototype.handleGroupsFilterChange = function (filteringGroupsCodes) {
        this.store.dispatch(new CartChangeFilters({ filteringGroupsCodes: filteringGroupsCodes }));
    };
    CartContainerComponent.prototype.handleProductCodeFilterChange = function (filteringPartialProductCode) {
        this.store.dispatch(new CartChangeFilters({
            filteringPartialProductCode: filteringPartialProductCode,
            forceFilteringPartialProductCodeValue: true,
        }));
    };
    CartContainerComponent.prototype.handleDescriptionFilterChange = function (filteringPartialDescription) {
        this.store.dispatch(new CartChangeFilters({
            filteringPartialDescription: filteringPartialDescription,
            forceFilteringPartialDescriptionValue: true,
        }));
    };
    CartContainerComponent.prototype.subscribeToProductQuantitiesSubject = function () {
        var _this = this;
        this.subscriptions.push(this.productQuantitiesSubject
            .pipe(debounceTime(this.subscribeToProductQuantitiesSubjectDebounceTime), 
        // Can't distinct anymore, as we need the isInCartRowsHttpProcessing$
        // to emit to have the submit order enabled back
        // distinctUntilChanged(this.productQuantitiesAreEqual()),
        delayWhen(function (productQuantities) {
            _this.accumulatedQuantityChanges = {};
            return _this.store.dispatch(new CartSetProductsQuantities(productQuantities));
        }), catchError(function (error) {
            _this.subscribeToProductQuantitiesSubject();
            return throwError(error);
        }))
            .subscribe());
    };
    CartContainerComponent.prototype.subscribeToNoteSubject = function () {
        var _this = this;
        this.subscriptions.push(this.noteSubject
            .pipe(debounceTime(500), distinctUntilChanged(), delayWhen(function (note) { return _this.store.dispatch(new CartChangeNote(note)); }), catchError(function (error) {
            _this.subscribeToNoteSubject();
            return throwError(error);
        }))
            .subscribe());
    };
    CartContainerComponent.prototype.subscribeToCustomerOrderNumberSubject = function () {
        var _this = this;
        this.subscriptions.push(this.customerOrderNumberSubject
            .pipe(debounceTime(500), distinctUntilChanged(), delayWhen(function (customerOrderNumber) {
            return _this.store.dispatch(new CartChangeCustomerOrderNumber(customerOrderNumber));
        }), catchError(function (error) {
            _this.subscribeToCustomerOrderNumberSubject();
            return throwError(error);
        }))
            .subscribe());
    };
    CartContainerComponent.prototype.productQuantitiesAreEqual = function () {
        return function (x, y) {
            var productCodes = Object.keys(x);
            return (productCodes.length !== Object.keys(y).length &&
                productCodes.every(function (k) { return x[k] === y[k]; }));
        };
    };
    tslib_1.__decorate([
        Select(ProductsState.groups),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "groups$", void 0);
    tslib_1.__decorate([
        Select(CartState.listFiltered),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "listFiltered$", void 0);
    tslib_1.__decorate([
        Select(CartState.quantities),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "quantities$", void 0);
    tslib_1.__decorate([
        Select(CartState.productQuantitiesTotal),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "productQuantitiesTotal$", void 0);
    tslib_1.__decorate([
        Select(CartState.combinedTotal),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "combinedTotal$", void 0);
    tslib_1.__decorate([
        Select(CartState.filteringGroupsCodes),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "filteringGroupsCodes$", void 0);
    tslib_1.__decorate([
        Select(CartState.filteringPartialProductCode),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "filteringPartialProductCode$", void 0);
    tslib_1.__decorate([
        Select(CartState.filteringPartialDescription),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "filteringPartialDescription$", void 0);
    tslib_1.__decorate([
        Select(CartState.dateFor),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "dateFor$", void 0);
    tslib_1.__decorate([
        Select(CartState.note),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "note$", void 0);
    tslib_1.__decorate([
        Select(CartState.customerOrderNumber),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "customerOrderNumber$", void 0);
    tslib_1.__decorate([
        Select(CartState.productsCount),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "productsCount$", void 0);
    tslib_1.__decorate([
        Select(CartState.isInCartRowsHttpProcessing),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "isInCartRowsHttpProcessing$", void 0);
    tslib_1.__decorate([
        Select(CartState.productsBeingAddedCodes),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "productsBeingAddedCodes$", void 0);
    tslib_1.__decorate([
        Select(CartState.isOrderSubmitModalOpen),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "isOrderSubmitModalOpen$", void 0);
    tslib_1.__decorate([
        Select(CartState.isOrderCourtesyModalOpen),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "isOrderCourtesyModalOpen$", void 0);
    tslib_1.__decorate([
        Select(OrdersState.isInHttpProcessing),
        tslib_1.__metadata("design:type", Observable)
    ], CartContainerComponent.prototype, "isOrderLoading$", void 0);
    return CartContainerComponent;
}());
export { CartContainerComponent };
