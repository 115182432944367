<div nz-row class="orders-container">
  <div nz-col nzXs="12" nzLg="6" nzXl="5">
    <nz-card class="orders-list-card">
      <div class="section__header">
        <h1 class="section__title">{{'translate.i_miei_ordini' | translate}}</h1>
      </div>

      <!-- Please notice that listFiltered is already a shared observable by ngxs, 
    should we extend the observable with pipe, adding side effects, 
    it will be our responsibility to cause the side effects only once -->
      <app-orders-filters [list]="listViewModelFiltered$ | async" [filteringOrderState]="filteringOrderStates$ | async"
        [filteringPartialOrderNumberId]="filteringPartialOrderNumberId$ | async"
        [orderStateLabelMap]="orderStateLabelMap" (orderNumberIdFilterChange)="handleOrderNumberIdFilterChange($event)"
        (orderStatesFilterChange)="handleOrderStatesFilterChange($event)"></app-orders-filters>

      <app-orders-list [list]="listViewModelFiltered$ | async"
        [selectedId]="(selectedDetailOrderWithFilteredProducts$ | async)?.id" [isCartLoading]="isCartLoading$ | async"
        [isOrderCloneModalVisible]="isOrderCloneModalOpen$ | async" [isOrderCloneCourtesyModalVisible]="
          isOrderCloneCourtesyModalOpen$ | async
        " [orderStateLabelMap]="orderStateLabelMap" (pdfDownloadRequest)="handlePdfDownloadRequest($event)"
        (orderRowClicked)="handleOrderRowClicked($event)" (orderCloneRequest)="handleOrderCloneRequest()"
        (goToProductsListRequest)="handleGoToProductsListRequest()" (goToCartRequest)="handleGoToCartRequest()"
        (orderCloneModalOpeningRequest)="
          handleOrderCloneModalOpeningRequest($event)
        " (orderCloneCourtesyModalOpeningRequest)="
          handleOrderCloneCourtesyModalOpeningRequest($event)
        "></app-orders-list>
    </nz-card>
  </div>
  <div nz-col nzXs="12" nzLg="6" nzXl="7">
    <app-order-detail [order]="selectedDetailOrderWithFilteredProducts$ | async" [imageBaseUrl]="imageBaseUrl"
      [cartQuantities]="cartQuantities$ | async" [productsGroups]="productsGroups$ | async"
      [productsFilters]="selectedDetailOrderProductsFilter$ | async"
      (addProductClicked)="handleAddProductClicked($event)"
      (productCodeFilterChange)="handleDetailOrderProductCodeFilterChange($event)" (productDescriptionFilterChange)="
      handleDetailOrderDescriptionFilterChange($event)
    " (productsGroupsFilterChange)="handleDetailOrderGroupsFilterChange($event)"></app-order-detail>
  </div>

</div>