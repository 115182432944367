<div class="header">
  <a class="logo" [routerLink]="['/']">
    <img [src]="logoUrl" alt="Frascheri"
  /></a>
  <ul nz-menu nzMode="horizontal">
    <li
      nz-menu-item
      [routerLink]="['/products']"
      routerLinkActive="ant-menu-item-selected"
    >
      {{ 'translate.prodotti' | translate }}
    </li>
    <ng-container
      *ngTemplateOutlet="ordersCount ? ordersTemplate : emptyOrdersTemplate"
    ></ng-container>
    <ng-template #ordersTemplate>
      <li
        nz-menu-item
        [routerLink]="['/orders']"
        routerLinkActive="ant-menu-item-selected"
      >
        {{ 'translate.i_miei_ordini' | translate }}
      </li>
    </ng-template>

    <ng-template #emptyOrdersTemplate>
      <li
        nz-menu-item
        nzDisabled
        nz-popover
        [nzContent]="emptyOrdersPopoverContentTemplate"
        nzPlacement="bottom"
      >
        {{ 'translate.i_miei_ordini' | translate }}
      </li>
    </ng-template>

    <li nz-menu-item>
      <a href="http://www.frascheriprofessionale.it/" target="_blank"
        ><i nz-icon nzType="global" nzTheme="outline" class="global-icon"></i>
        {{ 'translate.sito_professionale_link' | translate }}</a
      >
    </li>
  </ul>

  <div class="right-block">
    <div
      class="info"
      nz-popover
      [nzTitle]="infoPopoverTitleTemplate"
      [nzContent]="infoPopoverContentTemplate"
      [nzTrigger]="isMobile ? 'click' : 'hover'"
      nzPlacement="bottom"
    >
      <div class="customer">
        <div class="customer-info">
          <span class="customer-description"
            >{{ currentCustomer.description }},
          </span>
          <span class="customer-description-placeholder"
            >{{ 'translate.cliente' | translate }} </span
          >{{ currentCustomer.code }}
        </div>
        <div class="customer-address">
          {{ currentCustomer.city }}, {{ currentCustomer.address }}
        </div>
      </div>
      <i nz-icon nzType="caret-down" nzTheme="outline"></i>
    </div>
    <ng-container
      *ngTemplateOutlet="productsCount ? cartTemplate : emptyCartTemplate"
    ></ng-container>
  </div>
</div>

<ng-template #infoPopoverTitleTemplate>
  <div class="info-popover-title">
    <span>{{ userEmail || 'Account' }}</span>
  </div>
</ng-template>

<ng-template #cartTemplate>
  <div class="cart" [routerLink]="['/cart']" routerLinkActive="cart-selected">
    <nz-badge
      [nzOverflowCount]="999"
      [nzCount]="productsCount"
      [nzStyle]="{ backgroundColor: '#52c41a' }"
      ><i nz-icon nzType="shopping-cart" nzTheme="outline"></i
    ></nz-badge>
  </div>
</ng-template>

<ng-template #emptyCartTemplate>
  <div
    class="cart disabled"
    routerLinkActive="cart-selected"
    nz-popover
    [nzContent]="emptyCartPopoverContentTemplate"
    nzPlacement="bottomRight"
  >
    <i nz-icon nzType="shopping-cart" nzTheme="outline"></i>
  </div>
</ng-template>

<ng-template #emptyCartPopoverContentTemplate>
  <div class="empty-cart-popover-content">
    <span>{{ 'translate.carrello_vuoto' | translate }}</span>
  </div>
</ng-template>

<ng-template #infoPopoverContentTemplate>
  <div class="change-customer-container">
    <div
      *ngFor="let customer of otherAvailableCustomers"
      (click)="changeCustomerClicked.emit(customer.code)"
      class="change-customer"
    >
      <div>{{ customer.description }}, {{ customer.code }}</div>
      <div>{{ customer.city }}, {{ customer.address }}</div>

      <nz-divider
        nzType="horizontal"
        class="info-popover-customer-divider"
        nzDashed
      ></nz-divider>
    </div>
  </div>
  <a class="professional-site-link" href="http://www.frascheriprofessionale.it/"
    ><button nz-button nzBlock nzType="primary">
      {{ 'translate.vai_al_sito_professionale' | translate }}
    </button></a
  >

  <button
    class="logout-button"
    (click)="logoutClicked.emit()"
    nz-button
    nzBlock
  >
    Logout
  </button>

  <nz-divider
    nzType="horizontal"
    class="info-popover-language-divider"
    nzDashed
  ></nz-divider>

  <button
    class="change-language-button"
    nz-button
    nzBlock
    nzSize="small"
    (click)="changeLanguageClicked.emit()"
  >
    {{ languageLabel }}
  </button>
</ng-template>

<ng-template #emptyOrdersPopoverContentTemplate>
  <div class="empty-orders-popover-content">
    <span>{{ 'translate.nessun_ordine_presente' | translate }}</span>
  </div>
</ng-template>
