import { ProductsFiltersExtendedModel } from '../../products/types/products-filters.model';
import { OrderModel } from '../types/order.model';
import { OrdersFiltersModel } from '../types/orders-filters.model';

export class OrdersReset {
  static readonly type = '[Orders] Reset';
}

export class OrdersNewOrderFromCart {
  static readonly type = '[Orders] New Order From Cart';
}

export class OrdersSetList {
  static readonly type = '[Orders] Set List';

  constructor(public list: OrderModel[]) {}
}

export class OrdersUpdateList {
  static readonly type = '[Orders] Update List';

  constructor(public list: OrderModel[]) {}
}

export class OrdersCheckNoOrders {
  static readonly type = '[Orders] Check No Orders';
}

export class OrdersUpdateNotification {
  static readonly type = '[Orders] Update Notification';
}

export class OrdersSetSelectedDetailOrder {
  static readonly type = '[Orders] Set Selected Detail Order';

  constructor(public id: string) {}
}

export class OrdersChangeListFilters {
  static readonly type = '[Orders] Change List Filters';

  constructor(public filters: OrdersFiltersModel) {}
}

export class OrdersSetOrderCloneModalOpening {
  static readonly type = '[Orders] Set Order Clone Modal Opening';

  // Will close the modal if it's a falsy value
  constructor(public orderId: string) {}
}

export class OrdersSetOrderCloneCourtesyModalOpening {
  static readonly type = '[Orders] Set Order Clone Courtesy Modal Opening';

  constructor(public value: boolean) {}
}

export class OrdersChangeOrderFilters {
  static readonly type = '[Orders] Change Order Filters';

  constructor(
    public orderId: string,
    public filters: ProductsFiltersExtendedModel
  ) {}
}

export class OrdersClearAllOrdersFilters {
  static readonly type = '[Order] Clear All Order Filters';
}

export class OrdersDownloadPdf {
  static readonly type = '[Order] Download PDF';

  constructor(public order: OrderModel) {}
}
