import { Actions, getActionTypeFromInstance } from '@ngxs/store';
import { Gtag } from 'angular-gtag';
import { filter, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "angular-gtag";
var ErrorLoggingHandler = /** @class */ (function () {
    function ErrorLoggingHandler(actions$, gtag) {
        this.actions$ = actions$;
        this.actions$
            .pipe(filter(function (_a) {
            var status = _a.status;
            return status === "ERRORED" /* Errored */;
        }), tap(function (_a) {
            var action = _a.action, error = _a.error;
            console.error(getActionTypeFromInstance(action), Object.entries(action), error);
            gtag.event(getActionTypeFromInstance(action), {
                event_category: 'errors',
                event_label: JSON.stringify(action) + ' - error: ' + JSON.stringify(error)
            });
        }))
            .subscribe();
        // No need for unsubscribe, as handlers are application wide
    }
    ErrorLoggingHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorLoggingHandler_Factory() { return new ErrorLoggingHandler(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.Gtag)); }, token: ErrorLoggingHandler, providedIn: "root" });
    return ErrorLoggingHandler;
}());
export { ErrorLoggingHandler };
