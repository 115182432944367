<nz-card class="date-for-card">
  <h2>{{ 'translate.data_di_consegna_prevista' | translate }}</h2>
  <nz-date-picker
    nzSize="large"
    nzDropdownClassName="no-ant-calendar-input-wrap"
    nzPlaceHolder="{{ 'translate.selezionare_eventuale_data' | translate }}"
    [nzShowToday]="false"
    nzFormat="d MMMM yyyy"
    [ngModel]="dateFor"
    [nzDisabledDate]="isPastDate"
    (ngModelChange)="dateForChange.emit($event)"
  ></nz-date-picker>
</nz-card>

<nz-card class="customer-order-number-card">
  <h2>{{ 'translate.customer_order_number' | translate }}</h2>
  <input
    nz-input
    placeholder="{{ 'translate.customer_order_number_text' | translate }}"
    [ngModel]="customerOrderNumber"
    (ngModelChange)="customerOrderNumberChange.emit($event)"
  />
</nz-card>

<nz-card class="note-card">
  <h2>{{ 'translate.note' | translate }}</h2>
  <textarea
    nz-input
    rows="4"
    placeholder="{{ 'translate.note_text' | translate }}"
    [ngModel]="note"
    (ngModelChange)="noteChange.emit($event)"
  ></textarea>
</nz-card>

<nz-card class="summary-card">
  <!-- <h3 class="total-heading">
    {{ 'translate.totale_provvisorio' | translate }}
    <span class="total-products-count">
      ({{ productsCount }}
      {{
        (productsCount == 1
          ? 'translate.prodotto_space'
          : 'translate.prodotti_space') | translate
      }}):
    </span>
  </h3>
  <span class="total-price">{{
    totalPrice | currency: 'EUR':'symbol':'1.2-2'
  }}</span> -->
  <button
    (click)="orderSubmitModalOpeningRequest.emit(true)"
    class="order-submit-button"
    nz-button
    nzSize="large"
    nzType="primary"
    nzBlock="true"
    [disabled]="actionsDisabled || productsCount === 0"
  >
    {{ 'translate.procedi_all_ordine' | translate }}
  </button>

  <div class="summary-actions-container">
    <label class="goto-products" [routerLink]="['/products']"
      ><i nz-icon nzType="left" class="goto-products-icon" nzTheme="outline"></i
      ><span class="goto-products-label">{{
        'translate.ritorna_allo_shopping' | translate
      }}</span></label
    >

    <nz-divider
      nzType="vertical"
      *ngIf="showSummaryActionsDividerOnSmallBreakpoint"
      class="summary-actions-divider"
    >
    </nz-divider>

    <label
      class="clear-cart"
      [class.disabled]="actionsDisabled"
      nzPlacement="topRight"
      nz-popconfirm
      nzTitle="{{ 'translate.conferma_rimozione_carrello' | translate }}"
      (nzOnConfirm)="clearCartSubmit.emit()"
      >{{ 'translate.svuota_carrello' | translate }}</label
    >
  </div>
</nz-card>

<nz-modal
  class="order-modal"
  [nzVisible]="isOrderSubmitModalVisible"
  [nzOkLoading]="isOrderLoading"
  [nzTitle]="orderSubmitModalTitleTemplate"
  [nzContent]="orderSubmitModalContentTemplate"
  [nzCancelText]="null"
  [nzClosable]="!isOrderLoading"
  [nzMaskClosable]="!isOrderLoading"
  nzOkText="{{ 'translate.prosegui' | translate }}"
  (nzOnCancel)="orderSubmitModalOpeningRequest.emit(false)"
  (nzOnOk)="orderSubmit.emit()"
>
</nz-modal>

<nz-modal
  class="order-courtesy-modal"
  [nzVisible]="isOrderCourtesyModalVisible"
  [nzTitle]="orderCourtesyModalTitleTemplate"
  [nzContent]="orderCourtesyModalContentTemplate"
  [nzFooter]="orderCourtesyModalFooterTemplate"
  (nzOnCancel)="orderCourtesyModalOpeningRequest.emit(false)"
>
</nz-modal>

<ng-template #orderSubmitModalTitleTemplate>
  <h2 class="order-modal-title">
    {{ 'translate.conferma_ordine' | translate }}
  </h2>
</ng-template>

<ng-template #orderSubmitModalContentTemplate>
  <!-- <h2 class="total-heading in-modal">
    {{ 'translate.totale_provvisorio' | translate }} ({{ productsCount }}
    {{
      (productsCount == 1
        ? 'translate.prodotto_space'
        : 'translate.prodotti_space') | translate
    }}):
  </h2>
  <span class="total-price in-modal">{{
    totalPrice | currency: 'EUR':'symbol':'1.2-2'
  }}</span> -->
  <p>
    {{ 'translate.note_prezzo_finale' | translate }}
  </p>
</ng-template>

<ng-template #orderCourtesyModalTitleTemplate>
  <h2 class="order-modal-title">
    {{ 'translate.ordine_effettuato' | translate }}
  </h2>
</ng-template>

<ng-template #orderCourtesyModalContentTemplate>
  <p>
    {{ 'translate.ordine_preso_in_carico' | translate }}
  </p>
</ng-template>

<ng-template #orderCourtesyModalFooterTemplate>
  <button nz-button nzType="primary" (click)="goToProductsListRequest.emit()">
    {{ 'translate.vai_alla_lista_prodotti' | translate }}
  </button>
  <button nz-button nzType="primary" (click)="goToOrdersRequest.emit()">
    {{ 'translate.vai_ai_miei_ordini' | translate }}
  </button>
</ng-template>
