import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { Auth0UserProfile } from 'auth0-js';
import { Subscription, timer } from 'rxjs';
import { OnlineCheckService } from 'src/app/shared/services/online-check.service';
import { environment } from 'src/environments/environment';
import { AuthenticationData } from '../types/authentication-data';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  get callbackPath(): string {
    return this.AuthenticationBrowserCallbackPathStorage.getItem(
      this.AuthenticationCallbackPathBrowserStorageDataKey
    );
  }

  set callbackPath(value: string) {
    this.AuthenticationBrowserCallbackPathStorage.setItem(
      this.AuthenticationCallbackPathBrowserStorageDataKey,
      value
    );
  }

  get accessToken(): string {
    return this.authenticationData && this.authenticationData.accessToken;
  }

  get authenticationData(): AuthenticationData {
    return JSON.parse(
      this.AuthenticationBrowserStorage.getItem(
        this.AuthenticationBrowserStorageDataKey
      )
    );
  }

  get expiresAt(): number {
    return this.authenticationData && this.authenticationData.expiresAt;
  }

  get idToken(): string {
    return this.authenticationData && this.authenticationData.idToken;
  }

  auth0 = new auth0.WebAuth(environment.authenticationConfig);

  private readonly AuthenticationBrowserStorage: Storage = localStorage;
  private readonly AuthenticationBrowserStorageDataKey = 'authenticationData';
  private readonly AuthenticationBrowserCallbackPathStorage: Storage = sessionStorage;
  private readonly AuthenticationCallbackPathBrowserStorageDataKey =
    'authenticationCallbackPath';

  private readonly refreshSafetyMilliseconds = 60000;
  private refreshSubscription: Subscription;

  private forceRenewTokenRequestsResolves = [];

  constructor(private onlineCheckService: OnlineCheckService) {}

  public async getProfile(): Promise<Auth0UserProfile> {
    if (!this.accessToken) {
      throw new Error('Access Token must exist to fetch profile');
    }

    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(this.accessToken, (err, profile) => {
        if (err) reject(err);
        if (!profile)
          reject(new Error(`auth0.client.userInfo couldn't retrieve profile`));

        resolve(profile as Auth0UserProfile);
      });
    });
  }

  public handleAuthentication(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.localLogin(authResult);
          resolve();
        } else reject(err || new Error('Unknown error in auth0.parseHash'));
      });
    });
  }

  public isAuthenticated(): boolean {
    return this.accessToken && Date.now() < this.expiresAt;
  }

  public login(): void {
    this.callbackPath = window.location.pathname;

    this.auth0.authorize();
  }

  public logout(): void {
    this.AuthenticationBrowserStorage.removeItem(
      this.AuthenticationBrowserStorageDataKey
    );

    this.unscheduleRenewal();

    this.auth0.logout({
      returnTo: window.location.origin
    });
  }

  public forceRenewTokens(): Promise<void> {
    return new Promise(resolve => {
      this.forceRenewTokenRequestsResolves.push(resolve);

      // The first one wins the race condition and will do the real request
      if (this.forceRenewTokenRequestsResolves[0] !== resolve) return;

      this.renewTokens().then(() => {
        this.forceRenewTokenRequestsResolves.forEach(r => r());
        this.forceRenewTokenRequestsResolves = [];
        this.scheduleRenewal();
      });
    });
  }

  public renewTokens(): Promise<void> {
    return new Promise(resolve => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.localLogin(authResult);
          resolve();
        } else {
          const error = err || {
            error: 'Unkown error',
            errorDescription: 'in auth0.checkSession'
          };
          console.error(
            `Could not get a new token (error: ${error.error} - description: ${
              error.errorDescription
            }).`
          );

          this.onlineCheckService
            .getOnlineNotifier$()
            .toPromise()
            .then(_ => this.logout());
        }
      });
    });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) return;
    this.unscheduleRenewal();

    this.refreshSubscription = timer(
      Math.max(1, this.getExpireSafetyTimeRemaining(this.expiresAt))
    ).subscribe(() => {
      this.renewTokens();
      this.scheduleRenewal();
    });
  }

  private getExpireSafetyTimeRemaining(expiresAt: number): number {
    return expiresAt - Date.now() - this.refreshSafetyMilliseconds;
  }

  private localLogin(authResult: auth0.Auth0DecodedHash): void {
    const authenticationData: AuthenticationData = {
      accessToken: authResult.accessToken,
      idToken: authResult.idToken,
      expiresAt: authResult.expiresIn * 1000 + Date.now()
    };

    this.AuthenticationBrowserStorage.setItem(
      this.AuthenticationBrowserStorageDataKey,
      JSON.stringify(authenticationData)
    );
  }

  private unscheduleRenewal() {
    // tslint:disable-next-line: no-unused-expression
    this.refreshSubscription && this.refreshSubscription.unsubscribe();
  }
}
