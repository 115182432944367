import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import { debounceTime, first, skip } from 'rxjs/operators';
import { CartAddProduct } from 'src/app/features/cart/state/cart.actions';
import { CartState } from 'src/app/features/cart/state/cart.state';
import { ProductsBeingAddedCodesMap } from 'src/app/features/cart/types/products-being-added-codes.map';
import { FilesService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import {
  ProductsChangeFilters,
  ProductsSetQuantity
} from '../../state/products.actions';
import { ProductsState } from '../../state/products.state';
import { ProductGroupModel } from '../../types/product-group.model';
import { ProductOverviewModel } from '../../types/product-overview.model';
import { ProductQuantityModel } from '../../types/product-quantity.model';
import { ProductsQuantitiesModel } from '../../types/products-quantities.model';

@Component({
  selector: 'app-products-container',
  templateUrl: './products-container.component.html',
  styleUrls: ['./products-container.component.less']
})
export class ProductsContainerComponent implements OnInit {
  imageBaseUrl = environment.storageConfig.productsImagesMediumUrl;
  dataSheetBaseUrl = environment.storageConfig.productsDataSheetsUrl;

  @Select(ProductsState.listFiltered)
  listFiltered$: Observable<ProductOverviewModel[]>;

  @Select(ProductsState.groups)
  groups$: Observable<ProductGroupModel[]>;

  @Select(ProductsState.quantities)
  quantities$: Observable<ProductsQuantitiesModel>;

  @Select(CartState.productsCount)
  productsCount$: Observable<number>;

  @Select(CartState.quantities)
  cartQuantities$: Observable<ProductsQuantitiesModel>;

  @Select(CartState.productsBeingAddedCodes)
  productsBeingAddedCodes$: Observable<ProductsBeingAddedCodesMap>;
  addProductDisabledCodes$: Observable<ProductsBeingAddedCodesMap>;

  // @Select(CartState.isInCartRowsHttpProcessing)
  // isInCartRowsHttpProcessing: Observable<boolean>;
  // addProductDisabled$: Observable<boolean>;

  @Select(ProductsState.filteringGroupsCodes)
  filteringGroupsCodes$: Observable<string[]>;

  @Select(ProductsState.filteringPartialProductCode)
  filteringPartialProductCode$: Observable<number>;

  @Select(ProductsState.filteringPartialDescription)
  filteringPartialDescription$: Observable<string>;

  constructor(private store: Store, private filesService: FilesService) {}

  ngOnInit(): void {
    // this.addProductDisabled$ = this.isInCartRowsHttpProcessing.pipe(
    //   debounceTime(100)
    // );

    // Take the first immediatly otherwise we don't
    // have an inital state to render.
    // Debounce after that
    this.addProductDisabledCodes$ = merge(
      this.productsBeingAddedCodes$.pipe(first()),
      this.productsBeingAddedCodes$.pipe(
        skip(1),
        debounceTime(100)
      )
    );
  }

  handleGroupsFilterChange(filteringGroupsCodes: string[]): void {
    this.store.dispatch(new ProductsChangeFilters({ filteringGroupsCodes }));
  }

  handleProductCodeFilterChange(filteringPartialProductCode?: number): void {
    this.store.dispatch(
      new ProductsChangeFilters({
        filteringPartialProductCode,
        forceFilteringPartialProductCodeValue: true
      })
    );
  }

  handleDescriptionFilterChange(filteringPartialDescription?: string): void {
    this.store.dispatch(
      new ProductsChangeFilters({
        filteringPartialDescription,
        forceFilteringPartialDescriptionValue: true
      })
    );
  }

  handleQuantityChange(productQuantity: ProductQuantityModel): void {
    this.store.dispatch(new ProductsSetQuantity(productQuantity));
  }

  handleProductAdd(productCode: number): void {
    const quantity = this.store.selectSnapshot(ProductsState.quantities)[
      productCode
    ];

    this.store.dispatch(new CartAddProduct({ productCode, quantity }));
  }
}
