import { RouterState } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApolloQueryResult } from 'apollo-client';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import {
  CartCheckEmptyCart,
  CartReset,
  CartSetCart
} from 'src/app/features/cart/state/cart.actions';
import { CartModel } from 'src/app/features/cart/types/cart.model';
import {
  OrdersCheckNoOrders,
  OrdersReset,
  OrdersSetList
} from 'src/app/features/orders/state/orders.actions';
import {
  ProductsReset,
  ProductsSetGroups,
  ProductsSetList,
  ProductsSetMissingQuantities
} from 'src/app/features/products/state/products.actions';
import {
  CoreGQL,
  CoreQuery,
  CustomersGQL,
  CustomersQuery
} from 'src/app/graphql/graphql';
import { GraphqlSubscriptionsService } from '../services/graphql-subscriptions.service';
import { CustomerModel } from '../types/customer.model';
import {
  CoreChangeCustomer,
  CoreDecrementHttpRequestsInProgressCount,
  CoreIncrementHttpRequestsInProgressCount,
  CoreInitialize,
  CoreRetrieveCustomers,
  CoreSetCurrentCustomerCode,
  CoreSetIsShowingLoading
} from './core.actions';

export interface CoreStateModel {
  availableCustomers: CustomerModel[];
  currentCustomerCode: string;
  userEmail: string;
  httpRequestsInProgressCount: number;
  isShowingLoading: boolean;
  isInitialized: boolean;
  isCustomerChangeInProgress: boolean;
}

const currentCustomerStorage = localStorage;
const currentCustomerKey = 'currentCustomer';

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    availableCustomers: [],
    currentCustomerCode: null,
    userEmail: null,
    httpRequestsInProgressCount: 0,
    isShowingLoading: false,
    isInitialized: false,
    isCustomerChangeInProgress: false
  }
})
export class CoreState {
  constructor(
    private store: Store,
    private coreGQL: CoreGQL,
    private customersGQL: CustomersGQL,
    private graphqlSubscriptionsService: GraphqlSubscriptionsService
  ) {}

  @Selector()
  static httpRequestsInProgressCount({
    httpRequestsInProgressCount
  }: CoreStateModel): number {
    return httpRequestsInProgressCount;
  }

  @Selector()
  static isCustomerChangeInProgress({
    isCustomerChangeInProgress
  }: CoreStateModel): boolean {
    return isCustomerChangeInProgress;
  }

  @Selector()
  static userEmail({ userEmail }: CoreStateModel): string {
    return userEmail;
  }

  @Selector()
  static isShowingLoading({ isShowingLoading }: CoreStateModel): boolean {
    return isShowingLoading;
  }

  @Selector()
  static isInitialized({ isInitialized }: CoreStateModel): boolean {
    return isInitialized;
  }

  @Selector()
  static availableCustomers({
    availableCustomers
  }: CoreStateModel): CustomerModel[] {
    return availableCustomers;
  }

  @Selector()
  static otherAvailableCustomers({
    availableCustomers,
    currentCustomerCode
  }: CoreStateModel): CustomerModel[] {
    return availableCustomers.filter(
      customer => customer.code !== currentCustomerCode
    );
  }

  @Selector()
  static currentCustomerCode({ currentCustomerCode }: CoreStateModel): string {
    return currentCustomerCode;
  }

  @Selector()
  static currentCustomer(state: CoreStateModel): CustomerModel {
    const availableCustomers = CoreState.availableCustomers(state);
    const currentCustomerCode = CoreState.currentCustomerCode(state);
    const currentCustomer = availableCustomers.filter(
      customer => customer.code === currentCustomerCode
    )[0];

    return currentCustomer;
  }

  @Action(CoreSetIsShowingLoading)
  setIsShowingLoading(
    { patchState }: StateContext<CoreStateModel>,
    { value }: CoreSetIsShowingLoading
  ): void {
    patchState({
      isShowingLoading: value
    });
  }

  @Action(CoreIncrementHttpRequestsInProgressCount)
  incrementHttpRequestsInProgressCount({
    patchState,
    getState
  }: StateContext<CoreStateModel>): void {
    const { httpRequestsInProgressCount } = getState();

    patchState({
      httpRequestsInProgressCount: httpRequestsInProgressCount + 1
    });
  }

  @Action(CoreDecrementHttpRequestsInProgressCount)
  decrementHttpRequestsInProgressCount({
    patchState,
    getState
  }: StateContext<CoreStateModel>): void {
    const { httpRequestsInProgressCount } = getState();

    let newCount = httpRequestsInProgressCount - 1;
    if (newCount < 0) {
      console.error(
        `Decrement of Http Request in Progress count was going to set the count to: ${newCount}. Setting it to 0 instead.`
      );
      newCount = 0;
    }

    patchState({
      httpRequestsInProgressCount: newCount
    });
  }

  @Action(CoreInitialize, { cancelUncompleted: true })
  initialize(ctx: StateContext<CoreStateModel>): Observable<any> {
    const { isInitialized } = ctx.getState();
    if (isInitialized) throw new Error(`Core state is already initialized`);

    const currentCustomerCode$ = ctx
      .dispatch(new CoreRetrieveCustomers())
      .pipe(map(_ => ctx.getState().currentCustomerCode));

    return currentCustomerCode$.pipe(
      switchMap(customerCode =>
        this.initializeOtherStates(this.coreGQL.fetch({ customerCode }), ctx)
      ),
      tap(({ user_customer }: CoreQuery) =>
        ctx.patchState({
          userEmail: user_customer[0].email,
          isInitialized: true
        })
      )
    );
  }

  @Action(CoreChangeCustomer, { cancelUncompleted: true })
  changeCustomer(
    ctx: StateContext<CoreStateModel>,
    { customerCode }: CoreChangeCustomer
  ): Observable<any> {
    const currentGlobalState = this.store.snapshot();
    ctx.patchState({ isCustomerChangeInProgress: true });

    return ctx.dispatch(new CoreSetCurrentCustomerCode(customerCode)).pipe(
      switchMap(_ =>
        ctx.dispatch([new CartReset(), new ProductsReset(), new OrdersReset()])
      ),
      switchMap(_ =>
        this.initializeOtherStates(this.coreGQL.fetch({ customerCode }), ctx)
      ),
      switchMap(_ => this.performChecksBasedOnCurrentUrl(ctx)),
      tap(_ => ctx.patchState({ isCustomerChangeInProgress: false })),
      tap(_ =>
        currentCustomerStorage.setItem(currentCustomerKey, customerCode)
      ),
      catchError(error => {
        // Pseudo transactional action
        this.store.reset(currentGlobalState);
        return throwError(error);
      })
    );
  }

  @Action(CoreRetrieveCustomers, { cancelUncompleted: true })
  retrieveCustomers(ctx: StateContext<CoreStateModel>): Observable<any> {
    return this.customersGQL.fetch().pipe(
      map(result => result.data),
      tap(({ customers }: CustomersQuery) =>
        this.setAvailableCustomers(customers, ctx)
      ),
      delayWhen(({ customers }: CustomersQuery) =>
        this.storeCurrentCustomer(customers, ctx)
      )
    );
  }

  @Action(CoreSetCurrentCustomerCode)
  setCurrentCustomerCode(
    { patchState, getState }: StateContext<CoreStateModel>,
    { currentCustomerCode }: CoreSetCurrentCustomerCode
  ): void {
    if (
      // currentCustomerCode &&
      !getState().availableCustomers.some(
        customer => customer.code === currentCustomerCode
      )
    )
      throw new Error(
        `Cannot set current customer code to ${currentCustomerCode}, since it's not in the available customers list`
      );

    patchState({
      currentCustomerCode
    });
  }

  private performChecksBasedOnCurrentUrl(
    ctx: StateContext<CoreStateModel>
  ): Observable<any> {
    const currentUrl = this.store.selectSnapshot(RouterState.url);

    if (currentUrl.endsWith('/cart'))
      return ctx.dispatch(new CartCheckEmptyCart());

    if (currentUrl.endsWith('/orders'))
      return ctx.dispatch(new OrdersCheckNoOrders());

    return of(null);
  }

  private initializeOtherStates(
    coreQueryResult$: Observable<ApolloQueryResult<CoreQuery>>,
    ctx: StateContext<CoreStateModel>
  ): Observable<any> {
    return coreQueryResult$.pipe(
      map(result => result.data),
      delayWhen(({ cart_headers }: CoreQuery) =>
        this.storeCurrentCustomerCart(ctx, cart_headers)
      ),
      delayWhen(({ products_groups }: CoreQuery) =>
        ctx.dispatch(new ProductsSetGroups(products_groups))
      ),
      delayWhen(({ products }: CoreQuery) =>
        ctx.dispatch(new ProductsSetList(products))
      ),
      delayWhen(_ => ctx.dispatch(new ProductsSetMissingQuantities())),
      delayWhen(({ view_orders_headers }: CoreQuery) =>
        ctx.dispatch(new OrdersSetList(view_orders_headers))
      ),
      tap(_ => {
        const customerCode = ctx.getState().currentCustomerCode;
        this.graphqlSubscriptionsService.init(customerCode);
      })
    );
  }

  private storeCurrentCustomerCart(
    ctx: StateContext<CoreStateModel>,
    carts: CartModel[]
  ) {
    return ctx.dispatch(new CartSetCart(carts[0]));
  }

  private setAvailableCustomers(
    availableCustomers: CustomerModel[],
    ctx: StateContext<CoreStateModel>
  ): void {
    const { currentCustomerCode } = ctx.getState();
    if (
      currentCustomerCode &&
      !availableCustomers.some(
        customer => customer.code === currentCustomerCode
      )
    )
      throw new Error(
        // tslint:disable-next-line: max-line-length
        `Cannot set available customers, since the current customer code: ${currentCustomerCode} - it's not in the new available customers list. If you want to change both the current customer and the available customer list, please set the current customer to a falsy value before`
      );

    ctx.patchState({
      availableCustomers
    });
  }

  private storeCurrentCustomer(
    availableCustomers: CustomerModel[],
    ctx: StateContext<CoreStateModel>
  ): Observable<any> {
    const defaultCustomer = availableCustomers[0];
    if (!defaultCustomer)
      throw new Error(
        'CoreResolver: Could not retrieve current customer, since there are no available ones'
      );

    const storageCurrentCustomerCode = currentCustomerStorage.getItem(
      currentCustomerKey
    );

    const storageCurrentCustomerCodeInAvailableCustomers = availableCustomers.some(
      customer => customer.code === storageCurrentCustomerCode
    );

    return ctx.dispatch(
      new CoreSetCurrentCustomerCode(
        (storageCurrentCustomerCodeInAvailableCustomers &&
          storageCurrentCustomerCode) ||
          defaultCustomer.code
      )
    );
  }
}
