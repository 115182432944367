import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import { it_IT, NzConfig, } from 'ng-zorro-antd';
import { TranslateManagerService } from '../shared/services/translate-manager.service';
registerLocaleData(it);
registerLocaleData(en);
var ngZorroConfig = {
    message: { nzMaxStack: 1, nzDuration: 1800 },
    notification: { nzMaxStack: 2, nzDuration: 2200 },
};
var ɵ0 = it_IT, ɵ1 = ngZorroConfig, ɵ2 = function () { return function () { }; }, ɵ3 = function () { return function () { }; }, ɵ4 = function () { return function () { }; }, ɵ5 = function () { return function () { }; }, ɵ6 = function () { return function () { }; }, ɵ7 = function () { return function () { }; }, ɵ8 = function () { return function () { }; }, ɵ9 = function (translateManagerService) { return function () {
    return translateManagerService.setLanguage().toPromise();
}; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
