<nz-card>
  <div class="section__header">
    <h1 class="section__title">{{ 'translate.prodotti' | translate }}</h1>

    <app-products-cart-cta [enabled]="!!(productsCount$ | async)"></app-products-cart-cta>

  </div>

  <!-- Please notice that listFiltered is already a shared observable by ngxs, 
  should we extend the observable with pipe, adding side effects, 
  it will be our responsibility to cause the side effects only once -->
  <app-products-filters [list]="listFiltered$ | async" [groups]="groups$ | async"
    [filteringGroupsCodes]="filteringGroupsCodes$ | async"
    [filteringPartialProductCode]="filteringPartialProductCode$ | async"
    [filteringPartialDescription]="filteringPartialDescription$ | async" [haveSmallDescriptionFilter]="true"
    (productCodeFilterChange)="handleProductCodeFilterChange($event)"
    (groupsFilterChange)="handleGroupsFilterChange($event)"
    (descriptionFilterChange)="handleDescriptionFilterChange($event)"></app-products-filters>

  <app-products-list [imageBaseUrl]="imageBaseUrl" [dataSheetBaseUrl]="dataSheetBaseUrl" [list]="listFiltered$ | async"
    [quantities]="quantities$ | async" [cartQuantities]="cartQuantities$ | async"
    [addProductDisabledCodes]="addProductDisabledCodes$ | async" (quantityChange)="handleQuantityChange($event)"
    (productAdd)="handleProductAdd($event)"></app-products-list>
</nz-card>