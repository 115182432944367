import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  map,
  takeWhile,
  tap
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OnlineCheckService {
  constructor(private httpClient: HttpClient) {}

  getOnlineNotifier$(): Observable<boolean> {
    const onlineCheckHttpRequest$ = this.httpClient
      .get(`/assets/online-check.json?${Date.now()}`)
      .pipe(
        map(_ => true),
        catchError(_ => of(false))
      );

    let delayValue = 50;

    return interval(50).pipe(
      // Every call gets an increasing (to a max of 1800 ms) flat delay + a random small delay
      concatMap(i => of(i).pipe(delay(delayValue + Math.random() * 100))),
      tap(_ => (delayValue = Math.min(delayValue * 2, 1800))),
      concatMap(_ => onlineCheckHttpRequest$),
      takeWhile(succeed => !succeed)
    );
  }
}
